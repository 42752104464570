import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import PieChart from "components/common/PieChart";
import { CryptoHolding, CryptoPortfolio } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch } from "hooks/redux";

import AllocationList from "./AllocationList";

import styles from "./styles.module.scss";

const CryptoAllocationPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [holdings, setHoldings] = useState<CryptoHolding[]>([]);

  useEffect(() => {
    ampli.cryptoScreenAllocation();

    dispatch(setCurrentScreen(cryptoScreenNames.portfolioAllocation));
  }, []);

  useEffect(() => {
    const getPortfolioData = async () => {
      try {
        const { data } = await API.get<CryptoPortfolio>(
          cryptoService.portfolio
        );

        setHoldings(data.holdings);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getPortfolioData();
  }, []);

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  if (error || isEmpty(holdings))
    return (
      <div className={styles.allocationPageContainer}>
        <PageHeader
          icon={ArrowLeft}
          title={"Distribución"}
          onClick={() => navigate(-1)}
        />
        <ErrorPage errorMessage="En este momento no es posible cargar la página." />
      </div>
    );

  return (
    <div className={styles.allocationPageContainer}>
      <PageHeader
        icon={ArrowLeft}
        title={"Distribución"}
        onClick={() => navigate(-1)}
      />
      {holdings && (
        <>
          <PieChart
            size={128}
            thickness={14}
            hasCenter
            holdings={holdings}
            innerCircleColor="var(--background-2)"
          />
          <AllocationList holdings={holdings} />
        </>
      )}
    </div>
  );
};

export default CryptoAllocationPage;

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { Clock4 } from "lucide-react";
import px2rem from "utils/px2rem";
import { useCryptoCable } from "context/CryptoCableProvider";
import LoadingSpinner from "components/common/LoadingSpinner";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

const CreationProgress: React.FC = () => {
  const { goToHome } = useCryptoCable();

  const [isLoading, setisLoading] = useState<boolean>(true);

  useEffect(() => {
    ampli.cclCryptoDelayed();
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      setisLoading(false);
    }, 3000);
  }, []);

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "delayed" });
    return goToHome();
  };

  if (isLoading) {
    return (
      <>
        <div className={styles.loadingWrapper}>
          <LoadingSpinner size={px2rem(150)} variant={ThemeVariants.Crypto} />
          <Heading
            component="h1"
            color="var(--slate900)"
            variant={HeadingVariants.RegularTitle}
            className={styles.text}
          >
            Revisando tus datos...
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Esto puede tardar unos segundos.
          </Text>
        </div>
      </>
    );
  }

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Crypto}
      navigationHeaderProps={{
        title: "Creación en proceso",
        onClick: onClose,
      }}
      title="Pronto vas a poder recibir tus dólares del exterior."
      subtitle="Estamos procesando los datos para la creación de tu cuenta. Podés volver en unos minutos cuando haya finalizado."
      icon={Clock4}
      badgeIconColor="var(--orange900)"
      badgeColor={"var(--orange300)"}
      primaryButtonProps={{
        children: "Volver más tarde",
        onClick: onClose,
      }}
    />
  );
};

export default CreationProgress;

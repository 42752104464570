import { CDN_URL } from "constants/index";

import { useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { BannersService } from "apis/services";
import Heading, { HeadingVariants } from "components/common//Heading";
import QuietButton, { QuietButtonSizes } from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface CocosTagSignProps {
  onClick: () => void;
  withHide?: boolean;
  animated?: boolean;
}

const CocosTagSignBanner: React.FC<CocosTagSignProps> = ({
  onClick,
  animated,
  withHide = false,
}) => {
  const user = useAppSelector(getLoggedUser);

  const [isHidden, setIsHidden] = useState<boolean>(false);

  const hasTag = Object.prototype.hasOwnProperty.call(user, "tag");

  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const getBackgroundImage = () => {
    if (darkMode) {
      return `${CDN_URL}/reactions/sign-dark.png`;
    }
    return `${CDN_URL}/reactions/sign.png`;
  };

  const trackingEventClick = (action: string) => {
    ampli.cryptoBannerClicked({
      referral: "crypto",
      tapped_button: action,
      type: "tag",
    });
  };

  const hideBanner = async () => {
    trackingEventClick("dismiss");
    setIsHidden(true);
    const date_supressed = dayjs().add(2, "day");

    await API.put<any>(`${BannersService.banners}/cocos_pay_welcome`, {
      supressUntil: date_supressed,
    });
  };

  const onClickBanner = () => {
    trackingEventClick("open");
    onClick();
  };

  if (!hasTag || user?.tag || isHidden) return null;

  return (
    <div
      className={classNames(styles.cocosTagSignContainer, {
        [styles.animated]: animated,
      })}
    >
      <div
        className={styles.cocosTagSign}
        style={{
          backgroundImage: `url(${getBackgroundImage()})`,
        }}
      >
        <div className={styles.text}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h2"
            color="var(--slate900)"
          >
            Conocé cocostag
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Enviá y recibí crypto de forma personalizada y sin costo.
          </Text>
        </div>

        <div className={styles.buttonsWrapper}>
          <QuietButton
            variant={ThemeVariants.Crypto}
            size={QuietButtonSizes.Small}
            onClick={onClickBanner}
          >
            Quiero mi cocostag
          </QuietButton>
          {withHide && (
            <Text
              variant={TextVariants.SemiboldTextS}
              color="var(--purple800)"
              onClick={hideBanner}
            >
              Ahora no
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default CocosTagSignBanner;

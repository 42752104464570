import { Cryptos, Network } from "constants/cryptos";

import { UIModalSlider } from "components/common/modal-slider";
import { MovementStatus } from "interfaces/api-responses";
import { Operations } from "interfaces/crypto/enums";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import { useSearchParams } from "react-router-dom";
import LoadingSpinner from "components/common/LoadingSpinner";

import SheetHeader from "./SheetHeader";
import SheetIcons from "./SheetIcons";
import SheetAmount from "./SheetAmount";
import SheetContent from "./SheetContent";

import styles from "./styles.module.scss";

export enum TransferChannels {
  TAG = "TAG",
  BLOCKCHAIN = "BLOCKCHAIN",
}

type BuyAndSellDetail = {
  quoteQuantity: number;
  quoteTicker: Currencies.ARS;
  operation: Operations.BUY | Operations.SELL;
};

type SwapDetail = {
  quoteQuantity: number;
  quoteTicker: Cryptos;
  operation: Operations.SWAP;
};

type SendAndReceiveDetail = {
  txHash: string | null;
  txHashUrl: string | null;
  network: Network | "Offchain";
  operation: Operations.SEND | Operations.RECEIVE;
  channel: TransferChannels;
  address?: string;
};

export type ActivityDetailSheetProps = {
  createdAt: string;
  idOrder: string;
  isLoading: boolean;
  fee: number | null;
  baseTicker: Cryptos;
  baseQuantity: number;
  status: MovementStatus;
} & (BuyAndSellDetail | SwapDetail | SendAndReceiveDetail);

const ActivityDetailSheet: React.FC<ActivityDetailSheetProps> = ({
  ...props
}) => {
  const { operation, isLoading } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const idOrder = searchParams.get("id");
  const onClose = () => {
    setSearchParams({}, { replace: true });
  };

  return (
    <UIModalSlider
      open={!!idOrder}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      {isLoading && <LoadingSpinner variant={ThemeVariants.Crypto} />}
      {!isLoading && (
        <div className={styles.contentWrapper}>
          <SheetHeader operation={operation} onClose={onClose} />
          <SheetIcons {...props} />
          <SheetAmount {...props} />
          <SheetContent {...props} />
          {/* <DownloadButton /> */}
        </div>
      )}
    </UIModalSlider>
  );
};

export default ActivityDetailSheet;

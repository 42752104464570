import { useNavigate } from "react-router-dom";
import BasicWrapper from "components/common/BasicWrapper";
import ListSection from "components/page-cocos-card-settings/ListSection";

export const menuOptions = [
  {
    title: "¿Cómo funciona mi tarjeta?",
    route: "",
  },
  {
    title: "¿Cómo funcionan mis límites?",
    route: "",
  },
  {
    title: "Otras preguntas frecuentes",
    route: "",
  },
];

const PageHelp: React.FC = () => {
  const navigate = useNavigate();

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Ayuda",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  return (
    <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
      <ListSection menuOptions={menuOptions} subtitle="Preguntas frecuentes" />
    </BasicWrapper>
  );
};

export default PageHelp;

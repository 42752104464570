import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface Config2FAStepProps {
  step: number;
  title: string;
  subtitle: string | JSX.Element;
}

const Config2FAStep: React.FC<Config2FAStepProps> = ({
  step,
  title,
  subtitle,
}) => {
  return (
    <div className={styles.configStepContainer}>
      <div className={styles.step}>
        <Text variant={TextVariants.SemiboldTextS} color="var(--purple900)">
          {step}
        </Text>
      </div>
      <div className={styles.stepInfo}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {title}
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export default Config2FAStep;

import { ChevronRight, LucideIcon } from "lucide-react";
import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface RowSectionProps {
  icon?: LucideIcon;
  title: string;
  isWarning?: boolean;
}

const ProfileRow: React.FC<RowSectionProps> = ({
  icon: Icon,
  title,
  isWarning,
}) => {
  return (
    <div className={styles.profileRowContainer}>
      <div className={styles.rowData}>
        {Icon && (
          <div
            className={classNames(styles.icon, { [styles.warning]: isWarning })}
          >
            <Icon size={px2rem(16)} color="var(--background-1)" />
          </div>
        )}
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {title}
        </Text>
      </div>
      <ChevronRight size={px2rem(16)} color="var(--slate800)" />
    </div>
  );
};

export default ProfileRow;

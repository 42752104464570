import { EMAIL_VALIDATION } from "constants/validation";

import { useEffect } from "react";

import { ampli } from "ampli";
import { Formik, FormikHelpers } from "formik";
import { supabase } from "utils/supabase";
import * as Yup from "yup";
import BasicWrapper from "components/common/BasicWrapper";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import InputField from "components/common/input/InputField";
import { CircularProgress } from "@mui/material";

interface Values {
  email: string;
}

interface SendResetPasswordEmailProps {
  onSuccess: () => void;
  setError: (err: string) => void;
  setEmail: (email: string) => void;
}

const EMAIL_VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().matches(EMAIL_VALIDATION).required(),
});

export const getPrimaryButtonText = (state: boolean, text: string) => {
  return state ? <CircularProgress size={16} /> : text;
};

const SendResetPasswordEmail = ({
  onSuccess,
  setError,
  setEmail,
}: SendResetPasswordEmailProps) => {
  const navigate = useNavigate();

  const title = "Recuperá tu contraseña";
  const subtitle =
    "Ingresá el email asociado con tu cuenta. Te enviaremos instrucciones a esa dirección para recuperar tu contraseña.";
  const buttonText = "Recuperar contraseña";

  const handleOnClose = () => {
    navigate(mainRoutes.login);
  };

  const handleOnSubmit = async (
    values: Values,
    { setSubmitting }: FormikHelpers<Values>
  ) => {
    setSubmitting(true);

    try {
      const { email } = values;

      const { error } = await supabase.auth.resetPasswordForEmail(email);

      if (!error) {
        setEmail(email);
        onSuccess();
      } else {
        console.error("Error al enviar el mail:", error);
        setError("Error al enviar el mail. Por favor, inténtelo nuevamente.");
      }
    } catch (err) {
      setError("Ocurrió un error inesperado. Por favor, inténtelo nuevamente.");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    ampli.forgotPasswordScreenViewed();
  }, []);

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={EMAIL_VALIDATION_SCHEMA}
      onSubmit={handleOnSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values,
        isValid,
        dirty,
      }) => (
        <BasicWrapper
          navigationHeaderProps={{
            onClick: handleOnClose,
            withCloseIcon: false,
          }}
          title={title}
          subtitle={subtitle}
          primaryButtonProps={{
            onClick: () => handleSubmit(),
            type: "submit",
            children: getPrimaryButtonText(isSubmitting, buttonText),
            disabled: !isValid || !dirty || isSubmitting,
          }}
        >
          <form onSubmit={handleSubmit}>
            <InputField
              label="Email"
              name="email"
              id="email"
              placeholder="Ingresá tu email"
              type="email"
              onChange={handleChange}
              value={values.email}
            />
          </form>
        </BasicWrapper>
      )}
    </Formik>
  );
};

export default SendResetPasswordEmail;

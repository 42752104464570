import { Cryptos } from "constants/cryptos";

import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface BalanceProps {
  withTicker: boolean;
  balance: number | string;
  currency?: Cryptos | Currencies.ARS;
}

const Balance: React.FC<BalanceProps> = ({ balance, withTicker, currency }) => (
  <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
    Balance:{" "}
    {!withTicker && (
      <Text
        variant={TextVariants.RegularTextS}
        color="var(--slate800)"
        component="span"
      >
        $
      </Text>
    )}
    <Text
      variant={TextVariants.SemiboldUpperValueS}
      color="var(--slate800)"
      component="span"
    >
      {balance}
    </Text>
    {withTicker && (
      <Text
        className={styles.currency}
        variant={TextVariants.RegularUpperValueS}
        component="span"
        color="var(--slate800)"
      >
        {currency}
      </Text>
    )}
  </Text>
);
export default Balance;

import { Cryptos, Network, getCrypto } from "constants/cryptos";

import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import { CircleDollarSign, Network as NetworkIcon, User2 } from "lucide-react";
import { Currencies } from "interfaces/wallet";
import { CryptoTag } from "interfaces/api-responses";
import { SendMethod } from "context/CryptoSendProvider";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import { ThemeVariants } from "interfaces/theme";

import Select from "./Select";

import styles from "./styles.module.scss";

type SelectorsProps = {
  ticker: Cryptos | Currencies.ARS;
  onClickCrypto: () => void;
} & (NetworkSelectionProps | CocosTagSelectionProps);

interface NetworkSelectionProps {
  sendMethod: SendMethod.BLOCKCHAIN;
  network?: Network;
  onClickNetwork: () => void;
}

interface CocosTagSelectionProps {
  sendMethod: SendMethod.COCOSTAG;
  tag?: CryptoTag;
  onClickTag: () => void;
}

const Selector: React.FC<SelectorsProps> = ({
  ticker,
  onClickCrypto,
  ...props
}) => {
  const isBlockain = props.sendMethod === SendMethod.BLOCKCHAIN;

  const BlockchainIcon = isBlockain ? props.network?.icon : null;

  const { icon: Icon } = getCrypto(ticker);
  return (
    <div className={styles.netCurrencyContainer}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h2"
        color="var(--slate800)"
        className={styles.title}
      >
        Seleccionaste
      </Heading>
      <div className={styles.selectWrapper}>
        <Select icon={CircleDollarSign} title="Moneda" onClick={onClickCrypto}>
          <Text
            variant={TextVariants.RegularTextS}
            color="var(--slate900)"
            className={styles.selectedData}
          >
            <Icon size={px2rem(20)} /> {ticker}
          </Text>
        </Select>
        <Divider className={styles.divider} />
        {isBlockain && (
          <Select icon={NetworkIcon} title="Red" onClick={props.onClickNetwork}>
            <Text
              variant={TextVariants.RegularTextS}
              color="var(--slate900)"
              className={styles.selectedData}
            >
              {BlockchainIcon && (
                <>
                  <BlockchainIcon size={px2rem(20)} /> {props.network?.name}
                </>
              )}
            </Text>
          </Select>
        )}
        {!isBlockain && (
          <Select icon={User2} title="Cocostag" onClick={props.onClickTag}>
            <Text
              variant={TextVariants.RegularTextS}
              color="var(--slate900)"
              className={styles.selectedData}
            >
              {props.tag?.accountDisplayName}{" "}
              <NumbersBadge
                number={`${props.tag?.name} 🥥`}
                variant={ThemeVariants.Crypto}
                className={styles.badgeText}
              />
            </Text>
          </Select>
        )}
      </div>
    </div>
  );
};

export default Selector;

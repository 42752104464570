import FilledButton from "components/common/FilledButton";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface BottomSheetProps {
  children: JSX.Element | React.ReactNode;
  title?: string;
  buttonTitle?: JSX.Element | React.ReactNode;
  onClick?: () => void;
  outsideClikable?: boolean;
  onClose?: () => void;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  title,
  buttonTitle,
  onClick,
  outsideClikable = true,
  onClose,
}) => {
  const closeSheet = () => {
    if (!outsideClikable) return;
    onClose && onClose();
  };

  return (
    <div className={styles.bottomSheetContainer}>
      <div className={styles.backgroundContainer} onClick={closeSheet} />
      <div className={styles.bottomSheet}>
        {title && (
          <Text
            variant={TextVariants.RegularText}
            color="var(--slate900)"
            className={styles.title}
          >
            {title}
          </Text>
        )}
        {children}
        {buttonTitle && (
          <FilledButton onClick={onClick}>{buttonTitle}</FilledButton>
        )}
      </div>
    </div>
  );
};

export default BottomSheet;

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface IconBadge {
  children: JSX.Element | React.ReactNode;
  backgroundColor?: string;
  isSmall?: boolean;
  className?: string;
  variant?: ThemeVariants;
}

const IconBadge = ({
  children,
  backgroundColor,
  isSmall,
  variant = ThemeVariants.Capital,
  className,
}: IconBadge) => {
  const containerStyles = classNames(
    styles.iconBadgeContainer,
    styles[variant],
    {
      [styles.small]: isSmall,
    }
  );

  return (
    <div
      className={classNames(containerStyles, className)}
      style={{ backgroundColor }}
    >
      {children}
    </div>
  );
};

export default IconBadge;

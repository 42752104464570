import { Crypto, Cryptos, getCrypto } from "constants/cryptos";

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface SendAssetProps {
  icon: React.FC<IconProps>;
  name: string;
  ticker: Cryptos | Currencies.ARS | string;
  price?: number;
  variation?: number;
  onClick?: (asset: Crypto) => void;
  isPerformance?: boolean;
}

const MIN_FOR_TWO_DECIMALS = 0.05;

const SendAsset: React.FC<SendAssetProps> = ({
  icon: Icon,
  name,
  ticker,
  price,
  onClick,
}) => {
  const decimals = price && price < MIN_FOR_TWO_DECIMALS ? 8 : 2;

  const onClickAsset = () => {
    const asset = getCrypto(ticker);

    onClick && onClick(asset);
  };

  return (
    <div className={styles.assetRowContainer} onClick={onClickAsset}>
      <div className={styles.asset}>
        <Icon size={px2rem(24)} />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {name}
        </Text>
      </div>
      {price && (
        <div className={styles.price}>
          <Text
            variant={TextVariants.SemiboldUpperValue}
            color="var(--slate900)"
          >
            {formatToCurrency(price, false, false, decimals)}
          </Text>

          <Text
            variant={TextVariants.RegularUpperValue}
            color="var(--slate800)"
          >
            {ticker}
          </Text>
        </div>
      )}
    </div>
  );
};

export default SendAsset;

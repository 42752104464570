import { COPY_MESSAGE_TIME } from "constants/index";

import { useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import { ThemeVariants } from "interfaces/theme";
import IconButton, { IconButtonSizes } from "components/common/IconButton";
import Text, { TextVariants } from "components/common/Text";
import { CheckCheck, Copy } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

interface Config2FAPasswordProps {
  enrollData: AuthMFAEnrollResponse;
}

const Config2FAPassword: React.FC<Config2FAPasswordProps> = ({
  enrollData,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const password = enrollData.data?.totp.secret;

  const onClickCopy = async () => {
    trackAction(
      `${cryptoScreenNames.faConfig} - Click Copy ID`,
      undefined,
      Projects.CRYPTO
    );

    try {
      await Clipboard.write({ string: password ?? "" });
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.config2FAPasswordContainer}>
      <div className={styles.info}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Clave de configuración
        </Text>
        <Text
          className={styles.password}
          variant={TextVariants.RegularUpperTextS}
          color="var(--slate800)"
        >
          {password}
        </Text>
      </div>
      <IconButton
        variant={ThemeVariants.Crypto}
        size={IconButtonSizes.Large}
        icon={copied ? CheckCheck : Copy}
        onClick={onClickCopy}
      />
    </div>
  );
};

export default Config2FAPassword;

import { SVGProps } from "react";

interface RightArrowIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const RightArrowIcon: React.FC<RightArrowIconProps> = ({
  size = 16,
  color = "white",
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M3.3335 8.00004H12.6668M12.6668 8.00004L8.00016 3.33337M12.6668 8.00004L8.00016 12.6667"
      stroke={color}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightArrowIcon;

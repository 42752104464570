import { Preferences } from "@capacitor/preferences";
import { ISecureStorage } from "interfaces/secureStorage";

import { trackAction } from "./amplitude";

class SecureStorage implements ISecureStorage {
  async hasKey(key: string) {
    try {
      const { keys } = await Preferences.keys();
      return keys.includes(key);
    } catch (error: any) {
      trackAction("signin_error", {
        type: "Error on secureStorage.hasKey",
        email: "UNKNOWN",
        error: JSON.stringify(error),
      });
      return false;
    }
  }
  async remove(key: string) {
    try {
      await Preferences.remove({
        key,
      });
    } catch (error: any) {
      trackAction("signin_error", {
        type: "Error on secureStorage.remove",
        email: "UNKNOWN",
        error: JSON.stringify(error),
      });
    }
  }
  async get(key: string) {
    const { value } = await Preferences.get({
      key,
    });
    if (!value) {
      throw new Error(`The key ${key} does not exist`);
    }
    return value;
  }
  async set(key: string, value: string) {
    try {
      await Preferences.set({
        key,
        value,
      });
    } catch (error: any) {
      trackAction("signin_error", {
        type: "Error on secureStorage.set",
        email: "UNKNOWN",
        error: JSON.stringify(error),
      });
    }
  }
}
export default new SecureStorage();

import { useState } from "react";

import HiddenValue from "components/common/HiddenValue";
import Text, { TextVariants } from "components/common/Text";
import { Eye, EyeOff } from "lucide-react";
import { formatPercentage, formatToCurrency, getCurrencySymbol } from "utils";
import px2rem from "utils/px2rem";
import classNames from "classnames";
import { Currencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

export enum AvailableLabelVariants {
  Crypto = "Crypto",
  Capital = "Capital",
  Pay = "Pay",
}

interface AvailableLabelProps {
  available: number;
  withHideButton?: boolean;
  currency?: Currencies;
  variant?: AvailableLabelVariants;
  hasPercentage?: boolean;
}

const AvailableLabel: React.FC<AvailableLabelProps> = ({
  available,
  withHideButton = true,
  currency = Currencies.ARS,
  variant = AvailableLabelVariants.Crypto,
  hasPercentage = false,
}) => {
  const [showAvailable, setShowAvailable] = useState<boolean>(true);
  const availableToString =
    formatToCurrency(available, false, false, 2) || "0,00";

  const availableSplitted = hasPercentage
    ? formatPercentage(available).split(",")
    : availableToString.split(",");

  const onClickButton = () => {
    setShowAvailable(!showAvailable);
  };

  const availableLength =
    availableSplitted[0].length + availableSplitted[1].length;

  const Icon = showAvailable ? Eye : EyeOff;
  const percentageColor = available > 0 ? "var(--green800)" : "var(--red800)";

  return (
    <div className={styles.divContainer}>
      {showAvailable &&
        (hasPercentage ? (
          <Text
            variant={TextVariants.SemiboldTextXL}
            className={styles.availablePercentage}
            color={percentageColor}
          >
            {formatPercentage(available)}
          </Text>
        ) : (
          <Text
            variant={TextVariants.SemiboldText}
            className={`${styles.availableWrapper} ${styles.regularWeight}`}
          >
            <Text
              variant={TextVariants.RegularText}
              className={`${styles.regularWeight} ${styles.currency}`}
              component="span"
              color="var(--slate800)"
            >
              {getCurrencySymbol(currency)}
            </Text>
            <Text
              variant={TextVariants.SemiboldText}
              className={styles.integerPart}
              component="span"
              color="var(--slate900)"
            >
              {availableSplitted[0]},
            </Text>
            <Text
              variant={TextVariants.RegularText}
              className={styles.regularWeight}
              component="span"
              color="var(--slate800)"
            >
              {availableSplitted[1]}
            </Text>
          </Text>
        ))}
      {!showAvailable && (
        <Text
          variant={TextVariants.SemiboldText}
          className={`${styles.availableWrapper} ${styles.hidden} ${styles.regularWeight}`}
          color="var(--slate800)"
        >
          {!hasPercentage && (
            <Text
              variant={TextVariants.RegularText}
              className={`${styles.regularWeight} ${styles.currency}`}
              component="span"
              color="var(--slate800)"
            >
              {getCurrencySymbol(currency)}
            </Text>
          )}
          <HiddenValue
            size={px2rem(16)}
            color="var(--slate900)"
            count={availableLength}
          />
          {hasPercentage && (
            <Text
              variant={TextVariants.SemiboldTextXL}
              className={styles.availablePercentage}
              component="span"
              color="var(--slate800)"
            >
              %
            </Text>
          )}
        </Text>
      )}
      {withHideButton && (
        <button onClick={onClickButton}>
          <Icon
            size={px2rem(24)}
            className={classNames(styles.eyeIcon, styles[variant])}
          />
        </button>
      )}
    </div>
  );
};

export default AvailableLabel;

import { Crypto, getCrypto } from "constants/cryptos";

import SendAsset from "components/page-crypto-send/SendAsset";
import { CryptoHolding } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface AssetListProps {
  assets: CryptoHolding[];
  onClickAsset?: (asset: Crypto) => void;
}

const AssetList: React.FC<AssetListProps> = ({ assets, onClickAsset }) => {
  return (
    <div className={styles.wrapper}>
      {assets.map(({ ticker, quantity }) => {
        if (ticker === Currencies.ARS) return null;
        const { icon, name, ticker: crypto } = getCrypto(ticker);

        return (
          <SendAsset
            icon={icon}
            name={name}
            ticker={crypto}
            price={quantity}
            key={ticker}
            onClick={onClickAsset}
          />
        );
      })}
    </div>
  );
};

export default AssetList;

import { IconProps } from "assets/icons/BTC";

const POLYGONIcon: React.FC<IconProps> = ({ size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#8247E5"
      />
      <path
        d="M15.9721 9.43283C15.6843 9.26539 15.3102 9.26539 14.9936 9.43283L12.7485 10.7165L11.2231 11.5538L8.97807 12.8375C8.69023 13.0049 8.31608 13.0049 7.99946 12.8375L6.21495 11.8328C5.92714 11.6654 5.72567 11.3584 5.72567 11.0235V9.04213C5.72567 8.70725 5.89836 8.40027 6.21495 8.23283L7.97068 7.25607C8.25849 7.08864 8.63267 7.08864 8.94929 7.25607L10.705 8.23283C10.9928 8.40027 11.1943 8.70725 11.1943 9.04213V10.3258L12.7198 9.46072V8.177C12.7198 7.84213 12.5471 7.53514 12.2305 7.36771L8.97807 5.52585C8.69023 5.35841 8.31608 5.35841 7.99946 5.52585L4.6895 7.36771C4.37288 7.53514 4.2002 7.84213 4.2002 8.177V11.8886C4.2002 12.2235 4.37288 12.5305 4.6895 12.6979L7.99946 14.5398C8.2873 14.7073 8.66145 14.7073 8.97807 14.5398L11.2231 13.284L12.7485 12.4189L14.9936 11.1631C15.2814 10.9956 15.6556 10.9956 15.9721 11.1631L17.7279 12.1398C18.0157 12.3073 18.2172 12.6142 18.2172 12.9491V14.9305C18.2172 15.2654 18.0445 15.5724 17.7279 15.7398L15.9721 16.7445C15.6843 16.9119 15.3102 16.9119 14.9936 16.7445L13.2378 15.7677C12.95 15.6003 12.7485 15.2933 12.7485 14.9584V13.6747L11.2231 14.5398V15.8235C11.2231 16.1584 11.3958 16.4654 11.7124 16.6328L15.0223 18.4747C15.3102 18.6421 15.6843 18.6421 16.0009 18.4747L19.3109 16.6328C19.5987 16.4654 19.8002 16.1584 19.8002 15.8235V12.1119C19.8002 11.777 19.6275 11.47 19.3109 11.3026L15.9721 9.43283Z"
        fill="white"
      />
    </svg>
  );
};

export default POLYGONIcon;

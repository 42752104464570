import AvailableLabel from "components/common/AvailableLabel";

import styles from "./styles.module.scss";

interface LimitUsedProps {
  used: number;
  percentage: number;
}

const LimitUsed: React.FC<LimitUsedProps> = ({ used, percentage }) => {
  return (
    <div className={styles.LimitUsedContainer}>
      <AvailableLabel withHideButton={false} available={used} />
      <div
        className={styles.usedBar}
        style={{
          backgroundColor: "var(--purple800)",
          background: `linear-gradient(90deg, var(--purple800) ${
            percentage * 100
          }%, var(--slate200) 0%, var(--slate200) 100%)`,
        }}
      />
    </div>
  );
};

export default LimitUsed;

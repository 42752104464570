import { IconProps } from "../BTC";

const ARGFlag: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    {...props}
    style={{ borderRadius: "50%" }}
  >
    <g clipPath="url(#clip0_2028_14639)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="white"
      />
      <path
        d="M12.0003 0C7.24279 0 3.13204 2.76867 1.19104 6.78263H22.8096C20.8686 2.76867 16.7578 0 12.0003 0Z"
        fill="#338AF3"
      />
      <path
        d="M12.0003 24C16.7578 24 20.8686 21.2314 22.8096 17.2174H1.19104C3.13204 21.2314 7.24279 24 12.0003 24Z"
        fill="#338AF3"
      />
      <path
        d="M15.5866 12L14.1211 12.6894L14.9015 14.1087L13.3101 13.8043L13.1084 15.4119L12 14.2295L10.8915 15.4119L10.6899 13.8043L9.09845 14.1087L9.87883 12.6894L8.41333 12L9.87888 11.3106L9.09845 9.89135L10.6898 10.1958L10.8915 8.58813L12 9.77051L13.1085 8.58813L13.3101 10.1958L14.9015 9.89135L14.1211 11.3107L15.5866 12Z"
        fill="#FFDA44"
      />
    </g>
    <defs>
      <clipPath id="clip0_2028_14639">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ARGFlag;

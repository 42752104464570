import Text, { TextVariants } from "components/common/Text";
import { Operations, operationsLabels } from "interfaces/crypto/enums";
import { ArrowLeft, Share } from "lucide-react";

import styles from "./styles.module.scss";

interface SheetHeaderProps {
  operation: Operations;
  onClose: () => void;
}

const SheetHeader: React.FC<SheetHeaderProps> = ({ operation, onClose }) => (
  <div className={styles.sheetHeaderWrapper}>
    <ArrowLeft color="var(--purple900)" onClick={onClose} />
    <Text variant={TextVariants.RegularText} color="var(--slate900)">
      {operationsLabels[operation]}
    </Text>
    <Share color="var(--purple900)" style={{ opacity: 0 }} />
  </div>
);

export default SheetHeader;

import { IconProps } from "assets/icons/BTC";
import Variation from "components/common/CurrentVariation";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface DailyReturnCardProps {
  icon: React.FC<IconProps>;
  variation: number;
  name: string;
  short_name: string;
}

const DailyReturnCard: React.FC<DailyReturnCardProps> = ({
  icon: Icon,
  variation,
  name,
  short_name,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.divContainer}
      onClick={() => navigate(`${CryptoRoutes.MARKET}/${short_name}`)}
    >
      <div className={styles.header}>
        <div className={styles.icon}>{<Icon size={px2rem(32)} />}</div>
        <Variation value={variation} />
      </div>
      <div className={styles.bottom}>
        <Text
          variant={TextVariants.RegularText}
          className={styles.name}
          color={"var(--slate900)"}
        >
          {name}
        </Text>
        <Text
          variant={TextVariants.RegularUpperTextS}
          color={"var(--slate800)"}
        >
          {short_name}
        </Text>
      </div>
    </div>
  );
};

export default DailyReturnCard;

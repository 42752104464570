import { Cryptos, getCrypto } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ActionsSection from "components/common/ActionsSection";
import ActivitySection from "components/common/ActivitySection";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import {
  CryptoHolding,
  CryptoPortfolio,
  CryptoPrice,
} from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { ArrowLeft } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import SendMethodSheet from "components/common/SendMethodSheet";
import { SendMethod } from "context/CryptoSendProvider";
import { useAppDispatch } from "hooks/redux";

import CryptoChart from "./CryptoChart";
import CryptoDetail from "./CryptoDetail";
import HoldingSection from "./HoldingSection";
import PricesSection from "./PricesSection";

import styles from "./styles.module.scss";

type TickerParams = {
  ticker: Cryptos;
};

const CryptoDetailPage: React.FC = () => {
  const { ticker } = useParams<TickerParams>();

  if (!ticker) return null;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const icon = getCrypto(ticker).icon;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [crypto, setCrypto] = useState<CryptoPrice>();
  const [holding, setHolding] = useState<CryptoHolding>();
  const [priceToDisplay, setPriceToDisplay] = useState<number>();
  const [dateToDisplay, setDateToDisplay] = useState<string>();
  const [isSendSheetOpen, setIsSendSheetOpen] = useState<boolean>(false);

  const getTickerData = async () => {
    const getCryptoHolding = async () => {
      try {
        const { data } = await API.get<CryptoPortfolio>(
          `${cryptoService.portfolio}`,
          {
            params: {
              ticker: ticker,
            },
          }
        );
        if (!isEmpty(data.holdings)) setHolding(data.holdings[0]);
      } catch (error) {
        console.log("cant obtain holding");
      }
    };

    const getCryptoDetail = async () => {
      try {
        const { data } = await API.get<CryptoPrice[]>(
          `${cryptoService.prices}`,
          {
            params: {
              baseTicker: ticker,
            },
          }
        );
        setCrypto(data[0]);
      } catch (error) {
        setError(true);
      }
    };

    await getCryptoHolding();
    await getCryptoDetail();

    setIsLoading(false);
  };

  useEffect(() => {
    ampli.cryptoScreenMarketDetail({ ticker });
    dispatch(setCurrentScreen(cryptoScreenNames.marketDetail));

    getTickerData();
  }, []);

  const onClickSend = () => {
    setIsSendSheetOpen(true);
  };

  const onSelectMethod = (method: SendMethod) => {
    navigate(CryptoRoutes.SEND, {
      state: { defaultTicker: ticker, sendMethod: method },
    });
  };

  return (
    <div className={styles.cryptoDetailPage}>
      <PageHeader
        icon={ArrowLeft}
        title={ticker}
        onClick={() => navigate(-1)}
        className={styles.tickerDetailTitle}
      />
      {isLoading && <LoadingSpinner variant={ThemeVariants.Crypto} />}
      {error && (
        <ErrorPage errorMessage="En este momento no podemos mostrar este instrumento." />
      )}
      {!isLoading && !error && crypto && (
        <>
          <CryptoDetail
            icon={icon}
            name={crypto.baseTicker}
            price={priceToDisplay ?? crypto.last}
            variation={crypto.varDailyPricePercentage}
            dateToDisplay={dateToDisplay}
          />
          <CryptoChart
            ticker={ticker}
            setDateToDisplay={setDateToDisplay}
            setPriceToDisplay={setPriceToDisplay}
          />
          <HoldingSection holding={holding} icon={icon} />
          <ActionsSection ticker={ticker} onClickSend={onClickSend} />
          <PricesSection sell={crypto.bid} buy={crypto.ask} />
          <ActivitySection ticker={ticker} />
        </>
      )}
      <SendMethodSheet
        isOpen={isSendSheetOpen}
        onSelectMethod={onSelectMethod}
        onClose={() => setIsSendSheetOpen(false)}
      />
    </div>
  );
};

export default CryptoDetailPage;

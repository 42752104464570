import dayjs from "dayjs";
import "dayjs/locale/es-mx";

const formatDate = (date: string) => {
  const dateToCheck = dayjs(date);
  const today = dayjs();
  const yesterday = today.subtract(1, "day");

  if (dateToCheck.isSame(today, "day")) {
    return "Hoy";
  } else if (dateToCheck.isSame(yesterday, "day")) {
    return "Ayer";
  } else {
    return dayjs(date)
      .locale("es-mx")
      .format("DD MMM YYYY")
      .replace(/^(.{3})(.)/, (match, group1, group2) => {
        return group1 + group2.toUpperCase();
      });
  }
};

export default formatDate;

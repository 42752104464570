import { Cryptos } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import ActivityList from "components/common/ActivityList";
import SectionHeader from "components/common/SectionHeader";
import { CryptoActivity } from "interfaces/api-responses";
import CryptoPage from "layouts/CryptoPageLayout";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import ActivityFilters from "./ActivityFilters";

import styles from "./styles.module.scss";

const CryptoActivityPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [activity, setActivity] = useState<CryptoActivity>();
  const [tickerFilter, setTickerFilter] = useState<Cryptos>();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    ampli.cryptoScreenActivity({
      previous_screen: previousScreen,
    });

    dispatch(setCurrentScreen(cryptoScreenNames.activity));
  }, []);

  useEffect(() => {
    const getActivity = async () => {
      try {
        const { data } = await API.get<CryptoActivity>(
          `${cryptoService.movements}`,
          {
            params: {
              ticker: tickerFilter,
            },
          }
        );
        setActivity(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getActivity();
  }, [tickerFilter]);

  return (
    <CryptoPage loading={loading} error={error}>
      <div>
        <SectionHeader title="Actividad" />
        <div className={styles.contentWrapper}>
          <ActivityFilters onClick={setTickerFilter} />
          <ActivityList activity={activity} />
        </div>
      </div>
    </CryptoPage>
  );
};

export default CryptoActivityPage;

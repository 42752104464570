import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

const CryptoFullIcon: React.FC<IconProps> = ({
  width = 200,
  height = 109,
  color = "var(--slate500)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 200 109"
      fill="none"
      {...props}
    >
      <path
        d="M0.00612938 53.762C-0.0431585 52.0004 0.263297 50.2471 0.907123 48.607C1.55095 46.9669 2.51888 45.4739 3.75288 44.2176C4.9869 42.9611 6.46157 41.9672 8.08849 41.2954C9.71541 40.6235 11.461 40.2876 13.2208 40.3076C16.2574 40.2178 19.2277 41.2082 21.6043 43.1031C23.9811 44.9982 25.6103 47.6752 26.2029 50.6589H18.5885C18.1805 49.5952 17.4416 48.691 16.481 48.0804C15.5203 47.4697 14.3888 47.1846 13.254 47.2676C10.2547 47.2676 7.44352 49.5616 7.44352 53.762C7.44352 57.9623 10.2768 60.3119 13.254 60.3119C14.4522 60.3895 15.6398 60.0471 16.6133 59.3433C17.5867 58.6395 18.2851 57.6183 18.5885 56.4551H26.2029C25.7081 59.5192 24.1191 62.2994 21.7313 64.2787C19.3435 66.258 16.3193 67.3018 13.2208 67.2163C11.4598 67.2412 9.71199 66.9084 8.08293 66.2382C6.45389 65.568 4.97733 64.5742 3.74238 63.3168C2.50741 62.0594 1.53965 60.5646 0.897498 58.9224C0.255351 57.2802 -0.0478665 55.5247 0.00612938 53.762Z"
        fill={color}
      />
      <path
        d="M27.9299 53.762C27.919 51.0843 28.703 48.4638 30.1822 46.2333C31.6613 44.0026 33.7692 42.2625 36.2379 41.2337C38.7067 40.2051 41.425 39.9342 44.0478 40.4555C46.6706 40.9767 49.0796 42.2669 50.9687 44.1618C52.858 46.0566 54.1423 48.4711 54.6586 51.0983C55.1748 53.7256 54.8999 56.4472 53.8686 58.9176C52.8371 61.3881 51.0959 63.4959 48.8659 64.9734C46.6359 66.451 44.0177 67.2317 41.3438 67.2163C39.5704 67.2526 37.808 66.9293 36.1627 66.2656C34.5174 65.6021 33.0232 64.612 31.77 63.355C30.5169 62.098 29.5306 60.6002 28.8707 58.9516C28.2107 57.3029 27.8908 55.5376 27.9299 53.762ZM47.4642 53.762C47.4642 49.9273 44.9518 47.2453 41.4212 47.2453C37.8908 47.2453 35.3673 49.9273 35.3673 53.762C35.3673 57.5965 37.8796 60.2786 41.4212 60.2786C44.9628 60.2786 47.4642 57.6187 47.4642 53.762Z"
        fill={color}
      />
      <path
        d="M57.0268 53.762C56.9775 52.0004 57.2839 50.247 57.9276 48.607C58.5715 46.9669 59.5394 45.4739 60.7735 44.2176C62.0074 42.9611 63.4821 41.9673 65.1091 41.2954C66.7359 40.6235 68.4817 40.2875 70.2413 40.3076C73.2781 40.2178 76.2484 41.2081 78.625 43.1031C81.0016 44.9982 82.6309 47.6751 83.2236 50.6589H75.609C75.2012 49.5952 74.4622 48.691 73.5015 48.0803C72.5408 47.4696 71.4094 47.1846 70.2746 47.2675C67.2753 47.2675 64.464 49.5616 64.464 53.762C64.464 57.9623 67.2974 60.3118 70.2746 60.3118C71.4726 60.3895 72.6604 60.047 73.6339 59.3433C74.6072 58.6395 75.3056 57.6183 75.609 56.455H83.2236C82.7287 59.5192 81.1397 62.2993 78.7519 64.2785C76.3641 66.2578 73.34 67.3018 70.2413 67.2163C68.4804 67.2411 66.7326 66.9084 65.1035 66.2382C63.4744 65.5679 61.9978 64.5742 60.763 63.3168C59.528 62.0594 58.5602 60.5646 57.918 58.9224C57.2759 57.2802 56.9727 55.5247 57.0268 53.762Z"
        fill={color}
      />
      <path
        d="M84.9715 53.762C84.9605 51.0844 85.7445 48.4639 87.2238 46.2333C88.7029 44.0028 90.8106 42.2627 93.2795 41.2339C95.7483 40.2052 98.4665 39.9343 101.089 40.4556C103.712 40.9769 106.121 42.2669 108.01 44.1618C109.9 46.0567 111.184 48.4712 111.7 51.0984C112.217 53.7256 111.941 56.4473 110.91 58.9176C109.879 61.3882 108.137 63.4959 105.907 64.9735C103.677 66.451 101.059 67.2317 98.3854 67.2164C96.612 67.2527 94.8496 66.9294 93.2043 66.2658C91.559 65.6022 90.0648 64.6122 88.8116 63.3552C87.5584 62.0982 86.5722 60.6003 85.9123 58.9517C85.2523 57.303 84.9323 55.5378 84.9715 53.762ZM104.506 53.762C104.506 49.9274 101.993 47.2454 98.4628 47.2454C94.9323 47.2454 92.4199 49.9274 92.4199 53.762C92.4199 57.5967 94.9211 60.2787 98.4628 60.2787C102.004 60.2787 104.506 57.6188 104.506 53.762Z"
        fill={color}
      />
      <path
        d="M114.123 58.1065L121.317 58.1619C121.439 60.0127 122.656 61.3314 125.312 61.3314C127.725 61.3314 128.633 60.3784 128.633 59.0374C128.633 57.6964 127.227 57.0314 124.272 56.6435C119.247 56.0339 115.053 53.8396 115.053 48.4866C115.053 43.3553 118.716 40.3076 125.014 40.3076C131.311 40.3076 135.627 43.7212 135.627 48.9079L128.29 48.8523C128.19 47.4338 127.183 46.3921 125.036 46.3921C123.165 46.3921 122.413 47.2676 122.413 48.3204C122.413 49.739 122.944 50.1048 126.972 50.681C132.728 51.5122 136.303 53.3519 136.303 58.594C136.303 64.5121 131.432 67.2385 125.235 67.2385C117.51 67.2385 114.123 62.6503 114.123 58.1065Z"
        fill={color}
      />
      <path
        d="M124.416 30.8763C118.395 30.9428 112.85 28.017 110.316 22.8081C109.418 20.9019 108.952 18.8204 108.952 16.7127C108.952 14.6049 109.418 12.5234 110.316 10.6172C112.139 6.58424 115.331 3.32901 119.325 1.42966L122.269 0L125.135 5.8849L122.191 7.32564C119.592 8.54517 117.507 10.6452 116.304 13.2548C115.808 14.2912 115.546 15.4235 115.534 16.5723C115.523 17.721 115.763 18.8585 116.237 19.9045C118.351 24.2267 124.449 25.601 129.839 22.9744L132.782 21.5337L135.65 27.4185L132.706 28.8592C130.137 30.1226 127.321 30.8002 124.46 30.8431"
        fill={color}
      />
      <path
        d="M38.2998 96.2641C38.242 95.4369 38.4346 94.6115 38.8523 93.8957C39.2702 93.1798 39.8938 92.6068 40.6418 92.2514C41.3899 91.896 42.2276 91.7746 43.0456 91.9032C43.8635 92.0319 44.6238 92.4045 45.227 92.9724C45.8304 93.5404 46.2486 94.2773 46.4272 95.0868C46.6057 95.8966 46.5363 96.7412 46.2281 97.5108C45.9198 98.2803 45.387 98.9389 44.6993 99.4006C44.0114 99.8623 43.2007 100.105 42.3726 100.099C41.8504 100.129 41.3275 100.052 40.8359 99.8731C40.3443 99.6943 39.8942 99.4169 39.513 99.058C39.1319 98.6992 38.8279 98.2665 38.6192 97.7861C38.4106 97.3057 38.3019 96.7878 38.2998 96.2641Z"
        fill={color}
      />
      <path
        d="M140.342 38.5011L137.343 32.6716L140.254 31.1755C145.589 28.4269 148.146 22.7083 145.943 18.4303C143.741 14.1525 137.62 12.889 132.286 15.6486L129.375 17.1447L126.375 11.3153L129.286 9.81914C137.83 5.38606 147.913 7.93508 151.764 15.427C155.616 22.9189 151.764 32.6051 143.243 37.0049L140.342 38.5011Z"
        fill={color}
      />
      <path
        d="M186.371 100.359C178.429 100.359 172.89 94.3041 172.89 86.8524C172.89 79.4497 178.405 73.3462 186.396 73.3462C194.436 73.3462 200 79.4252 200 86.8524C200 94.3286 194.436 100.359 186.371 100.359ZM186.445 93.3971C189.999 93.3971 192.524 90.7253 192.524 86.8524C192.524 83.004 189.999 80.3076 186.445 80.3076C182.891 80.3076 180.366 83.004 180.366 86.8524C180.366 90.7253 182.891 93.3971 186.445 93.3971Z"
        fill={color}
      />
      <path
        d="M166.023 99.5496C162.272 99.5496 157.223 98.4466 157.223 89.6467V80.5772H152.345V74.155H157.223V66.8259L164.773 66.7769V74.155H171.636V80.5772H164.773V89.9899C164.773 92.4656 166.047 93.3235 167.641 93.3235H171.636V99.5496H166.023Z"
        fill={color}
      />
      <path
        d="M124.643 108.227V74.1551H131.825V77.3662C133.688 74.7189 136.58 73.3707 139.522 73.3707C146.802 73.3707 151.753 79.4742 151.753 86.8524C151.753 94.3041 146.728 100.383 139.644 100.383C136.384 100.383 133.639 98.7898 132.046 96.5347L132.193 99.5987V108.227H124.643ZM138.223 93.4461C141.434 93.4461 144.302 91.142 144.302 86.8524C144.302 82.7589 141.532 80.2831 138.174 80.2831C134.816 80.2831 132.119 83.1756 132.119 86.8524C132.119 90.7743 134.963 93.4461 138.223 93.4461Z"
        fill={color}
      />
      <path
        d="M96.3036 108.227V102.05H100.544C102.897 102.05 103.878 101.118 104.662 98.9368L105.005 97.9809L95.5928 74.1796H103.535L108.339 87.122L109.05 90.1124L109.736 87.122L114.443 74.2041L122.483 74.155L112.727 99.1084C109.614 107.099 106.452 108.227 100.691 108.227H96.3036Z"
        fill={color}
      />
      <path
        d="M79.2398 99.5496V74.155H86.4709V77.7338C87.623 75.6258 89.5104 74.155 92.4764 74.155H95.4178V80.5772H92.8931C89.0937 80.5772 86.8141 83.151 86.8141 87.8573V99.5496H79.2398Z"
        fill={color}
      />
      <path
        d="M63.083 100.359C55.2391 100.359 49.7974 94.255 49.7974 86.8524C49.7974 79.4987 55.2391 73.3462 63.083 73.3462C70.0445 73.3462 74.8243 77.8564 76.1235 83.7393H68.4757C67.5442 81.3371 65.4852 80.3321 63.1075 80.3321C60.0926 80.3321 57.2737 82.6363 57.2737 86.8524C57.2737 91.093 60.1171 93.4216 63.1075 93.4216C65.4852 93.4216 67.5688 92.3921 68.4757 89.5487H76.1235C74.9224 95.7993 70.0445 100.359 63.083 100.359Z"
        fill={color}
      />
    </svg>
  );
};

export default CryptoFullIcon;

import Text, { TextVariants } from "components/common/Text";

import { IconProps } from "../BTC";

const GoldBadgeIcon: React.FC<IconProps> = ({ size = 16, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={+size + 20}
    height={size}
    viewBox="0 0 36 16"
    fill="none"
    className="gold-badge"
    {...props}
  >
    <path
      d="M3.8638 1.57556C4.05856 0.79652 4.75853 0.25 5.56155 0.25H33.4384C34.5769 0.25 35.4123 1.31993 35.1362 2.42444L32.1362 14.4244C31.9414 15.2035 31.2415 15.75 30.4384 15.75H2.56155C1.42305 15.75 0.587677 14.6801 0.863803 13.5756L3.8638 1.57556Z"
      fill="url(#paint0_linear_6634_53357)"
      stroke="#E2A336"
      strokeWidth="0.5"
    />
    <text>GOLD</text>
    <Text variant={TextVariants.SemiboldValueXS} color="#090a0a">
      GOLD
    </Text>
    <defs>
      <linearGradient
        id="paint0_linear_6634_53357"
        x1="36"
        y1="8"
        x2="0"
        y2="8"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC53D" />
        <stop offset="1" stopColor="#FFBA18" />
      </linearGradient>
    </defs>
  </svg>
);

export default GoldBadgeIcon;

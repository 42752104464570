import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";
import LoadingSpinner from "components/common/LoadingSpinner";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface CococCardLoadingProps {
  title?: string;
  subtitle?: string;
}

const CococCardLoading: React.FC<CococCardLoadingProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingSpinner size={100} variant={ThemeVariants.Pay} />
      {title && (
        <Text variant={TextVariants.RegularTextL} color="var(--slate900)">
          {title}
        </Text>
      )}
      {subtitle && (
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {subtitle}
        </Text>
      )}
    </div>
  );
};

export default CococCardLoading;

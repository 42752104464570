import styles from "./styles.module.scss";

const TopShackle: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.topShackleWrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="152"
        height="123"
        viewBox="0 0 152 123"
        fill="none"
      >
        <path
          d="M-51.9614 -50.6025L17.3206 -90.6025L77.3206 13.3205C88.3663 32.4522 81.8113 56.9158 62.6796 67.9615C43.5479 79.0072 19.0843 72.4522 8.03857 53.3205L-51.9614 -50.6025Z"
          fill="var(--slate200)"
        />
        <path
          d="M2.67959 -35.9614L71.9616 -75.9614L131.962 27.9616C143.007 47.0933 136.452 71.5569 117.321 82.6026C98.1889 93.6483 73.7253 87.0933 62.6796 67.9616L2.67959 -35.9614Z"
          fill="var(--slate400)"
        />
      </svg>
    </div>
  </div>
);

export default TopShackle;

import { IconProps } from "assets/icons/BTC";
import Currency from "components/common/Text/Currency";
import { formatToCurrency } from "utils";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface CryptoHoldingRowProps {
  icon: React.FC<IconProps>;
  name: string;
  ticker: string;
  cryptoHolding: number;
  arsHolding: number;
}

const CryptoHoldingRow: React.FC<CryptoHoldingRowProps> = ({
  icon: Icon,
  name,
  ticker,
  cryptoHolding,
  arsHolding,
}) => {
  const MIN_THRESHOLD = 0.05;
  const nDecimals = cryptoHolding < MIN_THRESHOLD ? 6 : 2;

  const navigate = useNavigate();

  return (
    <div
      className={styles.cryptoHoldingRowContainer}
      onClick={() => navigate(`${CryptoRoutes.MARKET}/${ticker}`)}
    >
      <div className={styles.leftContent}>
        <Icon size={px2rem(32)} />
        <div className={styles.tickerData}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {name}
          </Text>
          <Text
            variant={TextVariants.RegularUpperTextS}
            color="var(--slate900)"
          >
            {formatToCurrency(cryptoHolding, false, false, nDecimals)} {ticker}
          </Text>
        </div>
      </div>
      <Currency amount={arsHolding} color="var(--slate900)" />
    </div>
  );
};

export default CryptoHoldingRow;

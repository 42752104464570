import type { BalancePeriodTime } from "interfaces/portfolio";
import type { Currencies } from "interfaces/wallet";

export const userKeys = {
  accountTier: ["accountTier"] as const,
  users: ["users"] as const,
};

export const portfolioKeys = {
  portfolio: (currency: Currencies) => ["portfolio", currency] as const,
  balance: (currency: Currencies, period: BalancePeriodTime) =>
    ["balance", currency, period] as const,
  history: (currency: Currencies, period: BalancePeriodTime) =>
    ["history", currency, period] as const,
};

import { Cryptos } from "constants/cryptos";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import LoadingSpinner from "components/common/LoadingSpinner";
import OutlinedButton from "components/common/OutlinedButton";
import { CryptoCreateOrder } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";

import { TransactionSteps } from "..";
import DetailsBody from "./DetailsBody";
import DetailsHeader from "./DetailsHeader";

import styles from "./styles.module.scss";

interface PreviewProps {
  isLoading: boolean;
  isBuyOrSell?: boolean;
  exchangeRate: number;
  remainingTime: number;
  closeModal: () => void;
  updatePrice: () => void;
  order?: CryptoCreateOrder;
  setLoading: (v: boolean) => void;
  setStep: (v: TransactionSteps) => void;
  baseCurrency: Cryptos | Currencies.ARS;
  quoteCurrency: Cryptos | Currencies.ARS;
  baseAmount?: number | string;
  quoteAmount?: number | string;
  operationType: string;
}

const Preview: React.FC<PreviewProps> = ({
  order,
  setStep,
  isLoading,
  setLoading,
  closeModal,
  isBuyOrSell = false,
  updatePrice,
  exchangeRate,
  baseCurrency,
  quoteCurrency,
  remainingTime,
  operationType,
}) => {
  const isOutdated = remainingTime <= 0;
  const isSell = quoteCurrency === Currencies.ARS;

  const getHeaderAmount = () => {
    if (!isBuyOrSell || isSell) {
      return order?.quoteQuantity;
    }
    return order?.baseQuantity;
  };

  const getBodyAmount = () => {
    if (!isBuyOrSell || isSell) {
      return order?.baseQuantity;
    }
    return order?.quoteQuantity;
  };

  const confirmOperation = async () => {
    if (!order?.idOrder) return;

    ampli.cryptoOperationsConfirm({
      operation_type: operationType,
      base_ticker: baseCurrency,
      quote_ticker: quoteCurrency,
      base_quantity: order.baseQuantity,
      quote_quantity: order.quoteQuantity,
      exchange_rate: exchangeRate,
    });

    try {
      setLoading(true);

      const getEndpoint = () => {
        if (isBuyOrSell) {
          if (quoteCurrency === Currencies.ARS) {
            return cryptoService.confirmSell;
          }
          return cryptoService.confirmBuy;
        }
        return cryptoService.confirmSwap;
      };

      await API.post(getEndpoint(), {
        idOrder: order.idOrder,
      });

      setStep(TransactionSteps.CONFIRMATION);
    } catch (error) {
      setStep(TransactionSteps.ERROR);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  return (
    <>
      <DetailsHeader
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
        quoteAmount={getHeaderAmount() || 0}
      />
      <DetailsBody
        baseAmount={getBodyAmount()}
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
        exchangeRate={order?.price ?? exchangeRate}
        isBuyOrSell={isBuyOrSell}
      />
      <div className={styles.buttonsWrapper}>
        <OutlinedButton variant={ThemeVariants.Crypto} onClick={closeModal}>
          Volver
        </OutlinedButton>
        <FilledButton
          variant={ThemeVariants.Crypto}
          onClick={() => (isOutdated ? updatePrice() : confirmOperation())}
          isLoading={isLoading}
        >
          {isOutdated ? "Actualizar" : `Confirmar (${remainingTime})s`}
        </FilledButton>
      </div>
    </>
  );
};

export default Preview;

import classNames from "classnames";
import Switch from "components/common/Switch";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { isBoolean } from "lodash";
import { ChevronRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export interface SectionRowProps {
  icon?: LucideIcon;
  iconColor?: string;
  label: string;
  onClick: () => void;
  toggleValue?: boolean;
  rightIcon?: LucideIcon;
  rightIconColor?: string;
  show?: boolean;
}

const SectionRow: React.FC<SectionRowProps> = ({
  icon: Icon,
  iconColor = "var(--blue900)",
  label,
  onClick,
  toggleValue,
  rightIcon: SecondaryIcon,
  rightIconColor,
  show = true,
}) => {
  if (!show) return null;

  const hasIcon = !!Icon;

  return (
    <div
      className={classNames(styles.row, {
        [styles.noIcon]: !hasIcon,
      })}
      onClick={onClick}
    >
      {hasIcon && (
        <div className={styles.icon}>
          <Icon size={px2rem(20)} color={iconColor} />
        </div>
      )}
      <div className={styles.wrapper}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {label}
        </Text>
        {isBoolean(toggleValue) ? (
          <Switch variant={ThemeVariants.Capital} value={toggleValue} />
        ) : (
          <div className={styles.rightIconWrapper}>
            {SecondaryIcon && (
              <SecondaryIcon size={px2rem(20)} color={rightIconColor} />
            )}
            <ChevronRight size={px2rem(16)} color="var(--slate800)" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionRow;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobalState {
  currentScreen: string;
}

export const initialState: GlobalState = {
  currentScreen: "",
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setCurrentScreen(state, action: PayloadAction<string>) {
      state.currentScreen = action.payload;
    },
  },
  selectors: {
    getCurrentScreen(state) {
      return state.currentScreen;
    },
  },
});

export const { setCurrentScreen } = globalSlice.actions;

export const { getCurrentScreen } = globalSlice.selectors;

export default globalSlice.reducer;

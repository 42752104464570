import { useEffect } from "react";

import mainRoutes from "router/routes";
import { TokenType } from "interfaces/auth";
import LoaderAndRedirect from "components/common/LoaderAndRedirect";
import ErrorContainer from "components/common/error-modal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthProvider";

const EmailValidationPage: React.FC = () => {
  const navigate = useNavigate();
  const { clearErrors, error, isAuthenticated, verifyUserEmail } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const handleOnClickPrimaryButton = () => {
    clearErrors();
    navigate(mainRoutes.login);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(mainRoutes.login);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    verifyUserEmail(token, TokenType.SIGN_UP);
  }, []);

  if (error || !token)
    return (
      <ErrorContainer
        subtitle={
          error || "Token inválido, por favor intente de nuevo más tarde."
        }
        textPrimaryButton="Volver a intentar"
        onClickPrimaryButton={handleOnClickPrimaryButton}
      />
    );

  return <LoaderAndRedirect />;
};

export default EmailValidationPage;

import {
  HistoricDataMarketData,
  InstrumentData,
  MarketData,
  OrderDetailResponse,
  OrdersResponse,
  TickersRulesResponse,
  TypeListResponse,
} from "interfaces/api-responses";
import {
  BookSocketResponse,
  TickerSocketResponse,
} from "interfaces/api-responses/socket/book";
import { MarketSchedules } from "interfaces/calendar";
import { ErrorMessage } from "interfaces/errors";
import {
  CableResponse,
  CaucionOrder,
  DolarMep,
  DolarMepOrder,
  InvestmentSuggestedResponse,
  MarketList,
  Method,
  Order,
  OrderType,
  SettlementTerms,
  LabelValue,
} from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

export interface MarketsInitialState {
  cable: CableResponse;
  cableError: ErrorMessage;
  cableLoading: boolean;
  cancelOrderError: ErrorMessage;
  cancelOrderLoading: boolean;
  caucionError: ErrorMessage;
  caucionOrder: CaucionOrder;
  confirmOrderData: LabelValue[];
  createListError: ErrorMessage;
  createListLoading: boolean;
  createOrderError: ErrorMessage;
  createOrderLoading: boolean;
  dolarMep: DolarMep;
  dolarMepOrder: DolarMepOrder;
  errorMessage: ErrorMessage;
  getOrderError: ErrorMessage;
  getOrderLoading: boolean;
  getOrdersListError: ErrorMessage;
  getOrdersListLoading: boolean;
  investmentSuggested: InvestmentSuggestedResponse[];
  loading: boolean;
  marketData: MarketData;
  marketDetail?: BookSocketResponse & InstrumentData; //market data for short ticker for just one selected currency, term etc.
  marketDetailLoading: boolean;
  marketHistoricData?: HistoricDataMarketData;
  markets: TickerSocketResponse[];
  marketsLists: MarketList[];
  marketsTypesLists: TypeListResponse[];
  newOrder: Optional<Order, "price" | "quantity" | "long_ticker">;
  orderDetail?: OrderDetailResponse;
  orders: OrdersResponse[];
  presettedWalletOptions: any;
  schedules: MarketSchedules;
  setCableError: ErrorMessage;
  shortTickerData: InstrumentData[]; //market data for short ticker, including all terms, currency, etc
  showModalMarketClosed: boolean;
  tickerSearchText: string;
  tickersRulesData?: TickersRulesResponse;
  tickersRulesError: ErrorMessage;
  tickersRulesLoading: boolean;
}

export const marketsInitialState: MarketsInitialState = {
  marketsLists: [],
  markets: [],
  marketHistoricData: undefined,
  marketDetail: undefined,
  errorMessage: {
    message: "",
    status: null,
  },
  dolarMep: {
    open: {
      short_ticker: "",
      settlement_date: "",
      bid: 0,
      ask: 0,
      price_ars: 0,
      price_usd: 0,
      settlementForBuy: "",
      settlementForSell: "",
    },
    close: {
      short_ticker: "",
      settlement_date: "",
      bid: 0,
      ask: 0,
      price_ars: 0,
      price_usd: 0,
      settlementForBuy: "",
      settlementForSell: "",
    },
    overnight: {
      short_ticker: "",
      settlement_date: "",
      bid: 0,
      ask: 0,
      price_ars: 0,
      price_usd: 0,
      settlementForBuy: "",
      settlementForSell: "",
    },
  },
  newOrder: {
    instrument_short_name: "",
    type: OrderType.Buy,
    term: SettlementTerms._24,
    currency: Currencies.ARS,
    method: Method.MARKET,
    enabledAmount: true,
    instrument_code: undefined,
  },
  loading: false,
  marketDetailLoading: false,
  createOrderLoading: false,
  cancelOrderLoading: false,
  getOrderLoading: false,
  getOrdersListLoading: false,
  createListLoading: false,
  createOrderError: {
    message: "",
    status: null,
  },
  cancelOrderError: {
    message: "",
    status: null,
  },
  getOrderError: {
    message: "",
    status: null,
  },
  getOrdersListError: {
    message: "",
    status: null,
  },
  createListError: {
    message: "",
    status: null,
  },
  shortTickerData: [],
  orders: [],
  dolarMepOrder: {
    type: OrderType.Buy,
    usdAmount: 0,
    arsAmount: 0,
    selectedCurrency: Currencies.ARS,
  },
  marketsTypesLists: [],
  marketData: {
    items: [],
    total_items: 0,
    total_pages: 0,
    per_page: 0,
    current_page: 0,
    instrument_subtype: "",
  },
  tickerSearchText: "",
  caucionOrder: {
    currency: Currencies.ARS,
    term: 1,
    amount: undefined,
    method: Method.MARKET,
    rate: undefined,
    id: undefined,
  },
  caucionError: {
    message: "",
    status: null,
  },
  tickersRulesError: {
    message: "",
    status: null,
  },
  tickersRulesLoading: false,
  showModalMarketClosed: true,
  cableError: {
    message: "",
    status: null,
  },
  cableLoading: false,
  setCableError: {
    message: "",
    status: null,
  },
  cable: {
    id_account: 0,
    id_order_canje: "string",
    quantity: 0,
  },
  investmentSuggested: [],
  presettedWalletOptions: [],
  confirmOrderData: [],
  schedules: {
    OVERNIGHT: {
      openHours: [],
    },
    24: {
      openHours: [],
    },
    CI: {
      openHours: [],
    },
    MEP: {
      openHours: [],
    },
    CAUCION: {
      openHours: [],
    },
    SOBERANOS: {
      openHours: [],
    },
  },
};

enum FeatureFlags {
  HIDE_ONBOARDING_BUTTON = "hide_onboarding_button",
  ENABLE_USD_CRYPTO_TRANSFER = "enable_usd_crypto_transfers",
  ENABLE_CARD = "enable_card",
  ENABLE_MEP_REDESIGN = "enable_mep_redesign",
  ENABLE_BID = "enable_bid",
  ENABLE_MEP_NO_BOTTOMSHEET = "enable_mep_redesign_no_bottomsheet",
  ENABLE_PORTFOLIO_REDESIGN = "enable_portfolio_redesign",
  ENABLE_PORTFOLIO_DOUBLE_REQUEST = "temp_double_portfolio_request_enabled",
}

export default FeatureFlags;

import Text, { TextVariants } from "components/common/Text";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface TagCardProps {
  tag: string;
  tagName: string;
  onClick: () => void;
}

const TagCard: React.FC<TagCardProps> = ({ tagName, tag, onClick }) => {
  return (
    <div className={styles.tagCardWrapper} onClick={onClick}>
      <Text variant={TextVariants.RegularText} color="var(--slate900)">
        {tagName}
      </Text>
      <NumbersBadge
        number={`${tag} 🥥`}
        className={styles.badgeText}
        variant={ThemeVariants.Crypto}
      />
    </div>
  );
};

export default TagCard;

import { useEffect } from "react";

import { ampli } from "ampli";
import { Capacitor } from "@capacitor/core";
import { InAppReview } from "@capacitor-community/in-app-review";
import SuccessIllustration from "assets/illustrations/Success";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import DetailBox from "components/page-mep/NewMep/CreateOrder/DetailBox";
import { getDolarType } from "components/page-mep/NewMep/getDolarType";
import dayjs from "dayjs";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { OrderType } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface SuccessProps {
  amount: string;
  isFixed: boolean;
  mepPrice: number;
  orderSide: OrderType;
  convertedAmount: number;
}

const Success: React.FC<SuccessProps> = ({
  amount,
  isFixed,
  mepPrice,
  orderSide,
  convertedAmount,
}) => {
  const { dolarMep } = useAppSelector((state) => state.markets);
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const dispatch = useAppDispatch();

  const dolarType = getDolarType(isFixed);

  const isBuy = orderSide === OrderType.Buy;

  const goToHome = () => {
    if (isMobile) {
      navigate(mainRoutes.home);
      return;
    }

    dispatch(closeRightModal());
  };

  const goToOrders = () => {
    navigate(mainRoutes.orders);

    if (!isMobile) {
      dispatch(closeRightModal());
    }
  };

  const getSuccessText = () => {
    if (isBuy) {
      if (isFixed)
        return "Ya podés encontrar disponibles para usar los dólares en tu portfolio.";

      return `Vas a encontrar los dólares disponibles para usar en tu portfolio el ${dayjs(
        dolarMep.open.settlementForBuy
      ).format("DD/MM/YYYY")}.`;
    }
    return "Ya podés encontrar disponibles para usar los pesos en tu portfolio.";
  };

  useEffect(() => {
    const arsAmount = isBuy ? Number(amount) : convertedAmount;
    const usdAmount = isBuy ? convertedAmount : Number(amount);

    if (Capacitor.isNativePlatform()) InAppReview.requestReview();

    ampli.mepOrderSuccess({
      arsAmount,
      usdAmount,
      side: orderSide,
      type: dolarType,
    });
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: goToHome,
        title: "¡Listo!",
      }}
      primaryButtonProps={{
        children: "Ver en órdenes del día",
        onClick: goToOrders,
      }}
      secondaryButtonProps={{
        children: "Volver al inicio",
        onClick: goToHome,
      }}
    >
      <div className={styles.successWrapper}>
        <SuccessIllustration width={px2rem(130)} />
        <Text
          color="var(--slate800)"
          className={styles.text}
          variant={TextVariants.RegularText}
        >
          {getSuccessText()}
        </Text>
        <DetailBox
          isBuy={isBuy}
          boxTitle="Monto"
          isFixed={isFixed}
          mepPrice={mepPrice}
          amount={convertedAmount}
          currency={isBuy ? Currencies.USD : Currencies.ARS}
        />
      </div>
    </BasicWrapper>
  );
};

export default Success;

import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import mainRoutes from "router/routes";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import Success from "assets/illustrations/Success";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const PinSuccess: React.FC = () => {
  const navigate = useNavigate();

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "¡Listo!",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={navigationHeaderProps}
      primaryButtonProps={{
        children: "Volver a tarjetas",
        onClick: () => navigate(mainRoutes.card),
      }}
    >
      <div className={styles.successContainer}>
        <Success size={px2rem(120)} />
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Cambiaste tu clave del cajero con éxito.
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default PinSuccess;

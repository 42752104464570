import FeatureFlags from "constants/featureFlags";

import { useEffect, useState } from "react";

import { Preferences } from "@capacitor/preferences";
import brazeClient from "utils/braze";

const useFeatureFlag = (featureFlag: FeatureFlags) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchFF = async () => {
      const flagKey = `ff-${featureFlag}`;
      try {
        const featureEnabled = await brazeClient.getFeatureFlag(featureFlag);

        setIsFeatureEnabled(!!featureEnabled && featureEnabled.enabled);

        Preferences.set({
          key: flagKey,
          value: JSON.stringify(!!featureEnabled && featureEnabled.enabled),
        });
      } catch (error) {
        const previousValue = (await Preferences.get({ key: flagKey })).value;

        if (!previousValue) {
          setIsFeatureEnabled(false);
          return;
        }

        setIsFeatureEnabled(JSON.parse(previousValue));
      } finally {
        setIsLoading(false);
      }
    };

    fetchFF();
  }, []);

  return { isFeatureEnabled, isLoading };
};

export default useFeatureFlag;

import { Browser } from "@capacitor/browser";

const openDDJJ = async () => {
  await Browser.open({
    url:
      "https://assets.cocos.capital/cocos/reglamentos/DDJJ_Operatoria_Dolar.pdf",
  });
};

export default openDDJJ;

import { useEffect } from "react";

import CryptoFullIcon from "assets/icons/CryptoIcon";
import LiriumCoinagTyC from "components/common/LiriumCoinagTyC";
import Text, { TextVariants } from "components/common/Text";
import dayjs from "dayjs";
import { Navigate } from "react-router-dom";
import mainRoutes, { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import { lockScroll, removeScrollLock } from "utils/lockScroll";
import px2rem from "utils/px2rem";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

const CryptoWelcomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getLoggedUser);

  const currentYear = dayjs().year();

  useEffect(() => {
    lockScroll();
    setTimeout(() => {
      removeScrollLock();
    }, 2500);

    dispatch(setCurrentScreen(cryptoScreenNames.tyc));
  }, []);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  return (
    <div className={styles.container}>
      <LiriumCoinagTyC status={user.status_crypto} />
      <div className={styles.welcomePageContainer}>
        <div className={styles.wrapper}>
          <CryptoFullIcon
            width={px2rem(200)}
            height={px2rem(117)}
            color="var(--background-1)"
          />
          <Text variant={TextVariants.SemiboldTextS} color="var(--purple200)">
            © Copyright {currentYear} — Cocos Crypto SA
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CryptoWelcomePage;

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ChipProps {
  icon: React.FC<IconProps>;
  primaryText: string | number;
  secondaryText: string | number;
  onClick: () => void;
}

const Chip: React.FC<ChipProps> = ({
  icon: Icon,
  primaryText,
  secondaryText,
  onClick,
}) => {
  return (
    <button className={styles.chipContainer} onClick={onClick}>
      <Icon size={px2rem(24)} />
      <div className={styles.labels}>
        <Text
          variant={TextVariants.SemiboldUpperValueS}
          color="var(--slate900)"
        >
          {primaryText}
        </Text>
        <Text
          variant={TextVariants.SemiboldUpperValueS}
          color="var(--slate800)"
        >
          {secondaryText}
        </Text>
      </div>
    </button>
  );
};

export default Chip;

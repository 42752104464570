import { CRYPTOS, Cryptos } from "constants/cryptos";

import { useState } from "react";

import FilterChip from "./FilterChip";

import styles from "./styles.module.scss";

interface ActivityFiltersProps {
  onClick: (ticker: Cryptos | undefined) => void;
}

const ActivityFilters: React.FC<ActivityFiltersProps> = ({ onClick }) => {
  const [selectedCrypto, setSelectedCrypto] = useState<Cryptos>();

  const handleClick = (ticker: Cryptos) => {
    let newCrypto;
    if (selectedCrypto !== ticker) newCrypto = ticker;
    setSelectedCrypto(newCrypto);
    onClick(newCrypto);
  };

  return (
    <div className={styles.activityFiltersWrapper}>
      <div className={styles.chipCarrousel}>
        {CRYPTOS.map(({ ticker }) => (
          <FilterChip
            key={ticker}
            crypto={ticker}
            onClick={handleClick}
            selected={ticker === selectedCrypto}
          />
        ))}
      </div>
    </div>
  );
};

export default ActivityFilters;

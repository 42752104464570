import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { ChevronDown } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CurrencySelectProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label?: string;
  icon?: React.FC<IconProps>;
}

const CurrencySelect: React.FC<CurrencySelectProps> = ({
  label,
  icon: Icon,
  className,
  ...props
}) => (
  <button
    {...props}
    className={classNames(
      styles.button,
      {
        [styles.disabled]: props.disabled,
      },
      className
    )}
  >
    {Icon ? (
      <Icon size={px2rem(20)} />
    ) : (
      <div className={styles.dottedCircle} />
    )}
    {label ? (
      <Text variant={TextVariants.SemiboldUpperValueS} color="var(--slate900)">
        {label}
      </Text>
    ) : (
      <Text variant={TextVariants.SemiboldTextS} color="var(--slate900)">
        Seleccionar
      </Text>
    )}
    {!props.disabled && (
      <ChevronDown size={px2rem(20)} color="var(--slate800)" />
    )}
  </button>
);

export default CurrencySelect;

import { getCrypto } from "constants/cryptos";

import CryptoHoldingRow from "components/common/CryptoHoldingRow";
import { CryptoHolding } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface HoldingsListProps {
  holdings: CryptoHolding[];
}

const HoldingsList: React.FC<HoldingsListProps> = ({ holdings }) => {
  return (
    <div className={styles.holdingsListContainer}>
      {holdings.map(({ quantity, amountArs, ticker }) => {
        const { icon, name } = getCrypto(ticker);
        return (
          <CryptoHoldingRow
            key={ticker}
            icon={icon}
            name={name}
            ticker={ticker}
            cryptoHolding={quantity}
            arsHolding={amountArs}
          />
        );
      })}
    </div>
  );
};

export default HoldingsList;

import { Cryptos, getCrypto } from "constants/cryptos";

import { IconProps } from "assets/icons/BTC";
import BarChart from "components/common/BarChart";
import Text, { TextVariants } from "components/common/Text";
import { formatPercentage, formatToCurrency } from "utils";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface AssetAllocationProps {
  assetName: string;
  icon: React.FC<IconProps>;
  assetAmount: number;
  assetTicker: Cryptos;
  amount: number;
  percentage: number;
}

const AssetAllocation: React.FC<AssetAllocationProps> = ({
  icon: Icon,
  amount,
  assetName,
  percentage,
  assetAmount,
  assetTicker,
}) => {
  return (
    <div className={styles.assetAllocationContainer}>
      <div className={styles.assetAllocationWrapper}>
        <div className={styles.assetInfoWrapper}>
          <Icon size={px2rem(32)} />
          <div>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              {assetName}
            </Text>
            <Text
              variant={TextVariants.RegularUpperValueS}
              color="var(--slate800)"
            >
              {assetAmount} {assetTicker}
            </Text>
          </div>
        </div>
        <div className={styles.amountWrapper}>
          <Text
            variant={TextVariants.SemiboldUpperValue}
            color="var(--slate900)"
          >
            {formatPercentage(percentage, false)}
          </Text>
          <Text
            variant={TextVariants.RegularUpperValueS}
            color="var(--slate800)"
          >
            ${formatToCurrency(amount, false, false)}
          </Text>
        </div>
      </div>
      <BarChart
        percentage={percentage}
        barColor={getCrypto(assetTicker).color}
      />
    </div>
  );
};

export default AssetAllocation;

import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cardService } from "apis/services";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useCocosCard } from "context/CocosCardProvider";
import { ThemeVariants } from "interfaces/theme";
import { CardTypesLabel } from "interfaces/card/enums";
import CardDeleted from "assets/illustrations/CocosCard/CardDeleted";
import Mail from "assets/illustrations/Mail";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface WrongChargeBottomSheetProps {
  open: boolean;
  onClose: () => void;
}

const WrongChargeBottomSheet: React.FC<WrongChargeBottomSheetProps> = ({
  open,
  onClose,
}) => {
  const {
    physicalCard,
    virtualCard,
    getPomeloData,
    selectedCard,
  } = useCocosCard();
  const { isIOS } = getPlatform();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardDeleted, setIsCardDeleted] = useState<boolean>(false);

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const idCard = isPhysicalCard ? physicalCard?.idCard : virtualCard?.idCard;

  const deleteCard = async () => {
    try {
      setIsLoading(true);
      await API.post(cardService.cancel, {
        idCard,
        reason: "CLIENT_INTERNAL_REASON",
      });
      await getPomeloData();
      setIsCardDeleted(true);
      ampli.cardMovProblemDeleted({ card_type: selectedCard });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const mailToSupport = () => {
    ampli.cardMovProblemSupport({ card_type: selectedCard });
    window.location.href = `mailto:${ATENCION_CAPITAL_EMAIL}`;
  };

  const backToCard = () => {
    onClose();
    navigate(mainRoutes.card);
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      {isCardDeleted ? (
        <>
          <Text variant={TextVariants.RegularText} className={styles.title}>
            Contactate con soporte
          </Text>
          <div className={styles.sheetContent}>
            <Mail />
            <Text variant={TextVariants.RegularText} className={styles.text}>
              Envianos un mail a <span>atencion@cocos.capital</span> desde el
              correo que utilizás para ingresar a la aplicación.
            </Text>
            <Text variant={TextVariants.RegularText} className={styles.text}>
              Incluí el número de comprobante y documentación de respaldo
              relevante.
            </Text>
          </div>
        </>
      ) : (
        <>
          <Text variant={TextVariants.RegularText} className={styles.title}>
            Desconozco una compra
          </Text>
          <div className={styles.sheetContent}>
            <CardDeleted />
            <Text variant={TextVariants.RegularText} className={styles.text}>
              Por motivos de seguridad,{" "}
              <span>vamos a dar de baja tu tarjeta.</span> Solo podés desconocer
              compras que no hayas realizado.
            </Text>
          </div>
        </>
      )}

      <div
        className={classNames(styles.buttonWrapper, {
          [styles.ios]: isIOS,
        })}
      >
        {isCardDeleted ? (
          <>
            <FilledButton variant={ThemeVariants.Pay} onClick={mailToSupport}>
              Contactar a soporte
            </FilledButton>
            <OutlinedButton variant={ThemeVariants.Pay} onClick={backToCard}>
              Volver a tarjeta
            </OutlinedButton>
          </>
        ) : (
          <>
            <FilledButton
              variant={FilledButtonVariants.Warning}
              onClick={deleteCard}
              isLoading={isLoading}
            >
              Dar de baja y continuar
            </FilledButton>
            <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
              En otro momento
            </OutlinedButton>
          </>
        )}
      </div>
    </UIModalSlider>
  );
};

export default WrongChargeBottomSheet;

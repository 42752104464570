export enum OrderStatusLabel {
  PENDING = "Pendiente",
  CANCELLED = "Cancelada",
  PARTIALLY_CANCELLED = "Cancelada",
  EXECUTED = "Ejecutada",
  PARTIALLY_EXECUTED = "Ejecutada parcialmente",
  MARKET = "En mercado",
  REJECTED = "Rechazada",
}

export enum OrderStatusDescription {
  PENDING = "La órden está pendiente de envío.",
  CANCELLED = "La órden ha sido cancelada sin haberse ejecutado.",
  PARTIALLY_CANCELLED = "La órden ha sido cancelada con una ejecución parcial.",
  EXECUTED = "La órden ha sido ejecutada completamente.",
  PARTIALLY_EXECUTED = "La órden ha sido ejecutada parcialmente y sigue activa en el mercado.",
  MARKET = "La órden ha sido aceptada y está activa en el mercado.",
  REJECTED = "La órden ha sido rechazada por el mercado.",
}

import { ChangeEvent, Fragment, KeyboardEvent, useEffect, useRef } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { ThemeVariants } from "interfaces/theme";
import { ClipboardPaste } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import { X, Check } from "lucide-react";
import px2rem from "utils/px2rem";

import ButtonLabel from "../ButtonLabel";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export enum MfaChallengeStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

interface Code2FAProps {
  onChange: (value: string) => void;
  disabled: boolean;
  variant: ThemeVariants;
  codes: string[];
  setCodes: (value: string[]) => void;
  status?: MfaChallengeStatus | null;
}

const Code2FA: React.FC<Code2FAProps> = ({
  onChange,
  disabled,
  variant,
  codes,
  setCodes,
  status,
}) => {
  const inputRefs = Array.from({ length: 6 }, () =>
    useRef<HTMLInputElement>(null)
  );

  useEffect(() => {
    inputRefs[0].current?.focus();
  }, []);

  const handleOnChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.nativeEvent.inputType === "insertFromPaste") {
      handleOnPaste();
      return;
    }
    const value = event.target.value;
    const newCodes = [...codes];
    newCodes[index] = value.slice(-1);

    updateCode(newCodes);

    if (inputRefs[5].current?.focus()) {
      inputRefs[5].current?.blur();
    }

    if (
      value !== "" &&
      index < inputRefs.length - 1 &&
      inputRefs !== null &&
      inputRefs[index + 1] !== null &&
      inputRefs[index + 1].current !== null
    ) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const updateCode = (values: string[]) => {
    setCodes(values);
    onChange(values.join(""));
  };

  const handleOnPaste = async () => {
    trackAction(
      `${cryptoScreenNames.faCode} - Click Paste Code`,
      undefined,
      Projects.CRYPTO
    );

    try {
      const { value } = await Clipboard.read();
      const lastSix = value.slice(-6);
      const newCodes = lastSix.split("");
      updateCode(newCodes);
      inputRefs[5].current?.focus();
    } catch (error) {
      console.log(error);
    }
  };

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      const newCodes = [...codes];
      newCodes[index] = "";
      updateCode(newCodes);
      inputRefs[index - 1]?.current?.focus();
    }
  };

  const color =
    variant === ThemeVariants.Crypto ? "var(--purple800)" : "var(--blue800)";

  return (
    <div className={styles.code2FAContainer}>
      <div className={styles.inputs}>
        {inputRefs.map((ref, index) => (
          <Fragment key={index}>
            <input
              type="number"
              ref={ref}
              maxLength={1}
              className={styles.input}
              id={"input" + index}
              name={"input" + index}
              value={codes[index]}
              pattern="\d*"
              onChange={(e) => handleOnChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              disabled={disabled}
            />
            {index === 2 && (
              <Text variant={TextVariants.SemiboldText} color="var(--slate600)">
                -
              </Text>
            )}
          </Fragment>
        ))}
        {status &&
          (status === MfaChallengeStatus.ERROR ? (
            <X
              className={styles.errorBadge}
              color={"var(--white-to-black)"}
              size={16}
            />
          ) : (
            <Check
              className={styles.successBadge}
              color={"var(--white-to-black)"}
              size={16}
            />
          ))}
      </div>
      <ButtonLabel onClick={handleOnPaste}>
        <ClipboardPaste size={px2rem(16)} color={color} />
        <Text
          variant={TextVariants.SemiboldTextS}
          component={"span"}
          color={color}
        >
          Pegar código
        </Text>
      </ButtonLabel>
    </div>
  );
};

export default Code2FA;

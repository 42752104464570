import {
  PASSWORD_LETTER_CASES,
  PASSWORD_MAX_CHARACTERS,
  PASSWORD_NUMBERS_AND_SYMBOLS,
} from "constants/validation";

import { ChangeEvent, FC, useState } from "react";

import { Asterisk, CheckIcon } from "lucide-react";
import classNames from "classnames";
import { getPasswordRightIcon } from "components/page-reset-password";

import InputField, { InputTypes } from "../input/InputField";

import styles from "./styles.module.scss";

interface PasswordValidatorProps {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<any>) => void;
}

const PasswordBullet: FC<{ valid: boolean; text: string }> = ({
  valid,
  text,
}) => (
  <li
    className={classNames(styles.passwordListValidator, {
      [styles.passwordSuccess]: valid,
      [styles.passwordError]: !valid,
    })}
  >
    <span className={styles.passwordListIcon}>
      {valid ? <CheckIcon size="14" /> : <Asterisk size="14" />}
    </span>
    {text}
  </li>
);

const PasswordValidator = ({
  className,
  label,
  name = "password",
  placeholder,
  onChange,
  value,
}: PasswordValidatorProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classNames(className, styles.passwordValidator)}>
      <InputField
        id={name}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        type={showPassword ? InputTypes.Text : InputTypes.Password}
        iconRight={getPasswordRightIcon(showPassword)}
        onChange={onChange}
        onIconClick={() => setShowPassword(!showPassword)}
      />
      <ul className={styles.generatePasswordList}>
        <PasswordBullet
          valid={value.length >= PASSWORD_MAX_CHARACTERS}
          text="8 caracteres o más."
        />
        <PasswordBullet
          valid={PASSWORD_LETTER_CASES.test(value)}
          text="Tanto letras mayúsculas como minúsculas."
        />
        <PasswordBullet
          valid={PASSWORD_NUMBERS_AND_SYMBOLS.test(value)}
          text="Al menos un número y un símbolo (como .!@#$%^)."
        />
      </ul>
    </div>
  );
};

export default PasswordValidator;

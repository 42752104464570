import { useEffect } from "react";

import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const API_URL = import.meta.env.VITE_API_URL;

// Regular expression from the base API URL to match any path under it
const API_URL_REGEX = new RegExp(
  `^${API_URL.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}(/|$)`
);

const sentryConfig: Sentry.BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  // debug: process.env.NODE_ENV === "development",
  environment: process.env.NODE_ENV,
  enabled: false,
  ignoreErrors: [
    "[Meta Pixel]",
    "[bugsnag]",
    "/logout",
    "/login",
    "Invalid Refresh Token",
  ],
  beforeSend(_event) {
    return null;
  },
  integrations: [
    Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.httpClientIntegration({ failedRequestStatusCodes: [[400, 599]] }),
  ],
  normalizeDepth: 6,
  // tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1,
  tracesSampleRate: 0,
  tracePropagationTargets: ["localhost", API_URL_REGEX],
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1,
  replaysOnErrorSampleRate: 0,
  // replaysOnErrorSampleRate: 1.0,
};

if (process.env.NODE_ENV === "production") {
  sentryConfig.release =
    "cocos-frontend@" + import.meta.env.VITE_RELEASE_VERSION;
}

Sentry.init(sentryConfig);

import { useEffect } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import { Fingerprint } from "lucide-react";
import { useBiometry } from "hooks/useBiometry";
import { ampli } from "ampli";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

const EnableBiometryPage: React.FC = () => {
  const navigate = useNavigate();
  const { biometricsSignIn } = useBiometry();
  const user = useAppSelector(getLoggedUser);

  const closeBiometry = () => {
    if (user?.phone_confirmed || !user?.hasAccount) {
      navigate(mainRoutes.intro);
      return;
    }

    navigate(mainRoutes.phoneValidation);
  };

  const onClickEnableBiometry = async () => {
    await biometricsSignIn.setEnabled(true);
    closeBiometry();
    ampli.signupBiometricsActivated();
  };

  useEffect(() => {
    ampli.signupBiometricsScreenViewed();
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: closeBiometry,
      }}
      icon={Fingerprint}
      primaryButtonProps={{
        children: "Sí, usar datos biométricos",
        onClick: onClickEnableBiometry,
      }}
      secondaryButtonProps={{
        children: "En otro momento",
        onClick: closeBiometry,
      }}
      title="¿Querés usar datos biométricos?"
      subtitle="Configurá tu huella o reconocimiento facial para iniciar sesión más rápido. Esta opción estará disponible luego en tu perfil. "
    />
  );
};

export default EnableBiometryPage;

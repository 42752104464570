import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { ArrowLeft, Network } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NetworksInfoProps {
  hideInfo: () => void;
}

const NetworksInfo: React.FC<NetworksInfoProps> = ({ hideInfo }) => {
  return (
    <div className={styles.infoWrapper}>
      <div className={styles.infoHeader}>
        <ArrowLeft
          size={px2rem(24)}
          onClick={hideInfo}
          className={styles.icon}
        />
        <Text
          color="var(--slate900)"
          className={styles.textHeader}
          variant={TextVariants.RegularText}
        >
          Redes disponibles
        </Text>
      </div>
      <div className={styles.networkIconWrapper}>
        <Network size={px2rem(24)} />
      </div>

      <Heading
        color="var(--slate900)"
        variant={HeadingVariants.RegularTitle}
        component="h2"
        className={styles.infoTitle}
      >
        Acerca de las redes cripto
      </Heading>

      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularText}
        className={styles.question}
      >
        ¿Cómo verifico la red?
      </Text>
      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularTextS}
        className={styles.answer}
      >
        Ingresá a la plataforma o página oficial de la billetera de destino y
        chequeá cuáles son las redes disponibles para la moneda que vayas a
        transferir.
      </Text>

      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularText}
        className={styles.question}
      >
        ¿Por qué es importante verificarla?
      </Text>
      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularTextS}
        className={styles.answer}
      >
        Si realizás un envío por una red que la billetera de destino no utiliza,
        el dinero no te llegará ya que se trata de dos redes que no son
        compatibles entre sí y perderás tus fondos.
      </Text>

      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularText}
        className={styles.question}
      >
        ¿Qué es el costo de red?
      </Text>
      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularTextS}
        className={styles.answer}
      >
        Es un costo que se le paga a cada red de cripto para mantenerla
        funcionando.
      </Text>

      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularText}
        className={styles.question}
      >
        ¿Por qué varía la demora?
      </Text>
      <Text
        color="var(--slate800)"
        variant={TextVariants.RegularTextS}
        className={styles.answer}
      >
        La demora varía según la cantidad de transacciones pendientes a
        ejecutarse en cada red.
      </Text>
    </div>
  );
};

export default NetworksInfo;

import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { BalancePeriodTime } from "interfaces/portfolio";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";

export enum PortfolioScreen {
  PORTFOLIO = "PORTFOLIO",
  PORTFOLIO_CASH_DETAIL = "PORTFOLIO_CASH_DETAIL",
}

interface Context {
  selectedCurrencyDetail: Currencies;
  portfolioScreen: PortfolioScreen;
  selectedCurrency: BalanceCurrencies;
  selectedPeriod: BalancePeriodTime;
  setSelectedCurrency: (currency: BalanceCurrencies) => void;
  setSelectedCurrencyDetail: (currency: Currencies) => void;
  setPortfolioScreen: (screen: PortfolioScreen) => void;
  setSelectedPeriod: (screen: BalancePeriodTime) => void;
}

const PortfolioContext = createContext<Context | null>(null);

export const PortfolioProvider = ({ children }: { children: ReactNode }) => {
  const [selectedCurrency, setSelectedCurrency] = useState<BalanceCurrencies>(
    Currencies.ARS
  );
  const [selectedPeriod, setSelectedPeriod] = useState<BalancePeriodTime>(
    BalancePeriodTime.MAX
  );
  const [portfolioScreen, setPortfolioScreen] = useState<PortfolioScreen>(
    PortfolioScreen.PORTFOLIO
  );
  const [
    selectedCurrencyDetail,
    setSelectedCurrencyDetail,
  ] = useState<Currencies>(Currencies.ARS);

  const memoizedValues = useMemo(() => {
    return {
      selectedCurrency,
      selectedCurrencyDetail,
      selectedPeriod,
      portfolioScreen,
      setSelectedCurrency,
      setSelectedCurrencyDetail,
      setSelectedPeriod,
      setPortfolioScreen,
    };
  }, [
    selectedCurrency,
    selectedCurrencyDetail,
    selectedPeriod,
    portfolioScreen,
    setSelectedCurrency,
    setSelectedCurrencyDetail,
    setPortfolioScreen,
    setSelectedPeriod,
  ]);

  return (
    <PortfolioContext.Provider value={memoizedValues}>
      {children}
    </PortfolioContext.Provider>
  );
};

export default PortfolioContext;

export const usePortfolio = () => {
  const context = useContext(PortfolioContext);
  if (!context) {
    throw new Error("[PortfolioContext] Missing context");
  }
  return context;
};

import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency } from "utils";
import { Operations } from "interfaces/crypto/enums";

type SwapProps = {
  operationType: Operations.SWAP;
  quoteAmount: number;
  quoteInstrument: string;
};

type RestProps = {
  operationType:
    | Operations.BUY
    | Operations.RECEIVE
    | Operations.SELL
    | Operations.SEND
    | Operations.SETTLEMENT;
  baseInstrument: string;
  baseAmount: number;
};

type PrimaryInstrumentProps = SwapProps | RestProps;

const PrimaryInstrument: React.FC<PrimaryInstrumentProps> = (props) => {
  const getDecimals = (amount: number) => {
    if (Math.abs(amount) < 0.05) return 6;
    return 2;
  };
  const { operationType } = props;
  if (operationType === Operations.SWAP)
    return (
      <Text variant={TextVariants.SemiboldUpperValue} color="var(--slate900)">
        {formatToCurrency(
          props.quoteAmount,
          false,
          false,
          getDecimals(props.quoteAmount)
        )}{" "}
        <Text
          variant={TextVariants.RegularUpperValue}
          color="var(--slate800)"
          component="span"
        >
          {props.quoteInstrument}
        </Text>
      </Text>
    );

  return (
    <Text variant={TextVariants.SemiboldUpperValue} color="var(--slate900)">
      {formatToCurrency(
        props.baseAmount,
        false,
        false,
        getDecimals(props.baseAmount)
      )}{" "}
      <Text
        variant={TextVariants.RegularUpperValue}
        color="var(--slate800)"
        component="span"
      >
        {props.baseInstrument}
      </Text>
    </Text>
  );
};

export default PrimaryInstrument;

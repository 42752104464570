import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";

import styles from "./styles.module.scss";

interface InfoModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const InfoModal: React.FC<InfoModalProps> = ({ isOpen, closeModal }) => {
  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={closeModal}
      classes={styles.sheetWrapper}
    >
      <div className={styles.infoModalWrapper}>
        <Text
          color="var(--slate900)"
          className={styles.headingText}
          variant={TextVariants.RegularText}
        >
          Costo de transacción
        </Text>
        <Text
          color="var(--slate800)"
          className={styles.feeText}
          variant={TextVariants.RegularText}
        >
          $
          <Text
            component="span"
            color="var(--slate900)"
            className={styles.feeText}
            variant={TextVariants.SemiboldText}
          >
            0,00
          </Text>
        </Text>
        <div className={styles.feeCard}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            Costo
          </Text>
          <Text variant={TextVariants.SemiboldText} color="var(--green800)">
            Bonificado
          </Text>
        </div>
        <FilledButton onClick={closeModal} variant={ThemeVariants.Crypto}>
          Entendido
        </FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default InfoModal;

import { Cryptos } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { UIModalSlider } from "components/common/modal-slider";
import { CryptoCreateOrder } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";

import Confirmation from "./Confirmation";
import Error from "./Error";
import Preview from "./Preview";

import styles from "./styles.module.scss";

export enum TransactionSteps {
  PREVIEW = "PREVIEW",
  CONFIRMATION = "CONFIRMATION",
  ERROR = "ERROR",
}

interface UpdatePriceEvent {
  base_ticker?: Cryptos | Currencies.ARS;
  base_amount: number;
  base_quantity: number;
  quote_ticker?: Cryptos | Currencies.ARS;
  quote_amount: number;
  quote_quantity: number;
  exchange_amount: number;
  operation_type?: "buy" | "sell";
}

interface ConfirmationBottomSheetProps {
  isOpen: boolean;
  exchangeRate: number;
  isBuyOrSell?: boolean;
  onToggleDisplay: () => void;
  continueSendFlow?: () => void;
  quoteAmount?: string | number;
  baseAmount?: string | number;
  onConfirmTransaction: () => void;
  baseCurrency?: Cryptos | Currencies.ARS;
  quoteCurrency?: Cryptos | Currencies.ARS;
  operationType: string;
}

const ConfirmationBottomSheet: React.FC<ConfirmationBottomSheetProps> = ({
  isOpen,
  baseAmount,
  quoteAmount,
  isBuyOrSell,
  baseCurrency,
  exchangeRate,
  quoteCurrency,
  onToggleDisplay,
  continueSendFlow,
  onConfirmTransaction,
  operationType,
}) => {
  const [step, setStep] = useState<TransactionSteps>(TransactionSteps.PREVIEW);
  const [orderData, setOrderData] = useState<CryptoCreateOrder>();
  const [loading, setLoading] = useState<boolean>(false);

  const [remainingTime, setRemainingTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const isSell = quoteCurrency === Currencies.ARS;
  const isBuy = !isSell && isBuyOrSell;

  useEffect(() => {
    if (!isOpen) return;
    updatePrice();

    return () => {
      setRemainingTime(0);
    };
  }, [isOpen]);

  const startCountdown = (remainingSeconds: number) => {
    setRemainingTime(remainingSeconds);

    if (timer && remainingTime <= 0) {
      clearInterval(timer);
    }

    const tempId = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);

    setTimer(tempId);
  };

  const updatePrice = async () => {
    try {
      setLoading(true);

      const getEndpoint = () => {
        if (isBuyOrSell) {
          if (isSell) return cryptoService.createSell;
          return cryptoService.createBuy;
        }

        return cryptoService.createSwap;
      };

      const getPostData = () => {
        if (isBuyOrSell) {
          if (isSell)
            return {
              baseTicker: baseCurrency,
              baseQuantity: Number(baseAmount),
            };

          return {
            baseTicker: quoteCurrency,
            quoteQuantity: Number(baseAmount),
          };
        }

        return {
          baseTicker: baseCurrency,
          quoteTicker: quoteCurrency,
          baseQuantity: Number(baseAmount),
        };
      };

      const { data } = await API.post<CryptoCreateOrder>(
        getEndpoint(),
        getPostData()
      );

      const properties: UpdatePriceEvent = {
        base_ticker: baseCurrency,
        base_amount: Number(baseAmount),
        base_quantity: data.baseQuantity,
        quote_ticker: quoteCurrency,
        quote_amount: Number(quoteAmount),
        quote_quantity: data.quoteQuantity,
        exchange_amount: exchangeRate,
      };

      if (isBuyOrSell) {
        properties.operation_type = isBuy ? "buy" : "sell";
      }

      startCountdown(
        Math.floor(
          (new Date(data.expiresAt).getTime() - new Date().getTime()) / 1000
        )
      );
      setOrderData(data);
    } catch (error) {
      setStep(TransactionSteps.ERROR);
    } finally {
      setLoading(false);
    }
  };

  if (!baseCurrency || !quoteCurrency || !quoteAmount) return null;

  const closeModal = () => {
    onToggleDisplay();
    setStep(TransactionSteps.PREVIEW);
  };

  const getPrice = () => {
    ampli.cryptoOperationsUpdate({
      operation_type: operationType,
      base_ticker: baseCurrency,
      quote_ticker: quoteCurrency,
      base_quantity: orderData?.baseQuantity,
      quote_quantity: orderData?.quoteQuantity,
      exchange_rate: exchangeRate,
    });

    return updatePrice();
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={closeModal}
      classes={styles.sheetWrapper}
    >
      {step === TransactionSteps.PREVIEW && (
        <Preview
          setStep={setStep}
          order={orderData}
          isLoading={loading}
          closeModal={closeModal}
          setLoading={setLoading}
          updatePrice={getPrice}
          isBuyOrSell={isBuyOrSell}
          exchangeRate={exchangeRate}
          baseCurrency={baseCurrency}
          remainingTime={remainingTime}
          quoteCurrency={quoteCurrency}
          baseAmount={baseAmount}
          quoteAmount={quoteAmount}
          operationType={operationType}
        />
      )}

      {step === TransactionSteps.CONFIRMATION && orderData && (
        <Confirmation
          quoteAmount={
            isBuy ? orderData?.baseQuantity : orderData?.quoteQuantity
          }
          baseAmount={
            isBuy ? orderData?.quoteQuantity : orderData?.baseQuantity
          }
          baseCurrency={baseCurrency}
          quoteCurrency={quoteCurrency}
          continueSendFlow={continueSendFlow}
          onConfirmTransaction={onConfirmTransaction}
          operationType={operationType}
          exchangeRate={exchangeRate}
        />
      )}
      {step === TransactionSteps.ERROR && (
        <Error operationType={operationType} />
      )}
    </UIModalSlider>
  );
};

export default ConfirmationBottomSheet;

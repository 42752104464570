import { IconProps } from "../BTC";

const ETHIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="28" height="28" rx="14" fill="#E0E1E6" />
    <path
      d="M13.9983 4L13.8574 4.47857V18.3642L13.9983 18.5048L20.4437 14.6948L13.9983 4Z"
      fill="#343434"
    />
    <path
      d="M13.9983 4L7.55273 14.6948L13.9983 18.5048V11.7651V4Z"
      fill="#8C8C8C"
    />
    <path
      d="M13.9984 19.7252L13.9189 19.822V24.7683L13.9984 25.0001L20.4477 15.9172L13.9984 19.7252Z"
      fill="#3C3C3B"
    />
    <path
      d="M13.9983 25.0001V19.7252L7.55273 15.9172L13.9983 25.0001Z"
      fill="#8C8C8C"
    />
    <path
      d="M13.998 18.5047L20.4435 14.6948L13.998 11.765V18.5047Z"
      fill="#141414"
    />
    <path
      d="M7.55273 14.6948L13.9983 18.5047V11.765L7.55273 14.6948Z"
      fill="#393939"
    />
  </svg>
);

export default ETHIcon;

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { CryptoHolding } from "interfaces/api-responses";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface HoldingSectionProps {
  holding?: CryptoHolding;
  icon: React.FC<IconProps>;
}

const HoldingSection: React.FC<HoldingSectionProps> = ({
  holding,
  icon: Icon,
}) => {
  return (
    <div className={styles.holdingSectionContainer}>
      <div className={styles.balance}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Balance
        </Text>
        <div className={styles.balanceAmount}>
          <Icon size={px2rem(16)} />
          <Text
            variant={TextVariants.SemiboldUpperValue}
            color="var(--slate900)"
          >
            {holding?.quantity ?? 0}
          </Text>
        </div>
      </div>
      <div className={styles.value}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Valor
        </Text>
        <Currency amount={holding?.amountArs ?? 0} color="var(--slate900)" />
      </div>
    </div>
  );
};

export default HoldingSection;

import { useState } from "react";

import Amount from "components/common/Amount";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";
import Heading, { HeadingVariants } from "components/common/Heading";
import { UIModalSlider } from "components/common/modal-slider";
import FilledButton from "components/common/FilledButton";
import classNames from "classnames";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import styles from "./styles.module.scss";

interface PriceBoxProps {
  isBuy: boolean;
  isFixed: boolean;
  mepPrice: number;
}

const PriceBox: React.FC<PriceBoxProps> = ({ isBuy, isFixed, mepPrice }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const { isMobile } = useDeviceCheck();

  const heading = isFixed ? "Precio asegurado" : "Precio estimado";
  const side = (isBuy ? "Comprás" : "Vendés") + " a";

  const textColor = "var(--slate800)";

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          color={textColor}
          component="h2"
        >
          {heading}
        </Heading>
        {!isFixed && (
          <div className={styles.icon} onClick={() => setIsInfoModalOpen(true)}>
            <Info size={px2rem(16)} color={textColor} />
          </div>
        )}
      </div>
      <div className={styles.priceBox}>
        <div className={styles.priceRow}>
          <Text variant={TextVariants.RegularText} color={textColor}>
            {side}
          </Text>
          <div className={styles.price}>
            {!isFixed && (
              <Text variant={TextVariants.RegularText} color={textColor}>
                ≈
              </Text>
            )}
            <Amount amount={mepPrice} currency={Currencies.ARS} />
          </div>
        </div>
        {!isFixed && (
          <Text variant={TextVariants.RegularTextS} color={textColor}>
            Esta operación se realiza mediante bonos AL30.
          </Text>
        )}
      </div>
      <UIModalSlider
        open={isInfoModalOpen}
        classes={classNames(styles.sheetWrapper, {
          [styles.desktop]: !isMobile,
        })}
        onToggleDisplay={closeInfoModal}
      >
        <div className={styles.textWrapper}>
          <Heading
            component="h2"
            color="var(--slate900)"
            className={styles.title}
            variant={HeadingVariants.RegularSubtitle}
          >
            ¿Qué es el precio estimado?
          </Heading>
          <Text
            color={textColor}
            className={styles.text}
            variant={TextVariants.RegularText}
          >
            {isBuy
              ? "La compra de dólares a precio estimado consiste en la compra en pesos del bono AL30 Contado Inmediato para su posterior venta en dólares. Debido al parking, esta operación demora 24hs hábiles."
              : "La venta de dólares a precio estimado consiste en la compra en dólares del bono AL30 para su posterior venta en pesos. Esta operación no tiene parking y por lo tanto la acreditación de tus pesos es inmediata."}
          </Text>
          <Text
            color={textColor}
            className={styles.text}
            variant={TextVariants.RegularText}
          >
            {isBuy
              ? "Al depender de la cotización de un bono en el mercado (AL30), el precio al que comprás tus dólares puede fluctuar durante las 24hs que transcurren entre la compra y venta."
              : "Al depender de la cotización de un bono en el mercado (AL30), el precio al que vendés tus dólares puede fluctuar durante el tiempo que transcurre entre la compra y venta del bono.”"}
          </Text>
          <FilledButton onClick={closeInfoModal}>Entendido</FilledButton>
        </div>
      </UIModalSlider>
    </div>
  );
};

export default PriceBox;

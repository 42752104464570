import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "apis";
import { ordersService } from "apis/services";
import {
  BuyingPowerResponse,
  SellingPowerResponse,
} from "interfaces/api-responses";
import { SellingPower, Wallet } from "interfaces/wallet";

export interface WalletState {
  wallet?: Wallet | null;
  loading: boolean;
  sellingPower?: SellingPower;
}

export const initialState: WalletState = {
  wallet: undefined,
  loading: false,
  sellingPower: undefined,
};

const floorToTwoDecimals = (num: number) => {
  return Math.floor(num * 100) / 100;
};

export const getWallet = createAsyncThunk("wallet/getWallet", async () => {
  const { data } = await API.get<BuyingPowerResponse>(
    ordersService.buyingPower
  );

  const formattedData = {
    CI: {
      ars: floorToTwoDecimals(data.CI.ars),
      usd: floorToTwoDecimals(data.CI.usd),
      ext: floorToTwoDecimals(data.CI.ext),
    },
    "24hs": {
      ars: floorToTwoDecimals(data["24hs"].ars),
      usd: floorToTwoDecimals(data["24hs"].usd),
      ext: floorToTwoDecimals(data["24hs"].ext),
    },
  };

  return formattedData;
});

export const getSellingPower = createAsyncThunk(
  "wallet/getSellingPower",
  async (long_ticker: string) => {
    const { data } = await API.get<SellingPowerResponse>(
      ordersService.sellingPower,
      {
        params: { long_ticker: long_ticker },
      }
    );

    return { long_ticker, available: data };
  }
);

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    clearSellingPower(state) {
      state.sellingPower = initialState.sellingPower;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWallet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWallet.fulfilled, (state, action) => {
      state.loading = false;
      state.wallet = action.payload;
    });
    builder.addCase(getWallet.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSellingPower.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSellingPower.fulfilled, (state, action) => {
      state.loading = false;
      state.sellingPower = action.payload;
    });
    builder.addCase(getSellingPower.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearSellingPower } = walletSlice.actions;

export default walletSlice.reducer;

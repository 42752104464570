import { useEffect, useState } from "react";

import { ShieldCheck } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { useAuth } from "context/AuthProvider";
import BasicWrapper from "components/common/BasicWrapper";
import { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { updateShowTrustedDeviceQuestion } from "features/user/userSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import { trackScreen, trackAction } from "utils/amplitude";
import { Capacitor } from "@capacitor/core";
import { useBiometry } from "hooks/useBiometry";
import { useAppDispatch, useAppSelector } from "hooks/redux";

const TrustedDeviceScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { addTrustedDevice } = useAuth();
  const currentScreen = useAppSelector(getCurrentScreen);
  const user = useAppSelector(getLoggedUser);
  const { biometricsSignIn } = useBiometry();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateToNextRoute = async () => {
    if (
      Capacitor.isNativePlatform() &&
      (await biometricsSignIn.isEnabled()) === null
    )
      return navigate(mainRoutes.enableBiometry);

    if (user?.phone_confirmed) return navigate(mainRoutes.intro);

    return navigate(mainRoutes.phoneValidation);
  };

  useEffect(() => {
    trackScreen(screenNames.trustedDevice, currentScreen);
    dispatch(setCurrentScreen(screenNames.trustedDevice));
  }, []);

  const onClickSkip = () => {
    trackAction("Safe device - safe_device_not_saved");
    dispatch(updateShowTrustedDeviceQuestion(false));
    navigateToNextRoute();
  };

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await addTrustedDevice();
      trackAction("Safe device - safe_device_saved");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigateToNextRoute();
    }
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: onClickSkip,
      }}
      icon={ShieldCheck}
      title="¿Querés guardar este dispositivo como seguro?"
      subtitle="Al configurar tu dispositivo como seguro no te volveremos a pedir 2FA cada vez que accedas a la app."
      primaryButtonProps={{
        children: "Sí, guardar como dispositivo seguro",
        onClick: onConfirm,
        isLoading: isLoading,
      }}
      secondaryButtonProps={{
        children: "En otro momento",
        onClick: onClickSkip,
      }}
    />
  );
};

export default TrustedDeviceScreen;

import { getCrypto } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import SingleHoldingReturn from "components/common/SingleHoldingReturn";
import {
  PerformanceTabs,
  getType,
} from "components/page-crypto-performance/utils";
import { CryptoPerformance } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface PerformanceListProps {
  type: PerformanceTabs;
}

const PerformanceList: React.FC<PerformanceListProps> = ({ type }) => {
  const [list, setList] = useState<CryptoPerformance[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const MIN_THRESHOLD = 0.05;

  useEffect(() => {
    const getPerformanceData = async () => {
      try {
        setIsLoading(true);
        setError(false);
        const formattedType = getType(type);
        const { data } = await API.get<CryptoPerformance[]>(
          `${cryptoService.performance}/${formattedType}`
        );

        setList(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getPerformanceData();
  }, [type]);

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  if (error || isEmpty(list))
    return <ErrorPage errorMessage="No pudimos cargar tus rendimientos." />;

  return (
    <div className={styles.performanceListContainer}>
      {list.map(({ ticker, quantity, revenue, revenuePercentage }) => {
        const { icon, name } = getCrypto(ticker);
        const nDecimals = quantity < MIN_THRESHOLD ? 6 : 2;
        return (
          <SingleHoldingReturn
            key={ticker}
            icon={icon}
            name={name}
            price={revenue}
            ticker={`${formatToCurrency(
              quantity,
              false,
              false,
              nDecimals
            )} ${ticker}`}
            variation={revenuePercentage}
          />
        );
      })}
    </div>
  );
};

export default PerformanceList;

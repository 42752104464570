import { useMediaQuery } from "react-responsive";
import { DesktopDevice, TabletDevice, MobileDevice } from "utils/responsive";

export const useDeviceCheck = () => {
  const isDesktop = useMediaQuery({ minWidth: DesktopDevice.minWidth });
  const isTablet = useMediaQuery({
    minWidth: TabletDevice.minWidth,
    maxWidth: TabletDevice.maxWidth,
  });
  const isMobile = useMediaQuery({ maxWidth: MobileDevice.maxWidth });
  const isDesktopOrTablet = useMediaQuery({ minWidth: TabletDevice.minWidth });

  return {
    isMobile,
    isTablet,
    isDesktop,
    isDesktopOrTablet,
  };
};

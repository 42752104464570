import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import EmptyState from "components/common/EmptyState";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import Toast, { ToastVariants } from "components/common/Toast";
import CryptoCreateAccountPage, {
  TrackingReferral,
} from "components/page-crypto-create-account";
import { CryptoAccount } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { BookDashed, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import SearchInput from "components/common/SearchInput";
import { useAppDispatch } from "hooks/redux";

import AccountsList from "./AccountsList";

import styles from "./styles.module.scss";

const CryptoAccountsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<CryptoAccount[]>([]);
  const [newAccount, setNewAccount] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredAccounts, setFilteredAccounts] = useState<CryptoAccount[]>([]);

  useEffect(() => {
    dispatch(setCurrentScreen(cryptoScreenNames.agenda));
  }, []);

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const { data } = await API.get<CryptoAccount[]>(
          cryptoService.withdrawalAddresses
        );
        setAccounts(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getAccounts();
  }, [newAccount]);

  const handleClickAdd = () => {
    setNewAccount(true);
  };

  const handleCreateAccount = () => {
    setNewAccount(false);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  useEffect(() => {
    if (!accounts) return;

    const filterResults = accounts.filter((account) =>
      account.name.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredAccounts(filterResults);
  }, [searchValue]);

  useEffect(() => {
    setFilteredAccounts(accounts);
  }, [accounts]);

  if (newAccount) {
    return (
      <CryptoCreateAccountPage
        onCreateAccount={handleCreateAccount}
        onClickExit={() => setNewAccount(false)}
        referral={TrackingReferral.PROFILE}
      />
    );
  }

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorPage errorMessage="En este momento no es posible mostrar esta pagina." />
    );
  }

  return (
    <div className={styles.cryptoAccountsPageContainer}>
      <div className={styles.wrapper}>
        <PageHeader icon={X} title={"Agenda"} onClick={() => navigate(-1)} />
        {isEmpty(accounts) ? (
          <EmptyState
            title="Tu agenda está vacía"
            subtitle='Hacé click en "Agendar una cuenta" para agregar una dirección nueva'
            icon={BookDashed}
          />
        ) : (
          <>
            <SearchInput
              searchValue={searchValue}
              onChange={setSearchValue}
              placeholder="Buscá una cuenta agendada"
            />
            <AccountsList accounts={filteredAccounts} />
          </>
        )}
      </div>
      <FilledButton
        variant={ThemeVariants.Crypto}
        onClick={handleClickAdd}
        className={styles.button}
      >
        Agendar una cuenta
      </FilledButton>
      {open && (
        <Toast
          message="Registramos la dirección exitosamente"
          variant={ToastVariants.Success}
        />
      )}
    </div>
  );
};

export default CryptoAccountsPage;

import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import { IdentifyProperties, ampli } from "ampli";
import { Cookies } from "react-cookie";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export enum Projects {
  CAPITAL = "Capital",
  CRYPTO = "Crypto",
  PAY = "Pay",
  ONBOARDING = "Onboarding",
}

const cookies = new Cookies();
/**
 * @description function for track access to a screen,
 *  it receives current screen, previous screen and in some cases properties
 * like ticker or side operation
 */
export const trackScreen = (
  currentScreen: string,
  previousScreen: string,
  properties?: any | any[],
  project: Projects = Projects.CAPITAL
) => {
  const isCapital = project === Projects.CAPITAL;
  const projectReference = !isCapital ? `[${project}] - ` : "";
  const id_account = cookies.get("id_account");
  const eventProperties = {
    previousScreen,
    account: id_account,
    ...properties,
  };
  amplitude.track(
    `${projectReference}Screen - ${currentScreen}`,
    eventProperties
  );
};

/**
 * @description function for track an action on a screen, receives event name,
 * name sintax is "{Project} - {Screen} - {Action}", e.g: "Home - Click Profile".
 * And a receives a properties object too.
 */
export const trackAction = (
  event: string,
  properties?: any | any[],
  project: Projects = Projects.CAPITAL
) => {
  const isCapital = project === Projects.CAPITAL;
  const projectReference = !isCapital ? `[${project}] - ` : "";
  const id_account = cookies.get("id_account");
  const trackProps = {
    account: id_account,
    ...properties,
  };
  amplitude.track(`${projectReference}${event}`, trackProps);
};

/**
 * @description function for track an event on Amplitude.
 */
export const trackAmplitude = (event: string, properties?: any | any[]) => {
  const id_account = cookies.get("id_account");
  const trackProps = {
    account: id_account,
    ...properties,
  };
  amplitude.track(event, trackProps);
};

export const initializeAmplitude = async (deviceId: string | undefined) => {
  const config = {
    defaultTracking: {
      attribution: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
    logLevel:
      process.env.NODE_ENV === "production"
        ? amplitude.Types.LogLevel.None
        : amplitude.Types.LogLevel.Verbose,
    ...(deviceId && { deviceId: deviceId }),
  };

  await amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, config).promise;
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  // Initialize Ampli Wrapper
  await ampli.load({ client: { instance: amplitude } }).promise;

  const deviceInfo = await Device.getInfo();

  const userProps: IdentifyProperties = {
    device_platform: deviceInfo.platform,
    device_model: deviceInfo.model,
    device_os: deviceInfo.operatingSystem,
    device_os_version: deviceInfo.osVersion,
    device_manufacturer: deviceInfo.manufacturer,
  };

  if (Capacitor.isNativePlatform()) {
    const [appInfo, current] = await Promise.all([
      App.getInfo(),
      CapacitorUpdater.current(),
    ]);

    const instanceId = await FirebaseAnalytics.getAppInstanceId();
    userProps.app_instance_id = instanceId?.instanceId || undefined;
    userProps.version = current.bundle.version;
    userProps.app_version = appInfo.version;
    userProps.app_build = appInfo.build;
  }

  ampli.identify(undefined, userProps);
};

import BasicWrapper from "components/common/BasicWrapper";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ThemeVariants } from "interfaces/theme";
import { ShieldCheck } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ActiveSection: React.FC<{ variant?: ThemeVariants }> = ({
  variant = ThemeVariants.Capital,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();

  return (
    <BasicWrapper
      themeVariant={variant}
      navigationHeaderProps={
        isMobile
          ? {
              onClick: () => navigate(-1),
              title: "Autenticación de 2 pasos",
            }
          : undefined
      }
      icon={ShieldCheck}
      badgeColor="var(--green300)"
      badgeIconColor="var(--green900)"
      title="Tu cuenta está asegurada"
      subtitle="La autenticación de dos pasos está activada correctamente."
    />
  );
};

export default ActiveSection;

import { MAX_LIMIT_AMOUNT } from "components/page-cocos-card/constants";
import NumbersBadge from "components/common/Badges/NumbersBadge";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { Limits } from "components/page-crypto-limits/utils";
import { Currencies } from "interfaces/wallet";
import { ShoppingBag } from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import LimitAvailable from "./components/LimitAvailable";

import styles from "./styles.module.scss";

interface LimitProps {
  available: number;
  type: Limits;
  currency?: Currencies;
  setCardLimit: (v: number) => void;
}

const LimitData: React.FC<LimitProps> = ({
  available,
  setCardLimit,
  type,
  currency = Currencies.ARS,
}) => {
  return (
    <div className={styles.limitContainer}>
      <div className={styles.row}>
        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          <ShoppingBag size={px2rem(12)} color="var(--slate800)" />
          Límite de consumo
        </Text>
        <NumbersBadge
          className={styles.badge}
          variant={ThemeVariants.Pay}
          number={type}
        />
      </div>
      <LimitAvailable available={available} setCardLimit={setCardLimit} />
      <div className={styles.row}>
        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          Mín.:
          <Currency
            className={styles.currency}
            amount={0}
            ticker={currency}
            color="var(--slate900)"
          />
        </Text>

        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          Máx.:
          <Currency
            className={styles.currency}
            amount={MAX_LIMIT_AMOUNT}
            ticker={currency}
            color="var(--slate900)"
          />
        </Text>
      </div>
    </div>
  );
};

export default LimitData;

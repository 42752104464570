export interface OperationSchedule {
  startTime: string;
  endTime: string;
}

export enum Schedules {
  OVERNIGHT = "OVERNIGHT",
  MEP = "MEP",
  CI = "CI",
  CAUCION = "CAUCION",
  "24hs" = "24",
  SOBERANOS = "SOBERANOS",
}

export interface openHours {
  openHours: OperationSchedule[];
}

export type MarketSchedules = {
  [K in Schedules]: openHours;
};

import { Capacitor } from "@capacitor/core";

const getPlatform = () => {
  const platform = Capacitor.getPlatform();
  const isAndroid = platform === "android";
  const isIOS = platform === "ios";
  return {
    isAndroid,
    isIOS,
    isNative: isAndroid || isIOS,
  };
};

export default getPlatform;

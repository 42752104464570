import { Network } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import MfaChallenge from "components/common/MfaChallenge";
import Select from "components/common/NewSelect";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import InputField, { InputTypes } from "components/common/input/InputField";
import { MfaVerifyParams } from "context/AuthProvider";
import { CryptoAvailableNetworks } from "interfaces/api-responses";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { X } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { mfaErrorCheck } from "utils/mfa";
import { useAppDispatch } from "hooks/redux";

import SelectBottomSheet from "./SelectBottomSheet";

import styles from "./styles.module.scss";

export enum TrackingReferral {
  PROFILE = "profile",
  SEND = "send",
}

interface CryptoCreateAccountPageProps {
  onCreateAccount: () => void;
  onClickExit: () => void;
  referral?: TrackingReferral;
}

const CryptoCreateAccountPage: React.FC<CryptoCreateAccountPageProps> = ({
  onCreateAccount,
  onClickExit,
  referral,
}) => {
  const dispatch = useAppDispatch();

  const [code2FA, setCode2FA] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [availableNetworks, setAvailableNetworks] = useState<
    CryptoAvailableNetworks[]
  >([]);
  const [error, setError] = useState<boolean>();
  const [mfaError, setMfaError] = useState<MFAErrorType>();
  const [createError, setCreateError] = useState<string>("");

  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const isDisabled = !selectedNetwork || !name || !address;

  useEffect(() => {
    referral === TrackingReferral.PROFILE
      ? ampli.cryptoScreenAgenda({ previous_screen: TrackingReferral.PROFILE })
      : ampli.cryptoScreenSendAgenda({
          previous_screen: TrackingReferral.SEND,
        });
    dispatch(setCurrentScreen(cryptoScreenNames.agendaCreate));
  }, []);

  useEffect(() => {
    const getNetworks = async () => {
      try {
        const { data } = await API.get<CryptoAvailableNetworks[]>(
          cryptoService.networks
        );
        setAvailableNetworks(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    getNetworks();
  }, []);

  const handleCreateAddress = async (
    mfaParams: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    let hasError = false;
    try {
      const { data } = await API.post(cryptoService.withdrawalAddresses, {
        name,
        address,
        network: selectedNetwork?.name,
        ...mfaParams,
      });
      if (data) {
        onCreateAccount();
      }
      setCode2FA(false);
    } catch (error: any) {
      const mfaError = mfaErrorCheck(error);
      if (mfaError) {
        setMfaError(error);
      } else {
        setCreateError("Ocurrió un error al crear la cuenta");
        setCode2FA(false);
      }
      hasError = true;
    } finally {
      callAfterVerify();
    }
    return hasError;
  };

  const handleOnClick = () => {
    setLoading(true);
    setCode2FA(true);
    setLoading(false);
  };

  const onClickNetwork = (v: Network) => {
    setSelectedNetwork(v);
    setIsSelectOpen(false);
  };

  const onPaste = (v: string) => {
    setAddress(v);
  };

  if (error) {
    return (
      <ErrorPage errorMessage="No podemos mostrar esta página en este momento." />
    );
  }

  if (code2FA) {
    return (
      <MfaChallenge
        onClickBack={() => setCode2FA(false)}
        onVerify={handleCreateAddress}
        factorType={MFAType.TOTP}
        variant={ThemeVariants.Crypto}
        error={mfaError}
      />
    );
  }

  return (
    <div className={styles.cryptoCreateAccountPageContainer}>
      <div>
        <div className={styles.wrapper}>
          <PageHeader icon={X} title="Agendar" onClick={onClickExit} />
          <Select
            label="Red disponible"
            valueIcon={selectedNetwork?.icon}
            value={selectedNetwork?.name || ""}
            placeholder="Seleccionar"
            open={isSelectOpen}
            setOpen={setIsSelectOpen}
          >
            <SelectBottomSheet
              networks={availableNetworks}
              onClickNetwork={onClickNetwork}
            />
          </Select>
          <InputField
            placeholder="Ingresá un nombre"
            type={InputTypes.Text}
            label="Nombre"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <InputField
            placeholder="Ingresá la dirección"
            type={InputTypes.Text}
            label="Dirección"
            withPaste
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            value={address}
            onClickPaste={onPaste}
          />
        </div>
        {/*<ButtonLabel>
          <Scan size={16} color="var(--purple800)" />
          <Text
            variant={TextVariants.SemiboldTextS}
            component={"span"}
            color="var(--purple800)"
          >
            Escanear QR
          </Text>
  </ButtonLabel>*/}
      </div>
      <div className={styles.buttonWrapper}>
        {createError && (
          <Text
            className={styles.error}
            variant={TextVariants.SemiboldTextS}
            color="var(--red600)"
          >
            {createError}
          </Text>
        )}

        <FilledButton
          variant={ThemeVariants.Crypto}
          className={styles.button}
          onClick={handleOnClick}
          disabled={isDisabled}
          isLoading={loading}
        >
          Agendar
        </FilledButton>
      </div>
    </div>
  );
};

export default CryptoCreateAccountPage;

import { IconProps } from "assets/icons/BTC";
import Text, { TextVariants } from "components/common/Text";
import Variation from "components/common/CurrentVariation";
import px2rem from "utils/px2rem";

import Heading, { HeadingVariants } from "../../common/Heading";
import Currency from "../../common/Text/Currency";

import styles from "./styles.module.scss";

interface CryptoDetailProps {
  icon: React.FC<IconProps>;
  name: string;
  price: number;
  variation: number;
  dateToDisplay?: string;
}

const CryptoDetail: React.FC<CryptoDetailProps> = ({
  icon: Icon,
  name,
  price,
  variation,
  dateToDisplay,
}) => {
  return (
    <div className={styles.cryptoDetailContainer}>
      <Icon size={px2rem(48)} />
      <div className={styles.info}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h1"
          color="var(--slate800)"
        >
          {name}
        </Heading>
        <div className={styles.wrapper}>
          <Currency amount={price} color="var(--slate900)" />
          {dateToDisplay ? (
            <Text variant={TextVariants.RegularTextS}>{dateToDisplay}</Text>
          ) : (
            <Variation value={variation} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CryptoDetail;

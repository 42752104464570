import { Cryptos } from "constants/cryptos";

export interface CryptoPrices {
  ask: number;
  bid: number;
  last: number;
  ticker: Cryptos;
  varDailyPrice: number;
  varDailyPricePercentage: number;
}

export interface BankAccount {
  developerFeePercent: number;
  sourceCurrency: string;
  sourceBankName: string;
  sourceBankAddress: string;
  sourceBankRoutingNumber: string;
  sourceBankRoutingAccount: string;
  sourcePaymentRail: string;
  accountHolderFirstName: string;
  accountHolderLastName: string;
}

export enum FiatExternalCustomerStatus {
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  PENDING = "PENDING",
  INACTIVE = "INACTIVE",
}
export interface ExternalAccountData {
  idAccount: number;
  status: FiatExternalCustomerStatus;
  externalProvider: string;
  bankAccounts: BankAccount[];
}

import { EllipseIcon } from "assets/icons/ellipse";

interface HiddenValueProps {
  size?: number | string;
  color?: string;
  count?: number;
}

const HiddenValue: React.FC<HiddenValueProps> = ({
  count = 4,
  size,
  color,
}) => (
  <>
    {Array.from(Array(count).keys()).map((i) => (
      <EllipseIcon key={i} size={size} color={color} />
    ))}
  </>
);

export default HiddenValue;

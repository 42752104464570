import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

type PaymentMethodInfoProps = {
  open: boolean;
  onClose: () => void;
};

const PaymentMethodInfo: React.FC<PaymentMethodInfoProps> = ({
  open,
  onClose,
}) => {
  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <div className={styles.header}>
        <Text variant={TextVariants.RegularText} className={styles.headerTitle}>
          Métodos de pago
        </Text>
      </div>
      <Text variant={TextVariants.RegularText} className={styles.text}>
        Podés elegir hasta tres métodos de pago diferentes para realizar tus
        consumos.
      </Text>
      <Text variant={TextVariants.RegularText} className={styles.text}>
        Los mismos son acumulables, es decir, en caso de no tener el saldo
        suficiente al momento de realizar un pago con el primer método,
        sumaremos el saldo del segundo y el tercero si fuera necesario.
      </Text>
      <FilledButton
        variant={ThemeVariants.Pay}
        onClick={onClose}
        className={styles.button}
      >
        Entendido
      </FilledButton>
    </UIModalSlider>
  );
};

export default PaymentMethodInfo;

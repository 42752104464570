import Divider from "components/common/Divider";
import { CryptoActivity } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { ThemeVariants } from "interfaces/theme";
import EmptyState from "components/common/EmptyState";
import { Plus } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import formatDate from "utils/formatDate";

import Heading, { HeadingVariants } from "../Heading";
import ActivityCard from "./ActivityCard";
import ActivityDetail from "./ActivityDetail";

import styles from "./styles.module.scss";

interface ActivityListProps {
  activity?: CryptoActivity;
}

const ActivityList: React.FC<ActivityListProps> = ({ activity }) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const onClickActivity = (idOrder: string) => {
    setSearchParams({ id: idOrder }, { replace: true });
  };

  if (!activity || isEmpty(activity.data))
    return (
      <div className={styles.emptyStateWrapper}>
        <EmptyState
          icon={Plus}
          title="Agregá crypto para empezar"
          subtitle="Todas tus compras y transferencias crypto se mostrarán en tu portfolio"
          hasButton
          buttonText="Comprá crypto"
          onClick={() =>
            navigate(CryptoRoutes.SWAP, {
              state: { isBuying: true },
            })
          }
          buttonVariant={ThemeVariants.Crypto}
        />
      </div>
    );

  return (
    <>
      <div className={styles.listWrapper}>
        {activity.data.map(({ date, movements }) => (
          <div key={date}>
            <Heading
              variant={HeadingVariants.RegularSubtitle}
              className={styles.sideSpace}
              color={"var(--slate800)"}
              component="h2"
            >
              {formatDate(date)}
            </Heading>
            <div className={styles.dayWrapper}>
              {movements.map(({ idOrder, ...movement }, index) => (
                <div className={styles.sideSpace} key={idOrder}>
                  <ActivityCard
                    {...movement}
                    onClick={() => onClickActivity && onClickActivity(idOrder)}
                  />
                  {index !== movements.length - 1 && (
                    <Divider className={styles.divider} />
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ActivityDetail />
    </>
  );
};

export default ActivityList;

import React, { useEffect, useState } from "react";

import API from "apis";
import { homeService } from "apis/services";
import Accordion, { AccordionProps } from "components/common/Accordion";
import ErrorPage from "components/common/ErrorPage";
import PageHeader from "components/common/PageHeader";
import { useProfile } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { FaqsSections } from "interfaces/api-responses";
import { ArrowLeft } from "lucide-react";

import ProfileSectionTemplate from "../../components/SectionTemplate";

import styles from "./styles.module.scss";

const FaqsPage: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const { breadcrumbList, addBreadcrumbOption } = useProfile();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [faqs, setFaqs] = useState<FaqsSections[]>([]);
  const [
    selectedSection,
    setSelectedSection,
  ] = useState<AccordionProps | null>();

  const showAccordion =
    selectedSection && (isMobile || (!isMobile && breadcrumbList.length > 2));

  const sectionOptions = faqs.map(({ section_name }) => ({
    label: section_name,
    onClick: () => handleOnClickSection(section_name),
  }));

  useEffect(() => {
    const getFaqs = async () => {
      try {
        const { data } = await API.get<FaqsSections[]>(homeService.faqs);
        setFaqs(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getFaqs();
  }, []);

  const handleOnClickSection = (section_name: string) => {
    const filteredSection = faqs.reduce<AccordionProps | null>(
      (acc, section) => {
        if (section.section_name === section_name) {
          acc = {
            title: section.section_name,
            options: section.qa.map((qaItem) => ({
              label: qaItem.question,
              detail: qaItem.answer,
            })),
          };
        }
        return acc;
      },
      null
    );
    setSelectedSection(filteredSection);
    if (!isMobile && filteredSection?.title) {
      addBreadcrumbOption(filteredSection.title);
    }
  };

  if (error) {
    <ErrorPage
      errorMessage="En este momento no es posible mostrar esta pagina."
      withClose
      capitalTheme
    />;
  }

  if (showAccordion) {
    return (
      <div className={styles.faqsContainer}>
        {isMobile && (
          <PageHeader
            icon={ArrowLeft}
            iconColor="var(--blue900)"
            title="Preguntas frecuentes"
            onClick={() => setSelectedSection(undefined)}
            className={styles.title}
          />
        )}
        <Accordion
          title={isMobile ? selectedSection.title : undefined}
          options={selectedSection.options}
        />
      </div>
    );
  }

  return (
    <ProfileSectionTemplate
      title="Preguntas frecuentes"
      subtitle={isMobile ? "Preguntas" : undefined}
      options={sectionOptions}
      withHeader={isMobile}
      loading={isLoading}
    />
  );
};

export default FaqsPage;

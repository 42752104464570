import { Cryptos, getCrypto } from "constants/cryptos";

import { ChangeEvent, forwardRef } from "react";

import ARGFlag from "assets/icons/ARGFlag";
import CurrencySelect from "components/common/CurrencySelect";
import Balance from "components/page-crypto-swap/Transaction/Balance";
import { Currencies } from "interfaces/wallet";
import UseMaxButton from "components/common/UseMaxButton";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";
interface BaseBlockProps {
  onClickMax: () => void;
  onClickSelectCurrency?: () => void;
  baseCurrency?: Cryptos | Currencies.ARS;
  balance?: number | string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const BaseBlock = forwardRef<HTMLInputElement, BaseBlockProps>(
  (
    {
      onClickMax,
      onClickSelectCurrency,
      baseCurrency,
      balance = 0,
      disabled = false,
      onChange,
    },
    ref
  ) => {
    const isBuy = baseCurrency === Currencies.ARS;

    const getSelectIcon = () => {
      if (!baseCurrency) return;

      if (isBuy) return ARGFlag;

      return getCrypto(baseCurrency).icon;
    };

    const onChangeQuantity = (e: ChangeEvent<HTMLInputElement>) => {
      const withoutCommas = e.target.value.replace(",", ".");

      onChange(withoutCommas);
    };

    return (
      <div className={styles.baseWrapper}>
        <div className={styles.row}>
          <input
            ref={ref}
            type="text"
            placeholder="0"
            inputMode="decimal"
            className={styles.input}
            disabled={!baseCurrency}
            onChange={onChangeQuantity}
          />
          <CurrencySelect
            onClick={onClickSelectCurrency}
            label={baseCurrency}
            icon={getSelectIcon()}
            disabled={disabled}
            className={styles.currencySelect}
          />
        </div>
        <div className={styles.row}>
          <Balance
            withTicker={!isBuy}
            currency={baseCurrency}
            balance={balance}
          />
          <UseMaxButton variant={ThemeVariants.Crypto} onClick={onClickMax} />
        </div>
      </div>
    );
  }
);

export default BaseBlock;

import { ReactNode } from "react";

import Text, { TextVariants } from "components/common/Text";
import { ChevronRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface SelectProps {
  icon: LucideIcon;
  title: string;
  onClick: () => void;
  children: ReactNode;
}

const Select: React.FC<SelectProps> = ({
  icon: Icon,
  title,
  onClick,
  children,
}) => {
  return (
    <div className={styles.selectContainer} onClick={onClick}>
      <div className={styles.leftWrapper}>
        <Icon size={px2rem(20)} color="var(--purple900)" />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {title}
        </Text>
      </div>
      <div className={styles.rightWrapper}>
        {children}
        <ChevronRight size={px2rem(16)} color="var(--slate800)" />
      </div>
    </div>
  );
};

export default Select;

import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export enum IconButtonSizes {
  XSmall = "XSmall",
  Small = "Small",
  Large = "Large",
  XLarge = "XLarge",
}

interface IconButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ThemeVariants;
  size?: IconButtonSizes;
  icon: LucideIcon;
  iconSize?: number;
}

const IconButton: React.FC<IconButtonProps> = ({
  variant = ThemeVariants.Capital,
  size = IconButtonSizes.Small,
  className,
  icon: Icon,
  color = "var(--slate900)",
  iconSize = 16,
  ...props
}) => (
  <button
    {...props}
    className={classNames(
      styles.iconButtonContainer,
      className,
      styles[variant],
      styles[size]
    )}
  >
    <Icon
      size={px2rem(iconSize)}
      color={props.disabled ? "var(--slate600)" : color}
    />
  </button>
);

export default IconButton;

import { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  CircleDollarSign,
  Asterisk,
  Siren,
  MessageCircleQuestion,
  Trash2,
} from "lucide-react";
import mainRoutes from "router/routes";
import { CardTypesLabel } from "interfaces/card/enums";
import BasicWrapper from "components/common/BasicWrapper";
import { useCocosCard } from "context/CocosCardProvider";

import ListSection from "./ListSection";
import CancelBottomSheet from "./CancelBottomSheet";

const CocosCardSettings: React.FC = () => {
  const navigate = useNavigate();

  const { selectedCard } = useCocosCard();
  const [openCardDetails, setOpenCardDetails] = useState<boolean>(false);

  const isVirtualCard = selectedCard === CardTypesLabel.VIRTUAL;

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Ajustes",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  const menuOptions = [
    {
      icon: CircleDollarSign,
      title: "Cambiar límite diario",
      route: mainRoutes.cardLimits,
    },
    {
      icon: Asterisk,
      title: "Clave del cajero",
      route: mainRoutes.cardPin,
      hideSection: isVirtualCard,
    },
    {
      icon: Siren,
      title: "Problemas con mi tarjeta",
      route: mainRoutes.cardIssues,
    },
    {
      icon: MessageCircleQuestion,
      title: "Preguntas frecuentes",
      route: mainRoutes.cardHelp,
    },
    {
      icon: Trash2,
      title: "Dar de baja mi tarjeta",
      onClick: () => setOpenCardDetails(true),
      isWarning: true,
    },
  ];

  return (
    <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
      <ListSection menuOptions={menuOptions} subtitle="Administrá tu tarjeta" />

      <CancelBottomSheet
        open={openCardDetails}
        onClose={() => setOpenCardDetails(false)}
      />
    </BasicWrapper>
  );
};

export default CocosCardSettings;

import { getCrypto } from "constants/cryptos";

import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { MovementStatus } from "interfaces/api-responses";
import { Operations, operationsLabels } from "interfaces/crypto/enums";
import {
  ArrowDownToLine,
  ArrowRight,
  IterationCw,
  Minus,
  PlusIcon,
  RefreshCw,
  Send,
} from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";
import classNames from "classnames";
import type { Currencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import Amount from "components/common/Amount";

import PrimaryInstrument from "./PrimaryInstrument";
import SecondaryInstrument from "./SecondaryInstrument";
import StatusLabel from "./StatusLabel";

import styles from "./styles.module.scss";

const operationsIcons = {
  [Operations.SWAP]: RefreshCw,
  [Operations.SEND]: Send,
  [Operations.RECEIVE]: ArrowDownToLine,
  [Operations.BUY]: PlusIcon,
  [Operations.SELL]: Minus,
  [Operations.SETTLEMENT]: IterationCw,
};

export type ActivityCardProps = {
  onClick: () => void;
  operation: Operations;
  quoteTicker: string;
  quoteQuantity: number;
  baseQuantity: number;
  baseTicker: string;
  status: MovementStatus;
  variant?: ThemeVariants;
  currency?: Currencies;
  capitalIcon?: React.ReactNode;
};

const ActivityCard: React.FC<ActivityCardProps> = (props) => {
  const {
    capitalIcon = <ARGFlag />,
    currency,
    operation,
    baseTicker,
    onClick,
    variant = ThemeVariants.Crypto,
  } = props;
  const SecondaryIcon = operationsIcons[operation];
  const operationLabel = operationsLabels[operation];
  const isSwap = operation === Operations.SWAP;
  const isSell = operation === Operations.SELL;
  const isBuy = operation === Operations.BUY;
  const isCapital = variant === ThemeVariants.Capital;

  const MainIcon = getCrypto(baseTicker).icon;

  const getSecondaryText = () => {
    if (isBuy)
      return {
        leftInstrument: props.quoteTicker,
        rightInstrument: baseTicker,
      };

    if (isSell || isSwap)
      return {
        leftInstrument: baseTicker,
        rightInstrument: props.quoteTicker,
      };

    return { leftInstrument: baseTicker };
  };

  const secondaryText = getSecondaryText();

  const hasSecondaryInstrument = isBuy || isSell || isSwap;

  return (
    <div className={styles.activityCardWrapper} onClick={onClick}>
      <div className={styles.iconsWrapper}>
        {isCapital ? (
          <div className={styles.capitalIcon}>{capitalIcon}</div>
        ) : (
          <MainIcon size={px2rem(28)} />
        )}
        <SecondaryIcon
          size={px2rem(8)}
          className={classNames(styles.operationIcon, styles[variant])}
          color="var(--background-1)"
        />
      </div>
      <div className={styles.rowsWrapper}>
        <div className={styles.mainRow}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color="var(--slate900)"
          >
            {operationLabel}
          </Heading>
          {isCapital && currency ? (
            <Amount amount={props.baseQuantity} currency={currency} />
          ) : (
            <>
              {!isSwap ? (
                <PrimaryInstrument
                  baseAmount={props.baseQuantity}
                  baseInstrument={props.baseTicker}
                  operationType={operation}
                />
              ) : (
                <PrimaryInstrument
                  quoteAmount={props.quoteQuantity}
                  quoteInstrument={props.quoteTicker}
                  operationType={operation}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.secondaryRow}>
          <div className={styles.secondaryTextWrapper}>
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {secondaryText.leftInstrument}
            </Text>
            {secondaryText.rightInstrument && (
              <>
                <ArrowRight
                  size={px2rem(12)}
                  color="var(--slate800)"
                  className={styles.arrow}
                />
                <Text
                  variant={TextVariants.RegularTextS}
                  color="var(--slate800)"
                >
                  {secondaryText.rightInstrument}
                </Text>
              </>
            )}
          </div>

          {hasSecondaryInstrument ? (
            <SecondaryInstrument
              isCapital={isCapital}
              baseInstrument={props.baseTicker}
              baseAmount={props.baseQuantity}
              operationType={operation}
              quoteAmount={props.quoteQuantity}
            />
          ) : (
            <StatusLabel status={props.status} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;

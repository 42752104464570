import { Schedules } from "interfaces/calendar";
import { DolarTypes } from "interfaces/markets";
import { checkMarketOpen } from "store/selectors/markets.selector";

export const getDolarType = (isFixed: boolean) => {
  const isOvernight = checkMarketOpen(Schedules.OVERNIGHT);
  if (isFixed) {
    return isOvernight ? DolarTypes.OVERNIGHT : DolarTypes.CLOSE;
  }

  return DolarTypes.OPEN;
};

import { Wallet2 } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface UseMaxButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant: ThemeVariants;
  isShort?: boolean;
}

const UseMaxButton: React.FC<UseMaxButtonProps> = ({
  variant,
  isShort,
  ...props
}) => (
  <button className={classNames(styles.button, styles[variant])} {...props}>
    <Wallet2 size={px2rem(16)} className={styles.icon} />
    <Text
      variant={TextVariants.SemiboldTextS}
      className={styles.text}
      component="span"
    >
      {isShort ? "Usar máx." : "Usar máximo"}
    </Text>
  </button>
);

export default UseMaxButton;

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import LineChart from "components/common/LineChart";
import LoadingSpinner from "components/common/LoadingSpinner";
import { CryptoHistoricPortfolio } from "interfaces/api-responses";
import px2rem from "utils/px2rem";

interface ChartSectionProps {
  onChangePortfolio: (v?: { time: number; value: number }) => void;
  portfolioTotal: number;
}

export enum DateRange {
  ONE_WEEK,
  ONE_MONTH,
  SIX_MONTHS,
  ONE_YEAR,
}

const ChartSection: React.FC<ChartSectionProps> = ({
  onChangePortfolio,
  portfolioTotal,
}) => {
  const [chartData, setChartData] = useState<CryptoHistoricPortfolio>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<number>(DateRange.ONE_WEEK);

  const timeOptions = [
    { label: "1S", value: DateRange.ONE_WEEK },
    { label: "1M", value: DateRange.ONE_MONTH },
    { label: "6M", value: DateRange.SIX_MONTHS },
    { label: "1A", value: DateRange.ONE_YEAR },
  ];

  useEffect(() => {
    const getChartData = async () => {
      try {
        setLoading(true);
        const { data } = await API.get<CryptoHistoricPortfolio>(
          `${cryptoService.historicPortfolio}?dateRange=${selectedTime}`
        );
        const today = new Date().toISOString().split("T")[0];
        if (!data.historicHoldings.find(({ date }) => date === today)) {
          setChartData({
            historicHoldings: [
              ...data.historicHoldings,
              {
                date: today,
                amount: portfolioTotal,
              },
            ],
          });
        } else {
          setChartData(data);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getChartData();
    onChangePortfolio();
  }, [selectedTime]);

  if (loading)
    return (
      <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(194.82)} />
    );

  if (error || !chartData) return null;

  const mappedChartData = chartData.historicHoldings.map(
    ({ date, amount }) => ({ time: Date.parse(date), value: amount })
  );

  return (
    <div>
      <LineChart
        chartData={mappedChartData}
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        onChangeTime={setSelectedTime}
        onChangeCrosshair={onChangePortfolio}
      />
    </div>
  );
};

export default ChartSection;

import classNames from "classnames";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

type PageHeaderProps = {
  title?: string;
  onClick: () => void;
  icon: LucideIcon;
  className?: string;
  iconColor?: string;
  titleColor?: string;
} & (
  | {
      withSecondaryIcon: true;
      secondaryIcon: LucideIcon;
      secondaryIconColor: string;
      onClickSecondaryIcon: () => void;
    }
  | {
      withSecondaryIcon?: false;
    }
) &
  (
    | {
        withIconTitle: true;
        iconTitle: JSX.Element;
      }
    | {
        withIconTitle?: false;
      }
  );

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  onClick,
  icon: Icon,
  className,
  iconColor = "var(--purple900)",
  titleColor = "var(--slate800)",
  ...props
}) => {
  const SecondaryIcon = props.withSecondaryIcon ? props.secondaryIcon : null;

  const IconTitle = props.withIconTitle ? props.iconTitle : null;

  return (
    <div className={classNames(styles.titleContainer, className)}>
      <Icon
        size={px2rem(24)}
        onClick={onClick}
        color={iconColor}
        className={styles.icon}
      />
      {title && (
        <Text variant={TextVariants.RegularText} color={titleColor}>
          {title}
        </Text>
      )}
      {props.withIconTitle && IconTitle}
      {props.withSecondaryIcon && SecondaryIcon && (
        <SecondaryIcon
          size={px2rem(24)}
          onClick={props.onClickSecondaryIcon}
          color={props.secondaryIconColor}
        />
      )}
      {!SecondaryIcon && <div className={styles.empty} />}
    </div>
  );
};

export default PageHeader;

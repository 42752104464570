import { useEffect, useState } from "react";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Code2FA from "components/common/Code2FA";
import BasicWrapper from "components/common/BasicWrapper";
import API from "apis";
import { phoneService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import { screenNames } from "router/routes";
import { trackAction, trackScreen } from "utils/amplitude";
import { usePhoneValidation } from "context/PhoneValidationProvider";
import { Screens } from "interfaces/phoneValidation";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import { MfaChallengeStatus } from "components/common/Code2FA";

import styles from "./styles.module.scss";

dayjs.extend(utc);

const ConfirmCodePage = () => {
  const {
    setScreen,
    setChallengeData,
    challengeData,
    remainingTime,
    setRemainingTime,
    goToIntro,
    phone,
  } = usePhoneValidation();

  const codesInitialState = ["", "", "", "", "", ""];

  const [code, setCode] = useState<string>("");
  const [codes, setCodes] = useState<string[]>(codesInitialState);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [status, setStatus] = useState<MfaChallengeStatus | null>(null);

  useEffect(() => {
    trackScreen(screenNames.phoneValidationChallenge, "", {
      referral: "sign_in",
    });
    startCountdown(dayjs(challengeData.expires_at).diff(dayjs.utc(), "second"));

    return () => {
      setRemainingTime(0);
    };
  }, [challengeData.expires_at]);

  useEffect(() => {
    const isCodeCompleted = code.length === 6;
    setStatus(null);

    if (isCodeCompleted) {
      setError(false);

      const validateCode = async () => {
        setIsLoading(true);

        try {
          await API.put(phoneService.validation, {
            code_id: challengeData.id,
            code,
          });
          setStatus(MfaChallengeStatus.SUCCESS);
          trackAction("phone_verification_code_success", {
            referral: "sign_in",
          });
          setTimeout(() => {
            goToIntro();
          }, 1000);
        } catch {
          trackAction("phone_already_verified_error");
          setStatus(MfaChallengeStatus.ERROR);
          setError(true);
        } finally {
          setIsLoading(false);
        }
      };

      validateCode();
    }
  }, [code]);

  const startCountdown = (remainingSeconds: number) => {
    setRemainingTime(remainingSeconds);

    if (timer && remainingTime <= 0) {
      clearInterval(timer);
    }

    const tempId = setInterval(() => {
      setRemainingTime((prev: number) => prev - 1);
    }, 1000);

    setTimer(tempId);
  };

  const onChangeCode = (value: string) => {
    setCode(value);
  };

  const remainingTimeFormatted = dayjs(remainingTime * 1000).format("mm:ss");

  const sendCodeValidation = async () => {
    setIsLoading(true);
    setCodes(codesInitialState);
    setStatus(null);
    setError(false);

    try {
      const { data } = await API.post(phoneService.validation);
      setChallengeData(data);
    } catch (error: any) {
      throw new Error(`Error sending validation sms code: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        onClick: () => setScreen(Screens.PHONE_CONFIRMATION),
        withCloseIcon: false,
      }}
      title="Ingresá el código"
      subtitle={`Escribí o pegá el código enviado al: +${phone}`}
    >
      <Code2FA
        onChange={onChangeCode}
        disabled={isLoading}
        setCodes={setCodes}
        variant={ThemeVariants.Capital}
        codes={codes}
        status={status}
      />
      {error && (
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
          className={styles.errorMessage}
        >
          El código que ingresaste es incorrecto. Por favor, volvé a ingresar el
          código nuevamente.
        </Text>
      )}
      <div className={styles.buttonContainer}>
        <OutlinedButton
          onClick={sendCodeValidation}
          disabled={remainingTime >= 0}
        >
          {remainingTime >= 0
            ? `Reenviar código en ${remainingTimeFormatted}`
            : "Reenviar código"}
        </OutlinedButton>
      </div>
    </BasicWrapper>
  );
};

export default ConfirmCodePage;

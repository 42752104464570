import { SafeArea, SafeAreaInsets } from "capacitor-plugin-safe-area";

import getPlatform from "./getPlatform";

const setSafeAreaInsets = (insets: SafeAreaInsets) => {
  const { insets: safeArea } = insets;
  for (const [key, value] of Object.entries(safeArea)) {
    const { isAndroid } = getPlatform();

    if (!isAndroid || key !== "bottom") {
      document.documentElement.style.setProperty(
        `--safe-area-${key}`,
        `${value}px`
      );
    } else {
      document.documentElement.style.setProperty(`--safe-area-${key}`, "0px");
    }
  }
};

const setSafeArea = async () => {
  setSafeAreaInsets(await SafeArea.getSafeAreaInsets());

  SafeArea.addListener("safeAreaChanged", setSafeAreaInsets);
};

export default setSafeArea;

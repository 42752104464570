import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { ArrowDownUp, Info } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import px2rem from "utils/px2rem";

import InfoModal from "./InfoModal";

import styles from "./styles.module.scss";

interface TransactionFooterProps {
  onClickSwitch: () => void;
}

const TransactionFooter: React.FC<TransactionFooterProps> = ({
  onClickSwitch,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);

  const closeModal = () => {
    setIsInfoModalOpen(false);
  };

  const onClickSeeCost = () => {
    trackAction(
      `${cryptoScreenNames.swap} - Click Cost Info`,
      undefined,
      Projects.CRYPTO
    );
    setIsInfoModalOpen(true);
  };

  return (
    <div className={styles.transactionFooter}>
      <button className={styles.button} onClick={onClickSwitch}>
        <ArrowDownUp size={px2rem(16)} color="var(--purple800)" />
        <Text
          variant={TextVariants.SemiboldTextS}
          color="var(--purple800)"
          component="span"
        >
          Voltear
        </Text>
      </button>
      <div className={styles.taxWrapper} onClick={onClickSeeCost}>
        <Info size={px2rem(16)} color="var(--purple800)" />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Costo{" "}
          <Text
            variant={TextVariants.SemiboldUpperValueS}
            color="var(--slate800)"
            component="span"
          >
            $0.00
          </Text>
        </Text>
      </div>
      <InfoModal isOpen={isInfoModalOpen} closeModal={closeModal} />
    </div>
  );
};

export default TransactionFooter;

import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import mainRoutes from "router/routes";
import Heading, { HeadingVariants } from "components/common/Heading";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import Success from "assets/illustrations/Success";

import styles from "./styles.module.scss";

interface LimitSuccessProps {
  newLimit: number;
}

const LimitSuccess: React.FC<LimitSuccessProps> = ({ newLimit }) => {
  const navigate = useNavigate();

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "¡Listo!",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={navigationHeaderProps}
      primaryButtonProps={{
        children: "Volver a tarjetas",
        onClick: () => navigate(mainRoutes.card),
      }}
    >
      <div className={styles.successContainer}>
        <Success size={120} />
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Ya modificaste el límite diario de consumo de tu tarjeta.
        </Text>
        <div className={styles.limit}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            color="var(--slate800)"
            component="span"
          >
            Nuevo límite
          </Heading>
          <Currency color="var(--slate900)" amount={newLimit} />
        </div>
      </div>
    </BasicWrapper>
  );
};

export default LimitSuccess;

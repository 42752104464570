import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency } from "utils";
import { Operations } from "interfaces/crypto/enums";

interface SecondaryInstrumentProps {
  operationType: Operations.BUY | Operations.SELL | Operations.SWAP;
  baseAmount: number;
  baseInstrument: string;
  quoteAmount: number;
  isCapital: boolean;
}

const SecondaryInstrument: React.FC<SecondaryInstrumentProps> = ({
  operationType,
  isCapital,
  ...props
}) => {
  const { quoteAmount, baseInstrument, baseAmount } = props;
  const formatSign = (type: Operations) => {
    return type === Operations.BUY ? "+" : "-";
  };

  const mainColor = "var(--slate800)";
  const capitalLabel = `${formatSign(
    operationType
  )} ${quoteAmount} ${baseInstrument}`;

  if (isCapital) {
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        {capitalLabel}
      </Text>
    );
  }

  if (operationType === Operations.SWAP)
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        {formatToCurrency(baseAmount)} {baseInstrument}
      </Text>
    );

  if (operationType === Operations.SELL)
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        $ {formatToCurrency(quoteAmount)}
      </Text>
    );

  return (
    <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
      $ {formatToCurrency(quoteAmount)}
    </Text>
  );
};

export default SecondaryInstrument;

import { io } from "socket.io-client";
import { supabase } from "utils/supabase";

const BOOK_URL = import.meta.env.VITE_WEBSOCKET_URL + "/book";
const CRYPTO_URL = import.meta.env.VITE_WEBSOCKET_URL + "/crypto";

export const socket = io(BOOK_URL, {
  autoConnect: false,
  transports: ["websocket"],
  auth: (cb) => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (!error) {
        cb({ token: data.session?.access_token });
      }
    });
  },
});

export const cryptoSocket = io(CRYPTO_URL, {
  autoConnect: false,
  transports: ["websocket"],
  auth: (cb) => {
    supabase.auth.getSession().then(({ data, error }) => {
      if (!error) {
        cb({ token: data.session?.access_token });
      }
    });
  },
});

import { useEffect } from "react";

import { ampli } from "ampli";
import BulletList from "components/common/BulletList";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import {
  ArrowDownToLine,
  BadgePercent,
  Banknote,
  DollarSign,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import CableObBackground from "./CableObBackground";

import styles from "./styles.module.scss";

const CableOnboardingSheet: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ampli.cclCryptoOnboarding({ status: "onboarding" });
  }, []);

  const onClickContinue = () => {
    navigate(CryptoRoutes.CABLE);
  };

  return (
    <div>
      <Text
        variant={TextVariants.RegularText}
        className={styles.title}
        color="var(--slate900)"
      >
        Recibí tus dólares en USDC
      </Text>
      <Text
        variant={TextVariants.RegularText}
        className={styles.subtitle}
        color="var(--slate800)"
      >
        Si tenés una cuenta en el extranjero vas a poder enviar tus fondos a
        Cocos Crypto de forma rápida, simple y a bajo costo.
      </Text>
      <CableObBackground />
      <BulletList
        bullets={[
          { icon: DollarSign, text: "Costo por única vez de 10 US$" },
          { icon: BadgePercent, text: "Costo por transacción del 1%" },
          {
            icon: Banknote,
            text: "Monto mínimo de depósito de 200 US$",
          },
          {
            icon: ArrowDownToLine,
            text: "Tarifa fija de 0,50 US$ por depósito ACH",
          },
        ]}
        className={styles.bulletList}
      />

      <FilledButton
        className={styles.button}
        onClick={onClickContinue}
        variant={ThemeVariants.Crypto}
      >
        Continuar
      </FilledButton>
    </div>
  );
};

export default CableOnboardingSheet;

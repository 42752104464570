import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface SwitchProps {
  variant?: ThemeVariants;
  value: boolean;
  onClick?: () => void;
}

const Switch: React.FC<SwitchProps> = ({
  variant = ThemeVariants.Capital,
  value,
  onClick,
}) => (
  <div
    className={classNames(styles.switchContainer, styles[variant], {
      [styles.isActive]: value,
    })}
    onClick={onClick}
  >
    <div className={styles.indicator} />
  </div>
);

export default Switch;

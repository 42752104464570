import { SupportedStorage, createClient } from "@supabase/supabase-js";
import { Cookies } from "react-cookie";

function sessionStorageProvider(): SupportedStorage {
  const cookies = new Cookies();
  return {
    getItem: (key: string) => cookies.get(key),
    setItem: (key: string, value: string) => {
      cookies.set(key, value);
    },
    removeItem: (key: string) => {
      cookies.remove(key);
    },
  };
}

export const supabase = createClient(
  import.meta.env.VITE_API_URL,
  import.meta.env.VITE_SUPABASE_ANON_KEY,
  {
    auth: { storage: sessionStorageProvider() },
  }
);

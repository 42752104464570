import Heading, { HeadingVariants } from "components/common/Heading";

import AccordionItem, { AccordionItemProps } from "./AccordionItem";

import styles from "./styles.module.scss";

export interface AccordionProps {
  title?: string;
  options: AccordionItemProps[];
}

const Accordion: React.FC<AccordionProps> = ({ title, options }) => {
  return (
    <div className={styles.accordionContainer}>
      {title && (
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          color="var(--slate800)"
          className={styles.title}
        >
          {title}
        </Heading>
      )}
      <div className={styles.list}>
        {options.map(({ label, detail }) => {
          return <AccordionItem key={label} label={label} detail={detail} />;
        })}
      </div>
    </div>
  );
};

export default Accordion;

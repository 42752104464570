import { COPY_MESSAGE_TIME } from "constants/index";

import { useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import IconButton, { IconButtonSizes } from "components/common/IconButton";
import Skeleton, { SkeletonVariants } from "components/common/Skeleton";
import Text, { TextVariants } from "components/common/Text";
import { CheckCheck, Copy } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

interface NetworkAddressProps {
  network?: { icon: React.FC<IconProps>; name: string };
  address?: string;
}

const NetworkAddress: React.FC<NetworkAddressProps> = ({
  network,
  address,
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onClickCopy = async () => {
    try {
      await Clipboard.write({ string: address || "" });
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }

    trackAction(
      `${cryptoScreenNames.receive} - Click Copy Address`,
      undefined,
      Projects.CRYPTO
    );
  };

  const shortenedAddress = `${address?.substring(0, 7)}...${address?.substring(
    address.length - 10
  )}`;

  return (
    <div className={styles.networkAddressContainer}>
      <div className={styles.container}>
        <div className={styles.address}>
          {address ? (
            <>
              <Text
                variant={TextVariants.RegularText}
                color="var(--slate900)"
                className={classNames(styles.text, {
                  [styles.disabled]: !network,
                })}
              >
                Tu dirección de {network?.name}
              </Text>
              <Text
                variant={TextVariants.RegularTextS}
                color={!network ? "transparent" : "var(--slate800)"}
                className={classNames(styles.text, {
                  [styles.disabled]: !network,
                })}
              >
                {shortenedAddress}
              </Text>
            </>
          ) : (
            <>
              <Skeleton variant={SkeletonVariants.TEXT} height={8} />
              <Skeleton variant={SkeletonVariants.TEXT} height={8} />
            </>
          )}
        </div>
        <IconButton
          variant={ThemeVariants.Crypto}
          size={IconButtonSizes.Large}
          icon={copied ? CheckCheck : Copy}
          onClick={onClickCopy}
          disabled={!address}
        />
      </div>
    </div>
  );
};

export default NetworkAddress;

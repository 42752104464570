import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { SendMethod } from "context/CryptoSendProvider";
import { ChevronRight, Network } from "lucide-react";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";
import px2rem from "utils/px2rem";

import CocosTagBottomSheet from "../CocosTagBottomSheet";

import styles from "./styles.module.scss";

interface SendMethodSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectMethod: (value: SendMethod) => void;
}

const SendMethodSheet: React.FC<SendMethodSheetProps> = ({
  isOpen,
  onClose,
  onSelectMethod,
}) => {
  const [showTagIntro, setShowTagIntro] = useState<boolean>(false);
  const user = useAppSelector(getLoggedUser);
  const chevronColor = "var(--slate800)";

  const onCloseSheet = () => {
    setShowTagIntro(false);
    onClose();
  };

  if (!user) return null;

  const onClickMethod = (method: SendMethod) => {
    if (method === SendMethod.COCOSTAG && !user.tag) {
      setShowTagIntro(true);
      return;
    }

    onSelectMethod(method);
  };

  if (showTagIntro)
    return <CocosTagBottomSheet onClose={onCloseSheet} open={isOpen} />;

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onCloseSheet}
      classes={styles.sheetWrapper}
    >
      <div>
        <Text
          color="var(--slate900)"
          className={styles.title}
          variant={TextVariants.RegularText}
        >
          ¿Cómo vas a enviar?
        </Text>
        <div className={styles.contentWrapper}>
          <div
            onClick={() => onClickMethod(SendMethod.BLOCKCHAIN)}
            className={styles.rowWrapper}
          >
            <div className={styles.svg}>
              <Network color="var(--background-1)" size={px2rem(16)} />
            </div>
            <div className={styles.textWrapper}>
              <Text variant={TextVariants.RegularText} color="var(--slate900)">
                A través de la blockchain
              </Text>
              <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
                Transferí billeteras externas. Deberás pagar costos de retiro.
              </Text>
            </div>
            <ChevronRight color={chevronColor} />
          </div>
          <div className={styles.separatorWrapper}>
            <div className={styles.noSeparator} />
            <div className={styles.separator} />
          </div>
          <div
            onClick={() => onClickMethod(SendMethod.COCOSTAG)}
            className={styles.rowWrapper}
          >
            <div className={styles.svg}>
              <Text variant={TextVariants.RegularText}>🥥</Text>
            </div>
            <div className={styles.textWrapper}>
              <Text variant={TextVariants.RegularText} color="var(--slate900)">
                A través de cocostag
              </Text>
              <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
                Transferí entre cuentas de Cocos Crypto, rápido, fácil, y{" "}
                <Text
                  component="span"
                  variant={TextVariants.SemiboldTextS}
                  color="var(--green800)"
                >
                  sin costo
                </Text>
                .
              </Text>
            </div>
            <ChevronRight color={chevronColor} />
          </div>
        </div>
      </div>
    </UIModalSlider>
  );
};

export default SendMethodSheet;

import { Cryptos } from "constants/cryptos";

import { Currencies } from "interfaces/wallet";
import { formatToCurrency } from "utils";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface DetailsBodyProps {
  baseCurrency: Cryptos | Currencies.ARS;
  quoteCurrency: Cryptos | Currencies.ARS;
  baseAmount?: string | number;
  exchangeRate: number;
  isBuyOrSell: boolean;
}

const DetailsBody: React.FC<DetailsBodyProps> = ({
  baseAmount,
  baseCurrency,
  quoteCurrency,
  exchangeRate,
  isBuyOrSell,
}) => {
  const isSell = quoteCurrency === Currencies.ARS;
  const rightSide = isSell || !isBuyOrSell ? quoteCurrency : baseCurrency;

  const leftSide = isSell || !isBuyOrSell ? baseCurrency : quoteCurrency;

  return (
    <div className={styles.details}>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {isSell ? "Vender" : "Convertir"}
        </Text>
        <Text
          variant={TextVariants.SemiboldText}
          component="span"
          color="var(--slate900)"
        >
          {baseCurrency === Currencies.ARS
            ? formatToCurrency(baseAmount)
            : baseAmount}{" "}
          <Text
            variant={TextVariants.RegularText}
            component="span"
            color="var(--slate900)"
          >
            {baseCurrency}
          </Text>
        </Text>
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Cotización
        </Text>
        <Text
          variant={TextVariants.SemiboldText}
          component="span"
          color="var(--slate900)"
        >
          1 {leftSide} ≈ {exchangeRate.toFixed(exchangeRate > 0.05 ? 2 : 8)}{" "}
          {rightSide}
        </Text>
      </div>
    </div>
  );
};

export default DetailsBody;

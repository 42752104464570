import { useQuery } from "@tanstack/react-query";
import API from "apis";
import { userKeys } from "apis/queryKeyFactories";
import { userService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import { Tiers, TierResponse } from "interfaces/user";

interface useAccountTierQueryResponse {
  tier: Tiers;
  duty: [];
}

export const fetchAccountTier = async () => {
  const { data } = await API.get<TierResponse>(userService.accountTier);
  const goldRequested =
    data.tier === Tiers.FREE &&
    data.tierUpgrade.requested &&
    Tiers.GOLD_REQUESTED;

  const goldAvailable =
    !goldRequested &&
    data.tier !== Tiers.GOLD &&
    data.tierUpgrade.propose === "gold" &&
    Tiers.GOLD_AVAILABLE;

  return {
    tier: goldRequested || goldAvailable || data.tier,
    duty: data.aranceles,
  };
};

export const useAccountTierQuery = () => {
  return useQuery<useAccountTierQueryResponse, ErrorMessage>({
    staleTime: Infinity,
    queryKey: userKeys.accountTier,
    queryFn: fetchAccountTier,
    onError: () => {
      return {
        message: "Hubo un error para activar Cocos GOLD",
        status: 500,
      };
    },
  });
};

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import SectionHeader from "components/common/SectionHeader";
import { CryptoPrice } from "interfaces/api-responses";
import CryptoPage from "layouts/CryptoPageLayout";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import AssetsSection from "./AssetsSection";

import styles from "./styles.module.scss";

const CryptoMarketPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isLoading, setisLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [cryptos, setCryptos] = useState<CryptoPrice[]>();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    ampli.cryptoScreenMarket({
      previous_screen: previousScreen,
    });

    dispatch(setCurrentScreen(cryptoScreenNames.market));
  }, []);

  useEffect(() => {
    const getCryptos = async () => {
      try {
        const { data } = await API.get<CryptoPrice[]>(cryptoService.prices);
        setCryptos(data);
      } catch (error) {
        setError(true);
      } finally {
        setisLoading(false);
      }
    };
    getCryptos();
  }, []);

  return (
    <CryptoPage loading={isLoading} error={error}>
      <div className={styles.pageMarketContainer}>
        <SectionHeader title="Mercado" />
        <AssetsSection cryptos={cryptos} />
      </div>
    </CryptoPage>
  );
};

export default CryptoMarketPage;

import { ampli } from "ampli";
import ErrorModal, { ErrorModalButton } from "components/common/ErrorModal";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import { LiriumCoinagTyCVariants } from "../..";

import styles from "./styles.module.scss";

interface TyCErrorProps {
  error: boolean;
  onToggleDisplay: () => void;
  variant: LiriumCoinagTyCVariants;
}

const TyCError: React.FC<TyCErrorProps> = ({
  error,
  onToggleDisplay,
  variant,
}) => {
  const navigate = useNavigate();

  const linkColor =
    variant === LiriumCoinagTyCVariants.CRYPTO
      ? "var(--purple800)"
      : "var(--black-to-white)";

  const mailTo = () => {
    ampli.cryptoTycSupport();
    window.location.href = "mailto:atencion@cocoscrypto.com";
  };

  const backToCapital = () => {
    navigate(mainRoutes.home);
  };

  const primaryButton: ErrorModalButton = {
    title: "Contactar a soporte",
    onClick: mailTo,
  };

  const secondaryButton: ErrorModalButton = {
    title: "Ir a Cocos Capital",
    onClick: backToCapital,
  };
  return (
    <ErrorModal
      open={error}
      onToggleDisplay={onToggleDisplay}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      variant={ThemeVariants[variant]}
    >
      <Text
        className={styles.children}
        variant={TextVariants.RegularTextL}
        color="var(--slate800)"
      >
        No pudimos crear tu cuenta. Por favor, por cualquier consulta comunicate
        a{" "}
        <Text
          variant={TextVariants.SemiboldTextL}
          component="a"
          color={linkColor}
          href="mailto:atencion@cocoscrypto.com"
        >
          atencion@cocoscrypto.com
        </Text>
      </Text>
    </ErrorModal>
  );
};

export default TyCError;

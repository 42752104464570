import { ConnectionLostIcon } from "assets/icons/connection-lost";
import { Link, useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";

import Text, { TextVariants } from "../Text";
import FilledButton from "../FilledButton";
import Heading, { HeadingVariants } from "../Heading";
import OutlinedButton from "../OutlinedButton";

import styles from "./styles.module.scss";

interface ErrorContainerProps {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  textPrimaryButton?: string;
  textSecondaryButton?: string;
  urlPrimaryButton?: string;
  urlSecondaryButton?: string;
  onClickPrimaryButton?: () => void;
  onClickSecondaryButton?: () => void;
  icon?: JSX.Element;
  variant?: ThemeVariants;
}

const ErrorContainer: React.FC<ErrorContainerProps> = ({
  title = "Ups... hubo un error",
  subtitle,
  textPrimaryButton,
  textSecondaryButton,
  urlPrimaryButton,
  urlSecondaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  icon,
  variant = ThemeVariants.Capital,
}) => {
  const navigate = useNavigate();

  const handleClickPrimaryButton = () => {
    if (onClickPrimaryButton) {
      onClickPrimaryButton();
    }
    if (urlPrimaryButton) {
      navigate(urlPrimaryButton);
    }
  };

  const handleClickSecondaryButton = () => {
    if (onClickSecondaryButton) {
      onClickSecondaryButton();
    }
  };

  return (
    <div className={styles.componentErrorModal}>
      <div className={styles.errorContainer}>
        {icon ? icon : <ConnectionLostIcon />}
        <div className={styles.contentWrapper}>
          <Heading
            component="h2"
            className={styles.errorHeading}
            variant={HeadingVariants.SemiboldValue}
          >
            {title}
          </Heading>
          <Text variant={TextVariants.RegularTextL}>{subtitle}</Text>
          {(textPrimaryButton || textSecondaryButton) && (
            <div className={styles.buttonsWrapper}>
              {textPrimaryButton && (
                <FilledButton
                  variant={ThemeVariants[variant]}
                  onClick={handleClickPrimaryButton}
                >
                  {textPrimaryButton}
                </FilledButton>
              )}
              {textSecondaryButton &&
                (urlSecondaryButton ? (
                  <Link
                    onClick={handleClickSecondaryButton}
                    to={urlSecondaryButton}
                    className={styles.errorLink}
                  >
                    <OutlinedButton variant={ThemeVariants[variant]}>
                      {textSecondaryButton}
                    </OutlinedButton>
                  </Link>
                ) : (
                  <FilledButton
                    variant={ThemeVariants[variant]}
                    onClick={handleClickSecondaryButton}
                  >
                    {textSecondaryButton}
                  </FilledButton>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorContainer;

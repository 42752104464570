import Heading, { HeadingVariants } from "components/common/Heading";

import SectionRow, { SectionRowProps } from "./SectionRow";

import styles from "./styles.module.scss";

interface SectionListProps {
  title?: string;
  options: SectionRowProps[];
}

const SectionList: React.FC<SectionListProps> = ({ title, options }) => {
  return (
    <div className={styles.sectionListContainer}>
      {title && (
        <Heading
          className={styles.title}
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          color="var(--slate800)"
        >
          {title}
        </Heading>
      )}
      <div className={styles.list}>
        {options.map(
          ({
            icon,
            label,
            onClick,
            toggleValue,
            iconColor = "var(--blue900)",
            rightIcon,
            rightIconColor,
            show,
          }) => (
            <SectionRow
              key={label}
              icon={icon}
              label={label}
              onClick={onClick}
              toggleValue={toggleValue}
              iconColor={iconColor}
              rightIcon={rightIcon}
              rightIconColor={rightIconColor}
              show={show}
            />
          )
        )}
      </div>
    </div>
  );
};

export default SectionList;

import React, { ReactNode } from "react";

import { Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

import ButtonUI, { ButtonSizes, ButtonVariants } from "../button";

import "./index.scss";

interface AlertModalProps {
  alertIcon?: JSX.Element;
  open: boolean;
  headingText?: ReactNode | string;
  bodyText?: ReactNode | string;
  bodyContainer?: ReactNode;
  primaryButtonText?: string;
  onClickMainButton?: () => void;
  secondaryButtonText?: string;
  onClickSecondButton?: () => void;
  classes?: string;
  isFirstButtonDisabled?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} direction="up" {...props} />;
});

const AlertModal: React.FC<AlertModalProps> = ({
  alertIcon,
  open,
  headingText,
  bodyText,
  bodyContainer,
  primaryButtonText,
  onClickMainButton,
  secondaryButtonText,
  onClickSecondButton,
  classes,
  isFirstButtonDisabled = false,
}) => (
  <Dialog
    keepMounted
    TransitionComponent={Transition}
    className={`dialog-modal ${classes ? classes : ""}`}
    open={open}
  >
    <div className="component-alert-modal">
      <div className="alert-modal">
        {alertIcon && <div className="alert-icon">{alertIcon}</div>}
        {headingText && (
          <Typography component="p" variant="heading4_bold">
            {headingText}
          </Typography>
        )}
        <Typography className="subtitle" component="p" variant="textL">
          {bodyText}
        </Typography>
        {bodyContainer}
      </div>
      <div className="buttons-wrapper">
        {onClickMainButton && (
          <ButtonUI
            disabled={isFirstButtonDisabled}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Primary}
            onClick={onClickMainButton}
          >
            <Typography variant="buttonL">{primaryButtonText}</Typography>
          </ButtonUI>
        )}
        {onClickSecondButton && (
          <ButtonUI
            size={ButtonSizes.Full}
            variant={ButtonVariants.Secondary}
            onClick={onClickSecondButton}
          >
            <Typography variant="buttonL">{secondaryButtonText}</Typography>
          </ButtonUI>
        )}
      </div>
    </div>
  </Dialog>
);

export default AlertModal;

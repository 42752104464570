const Cable: React.FC = () => (
  <svg
    width="171"
    height="112"
    viewBox="0 0 171 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7813_29909)">
      <path
        d="M-3.29803 143.032L55.9022 177.212L107.171 88.4109C116.609 72.0631 111.008 51.1594 94.6606 41.721C78.3129 32.2826 57.4092 37.8837 47.9709 54.2314L-3.29803 143.032Z"
        fill="var(--slate200)"
      />
      <path
        d="M46.3917 126.522L105.592 160.701L156.861 71.9004C166.299 55.5526 160.698 34.6489 144.35 25.2105C128.003 15.7721 107.099 21.3732 97.6606 37.7209L46.3917 126.522Z"
        fill="var(--slate400)"
      />
      <g filter="url(#filter0_f_7813_29909)">
        <ellipse
          cx="68.3585"
          cy="68.4266"
          rx="68.3585"
          ry="68.4266"
          transform="matrix(-1 0 0 1 258.337 -45.6987)"
          fill="var(--slate100)"
        />
      </g>
      <g filter="url(#filter1_dd_7813_29909)">
        <g clipPath="url(#clip1_7813_29909)">
          <rect
            width="33.1408"
            height="65.0306"
            rx="16.5704"
            transform="matrix(-0.866024 -0.500002 -0.499998 0.866026 166.78 34.8955)"
            fill="var(--slate100)"
            fillOpacity="0.9"
            shapeRendering="crispEdges"
          />
          <g clipPath="url(#clip2_7813_29909)">
            <path
              d="M143.717 51.9544C149.38 51.9544 153.971 47.3636 153.971 41.7006C153.971 36.0376 149.38 31.4468 143.717 31.4468C138.054 31.4468 133.464 36.0376 133.464 41.7006C133.464 47.3636 138.054 51.9544 143.717 51.9544Z"
              fill="white"
            />
            <path
              d="M143.272 41.7003L153.971 41.7003C153.971 40.7748 153.848 39.8783 153.618 39.0254L143.272 39.0254L143.272 41.7003Z"
              fill="#D80027"
            />
            <path
              d="M143.272 36.3505L152.466 36.3505C151.838 35.3262 151.036 34.4209 150.1 33.6755L143.272 33.6755L143.272 36.3505Z"
              fill="#D80027"
            />
            <path
              d="M143.717 51.9539C146.13 51.9539 148.349 51.1199 150.1 49.7249L137.334 49.7249C139.086 51.1199 141.304 51.9539 143.717 51.9539Z"
              fill="#D80027"
            />
            <path
              d="M134.969 47.0502L152.466 47.0502C152.97 46.2279 153.361 45.3291 153.618 44.3752L133.817 44.3752C134.074 45.3291 134.465 46.2279 134.969 47.0502Z"
              fill="#D80027"
            />
            <path
              d="M138.213 33.0481L139.148 33.0481L138.278 33.6795L138.61 34.7012L137.741 34.0698L136.872 34.7012L137.159 33.8186C136.394 34.456 135.723 35.2029 135.17 36.035L135.47 36.035L134.916 36.437C134.83 36.5808 134.748 36.7269 134.668 36.8751L134.933 37.6882L134.44 37.3301C134.317 37.5897 134.205 37.8551 134.104 38.1261L134.395 39.022L135.47 39.022L134.601 39.6535L134.933 40.6752L134.063 40.0438L133.543 40.422C133.491 40.8409 133.464 41.2676 133.464 41.7006L143.717 41.7006C143.717 36.0376 143.717 35.37 143.717 31.4468C141.692 31.4468 139.803 32.0344 138.213 33.0481ZM138.61 40.6752L137.741 40.0438L136.872 40.6752L137.204 39.6535L136.335 39.022L137.409 39.022L137.741 38.0003L138.073 39.022L139.148 39.022L138.278 39.6535L138.61 40.6752ZM138.278 36.6665L138.61 37.6882L137.741 37.0568L136.872 37.6882L137.204 36.6665L136.335 36.035L137.409 36.035L137.741 35.0133L138.073 36.035L139.148 36.035L138.278 36.6665ZM142.288 40.6752L141.419 40.0438L140.55 40.6752L140.882 39.6535L140.013 39.022L141.087 39.022L141.419 38.0003L141.751 39.022L142.826 39.022L141.956 39.6535L142.288 40.6752ZM141.956 36.6665L142.288 37.6882L141.419 37.0568L140.55 37.6882L140.882 36.6665L140.013 36.035L141.087 36.035L141.419 35.0133L141.751 36.035L142.826 36.035L141.956 36.6665ZM141.956 33.6795L142.288 34.7012L141.419 34.0698L140.55 34.7012L140.882 33.6795L140.013 33.0481L141.087 33.0481L141.419 32.0263L141.751 33.0481L142.826 33.0481L141.956 33.6795Z"
              fill="#0052B4"
            />
          </g>
          <path
            d="M127.46 73.4202L127.46 63.4512M127.46 73.4202L123.187 69.1477M127.46 73.4202L131.732 69.1477M132.444 76.2684L122.475 76.2684"
            stroke="var(--purple900)"
            strokeWidth="1.42414"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <g filter="url(#filter2_dd_7813_29909)">
        <g clipPath="url(#clip3_7813_29909)">
          <rect
            width="33.1408"
            height="65.0306"
            rx="16.5704"
            transform="matrix(-0.866024 -0.500002 -0.499998 0.866026 102.78 65.8955)"
            fill="var(--slate100)"
            fillOpacity="0.9"
            shapeRendering="crispEdges"
          />
          <path
            d="M74.476 70.6789C74.476 68.9792 75.1511 67.3492 76.353 66.1473C77.5548 64.9455 79.1849 64.2703 80.8846 64.2703C82.6762 64.277 84.3958 64.9761 85.6839 66.2213L87.2932 67.8306M87.2932 67.8306L87.2932 64.2703M87.2932 67.8306L83.7328 67.8306M87.2932 70.6789C87.2932 72.3786 86.618 74.0086 85.4161 75.2105C84.2143 76.4123 82.5842 77.0875 80.8846 77.0875C79.093 77.0808 77.3733 76.3817 76.0852 75.1365L74.476 73.5272M74.476 73.5272L78.0363 73.5272M74.476 73.5272L74.476 77.0875"
            stroke="var(--purple900)"
            strokeWidth="1.42414"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <g clipPath="url(#clip4_7813_29909)">
            <path
              d="M64.6269 109.092C70.29 109.092 74.8807 104.501 74.8807 98.838C74.8807 93.175 70.29 88.5842 64.6269 88.5842C58.9639 88.5842 54.3732 93.175 54.3732 98.838C54.3732 104.501 58.9639 109.092 64.6269 109.092Z"
              fill="white"
            />
            <path
              d="M64.6272 88.5842C60.562 88.5842 57.0494 90.95 55.3909 94.3799L73.8635 94.3799C72.205 90.95 68.6924 88.5842 64.6272 88.5842Z"
              fill="#338AF3"
            />
            <path
              d="M64.6272 109.092C68.6924 109.092 72.205 106.726 73.8635 103.296L55.3909 103.296C57.0494 106.726 60.562 109.092 64.6272 109.092Z"
              fill="#338AF3"
            />
            <path
              d="M67.6916 98.838L66.4393 99.4271L67.1062 100.64L65.7464 100.38L65.5741 101.753L64.6269 100.743L63.6797 101.753L63.5074 100.38L62.1476 100.64L62.8144 99.427L61.5622 98.838L62.8145 98.2489L62.1476 97.0362L63.5074 97.2963L63.6798 95.9226L64.6269 96.9329L65.5741 95.9226L65.7464 97.2963L67.1062 97.0362L66.4394 98.249L67.6916 98.838Z"
              fill="#FFDA44"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_7813_29909"
        x="107.948"
        y="-59.3705"
        width="164.06"
        height="164.197"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="6.83587"
          result="effect1_foregroundBlur_7813_29909"
        />
      </filter>
      <filter
        id="filter1_dd_7813_29909"
        x="98.7283"
        y="13.1981"
        width="74.8876"
        height="86.5604"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.70897" />
        <feGaussianBlur stdDeviation="3.41793" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7813_29909"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.70897" />
        <feGaussianBlur stdDeviation="1.70897" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_7813_29909"
          result="effect2_dropShadow_7813_29909"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_7813_29909"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_7813_29909"
        x="34.7283"
        y="44.1981"
        width="74.8876"
        height="86.5604"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.70897" />
        <feGaussianBlur stdDeviation="3.41793" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7813_29909"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.70897" />
        <feGaussianBlur stdDeviation="1.70897" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.376471 0 0 0 0 0.392157 0 0 0 0 0.423529 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_7813_29909"
          result="effect2_dropShadow_7813_29909"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_7813_29909"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_7813_29909">
        <rect width="171" height="112" fill="white" />
      </clipPath>
      <clipPath id="clip1_7813_29909">
        <rect
          width="33.1408"
          height="65.0306"
          rx="16.5704"
          transform="matrix(-0.866024 -0.500002 -0.499998 0.866026 166.78 34.8955)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip2_7813_29909">
        <rect
          width="20.5076"
          height="20.5076"
          fill="white"
          transform="translate(133.464 31.4468)"
        />
      </clipPath>
      <clipPath id="clip3_7813_29909">
        <rect
          width="33.1408"
          height="65.0306"
          rx="16.5704"
          transform="matrix(-0.866024 -0.500002 -0.499998 0.866026 102.78 65.8955)"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip4_7813_29909">
        <rect
          width="20.5076"
          height="20.5076"
          fill="white"
          transform="translate(54.3732 88.5842)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Cable;

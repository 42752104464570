import { useState } from "react";

import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import PageHeader from "components/common/PageHeader";
import { ArrowLeft } from "lucide-react";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import Config2FAPassword from "./ConfigPassword";
import Config2FAStep from "./ConfigStep";

import styles from "./styles.module.scss";

interface Config2FAProps {
  onClickBack: () => void;
  onClickContinue: () => void;
  enrollData?: AuthMFAEnrollResponse;
}

const Config2FA: React.FC<Config2FAProps> = ({
  onClickBack,
  onClickContinue,
  enrollData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const isIOS = /iPhone|iPad|Macintosh/i.test(window.navigator.userAgent);
  const stepsData = [
    {
      title: "Instalá Google Authenticator",
      subtitle: (
        <>
          Descargá e instalá la aplicación de Google Authenticator desde&nbsp;
          {isIOS ? (
            <a href="https://apps.apple.com/ar/app/google-authenticator/id388497605">
              iOS App Store
            </a>
          ) : (
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_AR&gl=US">
              Google Play Store
            </a>
          )}
          .
        </>
      ),
    },
    {
      title: "Ingresá la clave de configuración",
      subtitle:
        "Copiá la clave de configuración e ingresala en Google Authenticator creando una nueva cuenta desde el botón “+” que figura abajo a la derecha.",
    },
    {
      title: "Copiá el código temporal",
      subtitle:
        "Aparecerá un código temporal de 6 dígitos en Google Authenticator con la cuenta que acabas de crear. Copialo y volvé a esta pantalla.",
    },
    {
      title: "Pegá el código temporal",
      subtitle:
        "Presioná “Continuar” para ingresar el código temporal de 6 dígitos y asociarlo a tu cuenta.",
    },
  ];

  const handleContinue = () => {
    trackAction(
      `${cryptoScreenNames.faConfig} - Click Continue`,
      undefined,
      Projects.CRYPTO
    );

    setLoading(true);
    onClickContinue();
    setLoading(false);
  };

  if (!enrollData)
    return (
      <div className={styles.config2FAContainer}>
        <div className={styles.wrapper}>
          <PageHeader
            title="Configurá Google Authenticator"
            icon={ArrowLeft}
            onClick={onClickBack}
          />
          <ErrorPage errorMessage="En este momento no es posible mostrar esta pagina." />
        </div>
      </div>
    );

  return (
    <div className={styles.config2FAContainer}>
      <div className={styles.wrapper}>
        <PageHeader
          title="Configurá Google Authenticator"
          icon={ArrowLeft}
          onClick={onClickBack}
        />
        {stepsData.map(({ title, subtitle }, index) => (
          <div className={styles.secondRow} key={index}>
            <Config2FAStep title={title} subtitle={subtitle} step={index + 1} />
            {index === 1 && <Config2FAPassword enrollData={enrollData} />}
          </div>
        ))}
      </div>

      <FilledButton
        variant={ThemeVariants.Crypto}
        className={styles.button}
        onClick={handleContinue}
        isLoading={loading}
      >
        Continuar
      </FilledButton>
    </div>
  );
};

export default Config2FA;

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import PageHeader from "components/common/PageHeader";
import SegmentedControl from "components/common/SegmentedControl";
import { ArrowLeft } from "lucide-react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";

import PerformanceList from "./PerformanceList";
import { PerformanceTabs } from "./utils";

import styles from "./styles.module.scss";

const CryptoPerformancePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const previousScreen = useAppSelector(getCurrentScreen);

  const tabOptions = [
    { name: PerformanceTabs.DAILY },
    { name: PerformanceTabs.HISTORIC },
  ];

  const [selectedTab, setSelectedTab] = useState<PerformanceTabs>(
    PerformanceTabs.DAILY
  );

  useEffect(() => {
    ampli.cryptoScreenPerformance({
      previous_screen: previousScreen,
    });

    dispatch(setCurrentScreen(cryptoScreenNames.portfolioPerformance));
  }, []);

  const onChangeTab = (option: PerformanceTabs) => {
    if (option === PerformanceTabs.GLOBAL) return; //Disable global performance for now
    setSelectedTab(option);
  };

  return (
    <div className={styles.cryptoPerformancePageContainer}>
      <PageHeader
        icon={ArrowLeft}
        title={"Rendimiento"}
        onClick={() => navigate(-1)}
      />
      <div>
        <SegmentedControl
          options={tabOptions}
          onClick={(option) => onChangeTab(option)}
          selected={selectedTab}
        />
        <PerformanceList type={selectedTab} />
      </div>
    </div>
  );
};

export default CryptoPerformancePage;

import { useEffect, useState } from "react";

import { ThemeVariants } from "interfaces/theme";
import Code2FA from "components/common/Code2FA";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { MailCheck, KeySquare } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackAction, trackScreen } from "utils/amplitude";
import { mfaErrorTypeMessage } from "utils/mfa";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

const CODE_LENGTH = 6;

interface ConfirmCodePageProps {
  onClickContinue: (mfaCode: string) => Promise<boolean>;
  onClickBack?: () => void;
  variant: ThemeVariants;
  factorType: MFAType;
  error?: MFAErrorType;
}

const ConfirmCodePage: React.FC<ConfirmCodePageProps> = ({
  onClickContinue,
  onClickBack,
  variant,
  factorType,
  error,
}) => {
  const dispatch = useAppDispatch();

  const codesInitialState = ["", "", "", "", "", ""];

  const [code, setCode] = useState<string>("");
  const [codes, setCodes] = useState<string[]>(codesInitialState);
  const regex = /^[0-9]{6}$/;
  const isDisabled = !regex.test(code);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const is2FA = [MFAType.TOTP, MFAType.ENROLL].includes(factorType);

  const getTitle = (type: MFAType) => {
    const statusMap: {
      [key in MFAType]: string;
    } = {
      [MFAType.TOTP]: "Ingresá el código",
      [MFAType.ENROLL]: "Ingresá el código",
      [MFAType.SMS]: "Revisá tu celular e ingresá el código",
      [MFAType.MAIL]: "Revisá tu mail e ingresá el código",
    };
    return statusMap[type];
  };

  const getInfoString = (type: MFAType) => {
    const statusMap: {
      [key in MFAType]: string;
    } = {
      [MFAType.TOTP]: "generado en Google Authenticator.",
      [MFAType.ENROLL]: "generado en Google Authenticator.",
      [MFAType.SMS]: "que enviamos a tu celular",
      [MFAType.MAIL]: "que te llegó por mail.",
    };
    return statusMap[type];
  };

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(
      cryptoScreenNames.faCode,
      previousScreen,
      undefined,
      Projects.CRYPTO
    );
    dispatch(setCurrentScreen(cryptoScreenNames.faCode));

    return () => {
      trackScreen(
        previousScreen,
        cryptoScreenNames.faCode,
        undefined,
        Projects.CRYPTO
      );
      dispatch(setCurrentScreen(previousScreen));
    };
  }, []);

  useEffect(() => {
    const isCodeCompleted = code.length === CODE_LENGTH;

    if (isCodeCompleted) {
      handleOnClickConfirm();
    }
  }, [code]);

  const onChangeCode = (value: string) => {
    setCode(value);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && !isDisabled) handleOnClickConfirm();
  };

  const handleOnClickBack = () => {
    if (onClickBack) {
      onClickBack();
      return;
    }
    navigate(-1);
  };

  const handleOnClickConfirm = async () => {
    trackAction(
      `${cryptoScreenNames.faCode} - Activate`,
      undefined,
      Projects.CRYPTO
    );

    setIsLoading(true);
    const hasError = await onClickContinue(code);
    if (hasError) {
      setCodes(codesInitialState);
      setCode("");
    }
    setIsLoading(false);
  };

  return (
    <BasicWrapper
      themeVariant={variant}
      navigationHeaderProps={{
        onClick: handleOnClickBack,
        title: `Código de ${CODE_LENGTH} dígitos`,
      }}
      title={getTitle(factorType)}
      subtitle={`Escribí o pegá el código temporal de ${CODE_LENGTH} dígitos ${getInfoString(
        factorType
      )}`}
      icon={is2FA ? KeySquare : MailCheck}
    >
      <div className={styles.confirmCodePageContainer} onKeyDown={onKeyDown}>
        <Code2FA
          onChange={onChangeCode}
          disabled={isLoading}
          setCodes={setCodes}
          variant={variant}
          codes={codes}
        />
        {error && (
          <Text
            color="var(--red800)"
            variant={TextVariants.RegularTextS}
            className={styles.error}
          >
            {is2FA
              ? mfaErrorTypeMessage[error]
              : "El código que ingresaste es incorrecto. Por favor, volvé a ingresar el código que te llego " +
                (factorType === MFAType.SMS ? "por mensaje" : "por mail") +
                " nuevamente."}
          </Text>
        )}
      </div>
    </BasicWrapper>
  );
};

export default ConfirmCodePage;

import CryptoCablePage from "components/page-crypto-cable";
import { CryptoCableProvider } from "context/CryptoCableProvider";

const Cable: React.FC = () => (
  <CryptoCableProvider>
    <CryptoCablePage />
  </CryptoCableProvider>
);

export default Cable;

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import ErrorContainer from "components/common/error-modal";
import mainRoutes from "router/routes";
import { TokenType } from "interfaces/auth";
import LoaderAndRedirect from "components/common/LoaderAndRedirect";
import { useAuth } from "context/AuthProvider";

const ChangePasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, error, clearErrors, verifyUserEmail } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const handleOnClickPrimaryButton = () => {
    clearErrors();
    navigate(mainRoutes.forgotPassword);
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(mainRoutes.resetPassword);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    verifyUserEmail(token, TokenType.RECOVERY);
  }, []);

  if (error || !token)
    return (
      <ErrorContainer
        subtitle={
          error || "Token inválido, por favor intente de nuevo más tarde."
        }
        textPrimaryButton="Volver a intentar"
        onClickPrimaryButton={handleOnClickPrimaryButton}
      />
    );

  return <LoaderAndRedirect />;
};

export default ChangePasswordPage;

import { useState } from "react";

import PageHeader from "components/common/PageHeader";
import Toast, { ToastVariants } from "components/common/Toast";
import CryptoCreateAccountPage, {
  TrackingReferral,
} from "components/page-crypto-create-account";
import { CryptoAccount } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useCryptoSend } from "context/CryptoSendProvider";

import AccountList from "./AccountList";
import NoAccounts from "./NoAccounts";

import styles from "./styles.module.scss";

interface AccountsSectionProps {
  title: string;
  accounts: CryptoAccount[];
  onCreateAccount: () => void;
}

const AccountsSection: React.FC<AccountsSectionProps> = ({
  title,
  accounts,
  onCreateAccount,
}) => {
  const navigate = useNavigate();
  const { setSelectedAccount } = useCryptoSend();

  const [newAccount, setNewAccount] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const noAccounts = isEmpty(accounts);

  const handleClickAdd = () => {
    setNewAccount(true);
  };

  const handleCreateAccount = () => {
    onCreateAccount();
    setNewAccount(false);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  if (newAccount)
    return (
      <CryptoCreateAccountPage
        onCreateAccount={handleCreateAccount}
        onClickExit={() => setNewAccount(false)}
        referral={TrackingReferral.SEND}
      />
    );

  return (
    <div className={styles.accountsSectionContainer}>
      <PageHeader title={title} onClick={() => navigate(-1)} icon={X} />
      {noAccounts && <NoAccounts onClickButton={() => setNewAccount(true)} />}

      {!noAccounts && (
        <AccountList
          accounts={accounts}
          onClickAccount={(acc) => setSelectedAccount(acc)}
          handleClickAdd={handleClickAdd}
        />
      )}
      {open && (
        <Toast
          message="Registramos la dirección exitosamente"
          variant={ToastVariants.Success}
        />
      )}
    </div>
  );
};

export default AccountsSection;

import { Capacitor } from "@capacitor/core";
import { FacebookLogin } from "@capacitor-community/facebook-login";

export enum FbEvents {
  HOME_CLIC_COMPRAR_MEP = "fb_home_clic_comprar_mep",
  MEP_CLIC_PASO2_CONTINUAR_COMPRA_MEP = "fb_mep_clic_paso2_continuar_compra_mep",
  MEP_EXITO_FIN_COMPRA_MEP = "fb_mep_exito_fin_compra_mep",
  HOME_CLIC_IR_A_MERCADO = "fb_home_clic_ir_a_mercado",
  HOME_CLIC_ABRIR_COMITENTE = "fb_home_clic_abrir_comitente",
  ALTAS_CLIC_PASO1_CREAR_CUENTA = "fb_altas_clic_paso1_crear_cuenta",
  HOME_EXITO_PASO1_PROCESO_COMITENTE = "fb_home_exito_paso1_proceso_comitente",
  HOME_EXITO_PASO2_PROCESO_COMITENTE = "fb_home_exito_paso2_proceso_comitente",
  HOME_EXITO_PASO3_PROCESO_COMITENTE = "fb_home_exito_paso3_proceso_comitente",
  HOME_EXITO_PASO4_PROCESO_COMITENTE = "fb_home_exito_paso4_proceso_comitente",
  HOME_EXITO_PASO5_PROCESO_COMITENTE = "fb_home_exito_paso5_proceso_comitente",
  HOME_EXITO_PROCESO_COMITENTE = "fb_home_exito_proceso_comitente",
  HOME_REJECTED_PROCESO_COMITENTE = "fb_home_rejected_proceso_comitente",
  HOME_ERROR_PROCESO_COMITENTE = "fb_home_error_proceso_comitente",
  ALTAS_EXITO_FIN_REGISTRO_USUARIO = "fb_altas_exito_fin_registro_usuario",
  ClIC_COCOA_USD = "fb_clic_cocoa_usd",
  SUSCRIPCION_COCOA_USD_REVISAR_ORDEN = "fb_suscripcion_cocoa_usd_revisar_orden",
  SUSCRIPCION_CONFIRMAR_ORDEN_COCOA_USD = "fb_suscripcion_cocoa_confirmar_orden",
  SUSCRIPCION_COCOA_USD = "fb_suscripcion_exito_cocoa_usd",
}

export const trackFbEvent = (eventName: string) => {
  if (!Capacitor.isNativePlatform()) return null;

  FacebookLogin.logEvent({
    eventName,
  });
};

import Balance from "components/common/Balance";
import Heading, { HeadingVariants } from "components/common/Heading";

import styles from "./styles.module.scss";

interface BalanceSectionProps {
  available: number;
  variation: number;
  dateToDisplay?: string;
}

const BalanceSection: React.FC<BalanceSectionProps> = ({
  available,
  variation,
  dateToDisplay,
}) => {
  const variationAmount = variation * available;
  return (
    <div className={styles.balanceSectionContainer}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularTitle}
        color="var(--slate800)"
      >
        Balance total
      </Heading>
      <Balance
        available={available}
        variation={variation}
        amount={variationAmount}
        dateToDisplay={dateToDisplay}
      />
    </div>
  );
};

export default BalanceSection;

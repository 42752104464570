import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MessagesSquare } from "lucide-react";
import { useCocosCard } from "context/CocosCardProvider";
import { CardTypesLabel } from "interfaces/card/enums";
import BasicWrapper from "components/common/BasicWrapper";
import ListSection from "components/page-cocos-card-settings/ListSection";
import mainRoutes from "router/routes";

import LostBottomSheet from "./LostBottomSheet";
import StolenBottomSheet from "./StolenBottomSheet";

const PageIssues: React.FC = () => {
  const navigate = useNavigate();
  const { selectedCard } = useCocosCard();
  const [isLostBottomSheetOpen, setIsLostBottomSheetOpen] = useState<boolean>(
    false
  );
  const [
    isStolenBottomSheetOpen,
    setIsStolenBottomSheetOpen,
  ] = useState<boolean>(false);

  const isVirtualCard = selectedCard === CardTypesLabel.VIRTUAL;

  const menuOptions = [
    {
      title: "Perdí mi tarjeta",
      onClick: () => setIsLostBottomSheetOpen(true),
      hideSection: isVirtualCard,
    },
    {
      title: "Hay movimientos que no hice",
      route: mainRoutes.cardIssuesMovements,
    },
    {
      title: isVirtualCard
        ? "Me robaron los datos de mi tarjeta"
        : "Me robaron mi tarjeta",
      onClick: () => setIsStolenBottomSheetOpen(true),
    },
    {
      title: "Otros",
      route: mainRoutes.chat,
      hideSection: !isVirtualCard,
    },
  ];

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Problemas",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
    rightIcon: <MessagesSquare color="var(--black-to-white)" />,
    rightIconClick: () => navigate(mainRoutes.chat),
  };

  return (
    <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
      <ListSection menuOptions={menuOptions} subtitle="Informar un problema" />

      <LostBottomSheet
        open={isLostBottomSheetOpen}
        onClose={() => setIsLostBottomSheetOpen(false)}
      />
      <StolenBottomSheet
        open={isStolenBottomSheetOpen}
        onClose={() => setIsStolenBottomSheetOpen(false)}
        isVirtualCard={isVirtualCard}
      />
    </BasicWrapper>
  );
};

export default PageIssues;

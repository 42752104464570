import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import {
  ExternalAccountData,
  FiatExternalCustomerStatus,
} from "interfaces/crypto";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import LoadingSpinner from "../LoadingSpinner";
import { UIModalSlider } from "../modal-slider";
import CableOnboardingSheet from "./CableOnboarding";
import CreationFailed from "./CableOnboarding/CreationFailed";
import CreationInProgress from "./CableOnboarding/CreationInProgress";

import styles from "./styles.module.scss";

interface CableSheetProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const CableSheet: React.FC<CableSheetProps> = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [displayPendingPage, setDisplayPendingPage] = useState<boolean>(false);
  const [displayFailedPage, setDisplayFailedPage] = useState<boolean>(false);

  useEffect(() => {
    const getBridgeAccount = async () => {
      try {
        const { data } = await API.get<ExternalAccountData>(
          cryptoService.fiatExternalCustomer
        );
        const { status } = data;

        if (!status) return;

        if (status === FiatExternalCustomerStatus.ACTIVE)
          return navigate(CryptoRoutes.CABLE);

        if (status === FiatExternalCustomerStatus.PENDING) {
          return setDisplayPendingPage(true);
        }

        if (status === FiatExternalCustomerStatus.FAILED) {
          return setDisplayFailedPage(true);
        }
      } catch (error: any) {
        console.error("error");
      } finally {
        setIsLoading(false);
      }
    };

    isOpen && getBridgeAccount();
  }, [isOpen]);

  const renderPage = () => {
    if (displayFailedPage)
      return <CreationFailed onClickLater={() => setIsOpen(false)} />;

    if (displayPendingPage)
      return <CreationInProgress onClickLater={() => setIsOpen(false)} />;

    return <CableOnboardingSheet />;
  };

  return (
    <UIModalSlider
      open={isOpen && !isLoading}
      onToggleDisplay={() => setIsOpen(false)}
      classes={styles.sheetWrapper}
    >
      {isLoading ? (
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      ) : (
        renderPage()
      )}
    </UIModalSlider>
  );
};

export default CableSheet;

import React, { useEffect } from "react";

import { ThemeVariants } from "interfaces/theme";
import LoadingSpinner from "components/common/LoadingSpinner";
import { useAuth } from "context/AuthProvider";
import { Navigate, useLocation } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
interface PrivateRouteProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<PrivateRouteProps> = ({ children }) => {
  const {
    pendingPhoneSignUp,
    isAuthenticated,
    pending2FA,
    loading,
  } = useAuth();
  const location = useLocation();
  const isCrypto = location.pathname.includes(CryptoRoutes.HOME);

  const pedingAuthStep = !isAuthenticated || pending2FA || pendingPhoneSignUp;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const loadingVariant = isCrypto
    ? ThemeVariants.Crypto
    : ThemeVariants.Capital;
  if (loading) {
    return (
      <div
        style={{
          height: "100%",
          backgroundColor: `var(--${isCrypto ? "slate50" : "lb-to-darkest"})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner variant={loadingVariant} />
      </div>
    );
  }

  if (pedingAuthStep) {
    return <Navigate replace to="/login" />;
  }

  return <>{children}</>;
};

export default RequireAuth;

import { Crypto } from "constants/cryptos";

import { useState } from "react";

import PageHeader from "components/common/PageHeader";
import { SendLocationState } from "context/CryptoSendProvider";
import { X } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Transaction from "components/page-crypto-swap/Transaction";
import { useCryptoSend } from "context/CryptoSendProvider";

import NoAssets from "./NoAssets";
import AssetList from "./AssetList";

import styles from "./styles.module.scss";

interface AssetsSectionProps {
  onClick?: (value: Crypto) => void;
}

const AssetsSection: React.FC<AssetsSectionProps> = ({ onClick }) => {
  const navigate = useNavigate();
  const locationState = useLocation().state as SendLocationState | null;
  const defaultTicker = locationState && locationState.defaultTicker;
  const { getHoldings, cryptos } = useCryptoSend();

  const [isBuying, setIsBuying] = useState<boolean>();

  const handleClickBuy = () => {
    setIsBuying(true);
  };

  if (isBuying && defaultTicker)
    return (
      <Transaction
        isArsDefault
        selectedCrypto={defaultTicker}
        continueSendFlow={() => {
          getHoldings(true);
          setIsBuying(false);
        }}
      />
    );

  return (
    <div className={styles.assetsSectionContainer}>
      <PageHeader
        title="Seleccioná un activo"
        onClick={() => navigate(-1)}
        icon={X}
      />

      {defaultTicker || isEmpty(cryptos) ? (
        <NoAssets
          defaultTicker={defaultTicker}
          handleClickBuy={handleClickBuy}
        />
      ) : (
        <AssetList assets={cryptos} onClickAsset={onClick} />
      )}
    </div>
  );
};

export default AssetsSection;

import AccountRow from "components/common/AccountRow";
import { CryptoAccount } from "interfaces/api-responses";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface AccountsListProps {
  accounts: CryptoAccount[];
}

const AccountsList: React.FC<AccountsListProps> = ({ accounts }) => {
  const navigate = useNavigate();

  const handleOnClickAccount = (account: CryptoAccount) => {
    navigate(CryptoRoutes.SEND, { state: { defaultAccount: account } });
  };
  return (
    <div className={styles.accountsListContainer}>
      {accounts.map((account) => {
        const { address, name, tickers } = account;
        return (
          <AccountRow
            key={address}
            name={name}
            accountId={address}
            currencies={tickers}
            onClick={() => handleOnClickAccount(account)}
          />
        );
      })}
    </div>
  );
};

export default AccountsList;

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface NumbersBadgeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant?: ThemeVariants;
  label?: string;
  number?: number | string;
}

const NumbersBadge: React.FC<NumbersBadgeProps> = ({
  variant = ThemeVariants.Capital,
  label,
  number,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.NumbersBadgeContainer,
        styles[variant],
        className
      )}
      {...props}
    >
      {label && (
        <Text variant={TextVariants.SemiboldTextS} color="var(--slate800)">
          {label}&nbsp;
        </Text>
      )}
      {number && (
        <Text
          className={styles.text}
          variant={TextVariants.SemiboldUpperValueS}
        >
          {number}
        </Text>
      )}
    </div>
  );
};

export default NumbersBadge;

//TODO: Replace for util function
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

interface PropTypes {
  ruleId?: string | null;
  children: number | string | undefined | null;
  tickSize?: number | null;
}

export const DecimalRound = ({ children, ruleId }: PropTypes) => {
  const tickerRules = useAppSelector(
    (state: RootState) => state.markets.tickersRulesData
  );

  if (Number.isNaN(Number(children))) return null;
  if (!tickerRules) return null;

  const getDecimalScaleByRule = () => {
    if (!ruleId) {
      return 2;
    } else {
      const rule = tickerRules[ruleId].find(
        (r) =>
          Number(children) >= r.price_from && Number(children) <= r.price_to
      );

      if (rule) {
        return rule.num_decimals;
      } else return 1;
    }
  };

  return (
    <>
      {Number(children)
        .toFixed(getDecimalScaleByRule())
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
    </>
  );
};

import classNames from "classnames";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export enum SkeletonVariants {
  TEXT = "Text",
  CIRCULAR = "Circular",
  RECTANGULAR = "Rectangular",
  ROUNDED = "Rounded",
}

interface SkeletonProps {
  variant: SkeletonVariants;
  width?: number;
  height: number;
}

const Skeleton: React.FC<SkeletonProps> = ({ variant, width, height }) => {
  const getWidth = () => {
    if (width) return px2rem(width);

    if (variant === SkeletonVariants.CIRCULAR) return px2rem(height);

    return "100%";
  };

  return (
    <div
      className={classNames(styles.SkeletonContainer, styles[variant])}
      style={{ height: px2rem(height), width: getWidth() }}
    />
  );
};

export default Skeleton;

import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import IconBadge from "components/common/Badges/IconBadge";
import FilledButton, {
  FilledButtonProps,
} from "components/common/FilledButton";
import Footer from "components/common/Footer";
import Heading, { HeadingVariants } from "components/common/Heading";
import NavigationHeader, {
  NavigationHeaderProps,
} from "components/common/NavigationHeader";
import OutlinedButton, {
  OutlinedButtonProps,
} from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { Schedules } from "interfaces/calendar";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import getPlatform from "utils/getPlatform";

import MarketClosedHeader from "../market-closed-header";

import styles from "./styles.module.scss";

interface BasicWrapperProps {
  themeVariant?: ThemeVariants;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  badgeColor?: string;
  badgeIconColor?: string;
  className?: string;
  children?: JSX.Element | React.ReactNode;
  icon?: React.FC<IconProps> | LucideIcon;
  primaryButtonProps?: FilledButtonProps;
  secondaryButtonProps?: OutlinedButtonProps;
  navigationHeaderProps?: NavigationHeaderProps;
  isMarketOpen?: boolean;
}

const BasicWrapper: React.FC<BasicWrapperProps> = ({
  themeVariant = ThemeVariants.Capital,
  icon: Icon,
  title,
  subtitle,
  badgeColor,
  badgeIconColor,
  className,
  children,
  isMarketOpen = true,
  primaryButtonProps,
  secondaryButtonProps,
  navigationHeaderProps,
}) => {
  const shouldShowButtons = primaryButtonProps || secondaryButtonProps;
  const { isIOS } = getPlatform();

  return (
    <div className={classNames(styles.wrapper, className)}>
      {!isMarketOpen && (
        <MarketClosedHeader
          open={!isMarketOpen}
          type={Schedules.CI}
          className={classNames(styles.positionAbsolute, {
            [styles.isIos]: isIOS,
          })}
        />
      )}
      {navigationHeaderProps && (
        <NavigationHeader {...navigationHeaderProps} variant={themeVariant} />
      )}
      {(Icon || title || subtitle) && (
        <div className={styles.content}>
          {Icon && (
            <IconBadge variant={themeVariant} backgroundColor={badgeColor}>
              {
                <Icon
                  color={badgeIconColor}
                  className={classNames(styles.iconBadge, styles[themeVariant])}
                />
              }
            </IconBadge>
          )}
          {title && (
            <Heading
              variant={HeadingVariants.RegularTitle}
              component="h2"
              color="var(--slate900)"
              className={styles.title}
            >
              {title}
            </Heading>
          )}

          {subtitle && (
            <Text
              variant={TextVariants.RegularText}
              color="var(--slate800)"
              className={styles.text}
            >
              {subtitle}
            </Text>
          )}
        </div>
      )}

      {children}

      {shouldShowButtons && (
        <Footer>
          {primaryButtonProps && (
            <FilledButton {...primaryButtonProps} variant={themeVariant} />
          )}

          {secondaryButtonProps && (
            <OutlinedButton {...secondaryButtonProps} variant={themeVariant} />
          )}
        </Footer>
      )}
    </div>
  );
};

export default BasicWrapper;

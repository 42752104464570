import classNames from "classnames";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export enum IconSizes {
  SMALL = "Small",
  DEFAULT = "Default",
}

export enum IconVariants {
  SUCCESS = "Success",
  WARNING = "Warning",
  FAIL = "Fail",
  INFO = "Info",
  CRYPTO = "Crypto",
  PAY = "Pay",
}

interface IconProps {
  variant: IconVariants;
  icon: LucideIcon;
  size?: IconSizes;
}

const Icon: React.FC<IconProps> = ({
  variant,
  icon: PropIcon,
  size = IconSizes.DEFAULT,
}) => {
  const getIconColor = () => {
    if (variant === IconVariants.SUCCESS) return "var(--green900)";

    if (variant === IconVariants.WARNING) return "var(--yellow900)";

    if (variant === IconVariants.FAIL) return "var(--red900)";

    if (variant === IconVariants.CRYPTO) return "var(--purple900)";

    if (variant === IconVariants.PAY) return "var(--black-to-white)";

    return "var(--blue900)";
  };

  const getIconSize = () => {
    if (size === IconSizes.SMALL) return px2rem(20);
    return px2rem(24);
  };

  return (
    <div className={classNames(styles.icon, styles[variant], styles[size])}>
      <PropIcon size={getIconSize()} color={getIconColor()} />
    </div>
  );
};

export default Icon;

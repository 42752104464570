import {
  biometryServer,
  capacitorBiometryPreferencesKey,
  oldCapacitorBiometryPreferencesKey,
} from "constants/biometry";

import { useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";
import {
  AvailableResult,
  BiometryType,
  NativeBiometric,
} from "@capgo/capacitor-native-biometric";
import { updateSignInWithBiometrics } from "features/user/userSlice";
import { trackAction } from "utils/amplitude";

import { useAppDispatch } from "./redux";

export const useBiometry = () => {
  const dispatch = useAppDispatch();
  const [biometryType, setBiometryType] = useState<BiometryType>();
  useEffect(() => {
    const checkBiometryType = async () => {
      if (
        (await isNativeBiometricUnavailable()) ||
        !(await isBiometricsSignInEnabled())
      ) {
        return;
      }

      const nativeBiometricAvailable = await NativeBiometric.isAvailable();
      setBiometryType(nativeBiometricAvailable.biometryType);
    };

    checkBiometryType();
  }, []);

  const isNativeBiometricUnavailable = async () => {
    if (!Capacitor.isNativePlatform()) return true;

    const nativeBiometricAvailable: AvailableResult = await NativeBiometric.isAvailable()
      .then((value: AvailableResult) => ({
        ...value,
        isAvailable: !!value && value.isAvailable,
      }))
      .catch((reason: any) => {
        trackAction("biometry_error", {
          type: "Error on isNativeBiometricUnavailable",
          email: "UNKNOWN",
          error: JSON.stringify(reason),
        });

        return {
          isAvailable: false,
          biometryType: BiometryType.NONE,
        };
      });
    return !nativeBiometricAvailable.isAvailable;
  };

  const getOldBiometricsSignInPreference = async () => {
    const oldResult = await Preferences.get({
      key: oldCapacitorBiometryPreferencesKey,
    });
    const oldSignInWithBiometrics: boolean =
      !!oldResult && oldResult.value === "true";

    if (oldSignInWithBiometrics) {
      await setBiometricsSignInEnabled(true);
      return true;
    }
    return false;
  };

  const isBiometricsSignInEnabled = async () => {
    const result = await Preferences.get({
      key: capacitorBiometryPreferencesKey,
    });
    const signInWithBiometrics: boolean | null =
      result.value === null ? null : JSON.parse(result.value);

    /* 
      Si no tiene valor en la nueva key y tenía true en la vieja, 
      seteo true en la nueva y devuelvo true 
    */
    if (
      signInWithBiometrics === null &&
      (await getOldBiometricsSignInPreference())
    ) {
      return true;
    }

    return signInWithBiometrics;
  };

  const setBiometricsSignInEnabled = async (enable: boolean) => {
    await Preferences.set({
      key: capacitorBiometryPreferencesKey,
      value: JSON.stringify(enable),
    });
    dispatch(updateSignInWithBiometrics(enable));
  };

  const resetCredentials: (
    email: string,
    password: string
  ) => Promise<void> = async (email, password) => {
    if (await isNativeBiometricUnavailable()) {
      return;
    }

    await NativeBiometric.deleteCredentials({ server: biometryServer })
      .then()
      .catch((reason) => {
        trackAction("biometry_error", {
          type: "Error on deleteCredentials",
          email: "UNKNOWN",
          error: JSON.stringify(reason),
        });
      });
    await NativeBiometric.setCredentials({
      server: biometryServer,
      username: email,
      password,
    })
      .then()
      .catch((reason) => {
        trackAction("biometry_error", {
          type: "Error on setCredentials",
          email: "UNKNOWN",
          error: JSON.stringify(reason),
        });
      });
  };

  const verifyIdentity = async () => {
    return await NativeBiometric.verifyIdentity()
      .then(() => true)
      .catch(() => false);
  };

  const getCredentials = async () => {
    if (await isNativeBiometricUnavailable()) {
      return null;
    }

    const verified = await verifyIdentity();
    if (!verified) {
      return null;
    }
    const credentials = await NativeBiometric.getCredentials({
      server: biometryServer,
    });

    return credentials;
  };

  const removeCredentials = async () => {
    if (await isNativeBiometricUnavailable()) {
      return;
    }

    await NativeBiometric.deleteCredentials({ server: biometryServer });
    await setBiometricsSignInEnabled(false);
  };

  const biometricsCredentials = {
    remove: removeCredentials,
    get: getCredentials,
    reset: resetCredentials,
  };

  const biometricsSignIn = {
    isEnabled: isBiometricsSignInEnabled,
    setEnabled: setBiometricsSignInEnabled,
  };

  return {
    biometryType,
    biometricsCredentials,
    biometricsSignIn,
    isNativeBiometricUnavailable,
  };
};

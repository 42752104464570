import { useEffect } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CreationInProgressProps {
  onClickLater: () => void;
}

const CreationInProgress: React.FC<CreationInProgressProps> = ({
  onClickLater,
}) => {
  useEffect(() => {
    ampli.cclCryptoOnboarding({ status: "failed" });
  }, []);

  const onClickContactSupport = () => {
    ampli.cclCryptoSupport({ referral: "intro" });
    window.location.href = "mailto:atencion@cocoscrypto.com";
  };

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "failed" });
    onClickLater();
  };

  return (
    <div className={styles.contentWrapper}>
      <Text
        color="var(--slate900)"
        className={styles.title}
        variant={TextVariants.RegularText}
      >
        Algo salió mal
      </Text>
      <div className={styles.iconWrapper}>
        <X size={px2rem(24)} color="var(--red900)" />
      </div>
      <Text
        color="var(--slate800)"
        className={styles.text}
        variant={TextVariants.RegularText}
      >
        No pudimos crear tu cuenta. Por favor, por cualquier consulta comunicate
        a{" "}
        <a className={styles.email} href="mailto:atencion@cocoscrypto.com">
          atencion@cocoscrypto.com
        </a>
      </Text>
      <div className={styles.buttonContainer}>
        <FilledButton
          onClick={onClickContactSupport}
          variant={ThemeVariants.Crypto}
        >
          Contactar a soporte
        </FilledButton>
        <OutlinedButton onClick={onClose} variant={ThemeVariants.Crypto}>
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default CreationInProgress;

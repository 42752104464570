import {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  Fragment,
  KeyboardEvent,
} from "react";

import API from "apis";
import px2rem from "utils/px2rem";
import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import { useCocosCard } from "context/CocosCardProvider";
import Atm from "assets/illustrations/Atm";
import BasicWrapper from "components/common/BasicWrapper";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { cardService } from "apis/services";

import PinSuccess from "./PinSuccess";

import styles from "./styles.module.scss";

const PIN_LENGTH = 4;
const MAX_ATTEMPS = 2;

interface Error {
  isError: boolean;
  message?: string;
}

const PagePin: React.FC = () => {
  const navigate = useNavigate();
  const { physicalCard } = useCocosCard();
  const codesInitialState = ["", "", "", ""];

  const [code, setCode] = useState<string>("");
  const [codes, setCodes] = useState<string[]>(codesInitialState);
  const [codeInserted, setCodeInserted] = useState<string | null>(null);
  const [updateAttemps, setUpdateAttemps] = useState<number>(0);
  const [displayPinSuccess, setDisplayPinSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [error, setError] = useState<Error>({
    isError: false,
    message: "",
  });

  const isCodeCompleted = code.length === PIN_LENGTH;
  const isAscendant = /^(0123|1234|2345|3456|4567|5678|6789|7890)$/.test(code);
  const isDescendant = /^(3210|4321|5432|6543|7654|8765|9876)$/.test(code);
  const isRepeated = /^(.)\1{3}$/.test(code);

  const handleOnChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setError({ isError: false });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      return;
    }
    const value = event.target.value;
    const newCodes = [...codes];
    newCodes[index] = value.slice(-1);

    const shouldMoveToNextInput =
      value !== "" &&
      index < inputRefs.length - 1 &&
      inputRefs !== null &&
      inputRefs[index + 1] !== null &&
      inputRefs[index + 1].current !== null;

    updateCode(newCodes);

    if (inputRefs[3].current?.focus()) {
      inputRefs[3].current?.blur();
    }

    if (shouldMoveToNextInput) {
      inputRefs[index + 1].current?.focus();
    }
  };

  const updateCode = (values: string[]) => {
    setCodes(values);
    onChangeCode(values.join(""));
  };

  const onChangeCode = (value: string) => {
    setCode(value);
  };

  const inputRefs = Array.from({ length: PIN_LENGTH }, () =>
    useRef<HTMLInputElement>(null)
  );

  const handleKeyDown = (
    index: number,
    event: KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      const newCodes = [...codes];
      newCodes[index] = "";
      updateCode(newCodes);
      inputRefs[index - 1]?.current?.focus();
    }
  };

  const updatePin = async () => {
    setIsLoading(true);

    try {
      await API.patch(cardService.pin, {
        pin: code,
        idCard: physicalCard?.idCard,
      });

      setDisplayPinSuccess(true);
    } catch (error) {
      setCodeInserted(null);
      setUpdateAttemps(0);
      resetCodes();
      setError({
        isError: true,
        message:
          "Hubo un error al actualizar la clave. Por favor, intentá nuevamente.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Cajero",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  const resetCodes = () => {
    setCodes(codesInitialState);
    setCode("");
    inputRefs[0].current?.focus();
  };

  const checkCode = () => {
    if (codeInserted === code) {
      updatePin();
    } else {
      const newUpdateAttempts = updateAttemps + 1;
      setUpdateAttemps(newUpdateAttempts);
      resetCodes();
      setError({
        isError: true,
        message:
          newUpdateAttempts < MAX_ATTEMPS
            ? "Las claves no coinciden. Por favor, intentá nuevamente."
            : "Ingresaste incorrectamente el pin. Por favor, intentá nuevamente.",
      });
      if (newUpdateAttempts >= MAX_ATTEMPS) {
        setCodeInserted(null);
        setUpdateAttemps(0);
      }
    }
  };

  useEffect(() => {
    if (isAscendant || isDescendant || isRepeated) {
      let errorType = "";
      if (isAscendant) {
        errorType = "ascendente";
      } else if (isDescendant) {
        errorType = "descendiente";
      } else if (isRepeated) {
        errorType = "repetida";
      }

      setError({
        isError: true,
        message: `La clave no puede ser una secuencia ${errorType}. Por favor, intentá nuevamente.`,
      });
      resetCodes();
      return;
    }

    if (isCodeCompleted) {
      if (codeInserted) {
        checkCode();
      } else {
        setCodeInserted(code);
        resetCodes();
      }
    }
  }, [code]);

  if (displayPinSuccess) {
    return <PinSuccess />;
  }

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={navigationHeaderProps}
    >
      <div className={styles.imageContainer}>
        <Atm size={px2rem(106)} />
      </div>
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        {codeInserted ? "Repetí tu clave" : "Actualizá tu clave"}
      </Heading>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.subtitle}
      >
        {codeInserted
          ? "Ingresá nuevamente la clave para confirmar que la recordás correctamente."
          : "Ingresá una clave de 4 dígitos para poder usar tu tarjeta en cajero"}
      </Text>
      <div className={styles.inputs}>
        {inputRefs.map((ref, index) => (
          <Fragment key={index}>
            <input
              type="number"
              ref={ref}
              maxLength={1}
              className={styles.input}
              id={"input" + index}
              name={"input" + index}
              value={codes[index]}
              pattern="\d*"
              onChange={(e) => handleOnChange(index, e)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              disabled={isLoading}
            />
          </Fragment>
        ))}
      </div>
      {error.isError && (
        <Text variant={TextVariants.RegularTextS} color="var(--red800)">
          {error.message}
        </Text>
      )}
    </BasicWrapper>
  );
};

export default PagePin;

import { MAX_LIMIT_AMOUNT } from "components/page-cocos-card/constants";
import AvailableLabel from "components/common/AvailableLabel";

import styles from "./styles.module.scss";

interface LimitAvailableProps {
  available: number;
  setCardLimit: (v: number) => void;
}

const LimitAvailable: React.FC<LimitAvailableProps> = ({
  available,
  setCardLimit,
}) => {
  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardLimit(Number(event.target.value));
  };

  return (
    <div className={styles.limitAvailableContainer}>
      <AvailableLabel withHideButton={false} available={available} />
      <div>
        <input
          type="range"
          min="0"
          max={MAX_LIMIT_AMOUNT}
          step="10000"
          value={available}
          onChange={handleRangeChange}
          className={styles.rangeInput}
          style={{
            background: `linear-gradient(90deg, #090a0a ${
              (available / MAX_LIMIT_AMOUNT) * 100
            }%, var(--slate200) 0%, var(--slate200) 100%)`,
          }}
        />
      </div>
    </div>
  );
};

export default LimitAvailable;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorMessage } from "interfaces/errors";
import {
  HistoricDataMarketData,
  InstrumentData,
  OrderDetailResponse,
  OrdersResponse,
  TickersRulesResponse,
} from "interfaces/api-responses";
import {
  CaucionOrder,
  DolarMep,
  DolarMepOrder,
  InvestmentSuggestedResponse,
  LabelValue,
  Order,
} from "interfaces/markets";
import { MarketSchedules } from "interfaces/calendar";

import { marketsInitialState } from "./ marketsInitialState";
import { getMarketDetailByShortTicker } from "./marketsActions";

const marketsSlice = createSlice({
  name: "markets",
  initialState: marketsInitialState,
  reducers: {
    clearCaucionError(state) {
      state.caucionError = marketsInitialState.caucionError;
    },
    clearOrderDetail(state) {
      state.orderDetail = undefined;
    },
    clearOrdersListError(state) {
      state.getOrdersListError = marketsInitialState.getOrdersListError;
    },
    cleanHistoricalData(state) {
      state.marketHistoricData = undefined;
    },
    clearShortTickerMarketData(state) {
      state.shortTickerData = marketsInitialState.shortTickerData;
    },
    clearDolarOrder(state) {
      state.dolarMepOrder = marketsInitialState.dolarMepOrder;
    },
    clearCancelOrderError(state) {
      state.cancelOrderError = marketsInitialState.cancelOrderError;
    },
    setMarketHistoricalData(
      state,
      action: PayloadAction<HistoricDataMarketData>
    ) {
      state.marketHistoricData = action.payload;
    },
    setDolarMepData(state, action: PayloadAction<DolarMep>) {
      state.dolarMep = action.payload;
    },
    setDolarMepOrder(state, action: PayloadAction<DolarMepOrder>) {
      state.dolarMepOrder = action.payload;
    },
    setListError(state, action: PayloadAction<ErrorMessage>) {
      state.createListError = action.payload;
    },
    setMarketDetailLoading(state, action: PayloadAction<boolean>) {
      state.marketDetailLoading = action.payload;
    },
    setMarketLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMarketSchedules(state, action: PayloadAction<MarketSchedules>) {
      state.schedules = action.payload;
    },
    setMarketError(state, action: PayloadAction<ErrorMessage>) {
      state.errorMessage = action.payload;
    },
    setOrdersData(state, action: PayloadAction<OrdersResponse[]>) {
      state.orders = action.payload;
    },
    setCancelOrderError(state, action: PayloadAction<ErrorMessage>) {
      state.cancelOrderError = {
        message: action.payload.message,
        status: action.payload.status,
      };
    },
    removeMarketError(state) {
      state.errorMessage = {
        message: "",
        status: null,
      };
    },
    setMarketDataDetail(state, action) {
      if (action.payload) {
        state.marketDetail = {
          ...state.marketDetail,
          ...action.payload,
        };
      } else {
        state.marketDetail = undefined;
      }
    },
    resetMarketDataDetail(state) {
      state.marketDetail = undefined;
    },
    updateDolarOrderOptions(state, action: PayloadAction<DolarMepOrder>) {
      state.dolarMepOrder = action.payload;
    },
    updateCaucionOrderOptions(state, action: PayloadAction<CaucionOrder>) {
      state.caucionOrder = action.payload;
    },
    updateOrderOptions(
      state,
      action: PayloadAction<
        Optional<Order, "long_ticker" | "price" | "quantity">
      >
    ) {
      state.newOrder = action.payload;
    },
    setCaucionOrder(state, action: PayloadAction<CaucionOrder>) {
      state.caucionOrder = action.payload;
    },
    setCaucionOrderError(state, action: PayloadAction<ErrorMessage>) {
      state.caucionError = action.payload;
    },
    setCreateListLoading(state, action: PayloadAction<boolean>) {
      state.createListLoading = action.payload;
    },
    setOrderListError(state, action: PayloadAction<ErrorMessage>) {
      state.getOrdersListError = action.payload;
    },
    setOrderListLoading(state, action: PayloadAction<boolean>) {
      state.getOrdersListLoading = action.payload;
    },
    setCancelOrderLoading(state, action: PayloadAction<boolean>) {
      state.cancelOrderLoading = action.payload;
    },
    setCreateOrderLoading(state, action: PayloadAction<boolean>) {
      state.createOrderLoading = action.payload;
    },
    setCreateOrderError(state, action: PayloadAction<ErrorMessage>) {
      state.createOrderError = action.payload;
    },
    setConfirmOrderData(state, action: PayloadAction<LabelValue[]>) {
      state.confirmOrderData = action.payload;
    },
    setInvestmentSuggested(
      state,
      action: PayloadAction<InvestmentSuggestedResponse[]>
    ) {
      state.investmentSuggested = action.payload;
    },
    setOrderDetail(state, action: PayloadAction<OrderDetailResponse>) {
      state.orderDetail = action.payload;
    },
    setTickersRulesData(
      state,
      action: PayloadAction<TickersRulesResponse | undefined>
    ) {
      state.tickersRulesData = action.payload;
    },
    setTickersRulesError(state, action: PayloadAction<ErrorMessage>) {
      state.tickersRulesError = action.payload;
    },
    setTickersRulesLoading(state, action: PayloadAction<boolean>) {
      state.tickersRulesLoading = action.payload;
    },
    setGetOrderError(state, action: PayloadAction<ErrorMessage>) {
      state.getOrderError = action.payload;
    },
    setGetOrderLoading(state, action: PayloadAction<boolean>) {
      state.getOrderLoading = action.payload;
    },
    setMarketData(state, action) {
      state.marketData = action.payload;
    },
    resetOrder(state, action: PayloadAction<Partial<Order> | undefined>) {
      if (action?.payload) {
        state.newOrder = { ...marketsInitialState.newOrder, ...action.payload };
      } else {
        state.newOrder = marketsInitialState.newOrder;
      }
    },
    resetCaucionOrder(state) {
      state.caucionOrder = {
        ...state.caucionOrder,
        id: marketsInitialState.caucionOrder.id,
        amount: marketsInitialState.caucionOrder.amount,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMarketDetailByShortTicker.fulfilled,
      (state, action: PayloadAction<InstrumentData[] | undefined>) => {
        if (state.shortTickerData && action.payload !== undefined) {
          state.shortTickerData = action.payload;
          state.errorMessage = {
            message: "",
            status: null,
          };
        }
      }
    );
  },
});

export const {
  cleanHistoricalData,
  clearCaucionError,
  clearDolarOrder,
  clearOrderDetail,
  clearOrdersListError,
  clearShortTickerMarketData,
  removeMarketError,
  resetMarketDataDetail,
  resetCaucionOrder,
  resetOrder,
  setCancelOrderError,
  setCancelOrderLoading,
  setCaucionOrder,
  setCaucionOrderError,
  setConfirmOrderData,
  setCreateListLoading,
  setCreateOrderError,
  setCreateOrderLoading,
  setDolarMepData,
  setDolarMepOrder,
  setGetOrderError,
  setGetOrderLoading,
  setInvestmentSuggested,
  setListError,
  setMarketData,
  setMarketDataDetail,
  setMarketDetailLoading,
  setMarketError,
  setMarketHistoricalData,
  setMarketLoading,
  setMarketSchedules,
  setOrderDetail,
  setOrderListError,
  setOrderListLoading,
  setOrdersData,
  setTickersRulesData,
  setTickersRulesError,
  setTickersRulesLoading,
  updateCaucionOrderOptions,
  updateDolarOrderOptions,
  updateOrderOptions,
} = marketsSlice.actions;

export default marketsSlice.reducer;

import styles from "./styles.module.scss";

const BottomShackle: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.bottomShackleWrapper}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="152"
        height="123"
        viewBox="0 0 152 123"
        fill="none"
      >
        <path
          d="M203.961 173.603L134.679 213.603L74.6794 109.679C63.6337 90.5478 70.1887 66.0842 89.3204 55.0385C108.452 43.9928 132.916 50.5478 143.961 69.6795L203.961 173.603Z"
          fill="var(--slate200)"
        />
        <path
          d="M149.32 158.961L80.0384 198.961L20.0384 95.0384C8.99268 75.9067 15.5477 51.4431 34.6794 40.3974C53.8111 29.3517 78.2747 35.9067 89.3204 55.0384L149.32 158.961Z"
          fill="var(--slate400)"
        />
      </svg>
    </div>
  </div>
);

export default BottomShackle;

import { Cryptos } from "constants/cryptos";

import { useEffect, useState } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import ActivityList from "components/common/ActivityList";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import QuietButton from "components/common/QuietButton";
import SectionTitle from "components/common/SectionTitle";
import { CryptoActivity } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

const HOME_LIMIT = 3;

interface ActivitySectionProps {
  ticker?: Cryptos;
}

const ActivitySection: React.FC<ActivitySectionProps> = ({ ticker }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [activityData, setActivityData] = useState<CryptoActivity>();

  const navigate = useNavigate();

  const goToActivity = (label: string) => {
    trackAction(
      `${cryptoScreenNames.home} - Click ${label}`,
      undefined,
      Projects.CRYPTO
    );

    navigate(CryptoRoutes.ACTIVITY);
  };

  useEffect(() => {
    const getMovements = async () => {
      try {
        const { data } = await API.get<CryptoActivity>(
          cryptoService.movements,
          {
            params: {
              limit: HOME_LIMIT,
              ticker,
            },
          }
        );

        setActivityData(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getMovements();
  }, []);

  if (isLoading)
    return (
      <div>
        <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(100)} />
      </div>
    );

  if (error || !activityData)
    return (
      <ErrorPage errorMessage="En este momento no podemos mostrar tus movimientos." />
    );

  return (
    <div className={styles.activitySectionWrapper}>
      <div className={styles.titleWrapper}>
        <SectionTitle
          label="Actividad"
          onClick={() => goToActivity("Activity")}
        />
      </div>
      {!isEmpty(activityData.data) && (
        <>
          <ActivityList activity={activityData} />
          <div className={styles.buttonWrapper}>
            <QuietButton
              variant={ThemeVariants.Crypto}
              className={styles.button}
              onClickCapture={() => goToActivity("View More")}
            >
              Ver más
            </QuietButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ActivitySection;

import { useState } from "react";

import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import FilledButton from "components/common/FilledButton";
import PageHeader from "components/common/PageHeader";
import BasicWrapper from "components/common/BasicWrapper";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ArrowLeft } from "lucide-react";

import Config2FAPassword from "./ConfigPassword";
import Config2FAStep from "./ConfigStep";

import styles from "./styles.module.scss";

interface Config2FAProps {
  onClickBack: () => void;
  onClickContinue: () => void;
  enrollData?: AuthMFAEnrollResponse;
  variant?: ThemeVariants;
}

const Config2FA: React.FC<Config2FAProps> = ({
  onClickBack,
  onClickContinue,
  enrollData,
  variant,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useDeviceCheck();

  const isIOS = /iPhone|iPad|Macintosh/i.test(window.navigator.userAgent);
  const stepsData = [
    {
      title: "Instalá Google Authenticator",
      subtitle: isMobile ? (
        <>
          Descargá e instalá la aplicación de Google Authenticator desde&nbsp;
          {isIOS ? (
            <a href="https://apps.apple.com/ar/app/google-authenticator/id388497605">
              iOS App Store
            </a>
          ) : (
            <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_AR&gl=US">
              Google Play Store
            </a>
          )}
          .
        </>
      ) : (
        "Descargá e instalá la aplicación de Google Authenticator en tu teléfono"
      ),
    },
    {
      title: isMobile ? "Ingresá la clave de configuración" : "Escaneá el QR",
      subtitle: isMobile
        ? "Copiá la clave de configuración e ingresala en Google Authenticator creando una nueva cuenta desde el botón “+” que figura abajo a la derecha."
        : "Escaneá el siguiente QR desde la app de Google Authenticator creando una nueva cuenta aprentando el botón “+” que figura abajo a la derecha.",
    },
    {
      title: "Copiá el código temporal",
      subtitle:
        "Aparecerá un código temporal de 6 dígitos en Google Authenticator con la cuenta que acabas de crear. Copialo y volvé a esta pantalla.",
    },
    {
      title: "Pegá el código temporal",
      subtitle:
        "Presioná “Continuar” para ingresar el código temporal de 6 dígitos y asociarlo a tu cuenta.",
    },
  ];

  const handleContinue = () => {
    setLoading(true);
    onClickContinue();
    setLoading(false);
  };

  if (!enrollData)
    return (
      <BasicWrapper
        themeVariant={variant}
        navigationHeaderProps={{
          title: "Configurá Google Authenticator",
          withCloseIcon: false,
          onClick: onClickBack,
        }}
      >
        <ErrorPage errorMessage="En este momento no es posible mostrar esta pagina." />
      </BasicWrapper>
    );

  return (
    <BasicWrapper
      themeVariant={variant}
      navigationHeaderProps={{
        title: "Configurá Google Authenticator",
        withCloseIcon: false,
        onClick: onClickBack,
      }}
      primaryButtonProps={{
        children: "Continuar",
        onClick: handleContinue,
      }}
    >
      <div className={styles.configContainer}>
        {stepsData.map(({ title, subtitle }, index) => (
          <div className={styles.step} key={index}>
            <Config2FAStep
              title={title}
              subtitle={subtitle}
              step={index + 1}
              variant={variant}
            />
            {index === 1 && (
              <Config2FAPassword
                enrollData={enrollData}
                displayCode={isMobile}
                variant={variant}
              />
            )}
          </div>
        ))}
      </div>
    </BasicWrapper>
  );
};

export default Config2FA;

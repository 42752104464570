import Text, { TextVariants } from "../Text";
import Currency from "../Text/Currency";

import styles from "./styles.module.scss";

interface PricesBoxProps {
  sell: number;
  buy: number;
}

const PricesBox: React.FC<PricesBoxProps> = ({ sell, buy }) => {
  return (
    <div className={styles.pricesBoxContainer}>
      <div className={styles.priceContainer}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Venta
        </Text>
        <Currency amount={sell} color="var(--red800)" />
      </div>
      <div className={styles.divider} />
      <div className={styles.priceContainer}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Compra
        </Text>
        <Currency amount={buy} color="var(--green800)" />
      </div>
    </div>
  );
};

export default PricesBox;

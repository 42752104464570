import { CocosSmallLogo } from "assets/logos/cocos-logo";
import classNames from "classnames";
import Avatar from "components/common/avatar";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { LogOut, LucideIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";

import NavigationTab from "./NavigationTab";

import styles from "./styles.module.scss";

interface BottomNavigationProps {
  options: { title: string; icon: LucideIcon; route: string }[];
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ options }) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();

  const { isIOS } = getPlatform();

  if (isMobile)
    return (
      <div
        className={classNames(styles.bottomNavigationContainer, {
          [styles.ios]: isIOS,
        })}
      >
        {options.map(({ title, icon, route }) => (
          <NavigationTab key={title} title={title} icon={icon} route={route} />
        ))}
      </div>
    );

  return (
    <div className={styles.bottomNavigationContainer}>
      <div className={styles.topWrapper}>
        <CocosSmallLogo />

        <div className={styles.tabs}>
          {options.map(({ title, icon, route }) => (
            <NavigationTab
              key={title}
              title={title}
              icon={icon}
              route={route}
            />
          ))}
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <Avatar />
        <LogOut
          size={px2rem(24)}
          color="var(--slate800)"
          onClick={() => navigate(mainRoutes.logout)}
        />
      </div>
    </div>
  );
};

export default BottomNavigation;

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface Config2FAStepProps {
  step: number;
  title: string;
  subtitle: string | JSX.Element;
  variant?: ThemeVariants;
}

const Config2FAStep: React.FC<Config2FAStepProps> = ({
  step,
  title,
  subtitle,
  variant = ThemeVariants.Capital,
}) => {
  return (
    <div className={styles.configStepContainer}>
      <div className={classNames(styles.step, styles[variant])}>
        <Text variant={TextVariants.SemiboldTextS}>{step}</Text>
      </div>
      <div className={styles.stepInfo}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {title}
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {subtitle}
        </Text>
      </div>
    </div>
  );
};

export default Config2FAStep;

/* eslint-disable no-case-declarations */
import { FC, useEffect, useState } from "react";

import { supabase } from "utils/supabase";
import { MfaVerifyParams } from "context/AuthProvider";
import AuthAPI from "apis/auth";
import { ThemeVariants } from "interfaces/theme";
import ConfirmCodePage from "components/common/ConfirmCode";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { factorsService } from "apis/services";

import LoadingSpinner from "../LoadingSpinner";
import ErrorPage from "../ErrorPage";

import styles from "./styles.module.scss";

export type MfaFactor =
  | { type: MFAType.ENROLL; id: string }
  | { type: MFAType.MAIL | MFAType.TOTP | MFAType.SMS };

interface WithValidationProps {
  factorType?: MFAType;
  enrollId?: string;
  onVerify: (
    params: MfaVerifyParams,
    callAfterVerify: () => void
  ) => Promise<boolean>;
  onClickBack: () => void;
  variant?: ThemeVariants;
  error?: MFAErrorType;
}

interface FactorData {
  id: string;
  type: MFAType;
}

const getFactor = async (type?: string) => {
  try {
    const { data } = await AuthAPI.get(
      `${factorsService.default}${type ? `?type=${type}` : ""}`
    );
    return { data };
  } catch (error: any) {
    return { error };
  }
};

const MfaChallenge: FC<WithValidationProps> = ({
  factorType,
  enrollId,
  onVerify,
  onClickBack,
  variant = ThemeVariants.Capital,
  error,
}) => {
  const [factorData, setFactorData] = useState<FactorData>();
  const [challengeId, setChallengeId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      let factorData: FactorData;
      if (
        !factorType ||
        [MFAType.MAIL, MFAType.TOTP, MFAType.SMS].includes(factorType)
      ) {
        const { data, error } = await getFactor(factorType);
        if (error) {
          console.error(error);
          setErrorMessage("No podemos mostrar esta página en este momento.");
          return;
        }
        factorData = { id: data.id, type: data.factor_type };
      } else if (factorType === MFAType.ENROLL && enrollId) {
        factorData = { id: enrollId, type: MFAType.ENROLL };
      } else {
        console.error("Invalid factor param");
        setErrorMessage("No podemos mostrar esta página en este momento.");
        return;
      }

      const { data, error } = await supabase.auth.mfa.challenge({
        factorId: factorData.id,
      });
      if (error) {
        console.error(error);
        setErrorMessage("No podemos mostrar esta página en este momento.");
        return;
      }
      setFactorData(factorData);
      setChallengeId(data.id);
      setLoading(false);
    })();
  }, [factorType]);

  const handleVerify = async (mfaCode: string) => {
    if (!factorData || !challengeId) return true;
    setLoading(true);
    return await onVerify(
      {
        mfaFactorId: factorData.id,
        mfaChallengeId: challengeId,
        mfaCode,
      },
      () => {
        setLoading(false);
      }
    );
  };

  if (loading || !factorData || !challengeId) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingSpinner size={100} variant={variant} />
      </div>
    );
  }

  if (errorMessage) {
    return <ErrorPage errorMessage={errorMessage} />;
  }

  return (
    <div className={styles.challengeWrapper}>
      <ConfirmCodePage
        onClickContinue={handleVerify}
        onClickBack={onClickBack}
        variant={variant}
        factorType={factorData.type}
        error={error}
      />
    </div>
  );
};

export default MfaChallenge;

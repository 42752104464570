import React, { ReactElement } from "react";

import Heading, { HeadingVariants } from "components/common/Heading";
import { ChevronRight } from "lucide-react";
import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface SectionTitleProps {
  hideIcon?: boolean;
  label: string;
  onClick?: () => void;
  onSubTitleClick?: () => void;
  subTitle?: string;
  subTitleIcon?: ReactElement;
  variant?: ThemeVariants;
}

/**
 * @name SectionTitle
 * @description Componente para separar titulos y subtitulos
 * @prop {boolean} [hideIcon] - Mostrar/esconder el arrow del Title. (default = false)
 * @prop {string} label - Titulo de la seccion.
 * @prop {() => void} [onClick] - Function para el title al hacer click. (opcional)
 * @prop {() => void} [onSubTitleClick] - Function para el subtitle al hacer click. (opcional)
 * @prop {string} [subTitle] - Subtitulo de la seccion. (opcional)
 * @prop {ReactElement} [subTitleIcon] - Icono del subtitulo. (opcional)
 * @prop {ThemeVariants} [variant] - Crypto o Capital variant (default = Crypto)
 */

const SectionTitle: React.FC<SectionTitleProps> = ({
  hideIcon = false,
  label,
  onClick,
  onSubTitleClick,
  subTitle,
  subTitleIcon,
  variant = ThemeVariants.Crypto,
}) => {
  const handleClickTitle = (event: React.MouseEvent) => {
    event.stopPropagation;
    onClick && onClick();
  };

  const handleClickSubTitle = (event: React.MouseEvent) => {
    event.stopPropagation;
    onSubTitleClick && onSubTitleClick();
  };

  const arrowColor =
    variant === ThemeVariants.Crypto ? "var(--purple900)" : "var(--blue900)";

  return (
    <>
      <div
        onClick={handleClickTitle}
        className={classNames(styles.sectionTitle, variant)}
      >
        <Heading
          className={styles.sectionTitleHeading}
          variant={HeadingVariants.RegularTitle}
          component="h2"
          color="var(--slate800)"
        >
          {label}
        </Heading>
        {!hideIcon && <ChevronRight color={arrowColor} />}
      </div>

      {subTitle && (
        <div
          className={`${styles.sectionTitle} ${styles.sectionSubTitle}`}
          onClick={handleClickSubTitle}
        >
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h3"
            color="var(--slate800)"
          >
            {subTitle}
          </Heading>
          {subTitleIcon && subTitleIcon}
        </div>
      )}
    </>
  );
};

export default SectionTitle;

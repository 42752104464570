import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import { UIModalSlider } from "components/common/modal-slider";
import { CryptoWithdrawalErrors } from "components/page-crypto-send/AmountPage/utils";
import { useCryptoSend } from "context/CryptoSendProvider";
import { CreateSendOrderResponse } from "interfaces/api-responses";
import { MFAErrorType } from "interfaces/auth";
import { ThemeVariants } from "interfaces/theme";

import Confirmation from "./Confirmation";
import DetailsBody from "./DetailsBody";
import DetailsHeader from "./DetailsHeader";
import Error from "./Error";

import styles from "./styles.module.scss";

export enum Step {
  PREVIEW = "PREVIEW",
  CONFIRMATION = "CONFIRMATION",
  ERROR = "ERROR",
}

interface ConfirmationBottomSheetProps {
  isOpen: boolean;
  onToggleDisplay: () => void;
  amount: number;
  order: CreateSendOrderResponse;
  step: Step;
  onClickSend: () => void;
  error?: CryptoWithdrawalErrors | MFAErrorType;
  isLoading: boolean;
}

const ConfirmationBottomSheet: React.FC<ConfirmationBottomSheetProps> = ({
  step,
  order,
  isOpen,
  amount,
  error,
  isLoading,
  onClickSend,
  onToggleDisplay,
}) => {
  const { selectedCrypto } = useCryptoSend();

  const closeModal = () => {
    onToggleDisplay();
  };

  if (!selectedCrypto) return null;

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={closeModal}
      classes={styles.sheetWrapper}
    >
      {step === Step.PREVIEW && (
        <>
          <DetailsHeader amount={amount} ticker={selectedCrypto.ticker} />
          <DetailsBody amount={amount} fee={+order.fee} />
          <div className={styles.buttonsWrapper}>
            <OutlinedButton onClick={closeModal} variant={ThemeVariants.Crypto}>
              Volver
            </OutlinedButton>
            <FilledButton
              onClick={onClickSend}
              isLoading={isLoading}
              variant={ThemeVariants.Crypto}
            >
              Enviar
            </FilledButton>
          </div>
        </>
      )}

      {step === Step.CONFIRMATION && (
        <Confirmation amount={amount} ticker={selectedCrypto.ticker} />
      )}
      {step === Step.ERROR && <Error error={error} />}
    </UIModalSlider>
  );
};

export default ConfirmationBottomSheet;

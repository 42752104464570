import classNames from "classnames";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencySymbol } from "utils";

import styles from "./styles.module.scss";

export enum PriceLabelSizes {
  Default = "default",
  Large = "large",
  Small = "small",
  Xsmall = "xsmall",
}

interface PriceLabelProps {
  price: number;
  currency?: Currencies;
  size?: PriceLabelSizes;
}

const PriceLabel: React.FC<PriceLabelProps> = ({
  price,
  currency = Currencies.ARS,
  size = PriceLabelSizes.Default,
}) => {
  const splittedPrice = (formatToCurrency(price) || "0,00").split(",");

  const integerPart = splittedPrice[0];
  const decimalPart = splittedPrice[1];

  return (
    <p className={classNames(styles.priceLabelContainer, styles[size])}>
      <span className={styles.symbol}>{getCurrencySymbol(currency)} </span>
      {integerPart},
      <span className={styles.decimal}>{decimalPart || "00"}</span>
    </p>
  );
};

export default PriceLabel;

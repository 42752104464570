import PageHeader from "components/common/PageHeader";
import DefaultSection from "components/page-crypto-profile/DefaultSection";
import { ArrowLeft, HelpCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

const HelpSection: React.FC = () => {
  const navigate = useNavigate();
  const helpOptions = [
    {
      label: "Preguntas frecuentes",
      icon: HelpCircle,
      onClick: () => navigate(CryptoRoutes.FAQS),
    },
  ];
  return (
    <div className={styles.helpSectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Centro de ayuda"
        onClick={() => navigate(-1)}
      />
      <DefaultSection title="Centro de ayuda" options={helpOptions} />
    </div>
  );
};

export default HelpSection;

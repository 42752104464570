import Avatar from "components/common/avatar";
import Text, { TextVariants } from "components/common/Text";
import ToCryptoButton, {
  ToCryptoButtonSizes,
} from "components/common/to-crypto-button";
import { useAppSelector } from "hooks/redux";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { Projects, trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

const HomeHeader: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector(getLoggedUser);

  const tag = user?.tag;

  const onClickProfile = () => {
    trackAction(
      `${cryptoScreenNames.home} - Click Profile`,
      undefined,
      Projects.CRYPTO
    );
    navigate(CryptoRoutes.PROFILE);
  };
  return (
    <>
      <div className={styles.homeHeaderContainer}>
        <div className={styles.profile}>
          <Avatar variant={ThemeVariants.Crypto} onClick={onClickProfile} />

          {tag && (
            <Text variant={TextVariants.SemiboldTextS} color="var(--slate800)">
              {tag} 🥥
            </Text>
          )}
        </div>

        <div className={styles.rightDiv}>
          <ToCryptoButton
            size={ToCryptoButtonSizes.Large}
            variant={ThemeVariants.Crypto}
          />
        </div>
      </div>
    </>
  );
};

export default HomeHeader;

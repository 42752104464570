import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { MarketSchedules, Schedules } from "interfaces/calendar";
import { get } from "lodash";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

const getSchedules = (state: RootState): MarketSchedules =>
  get(state, "markets.schedules");

export const checkMarketOpen = (type: Schedules) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);

  dayjs.tz.setDefault("America/Argentina/Buenos_Aires");

  const now = dayjs().tz();
  const today = now.format("YYYY-MM-DD");

  const schedules = useAppSelector(getSchedules);

  const openHours = schedules[type].openHours;

  const checkSomeOpen = openHours.some(({ startTime, endTime }) =>
    now.isBetween(
      dayjs.tz(`${today} ${startTime}`),
      dayjs.tz(`${today} ${endTime}`),
      "second",
      "[]"
    )
  );
  return checkSomeOpen;
};

import classNames from "classnames";
import { AlertCircle, Check, Info, X } from "lucide-react";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export enum ToastVariants {
  Success = "success",
  Warning = "warning",
  Error = "error",
  Capital = "capital",
  Crypto = "crypto",
}

interface ToastProps {
  message: string;
  variant: ToastVariants;
  onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, variant, onClose }) => {
  const { isIOS } = getPlatform();
  const iconSize = px2rem(12);

  const getIcon = () => {
    if (variant === ToastVariants.Success) {
      return <Check size={iconSize} color="var(--green200)" />;
    }
    if (variant === ToastVariants.Warning) {
      return <AlertCircle size={iconSize} color="var(--orange200)" />;
    }
    if (variant === ToastVariants.Capital) {
      return <Info size={iconSize} color="var(--blue200)" />;
    }
    if (variant === ToastVariants.Crypto) {
      return <Info size={iconSize} color="var(--purple200)" />;
    }
    return <X size={iconSize} color="var(--red200)" />;
  };

  const getIconColor = () => {
    if (variant === ToastVariants.Success) {
      return "var(--green800)";
    }
    if (variant === ToastVariants.Warning) {
      return "var(--orange800)";
    }
    if (variant === ToastVariants.Capital) {
      return "var(--blue800)";
    }
    if (variant === ToastVariants.Crypto) {
      return "var(--purple800)";
    }
    return "var(--red800)";
  };

  return (
    <div
      className={classNames(styles.toastContainer, styles[variant], {
        [styles.withoutClose]: !onClose,
        [styles.ios]: isIOS,
      })}
    >
      <div className={styles.wrapper}>
        <div className={styles.icon}>{getIcon()}</div>
        <Text variant={TextVariants.SemiboldTextS} className={styles.text}>
          {message}
        </Text>
      </div>
      {onClose && (
        <X size={px2rem(16)} color={getIconColor()} onClick={onClose} />
      )}
    </div>
  );
};

export default Toast;

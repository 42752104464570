import { useMutation } from "@tanstack/react-query";
import API from "apis";
import { queryClient } from "apis/queryClient";
import { userKeys } from "apis/queryKeyFactories";
import { userService } from "apis/services";
import { updateDarkTheme } from "features/user/userSlice";
import { useAppDispatch } from "hooks/redux";
import type { ErrorMessage } from "interfaces/errors";

type DarkModeParams = boolean;
type DarkModeResponse = void;

export const useDarkModeMutation = () => {
  const dispatch = useAppDispatch();

  return useMutation<DarkModeResponse, ErrorMessage, DarkModeParams>({
    mutationFn: async (dark_theme: boolean) => {
      return await API.patch(userService.userSettings, {
        dark_theme,
      });
    },
    onError: () => {
      return {
        message: "Hubo un error para actualizar el tema.",
        status: 500,
      };
    },
    onSuccess: (_data, dark_theme) => {
      dispatch(updateDarkTheme(dark_theme));
      queryClient.invalidateQueries({
        queryKey: userKeys.users,
      });
    },
  });
};

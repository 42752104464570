import classNames from "classnames";

import styles from "./styles.module.scss";

interface DividerProps {
  orientation?: "horizontal" | "vertical";
  className?: string;
}

const Divider: React.FC<DividerProps> = ({
  orientation = "horizontal",
  className,
}) => (
  <div className={classNames(styles.divider, className, styles[orientation])} />
);
export default Divider;

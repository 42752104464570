interface PropTypes {
  width?: number;
  height?: number;
}

export const CocosSmallLogo = ({ width = 34, height = 28 }: PropTypes) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 34 28"
      fill="none"
    >
      <g clipPath="url(#clip0_1274_16753)">
        <path
          d="M12.1246 22.4998C7.76257 22.548 3.74571 20.4282 1.90965 16.6545C1.25876 15.2735 0.921387 13.7655 0.921387 12.2385C0.921387 10.7115 1.25876 9.20346 1.90965 7.82246C3.2307 4.90064 5.54322 2.5423 8.4364 1.16625L10.569 0.130493L12.646 4.39399L10.513 5.43778C8.62965 6.32129 7.11902 7.84275 6.24735 9.73339C5.8884 10.4842 5.69837 11.3046 5.6901 12.1368C5.68184 12.9691 5.85535 13.7931 6.19916 14.5509C7.7309 17.6823 12.1489 18.6779 16.0538 16.775L18.1864 15.7312L20.263 19.9947L18.1304 21.0385C16.2695 21.9538 14.2297 22.4446 12.1567 22.4757"
          fill="#113264"
        />
        <path
          d="M23.6634 28.0238L21.4905 23.8004L23.5992 22.7165C27.4641 20.7253 29.3162 16.5822 27.7207 13.4829C26.1247 10.3837 21.6911 9.46835 17.8261 11.4676L15.7174 12.5516L13.5444 8.3282L15.6532 7.24427C21.843 4.03259 29.1478 5.8793 31.9381 11.307C34.7285 16.7348 31.9381 23.7522 25.7639 26.9398L23.6634 28.0238Z"
          fill="#0D74CE"
        />
      </g>
      <defs>
        <clipPath id="clip0_1274_16753">
          <rect
            width="32.1311"
            height="28"
            fill="white"
            transform="translate(0.934082)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

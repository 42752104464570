interface PropTypes {
  size: number;
  color?: string;
}

export const CloseIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      className="close-icon"
      fill="none"
      height={`${size}`}
      viewBox="0 0 16 16"
      width={`${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.72083 2.6665L8.11733 8.063L2.72083 13.4595"
        stroke={color || "#060D12"}
        strokeWidth="2"
      />
      <path
        d="M13.405 2.6665L8.00853 8.063L13.405 13.4595"
        stroke={color || "#060D12"}
        strokeWidth="2"
      />
    </svg>
  );
};

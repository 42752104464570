import React from "react";

import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import { UIModalSlider } from "components/common/modal-slider";
import { ChevronDown, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  placeholder: string;
  label?: string;
  valueIcon?: React.FC<IconProps> | LucideIcon;
  value: string;
  open: boolean;
  setOpen: (v: boolean) => void;
  children: JSX.Element;
}

const Select: React.FC<SelectProps> = ({
  placeholder,
  label,
  valueIcon: Icon,
  value,
  open,
  setOpen,
  children,
}) => {
  const selectColor = value ? "var(--slate900)" : "var(--slate600)";
  const handleToggleDisplayModal = () => setOpen(!open);

  const handleOnClick = () => {
    setOpen(true);
  };

  return (
    <div className={styles.selectContainer}>
      {label && (
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {label}
        </Text>
      )}
      <div
        id="defaultSelect"
        className={classNames(styles.select, {
          [styles.withIcon]: Icon,
        })}
        onClick={handleOnClick}
      >
        <div className={styles.wrapper}>
          {Icon && <Icon size={24} />}
          <Text variant={TextVariants.RegularTextS} color={selectColor}>
            {value || placeholder}
          </Text>
        </div>
        <ChevronDown size={px2rem(16)} />
      </div>

      <UIModalSlider
        open={open}
        onToggleDisplay={handleToggleDisplayModal}
        classes={styles.sheetWrapper}
      >
        {children}
      </UIModalSlider>
    </div>
  );
};

export default Select;

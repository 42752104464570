import { Cryptos } from "constants/cryptos";

import { useEffect, useRef } from "react";

import { createChart, LineType, UTCTimestamp } from "lightweight-charts";
import { getColor } from "utils/getColor";

import getChartOptions from "./options";
import TimeFrameTabs from "./TimeFrameTabs";

import styles from "./styles.module.scss";

interface LineChartProps {
  selectedTime: number;
  ticker?: Cryptos;
  onChangeTime: (v: number) => void;
  chartData: { time: number; value: number }[];
  timeOptions: { label: string; value: number }[];
  onChangeCrosshair: (v?: { time: number; value: number }) => void;
}

const MIN_DATA = 7;

const LineChart: React.FC<LineChartProps> = ({
  ticker,
  chartData,
  timeOptions,
  selectedTime,
  onChangeTime,
  onChangeCrosshair,
}) => {
  let chart;

  chartData.sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime()
  );

  const chartRef = useRef<HTMLDivElement>(null);
  const lineColor = !ticker
    ? getColor("--purple800")
    : getColor(`--crypto-${ticker.toLowerCase()}`);

  useEffect(() => {
    if (!chartRef.current) return;

    chart = createChart(
      chartRef.current,
      getChartOptions(getColor("--background-2"))
    );
    const lineSeries = chart.addLineSeries({
      color: lineColor,
      lineType: LineType.Curved,
      lastPriceAnimation: 1,
      priceLineVisible: false,
    });

    chart.subscribeCrosshairMove((param) => {
      const hoveredTime = param.time as UTCTimestamp;

      if (!hoveredTime) {
        onChangeCrosshair(undefined);
        return;
      }

      const foundDate = chartData.find(
        ({ time }) => time === Number(hoveredTime) * 1000
      );

      if (!foundDate) {
        onChangeCrosshair({
          value: 0,
          time: Date.parse(hoveredTime.toString()),
        });
        return;
      }

      onChangeCrosshair({
        time: Number(foundDate.time),
        value: foundDate.value,
      });
    });

    const firstDate = new Date(chartData[0].time);

    while (chartData.length < MIN_DATA) {
      //Fullfills the array with fake data in case there is not enough
      const oneDayMilliseconds = 24 * 60 * 60 * 1000;

      firstDate.setTime(firstDate.getTime() - oneDayMilliseconds);

      const newDate = firstDate.toISOString().split("T")[0];
      chartData.unshift({ time: Date.parse(newDate), value: 0 });
    }

    lineSeries.setData(
      chartData.map(({ time, value }) => ({
        time: (time / 1000) as UTCTimestamp,
        value,
      }))
    );
    chart.timeScale().fitContent();
  }, []);

  return (
    <>
      <div ref={chartRef} className={styles.chart} />
      <TimeFrameTabs
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        onClickOption={(v: number) => onChangeTime(v)}
      />
    </>
  );
};

export default LineChart;

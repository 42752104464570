import { COPY_MESSAGE_TIME } from "constants/index";

import { useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import { ThemeVariants } from "interfaces/theme";
import IconButton, { IconButtonSizes } from "components/common/IconButton";
import Text, { TextVariants } from "components/common/Text";
import { CheckCheck, Copy } from "lucide-react";

import styles from "./styles.module.scss";

interface Config2FAPasswordProps {
  enrollData: AuthMFAEnrollResponse;
  displayCode: boolean;
  variant?: ThemeVariants;
}

const Config2FAPassword: React.FC<Config2FAPasswordProps> = ({
  enrollData,
  displayCode,
  variant = ThemeVariants.Capital,
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const password = enrollData.data?.totp.secret;

  const onClickCopy = async () => {
    try {
      await Clipboard.write({ string: password ?? "" });

      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.config2FAPasswordContainer}>
      {displayCode ? (
        <>
          {" "}
          <div className={styles.info}>
            <Text variant={TextVariants.RegularText} color="var(--slate900)">
              Clave de configuración
            </Text>
            <Text
              className={styles.password}
              variant={TextVariants.RegularUpperTextS}
              color="var(--slate800)"
            >
              {password}
            </Text>
          </div>
          <IconButton
            size={IconButtonSizes.Large}
            icon={copied ? CheckCheck : Copy}
            onClick={onClickCopy}
            variant={variant}
          />{" "}
        </>
      ) : (
        <img className={styles.qr} src={enrollData.data?.totp.qr_code} />
      )}
    </div>
  );
};

export default Config2FAPassword;

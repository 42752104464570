import { useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import { cardService } from "apis/services";
import mainRoutes from "router/routes";
import { useCocosCard } from "context/CocosCardProvider";
import { ThemeVariants } from "interfaces/theme";
import { CardTypesLabel } from "interfaces/card/enums";
import CardDeleted from "assets/illustrations/CocosCard/CardDeleted";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton, {
  FilledButtonVariants,
} from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface StolenBottomSheetProps {
  open: boolean;
  onClose: () => void;
  isVirtualCard: boolean;
}

const StolenBottomSheet: React.FC<StolenBottomSheetProps> = ({
  open,
  onClose,
  isVirtualCard,
}) => {
  const {
    physicalCard,
    virtualCard,
    selectedCard,
    getPomeloData,
  } = useCocosCard();
  const { isIOS } = getPlatform();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const idCard = isPhysicalCard ? physicalCard?.idCard : virtualCard?.idCard;

  const deleteCard = async () => {
    try {
      setIsLoading(true);
      await API.post(cardService.cancel, {
        idCard,
        reason: "CLIENT_INTERNAL_REASON",
      });
      ampli.cardStolenDeleted({ card_type: selectedCard });
      await getPomeloData();
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate(mainRoutes.card);
    }
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        {isVirtualCard ? "Robo de datos" : "Robo de tarjeta"}
      </Text>
      <div className={styles.sheetContent}>
        <CardDeleted />
        <Text variant={TextVariants.RegularText} className={styles.text}>
          {isVirtualCard
            ? "¿Estás seguro que querés dar de baja tu tarjeta? Recordá que vas a poder solicitar una nueva tarjeta virtual en cualquier momento."
            : "¿Estás seguro que querés dar de baja tu tarjeta física? Recordá que si pedís una tarjeta nueva vas a tener que pagar el costo de emisión y de envío."}
        </Text>
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton
          variant={FilledButtonVariants.Warning}
          onClick={deleteCard}
          isLoading={isLoading}
        >
          Sí, dar de baja
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          En otro momento
        </OutlinedButton>
      </div>
    </UIModalSlider>
  );
};

export default StolenBottomSheet;

import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import AlertModal from "components/common/alert-modal";
import { checkForUpdates } from "utils/updater";
import { Spinner } from "components/common/spinner";

import "./index.scss";

const UploaderModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    checkForUpdates(setIsOpen);
  }, []);

  return (
    <AlertModal
      headingText={
        <Typography component="p" variant="heading2">
          ¡Te damos la bienvenida!
        </Typography>
      }
      bodyText={
        <>
          <Typography
            id="subtitle"
            className="subtitle"
            component="p"
            variant="h5"
          >
            Aguardá un momento, estamos actualizando la aplicación...
          </Typography>
          <div id="spinner-wrapper">
            <Spinner />
          </div>
        </>
      }
      open={isOpen}
    />
  );
};

export default UploaderModal;

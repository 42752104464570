import { IconProps } from "assets/icons/BTC";
import Chip from "components/common/chip";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import { formatToCurrency } from "utils";

import styles from "./styles.module.scss";

interface ChipsList {
  icon: React.FC<IconProps>;
  primaryText: string | number;
  secondaryText: string | number;
}

interface ChipCarrouselProps {
  list: ChipsList[];
}

const ChipCarrousel: React.FC<ChipCarrouselProps> = ({ list }) => {
  const navigate = useNavigate();
  return (
    <div className={styles.divContainer}>
      {list.map(({ icon, primaryText, secondaryText }) => {
        const decimals = +primaryText < 0.05 ? 6 : 2;
        const holding = formatToCurrency(primaryText, false, false, decimals);
        if (!holding) return;
        return (
          <Chip
            key={primaryText}
            icon={icon}
            primaryText={holding}
            secondaryText={secondaryText}
            onClick={() => navigate(`${CryptoRoutes.MARKET}/${secondaryText}`)}
          />
        );
      })}
    </div>
  );
};

export default ChipCarrousel;

export enum PerformanceTabs {
  DAILY = "Diario",
  HISTORIC = "Histórico",
  GLOBAL = "Global",
}

export const getType = (option: PerformanceTabs) => {
  if (option === PerformanceTabs.DAILY) return "daily";
  if (option === PerformanceTabs.HISTORIC) return "historic";
  return "global";
};

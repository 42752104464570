import { NavigationOptions } from "constants/cryptos";

import { ThemeVariants } from "interfaces/theme";
import BottomNavigation from "components/common/BottomNavigation";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";

import styles from "./styles.module.scss";

interface CryptoPageProps {
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode[]
    | React.ReactNode;
  loading?: boolean;
  error?: boolean;
}

const CryptoPage: React.FC<CryptoPageProps> = ({
  children,
  loading,
  error,
}) => {
  return (
    <div className={styles.cryptoPageContainer}>
      {loading && (
        <div className={styles.loading}>
          <LoadingSpinner variant={ThemeVariants.Crypto} />
        </div>
      )}
      {error && !loading && (
        <ErrorPage errorMessage="En este momento no es posible cargar la página." />
      )}
      {!loading && !error && <div className={styles.children}>{children}</div>}
      <div className={styles.navigationWrapper}>
        <BottomNavigation options={NavigationOptions} />
      </div>
    </div>
  );
};

export default CryptoPage;

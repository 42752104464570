import { useNavigate } from "react-router-dom";
import { LucideIcon } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import Divider from "components/common/Divider";

import RowSection from "../RowSection";

import styles from "./styles.module.scss";

interface MenuOptions {
  icon?: LucideIcon;
  title: string;
  route?: string;
  onClick?: () => void;
  isWarning?: boolean;
  hideSection?: boolean;
}

interface RowSectionProps {
  menuOptions: MenuOptions[];
  subtitle: string;
}

const ProfileRow: React.FC<RowSectionProps> = ({ menuOptions, subtitle }) => {
  const navigate = useNavigate();
  const availableOptions = menuOptions.filter((op) => !op.hideSection);

  return (
    <>
      <Text
        variant={TextVariants.RegularText}
        className={styles.sectionSubtitle}
      >
        {subtitle}
      </Text>
      <div className={styles.listContainer}>
        {availableOptions.map(
          ({ icon, title, route, isWarning, onClick }, index) => {
            return (
              <div
                key={title}
                onClick={() => (route ? navigate(route) : onClick && onClick())}
              >
                <RowSection icon={icon} title={title} isWarning={isWarning} />
                {index !== availableOptions.length - 1 && <Divider />}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default ProfileRow;

import Heading, { HeadingVariants } from "components/common/Heading";
import PricesBox from "components/common/PricesBox";

import styles from "./styles.module.scss";

interface PricesSectionProps {
  sell: number;
  buy: number;
}

const PricesSection: React.FC<PricesSectionProps> = ({ sell, buy }) => {
  return (
    <div className={styles.pricesSectionContainer}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
        className={styles.title}
      >
        Cotización
      </Heading>
      <PricesBox sell={sell} buy={buy} />
    </div>
  );
};

export default PricesSection;

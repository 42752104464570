import { Capacitor } from "@capacitor/core";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export enum AnalyticsEvents {
  HOME_CLIC_COMPRAR_MEP = "app_home_clic_comprar_mep",
  MEP_CLIC_PASO2_CONTINUAR_COMPRA_MEP = "app_mep_clic_paso2_continuar_compra_mep",
  MEP_EXITO_FIN_COMPRA_MEP = "app_mep_exito_fin_compra_mep",
  HOME_CLIC_IR_A_MERCADO = "app_home_clic_ir_a_mercado",
  HOME_CLIC_ABRIR_COMITENTE = "app_home_clic_abrir_comitente",
  ALTAS_CLIC_PASO1_CREAR_CUENTA = "app_altas_clic_paso1_crear_cuenta",
  ALTAS_ENVIO_MAIL_VALIDACION = "app_altas_envio_mail_validacion",
  HOME_EXITO_PASO1_PROCESO_COMITENTE = "app_home_exito_paso1_proceso_comitente",
  HOME_EXITO_PASO2_PROCESO_COMITENTE = "app_home_exito_paso2_proceso_comitente",
  HOME_EXITO_PASO3_PROCESO_COMITENTE = "app_home_exito_paso3_proceso_comitente",
  HOME_EXITO_PASO4_PROCESO_COMITENTE = "app_home_exito_paso4_proceso_comitente",
  HOME_EXITO_PASO5_PROCESO_COMITENTE = "app_home_exito_paso5_proceso_comitente",
  HOME_EXITO_PROCESO_COMITENTE = "app_home_exito_proceso_comitente",
  HOME_REJECTED_PROCESO_COMITENTE = "app_home_rejected_proceso_comitente",
  HOME_ERROR_PROCESO_COMITENTE = "app_home_error_proceso_comitente",
  ALTAS_EXITO_FIN_REGISTRO_USUARIO = "app_altas_exito_fin_registro_usuario",
  ClIC_COCOA_USD = "app_clic_cocoa_usd",
  SUSCRIPCION_COCOA_USD_REVISAR_ORDEN = "app_suscripcion_cocoa_usd_revisar_orden",
  SUSCRIPCION_CONFIRMAR_ORDEN_COCOA_USD = "app_suscripcion_cocoa_confirmar_orden",
  SUSCRIPCION_COCOA_USD = "app_suscripcion_exito_cocoa_usd",
}

export const trackAnalyticsEvent = (eventName: string) => {
  if (!Capacitor.isNativePlatform()) return null;

  FirebaseAnalytics.logEvent({
    name: eventName,
    params: {},
  });
};

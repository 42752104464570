import { useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cardService } from "apis/services";
import { useNavigate } from "react-router-dom";
import { useCocosCard } from "context/CocosCardProvider";
import { ThemeVariants } from "interfaces/theme";
import mainRoutes from "router/routes";
import CardPaused from "assets/illustrations/CocosCard/CardPaused";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface LostBottomSheetProps {
  open: boolean;
  onClose: () => void;
}

const LostBottomSheet: React.FC<LostBottomSheetProps> = ({ open, onClose }) => {
  const { physicalCard, selectedCard, getPomeloData } = useCocosCard();
  const { isIOS } = getPlatform();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const pauseCard = async () => {
    try {
      setIsLoading(true);
      await API.post(cardService.pause, {
        idCard: physicalCard?.idCard,
        reason: "USER_INTERNAL_REASON",
      });
      await getPomeloData();
      ampli.cardLostPaused({ card_type: selectedCard });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      navigate(mainRoutes.card);
    }
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        Perdí mi tarjeta
      </Text>
      <div className={styles.sheetContent}>
        <CardPaused />
        <Text variant={TextVariants.RegularText} className={styles.text}>
          Por seguridad, te recomendamos pausar tu tarjeta para evitar consumos
          hasta que la encuentres o des de baja tu tarjeta y pidas una nueva.
        </Text>
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton
          variant={ThemeVariants.Pay}
          onClick={pauseCard}
          isLoading={isLoading}
        >
          Pausar tarjeta
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          En otro momento
        </OutlinedButton>
      </div>
    </UIModalSlider>
  );
};

export default LostBottomSheet;

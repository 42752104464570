import React from "react";

import { createTheme, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import * as Sentry from "@sentry/react";
import ErrorPage from "components/common/ErrorPage";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "apis/queryClient";

import App from "./App";
import "./sentry";

import "./index.scss";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    textS: React.CSSProperties;
    textS_bold: React.CSSProperties;
    textXS?: React.CSSProperties;
    textXXS?: React.CSSProperties;
    textXS_bold?: React.CSSProperties;
    textM_bold: React.CSSProperties;
    textM: React.CSSProperties;
    textL: React.CSSProperties;
    textL_bold: React.CSSProperties;
    heading1: React.CSSProperties;
    heading2: React.CSSProperties;
    heading3: React.CSSProperties;
    heading4: React.CSSProperties;
    heading4_bold?: React.CSSProperties;
    buttonS: React.CSSProperties;
    buttonL: React.CSSProperties;
    captionS: React.CSSProperties;
    captionM: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    textS?: React.CSSProperties;
    textS_bold?: React.CSSProperties;
    textXXS?: React.CSSProperties;
    textXS?: React.CSSProperties;
    textXS_bold?: React.CSSProperties;
    textM?: React.CSSProperties;
    textM_bold?: React.CSSProperties;
    textL?: React.CSSProperties;
    textL_bold?: React.CSSProperties;
    heading1?: React.CSSProperties;
    heading2?: React.CSSProperties;
    heading3?: React.CSSProperties;
    heading4?: React.CSSProperties;
    heading3_bold?: React.CSSProperties;
    heading4_bold?: React.CSSProperties;
    buttonS?: React.CSSProperties;
    buttonL?: React.CSSProperties;
    captionS?: React.CSSProperties;
    captionM?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    textS: true;
    textS_bold: true;
    textXXS: true;
    textXS: true;
    textXS_bold: true;
    textM: true;
    textM_bold: true;
    textL: true;
    textL_bold: true;
    heading1: true;
    heading2: true;
    heading3: true;
    heading4: true;
    heading3_bold?: true;
    heading4_bold: true;
    buttonS: true;
    buttonL: true;
    captionS: true;
    captionM: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0062e1",
      dark: "#accefa",
    },
    text: {
      primary: "#002C65",
      secondary: "#002C65",
    },
  },
  typography: {
    fontFamily:
      '"Nunito Sans", "Plus Jakarta Sans", "Helvetica", "Arial", sans-serif',
    allVariants: {
      color: "#002C65",
    },
    textXXS: {
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    textXS: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    textXS_bold: {
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "18px",
    },
    textS: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "18px",
    },
    textS_bold: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "18px",
    },
    textM: {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
    },
    textM_bold: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    textL: {
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "28px",
    },
    textL_bold: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "28px",
    },
    heading1: {
      fontSize: "40px",
      fontWeight: 600,
      lineHeight: "48px",
    },
    heading2: {
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "40px",
    },
    heading3: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "32px",
      letterSpacing: "-0.3px",
    },
    heading4: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "24px",
    },
    heading3_bold: {
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
    },
    heading4_bold: {
      fontSize: "20px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    buttonS: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "20px",
    },
    buttonL: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    captionS: {
      fontSize: "10px",
      fontWeight: 700,
      lineHeight: "16px",
      textTransform: "uppercase",
      letterSpacing: "1.5px",
    },
    captionM: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
  },
});

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: import.meta.env.VITE_BUGSNAG_KEY });

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

function FallbackComponent() {
  return <ErrorPage errorMessage="Ocurrió un error inesperado." />;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={myFallback} showDialog>
    <ErrorBoundary>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ErrorBoundary>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

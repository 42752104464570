import { Cryptos, Networks } from "constants/cryptos";

import classNames from "classnames";
import { Check } from "lucide-react";
import { IconProps } from "assets/icons/BTC";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import { PaymentCurrencies } from "interfaces/pay/enums";
import px2rem from "utils/px2rem";

import TextBadge from "../Badges/TextBadge";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface AssetSelectCardProps {
  icon?: React.FC<IconProps>;
  ticker: Cryptos | Currencies | Networks | PaymentCurrencies | string;
  name?: string;
  onClick?: (asset: string) => void;
  badge?: string;
  checked?: boolean;
  disabled?: boolean;
}

const AssetSelectCard: React.FC<AssetSelectCardProps> = ({
  icon: Icon,
  ticker,
  name,
  onClick,
  badge,
  checked,
  disabled,
}) => {
  const handleOnClick = () => {
    if (disabled) return;

    onClick && onClick(ticker);
  };

  return (
    <div
      className={classNames(styles.assetWrapper, {
        [styles.disabled]: disabled,
      })}
      onClick={handleOnClick}
    >
      {Icon && <Icon size={px2rem(24)} className={styles.icon} />}
      <Text variant={TextVariants.RegularText} color="var(--slate900)">
        {ticker}
      </Text>
      {name && (
        <Text
          component="span"
          variant={TextVariants.RegularText}
          color="var(--slate800)"
        >
          {name}
        </Text>
      )}
      {badge && <TextBadge text={badge} variant={ThemeVariants.Crypto} />}
      {checked && (
        <div className={styles.checkIcon}>
          <Check color="var(--white-to-black)" size={px2rem(16)} />
        </div>
      )}
    </div>
  );
};

export default AssetSelectCard;

import { ThemeVariants } from "interfaces/theme";
import LoadingSpinner from "components/common/LoadingSpinner";

import styles from "./styles.module.scss";

const LoadingScreen = ({
  variant = ThemeVariants.Crypto,
}: {
  variant?: ThemeVariants;
}) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingSpinner size={150} variant={variant} />
    </div>
  );
};

export default LoadingScreen;

import {
  TimeChartOptions,
  DeepPartial,
  CrosshairMode,
} from "lightweight-charts";

const getChartOptions = (color: string) => {
  const chartOptions: DeepPartial<TimeChartOptions> = {
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    leftPriceScale: {
      visible: false,
    },
    rightPriceScale: {
      visible: false,
    },
    timeScale: {
      visible: false,
    },
    handleScroll: {
      horzTouchDrag: false,
      vertTouchDrag: false,
      mouseWheel: false,
      pressedMouseMove: false,
    },
    kineticScroll: {
      mouse: false,
      touch: false,
    },
    handleScale: {
      mouseWheel: false,
      pinch: false,
    },
    layout: { background: { color } },
    crosshair: {
      mode: CrosshairMode.Normal,
      vertLine: {
        visible: true,
        labelVisible: false,
      },
      horzLine: {
        visible: false,
        labelVisible: false,
      },
    },
  };
  return chartOptions;
};

export default getChartOptions;

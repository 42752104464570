import Text, { TextVariants } from "components/common/Text";
import Amount from "components/common/Amount";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface DetailBoxProps {
  isBuy: boolean;
  boxTitle: string;
  isFixed: boolean;
  currency: BalanceCurrencies;
  amount: number;
  mepPrice: number;
}

const DetailBox: React.FC<DetailBoxProps> = ({
  isBuy,
  amount,
  isFixed,
  currency,
  boxTitle,
  mepPrice,
}) => {
  const transactionSymbol = isFixed ? "=" : "≈";

  return (
    <div className={styles.detailBox}>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          {boxTitle}
        </Text>
        <Amount currency={currency} amount={amount} />
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Cotización
        </Text>
        <div
          className={classNames(styles.quoteWrapper, {
            [styles.reverse]: isBuy,
          })}
        >
          <Amount amount={1} withDecimals={false} currency={Currencies.USD} />
          <Text
            color="var(--slate900)"
            variant={TextVariants.SemiboldUpperValue}
          >
            {transactionSymbol}
          </Text>
          <Amount amount={mepPrice} currency={Currencies.ARS} />
        </div>
      </div>
    </div>
  );
};

export default DetailBox;

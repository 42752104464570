import { useProfile } from "context/ProfileProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { HelpCircle, MessagesSquare } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import ProfileSectionTemplate from "../components/SectionTemplate";
import FaqsPage from "./page-faqs";

const ProfileHelp: React.FC = () => {
  const navigate = useNavigate();
  const { zendeskOpen } = useProfile();
  const { isMobile } = useDeviceCheck();
  const { breadcrumbList, addBreadcrumbOption } = useProfile();

  const faqsTitle = "Preguntas frecuentes";

  const handleOnClickFaqs = () => {
    if (isMobile) {
      navigate(mainRoutes.faqs);
      return;
    }

    addBreadcrumbOption(faqsTitle);
  };

  const handleOnClickCX = () => {
    if (isMobile) {
      navigate(mainRoutes.chat);
      return;
    }
    window.zE("messenger", zendeskOpen ? "close" : "open");
  };

  const sectionOptions = [
    {
      icon: MessagesSquare,
      label: "Atención al cliente",
      onClick: handleOnClickCX,
    },
    {
      icon: HelpCircle,
      label: faqsTitle,
      onClick: handleOnClickFaqs,
    },
  ];

  if (breadcrumbList.includes(faqsTitle)) return <FaqsPage />;

  return (
    <ProfileSectionTemplate
      title="Centro de ayuda"
      subtitle={isMobile ? "Ayuda" : undefined}
      options={sectionOptions}
      withHeader={isMobile}
    />
  );
};

export default ProfileHelp;

import { MFAErrorType } from "interfaces/auth";
import { mfaErrorTypeMessage } from "utils/mfa";

export enum CryptoWithdrawalPrevisualizationError {
  SMALL_AMOUNT = "QUOTE_AMOUNT_TOO_SMALL",
}

export enum CryptoWithdrawalErrors {
  MAX_DAILY = "MAX_DAILY_WITHDRAWAL_AMOUNT_REACHED",
  MAX_WEEKLY = "MAX_WEEKLY_WITHDRAWAL_AMOUNT_REACHED",
  MAX_MONTHLY = "MAX_MONTHLY_WITHDRAWAL_AMOUNT_REACHED",
}

export const CryptoWithdrawalErrorMessages: {
  [key in CryptoWithdrawalErrors]: string;
} = {
  [CryptoWithdrawalErrors.MAX_DAILY]: "Alcanzaste tu límite de envíos diario.",
  [CryptoWithdrawalErrors.MAX_WEEKLY]:
    "Alcanzaste tu límite de envíos semanal.",
  [CryptoWithdrawalErrors.MAX_MONTHLY]:
    "Alcanzaste tu límite de envíos mensual.",
};

export const ErrorMessages = {
  ...CryptoWithdrawalErrorMessages,
  ...mfaErrorTypeMessage,
} as { [key in CryptoWithdrawalErrors | MFAErrorType]: string };

export const getWithdrawalErrorMessage = (
  error?: CryptoWithdrawalErrors | MFAErrorType
) => {
  const defaultErrorMessage =
    "No pudimos realizar el envío. Por favor, volvé a intentarlo más tarde.";
  if (!error || error === MFAErrorType.INVALID_SECURITY_CODE)
    return defaultErrorMessage;

  const mappedErrorMessage = ErrorMessages[error];

  if (!mappedErrorMessage) return defaultErrorMessage;

  return mappedErrorMessage;
};

export const getWithdrawalErrorTitle = (
  error?: CryptoWithdrawalErrors | MFAErrorType
) => {
  const defaultErrorTitle = "Algo salió mal";

  if (!error) return defaultErrorTitle;

  if (error === MFAErrorType.INVALID_SECURITY_CODE) return "Código incorrecto";

  const withdrawalErrors = Object.values(CryptoWithdrawalErrors);

  if (withdrawalErrors.includes(error as CryptoWithdrawalErrors))
    return "Alcanzaste tu límite";

  return defaultErrorTitle;
};

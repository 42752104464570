import classNames from "classnames";
import { AlertCircle, Check, Info, X } from "lucide-react";
import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export enum BannerVariants {
  SUCCESS = "success",
  INFO_CAPITAL = "infoCapital",
  INFO_CRYPTO = "infoCrypto",
  INFO_PAY = "infoPay",
  WARNING = "warning",
  ERROR = "error",
}

interface BannerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  text: string;
  variant: BannerVariants;
}

const Banner: React.FC<BannerProps> = ({
  text,
  variant,
  className,
  ...props
}) => {
  const icons = {
    [BannerVariants.SUCCESS]: Check,
    [BannerVariants.INFO_CAPITAL]: Info,
    [BannerVariants.INFO_CRYPTO]: Info,
    [BannerVariants.INFO_PAY]: Info,
    [BannerVariants.WARNING]: AlertCircle,
    [BannerVariants.ERROR]: X,
  };

  const BannerIcon = icons[variant];

  return (
    <div
      {...props}
      className={classNames(className, styles.bannerWrapper, styles[variant])}
    >
      <div className={styles.iconWrapper}>
        <BannerIcon size={px2rem(12)} />
      </div>

      <Text className={styles.text} variant={TextVariants.SemiboldTextS}>
        {text}
      </Text>
    </div>
  );
};

export default Banner;

interface PropTypes {
  size?: number | string;
  color?: string;
}

export const CocosLogo = ({ size, color }: PropTypes) => {
  return (
    <svg
      className="checked-icon"
      fill="none"
      height={`${size ? size : 36}`}
      viewBox="0 0 40 36"
      width={`${size ? size : 40}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9239 28.4226C8.53905 28.4226 3.61329 25.7671 1.39135 21.0907C-0.213363 17.7154 -0.187318 13.846 1.46228 10.1954C3.03652 6.71132 5.92757 3.83028 9.60585 2.08301L12.2473 0.827148L14.7567 6.1106L12.1155 7.36646C9.68677 8.51999 7.79694 10.3805 6.79202 12.6039C5.86176 14.6616 5.82035 16.7843 6.67411 18.5808C8.51859 22.4626 13.9609 23.7475 18.8059 21.4454L21.4474 20.1895L23.9575 25.4725L21.3158 26.7283C19.0081 27.8346 16.4831 28.4134 13.9239 28.4228"
        fill={color ?? "#002C65"}
      />
      <path
        d="M28.0858 35.3849L25.4646 30.1566L28.0789 28.8458C32.8744 26.4416 35.2087 21.3602 33.2825 17.5185C31.3564 13.6767 25.8885 12.5069 21.0928 14.9107L18.4785 16.2214L15.8572 10.9931L18.4715 9.68235C26.1497 5.83337 35.1393 8.1723 38.5109 14.8972C41.8824 21.6221 38.3783 30.2247 30.6999 34.0744L28.0858 35.3849Z"
        fill={color ?? "#0062E1"}
      />
    </svg>
  );
};

import { Cryptos, Network } from "constants/cryptos";

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import {
  CryptoAccount,
  CryptoAvailableNetworks,
  CryptoHolding,
  CryptoPortfolio,
  CryptoTag,
} from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import { useLocation } from "react-router-dom";

export interface SendLocationState {
  sendMethod?: SendMethod;
  defaultTicker?: Cryptos;
  defaultAccount?: CryptoAccount;
}

export enum SendMethod {
  COCOSTAG = "cocostag",
  BLOCKCHAIN = "blockchain",
}

interface Context {
  error: boolean;
  isLoading: boolean;
  selectedTag?: CryptoTag;
  sendMethod?: SendMethod;
  cryptos: CryptoHolding[];
  selectedNetwork?: Network;
  selectedCrypto?: CryptoHolding;
  selectedAccount?: CryptoAccount;
  networks: CryptoAvailableNetworks[];
  setSelectedTag: (value?: CryptoTag) => void;
  setSendMethod: (value?: SendMethod) => void;
  getHoldings: (withLoading?: boolean) => void;
  setSelectedNetwork: (value?: Network) => void;
  setSelectedCrypto: (value?: CryptoHolding) => void;
  setSelectedAccount: (value?: CryptoAccount) => void;
  isLimitsOpen: boolean;
  setIsLimitsOpen: (value: boolean) => void;
}

const CryptoSendContext = createContext<Context | null>(null);

export const CryptoSendProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedTag, setSelectedTag] = useState<CryptoTag>();
  const [selectedCrypto, setSelectedCrypto] = useState<CryptoHolding>();
  const [selectedAccount, setSelectedAccount] = useState<CryptoAccount>();
  const [selectedNetwork, setSelectedNetwork] = useState<Network>();
  const [cryptos, setCryptos] = useState<CryptoHolding[]>([]);
  const [networks, setNetworks] = useState<CryptoAvailableNetworks[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [sendMethod, setSendMethod] = useState<SendMethod>();
  const [isLimitsOpen, setIsLimitsOpen] = useState<boolean>(false);

  const locationState = useLocation().state as SendLocationState | null;

  useEffect(() => {
    if (!locationState) return;

    if (locationState.defaultAccount)
      setSelectedAccount(locationState.defaultAccount);

    if (locationState.sendMethod) setSendMethod(locationState.sendMethod);
  }, []);

  const getHoldings = async (withLoading?: boolean) => {
    withLoading && setIsLoading(true);
    try {
      const { data } = await API.get<CryptoPortfolio>(cryptoService.portfolio);

      if (locationState && locationState.defaultAccount) {
        const filteredData = data.holdings.filter(
          (holding) =>
            holding.ticker !== Currencies.ARS &&
            locationState.defaultAccount?.tickers.includes(holding.ticker)
        );
        setCryptos(filteredData);
        return;
      }
      setCryptos(data.holdings);
      if (locationState && locationState?.defaultTicker) {
        const tickerState = data.holdings.find(
          (crypto: CryptoHolding) =>
            locationState?.defaultTicker === crypto.ticker
        );
        setSelectedCrypto(tickerState);
      }
    } catch (error) {
      setError(true);
    } finally {
      withLoading && setIsLoading(false);
    }
  };

  const getNetworks = async () => {
    try {
      const { data } = await API.get<CryptoAvailableNetworks[]>(
        cryptoService.networks
      );
      setNetworks(data);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    const getSendData = async () => {
      setIsLoading(true);
      await Promise.all([getHoldings(), getNetworks()]).finally(() =>
        setIsLoading(false)
      );
    };

    getSendData();
  }, []);

  const memoizedValues = useMemo(() => {
    return {
      error,
      cryptos,
      networks,
      isLoading,
      sendMethod,
      getHoldings,
      selectedTag,
      setSendMethod,
      setSelectedTag,
      selectedCrypto,
      selectedAccount,
      selectedNetwork,
      setSelectedCrypto,
      setSelectedAccount,
      setSelectedNetwork,
      isLimitsOpen,
      setIsLimitsOpen,
    };
  }, [
    error,
    cryptos,
    networks,
    isLoading,
    sendMethod,
    selectedTag,
    getHoldings,
    setSendMethod,
    setSelectedTag,
    selectedCrypto,
    selectedAccount,
    selectedNetwork,
    setSelectedCrypto,
    setSelectedAccount,
    setSelectedNetwork,
    isLimitsOpen,
    setIsLimitsOpen,
  ]);

  return (
    <CryptoSendContext.Provider value={memoizedValues}>
      {children}
    </CryptoSendContext.Provider>
  );
};

export const useCryptoSend = () => {
  const context = useContext(CryptoSendContext);

  if (!context) throw new Error("[CryptoSendContext] Missing context");

  return context;
};

import Text, { TextVariants } from "components/common/Text";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { TrendingDown, TrendingUp } from "lucide-react";
import { formatPercentage, formatToCurrency, getCurrencySymbol } from "utils";

import styles from "./styles.module.scss";

interface VariationProps {
  value: number;
  amount?: number;
  withArrow?: boolean;
  text?: string;
  currency?: BalanceCurrencies;
}

const Variation: React.FC<VariationProps> = ({
  value,
  amount,
  withArrow = true,
  text = "",
  currency = Currencies.ARS,
}) => {
  const isNegative = value < 0;
  const isEqual = value === 0;

  const variationColor = isNegative ? "var(--red800)" : "var(--green800)";
  const primaryColor = isEqual ? "var(--blue800)" : variationColor;

  const amountVariation = isNegative ? "-" : "+";
  const amountCurrencyAndPrice = `${getCurrencySymbol(
    currency
  )}${formatToCurrency(Math.abs(Number(amount)))}`;

  const showAmount = amount && (value > 1 || value < 1);
  const amountLabel =
    !!showAmount && `(${amountVariation}${amountCurrencyAndPrice})`;

  const Arrow = isNegative ? TrendingDown : TrendingUp;

  return (
    <div className={styles.variationContainer}>
      {withArrow && !isEqual && <Arrow color={variationColor} size={16} />}
      <Text variant={TextVariants.SemiboldUpperValueS} color={primaryColor}>
        {formatPercentage(value, false)} {amountLabel}
      </Text>
      {text && (
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {text}
        </Text>
      )}
    </div>
  );
};

export default Variation;

import { getNetwork } from "constants/cryptos";

import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import px2rem from "utils/px2rem";
import { SendMethod, useCryptoSend } from "context/CryptoSendProvider";

import styles from "./styles.module.scss";

interface DetailsBodyProps {
  amount: number;
  fee: number;
}

const DetailsBody: React.FC<DetailsBodyProps> = ({ amount, fee }) => {
  const {
    sendMethod,
    selectedTag,
    selectedCrypto,
    selectedNetwork,
    selectedAccount,
  } = useCryptoSend();

  const NetworkIcon = selectedNetwork
    ? getNetwork(selectedNetwork.name).icon
    : null;

  const total = amount - fee;

  const isTag = sendMethod === SendMethod.COCOSTAG;
  const isBlockchain = sendMethod === SendMethod.BLOCKCHAIN;

  return (
    <div className={styles.details}>
      {selectedNetwork && (
        <div className={styles.row}>
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            Red
          </Text>
          <Text
            variant={TextVariants.SemiboldText}
            color="var(--slate900)"
            className={styles.network}
          >
            {NetworkIcon && (
              <>
                <NetworkIcon size={px2rem(20)} />
                {selectedNetwork?.name}
              </>
            )}
          </Text>
        </div>
      )}
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Enviar a
        </Text>
        {selectedAccount && (
          <Text
            variant={TextVariants.SemiboldText}
            component="span"
            color="var(--slate900)"
          >
            {selectedAccount.name}
          </Text>
        )}
        {selectedTag && (
          <Text
            variant={TextVariants.SemiboldText}
            component="span"
            color="var(--slate900)"
          >
            {selectedTag.accountDisplayName}
          </Text>
        )}
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Monto
        </Text>
        <Currency
          color="var(--slate900)"
          amount={amount}
          ticker={selectedCrypto?.ticker}
          decimal={6}
        />
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Costo
        </Text>
        {isBlockchain && (
          <Currency
            color="var(--slate900)"
            amount={fee}
            ticker={selectedCrypto?.ticker}
            decimal={2}
          />
        )}
        {isTag && (
          <Text variant={TextVariants.SemiboldText} color="var(--green800)">
            Sin costo
          </Text>
        )}
      </div>
      <div className={styles.row}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Total a recibir
        </Text>
        <Currency
          color="var(--slate900)"
          amount={total}
          ticker={selectedCrypto?.ticker}
          decimal={6}
        />
      </div>
    </div>
  );
};

export default DetailsBody;

import { IconProps } from "assets/icons/BTC";

const USFlag: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2028_14826)">
      <path
        d="M11.9996 24.0004C18.627 24.0004 23.9996 18.6279 23.9996 12.0005C23.9996 5.37306 18.627 0.000488281 11.9996 0.000488281C5.37221 0.000488281 -0.000366211 5.37306 -0.000366211 12.0005C-0.000366211 18.6279 5.37221 24.0004 11.9996 24.0004Z"
        fill="white"
      />
      <path
        d="M11.4779 12.0004H23.9996C23.9996 10.9174 23.8552 9.8681 23.5862 8.87H11.4779V12.0004Z"
        fill="#D80027"
      />
      <path
        d="M11.4779 5.73958H22.2383C21.5037 4.54089 20.5645 3.48138 19.4694 2.60913H11.4779V5.73958Z"
        fill="#D80027"
      />
      <path
        d="M11.9996 24.0004C14.8238 24.0004 17.4195 23.0243 19.4694 21.3917H4.52979C6.57962 23.0243 9.17542 24.0004 11.9996 24.0004Z"
        fill="#D80027"
      />
      <path
        d="M1.76097 18.2613H22.2384C22.8281 17.299 23.2855 16.2471 23.5863 15.1309H0.413086C0.713882 16.2471 1.17124 17.299 1.76097 18.2613Z"
        fill="#D80027"
      />
      <path
        d="M5.55825 1.87445H6.65179L5.63461 2.61344L6.02315 3.80917L5.00601 3.07019L3.98888 3.80917L4.3245 2.77619C3.42891 3.5222 2.64394 4.39623 1.99707 5.3701H2.34746L1.69997 5.84049C1.5991 6.00877 1.50235 6.17973 1.40963 6.35321L1.71882 7.30482L1.14198 6.88571C0.998585 7.18951 0.867429 7.50015 0.749539 7.81725L1.09018 8.86575H2.34746L1.33027 9.60474L1.71882 10.8005L0.701679 10.0615L0.0923992 10.5042C0.031415 10.9944 -0.000366211 11.4937 -0.000366211 12.0005H11.9996C11.9996 5.3731 11.9996 4.59179 11.9996 0.000488281C9.62905 0.000488281 7.41923 0.688143 5.55825 1.87445ZM6.02315 10.8005L5.00601 10.0615L3.98888 10.8005L4.37742 9.60474L3.36024 8.86575H4.61752L5.00601 7.67002L5.39451 8.86575H6.65179L5.63461 9.60474L6.02315 10.8005ZM5.63461 6.10909L6.02315 7.30482L5.00601 6.56584L3.98888 7.30482L4.37742 6.10909L3.36024 5.3701H4.61752L5.00601 4.17437L5.39451 5.3701H6.65179L5.63461 6.10909ZM10.3275 10.8005L9.31035 10.0615L8.29321 10.8005L8.68176 9.60474L7.66457 8.86575H8.92185L9.31035 7.67002L9.69885 8.86575H10.9561L9.93894 9.60474L10.3275 10.8005ZM9.93894 6.10909L10.3275 7.30482L9.31035 6.56584L8.29321 7.30482L8.68176 6.10909L7.66457 5.3701H8.92185L9.31035 4.17437L9.69885 5.3701H10.9561L9.93894 6.10909ZM9.93894 2.61344L10.3275 3.80917L9.31035 3.07019L8.29321 3.80917L8.68176 2.61344L7.66457 1.87445H8.92185L9.31035 0.678721L9.69885 1.87445H10.9561L9.93894 2.61344Z"
        fill="#0052B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_2028_14826">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default USFlag;

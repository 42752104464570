import { Suspense, lazy, useEffect, useState } from "react";

import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import * as Sentry from "@sentry/react";
import AppUrlListener from "AppUrlListener";
import { IdentifyProperties, ampli } from "ampli";
import API from "apis";
import { publicService } from "apis/services";
import BackDropSpinner from "components/common/back-drop";
import Preloader from "components/common/preloader";
import UploaderModal from "components/common/uploader-modal";
import TwoFAPage from "components/page-2fa";
import ChangePasswordPage from "components/page-change-password";
import Crypto2FAPage from "components/page-crypto-2fa";
import AgendaSection from "components/page-crypto-profile/SectionsList/AgendaSection";
import ConfigSection from "components/page-crypto-profile/SectionsList/ConfigSection";
import FaqsSection from "components/page-crypto-profile/SectionsList/FaqsSection";
import HelpSection from "components/page-crypto-profile/SectionsList/HelpSection";
import LearnSection from "components/page-crypto-profile/SectionsList/LearnSection";
import LegalsSection from "components/page-crypto-profile/SectionsList/LegalsSection";
import SecuritySection from "components/page-crypto-profile/SectionsList/SecuritySection";
import CryptoWelcomePage from "components/page-crypto-welcome";
import ResetPassword from "components/page-reset-password";
import { useAppState } from "context/AppStateProvider";
import { useAuth } from "context/AuthProvider";
import { CapitalSendAccountsProvider } from "context/CapitalSendAccountsProvider";
import { CocosCardProvider } from "context/CocosCardProvider";
import { useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import CocosCardTransactions from "pages/cocos-card/transactions";
import CocosCardTransactionDetail from "pages/cocos-card/transactions/detail";
import CocosCardHelp from "pages/cocos-card/help";
import CocosCardLimits from "pages/cocos-card/limit";
import CocosCardPin from "pages/cocos-card/pin";
import CocosCardIssues from "pages/cocos-card/issues";
import CocosCardIssueMovements from "pages/cocos-card/issueMovements";
import CocosCardSettings from "pages/cocos-card/settings";
import CryptoHome from "pages/crypto";
import Activity from "pages/crypto/activity";
import Allocation from "pages/crypto/allocation";
import Cable from "pages/crypto/cable";
import CryptoLimits from "pages/crypto/limits";
import CryptoMarket from "pages/crypto/market";
import CryptoMarketDetail from "pages/crypto/market/detail";
import CryptoPerformance from "pages/crypto/performance";
import CryptoPortfolio from "pages/crypto/portfolio";
import Receive from "pages/crypto/receive";
import ReceiveArs from "pages/crypto/receive/ars";
import Send from "pages/crypto/send";
import Swap from "pages/crypto/swap";
import EmailValidation from "pages/email-validation";
import EnableBiometry from "pages/enable-biometry";
import PhoneValidation from "pages/phone-validation";
import TrustedDevice from "pages/trusted-device";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { socket } from "socket";
import { RootState } from "store/store";
import brazeClient from "utils/braze";
import getPlatform from "utils/getPlatform";
import { PortfolioProvider } from "context/PortfolioProvider";

import CryptoRoute from "./CryptoRoute";
import RequireAuth from "./RequireAuth";
import mainRoutes, { CryptoRoutes, default as routes } from "./routes";

const CapitalAccounts = lazy(() => import("pages/capital/accounts"));
const CapitalPortfolio = lazy(() => import("pages/capital/portfolio"));
const SignUp = lazy(() => import("pages/sign-up"));
const Bid = lazy(() => import("pages/bid"));
const CapitalReceivePage = lazy(() => import("pages/capital/receive"));
const CapitalSendPage = lazy(() => import("pages/capital/send"));
const Calculadora = lazy(() => import("pages/calculadora"));
const Chat = lazy(() => import("pages/profile/help/chat"));
const CocosGold = lazy(() => import("pages/cocos-gold"));
const CocosPay = lazy(() => import("pages/cocos-pay"));
const CocosCard = lazy(() => import("pages/cocos-card"));
const CreateAccount = lazy(() => import("pages/create-account"));
const CryptoCocosTag = lazy(() => import("pages/crypto/cocostag"));
const CryptoProfile = lazy(() => import("pages/crypto/profile"));
const Faqs = lazy(() => import("pages/profile/help/faqs"));
const ForgotPassword = lazy(() => import("pages/forgot-password"));
const Help = lazy(() => import("pages/profile/help"));
const Home = lazy(() => import("pages/home"));
const Intro = lazy(() => import("pages/intro"));
const InvestmentSuggested = lazy(() => import("pages/investment-suggestion"));
const InvestorTest = lazy(() => import("pages/profile/investor-test"));
const Login = lazy(() => import("pages/login"));
const Logout = lazy(() => import("pages/logout"));
const Market = lazy(() => import("pages/market"));
const MarketDetail = lazy(() => import("pages/market/market-detail"));
const Mep = lazy(() => import("pages/mep"));
const Movements = lazy(() => import("pages/movements"));
const MovementReceipt = lazy(() => import("pages/movements/receipt"));
const NotFound = lazy(() => import("pages/errors/not-found"));
const OrderConfirm = lazy(() => import("pages/order-confirm"));
const OrderDetail = lazy(() => import("pages/order-detail"));
const Orders = lazy(() => import("pages/orders"));
const PersonalData = lazy(() => import("pages/profile/personal-data"));
const Legals = lazy(() => import("pages/profile/legals"));
const Learn = lazy(() => import("pages/profile/learn"));
const Config = lazy(() => import("pages/profile/config"));
const Profile = lazy(() => import("pages/profile"));
const Results = lazy(() => import("pages/calculadora/results"));
const Security = lazy(() => import("pages/profile/security"));
const Trading = lazy(() => import("pages/trading"));
const Wallet = lazy(() => import("pages/wallet"));

//TODO: Mover a pages
const Maintenance = lazy(() => import("pages/maintenance"));
const TickerMovements = lazy(
  () =>
    import(
      "components/page-movements/components/movements-common/movements-ticker"
    )
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const AppRouter: React.FC = () => {
  const { isMobile } = useDeviceCheck();
  const isDarkMode = useAppSelector((state: RootState) => {
    return Boolean(state.user.user?.dark_theme);
  });
  const { subscribeList } = useAppSelector((state: RootState) => state.prices);
  const [isAppActive, setIsAppActive] = useState<boolean>(true);
  const isNative = Capacitor.isNativePlatform();
  const { isAndroid } = getPlatform();
  const { isAuthenticated, user } = useAuth();
  const { isBrazeInitialized } = useAppState();

  useEffect(() => {
    const getAppStatus = async () => {
      const { data } = await API.get(publicService.status);

      setIsAppActive(data);
    };

    getAppStatus();
  }, []);

  useEffect(() => {
    if (!isNative) return;

    if (isAndroid) {
      StatusBar.setOverlaysWebView({ overlay: false });

      setTimeout(() => {
        StatusBar.setOverlaysWebView({ overlay: true });
      }, 500);
      return;
    }
    StatusBar.setOverlaysWebView({ overlay: true });
  }, []);

  useEffect(() => {
    if (!isNative) return;
    StatusBar.setStyle({ style: isDarkMode ? Style.Dark : Style.Light });
  }, [isDarkMode]);

  useEffect(() => {
    subscribeList?.length > 0 && socket.emit("subscribe", ...subscribeList);
  }, [subscribeList]);

  useEffect(() => {
    if (!isBrazeInitialized) {
      return;
    }

    if (isAuthenticated && user?.id) {
      brazeClient.changeUser(user.id);

      const userProps: IdentifyProperties = {};

      if (user.email) {
        brazeClient.setEmail(user.email);
        userProps.email = user.email;
      }

      ampli.identify(user.id, userProps);
    }

    brazeClient.openSession();
  }, [isBrazeInitialized, user?.id]);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user?.id });
    } else {
      Sentry.setUser(null);
    }
  }, [user?.id]);

  if (!isAppActive)
    return (
      <Suspense fallback={<Preloader />}>
        <BrowserRouter>
          <SentryRoutes>
            <Route path={routes.maintenance} element={<Maintenance />} />
            <Route path="*" element={<Navigate to={routes.maintenance} />} />
          </SentryRoutes>
        </BrowserRouter>
      </Suspense>
    );

  return (
    <Suspense fallback={<Preloader />}>
      <BrowserRouter>
        <UploaderModal />
        <AppUrlListener />
        <SentryRoutes>
          <Route
            path={routes.maintenance}
            element={<Navigate to={routes.home} />}
          />
          <Route
            path={routes.home}
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />

          <Route
            path={routes.intro}
            element={
              <RequireAuth>
                <Intro />
              </RequireAuth>
            }
          />

          <Route
            path={routes.calculator}
            element={
              <RequireAuth>
                <Calculadora />
              </RequireAuth>
            }
          />

          <Route
            path={routes.calculatorResult}
            element={
              <RequireAuth>
                <Results />
              </RequireAuth>
            }
          />

          <Route
            path={routes.orderConfirm}
            element={
              <RequireAuth>
                {isMobile ? (
                  <OrderConfirm />
                ) : (
                  <Navigate replace to={routes.home} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.cocosGold}
            element={
              <RequireAuth>
                {isMobile ? (
                  <CocosGold />
                ) : (
                  <Navigate replace to={routes.home} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.investment}
            element={
              <RequireAuth>
                {isMobile ? (
                  <InvestmentSuggested />
                ) : (
                  <Navigate replace to={routes.home} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.bid}
            element={
              <RequireAuth>
                {isMobile ? <Bid /> : <Navigate replace to={routes.home} />}
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.orderDetail}/:id`}
            element={
              <RequireAuth>
                {isMobile ? (
                  <OrderDetail />
                ) : (
                  <Navigate replace to={`${routes.market}/ACCIONES`} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.marketMep}
            element={
              <RequireAuth>
                <Mep />
              </RequireAuth>
            }
          />

          <Route
            path={routes.marketMep}
            element={
              <RequireAuth>
                <Navigate replace to={routes.marketMep} />
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.market}/:instrument_type/:id`}
            element={
              <RequireAuth>
                <Trading />
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.market}/FCI/:id`}
            element={
              <RequireAuth>
                <MarketDetail />
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.market}/:instrument_type`}
            element={
              <RequireAuth>
                <Market />
              </RequireAuth>
            }
          />

          <Route
            path={routes.market}
            element={
              <RequireAuth>
                <Navigate replace to={`${routes.market}/ACCIONES`} />
              </RequireAuth>
            }
          />

          <Route
            path={routes.personalData}
            element={
              <RequireAuth>
                {isMobile ? (
                  <PersonalData />
                ) : (
                  <Navigate replace to={routes.profile} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.profile}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />

          <Route
            path={routes.profileTest}
            element={
              <RequireAuth>
                <InvestorTest />
              </RequireAuth>
            }
          />

          <Route
            path={routes.profileConfig}
            element={
              <RequireAuth>
                {isMobile ? (
                  <Config />
                ) : (
                  <Navigate replace to={routes.profile} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.profileLearn}
            element={
              <RequireAuth>
                {isMobile ? (
                  <Learn />
                ) : (
                  <Navigate replace to={routes.profile} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.profileLegals}
            element={
              <RequireAuth>
                {isMobile ? (
                  <Legals />
                ) : (
                  <Navigate replace to={routes.profile} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.wallet}
            element={
              <RequireAuth>
                <Wallet />
              </RequireAuth>
            }
          />

          <Route
            path={routes.portfolio}
            element={
              <RequireAuth>
                <PortfolioProvider>
                  <CapitalPortfolio />
                </PortfolioProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.accounts}
            element={
              <RequireAuth>
                <CapitalSendAccountsProvider>
                  <CapitalAccounts />
                </CapitalSendAccountsProvider>
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.movements}/:instrument_code`}
            element={
              <RequireAuth>
                <TickerMovements />
              </RequireAuth>
            }
          />

          <Route
            path={routes.movements}
            element={
              <RequireAuth>
                <Movements />
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.movementReceipt}/:id`}
            element={
              <RequireAuth>
                {isMobile ? (
                  <MovementReceipt />
                ) : (
                  <Navigate replace to={routes.movements} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.orders}
            element={
              <RequireAuth>
                <Orders />
              </RequireAuth>
            }
          />

          <Route
            path={routes.help}
            element={
              <RequireAuth>
                {isMobile ? <Help /> : <Navigate replace to={routes.profile} />}
              </RequireAuth>
            }
          />

          <Route
            path={routes.faqs}
            element={
              <RequireAuth>
                {isMobile ? <Faqs /> : <Navigate replace to={routes.profile} />}
              </RequireAuth>
            }
          />

          <Route
            path={routes.chat}
            element={
              <RequireAuth>
                <Chat />
              </RequireAuth>
            }
          />

          <Route
            path={routes.createAccount}
            element={
              <RequireAuth>
                <CreateAccount />
              </RequireAuth>
            }
          />

          <Route
            path={routes.pay}
            element={
              <RequireAuth>
                <CocosPay />
              </RequireAuth>
            }
          />

          <Route
            path={routes.card}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCard />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardSettings}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardSettings />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardTransactions}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardTransactions />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={`${routes.cardTransactions}/:id`}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardTransactionDetail />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardIssues}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardIssues />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardIssuesMovements}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardIssueMovements />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardHelp}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardHelp />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardLimits}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardLimits />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.cardPin}
            element={
              <RequireAuth>
                <CocosCardProvider>
                  <CocosCardPin />
                </CocosCardProvider>
              </RequireAuth>
            }
          />

          <Route
            path={routes.security}
            element={
              <RequireAuth>
                {isMobile ? (
                  <Security />
                ) : (
                  <Navigate replace to={routes.profile} />
                )}
              </RequireAuth>
            }
          />

          <Route
            path={routes.cocos2FA}
            element={
              <RequireAuth>
                <TwoFAPage />
              </RequireAuth>
            }
          />

          <Route
            path={routes.trustedDevice}
            element={
              <RequireAuth>
                <TrustedDevice />
              </RequireAuth>
            }
          />

          <Route
            path={routes.phoneValidation}
            element={
              <RequireAuth>
                <PhoneValidation />
              </RequireAuth>
            }
          />

          <Route
            path={routes.enableBiometry}
            element={
              <RequireAuth>
                <EnableBiometry />
              </RequireAuth>
            }
          />

          <Route
            path={routes.portfolio}
            element={
              <RequireAuth>
                <CapitalPortfolio />
              </RequireAuth>
            }
          />

          <Route
            path={routes.receive}
            element={
              <RequireAuth>
                <CapitalReceivePage />
              </RequireAuth>
            }
          />

          <Route
            path={routes.send}
            element={
              <RequireAuth>
                <CapitalSendAccountsProvider>
                  <CapitalSendPage />
                </CapitalSendAccountsProvider>
              </RequireAuth>
            }
          />

          {/*  CRYPTO  */}

          <Route
            path={CryptoRoutes.MARKET}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoMarket />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.PORTFOLIO}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoPortfolio />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.PERFORMANCE}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoPerformance />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.TICKER}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoMarketDetail />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.HOME}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoHome />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.ACTIVITY}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Activity />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.AGENDA}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <AgendaSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.SWAP}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Swap />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.RECEIVE}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Receive />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.SEND}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Send />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.ALLOCATION}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Allocation />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.LEGALS}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <LegalsSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.HELP}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <HelpSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.FAQS}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <FaqsSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.LEARN}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <LearnSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.CONFIG}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <ConfigSection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.SECURITY}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <SecuritySection />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.PROFILE}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoProfile />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.TWOFA}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Crypto2FAPage />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.TYC}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoWelcomePage />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.CABLE}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <Cable />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.TAG}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoCocosTag />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.LIMITS}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <CryptoLimits />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route
            path={CryptoRoutes.RECEIVE_ARS}
            element={
              <RequireAuth>
                <CryptoRoute>
                  <ReceiveArs />
                </CryptoRoute>
              </RequireAuth>
            }
          />

          <Route path={CryptoRoutes.AGREEMENT} element={<div />} />

          <Route path={mainRoutes.signUp} element={<SignUp />} />

          <Route path={mainRoutes.altas} element={<SignUp />} />

          <Route path={routes.login} element={<Login />} />

          <Route path={routes.forgotPassword} element={<ForgotPassword />} />

          <Route path={routes.resetPassword} element={<ResetPassword />} />

          <Route
            path={routes.changePassword}
            element={<ChangePasswordPage />}
          />

          <Route path={routes.emailValidation} element={<EmailValidation />} />

          <Route path={routes.logout} element={<Logout />} />

          <Route path="*" element={<NotFound />} />
        </SentryRoutes>

        <BackDropSpinner />
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;

import { IconProps } from "assets/icons/BTC";

const Atm: React.FC<IconProps> = ({ size, ...props }) => (
  <svg
    width="107"
    height="68"
    viewBox="0 0 107 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3486_43512)">
      <path
        d="M99.3389 0H82.4114C78.6657 0 75.6292 3.04764 75.6292 6.80709V23.7967C75.6292 27.5562 78.6657 30.6038 82.4114 30.6038H99.3389C103.085 30.6038 106.121 27.5562 106.121 23.7967V6.80709C106.121 3.04764 103.085 0 99.3389 0Z"
        fill="url(#paint0_linear_3486_43512)"
      />
      <path
        d="M23.7098 0H6.78222C3.0365 0 0 3.04764 0 6.80709V23.7967C0 27.5562 3.0365 30.6038 6.78222 30.6038H23.7098C27.4555 30.6038 30.492 27.5562 30.492 23.7967V6.80709C30.492 3.04764 27.4555 0 23.7098 0Z"
        fill="black"
      />
      <path
        d="M61.5229 0H44.5953C40.8496 0 37.8131 3.04764 37.8131 6.80709V23.7967C37.8131 27.5562 40.8496 30.6038 44.5953 30.6038H61.5229C65.2686 30.6038 68.3051 27.5562 68.3051 23.7967V6.80709C68.3051 3.04764 65.2686 0 61.5229 0Z"
        fill="black"
      />
      <path
        d="M99.3389 37.3945H82.4114C78.6657 37.3945 75.6292 40.4422 75.6292 44.2016V61.1913C75.6292 64.9507 78.6657 67.9984 82.4114 67.9984H99.3389C103.085 67.9984 106.121 64.9507 106.121 61.1913V44.2016C106.121 40.4422 103.085 37.3945 99.3389 37.3945Z"
        fill="url(#paint1_linear_3486_43512)"
      />
      <path
        d="M61.5229 37.3945H44.5953C40.8496 37.3945 37.8131 40.4422 37.8131 44.2016V61.1913C37.8131 64.9507 40.8496 67.9984 44.5953 67.9984H61.5229C65.2686 67.9984 68.3051 64.9507 68.3051 61.1913V44.2016C68.3051 40.4422 65.2686 37.3945 61.5229 37.3945Z"
        fill="url(#paint2_linear_3486_43512)"
      />
      <path
        d="M23.7097 37.3945H6.78209C3.03638 37.3945 -0.00012207 40.4422 -0.00012207 44.2016V61.1913C-0.00012207 64.9507 3.03638 67.9984 6.78209 67.9984H23.7097C27.4554 67.9984 30.4919 64.9507 30.4919 61.1913V44.2016C30.4919 40.4422 27.4554 37.3945 23.7097 37.3945Z"
        fill="url(#paint3_linear_3486_43512)"
      />
      <path
        d="M15.246 24.3213V7.14844"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M6.6908 15.7344H23.8009"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M9.19702 9.66211L21.2949 21.8043"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M21.2949 9.66211L9.19702 21.8043"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M53.062 24.3213V7.14844"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M44.507 15.7344H61.6171"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M47.0101 9.66211L59.1109 21.8043"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M59.1109 9.66211L47.0101 21.8043"
        stroke="white"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M82.4496 23.5332H99.3006"
        stroke="#1C2024"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M82.4496 60.9297H99.3006"
        stroke="#1C2024"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M44.6365 60.9297H61.4845"
        stroke="#1C2024"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
      <path
        d="M6.82336 60.9297H23.6714"
        stroke="#1C2024"
        strokeWidth="1.42593"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3486_43512"
        x1="109.758"
        y1="-3.65035"
        x2="79.1338"
        y2="26.8621"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#E6EAF4" />
        <stop offset="0.56" stopColor="#DADEE9" />
        <stop offset="0.85" stopColor="#D1D5E0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3486_43512"
        x1="109.758"
        y1="33.7442"
        x2="79.1338"
        y2="64.2566"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#E6EAF4" />
        <stop offset="0.56" stopColor="#DADEE9" />
        <stop offset="0.85" stopColor="#D1D5E0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3486_43512"
        x1="71.9451"
        y1="33.7442"
        x2="41.3207"
        y2="64.2566"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#E6EAF4" />
        <stop offset="0.56" stopColor="#DADEE9" />
        <stop offset="0.85" stopColor="#D1D5E0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3486_43512"
        x1="34.1289"
        y1="33.7442"
        x2="3.50456"
        y2="64.2566"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.07" stopColor="#E6EAF4" />
        <stop offset="0.56" stopColor="#DADEE9" />
        <stop offset="0.85" stopColor="#D1D5E0" />
      </linearGradient>
      <clipPath id="clip0_3486_43512">
        <rect width="106.121" height="68" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Atm;

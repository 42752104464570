import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface VerticalStepsProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  variant: ThemeVariants;
  steps: { label: string; value: string; isWarning?: boolean }[];
}

const VerticalSteps: React.FC<VerticalStepsProps> = ({
  variant,
  steps,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.verticalStepsWrapper,
        styles[variant],
        className
      )}
      {...props}
    >
      <ol>
        {steps.map(({ label, value, isWarning }, i) => (
          <li key={label}>
            <div className={styles.titleRow}>
              <div
                className={classNames(styles.numberBubble, {
                  [styles.isWarning]: isWarning,
                })}
              >
                <Text
                  variant={TextVariants.SemiboldTextS}
                  color={isWarning ? "var(--red800)" : "var(--slate900)"}
                >
                  {i + 1}
                </Text>
              </div>
              <Text
                variant={TextVariants.RegularText}
                color={isWarning ? "var(--red800)" : "var(--slate900)"}
              >
                {label}
              </Text>
            </div>
            <Text
              variant={TextVariants.RegularTextS}
              color="var(--slate800)"
              className={styles.valueRow}
            >
              {value}
            </Text>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default VerticalSteps;

//TODO: Replace for util function
interface PropTypes {
  children: number | string | undefined | null;
  withoutDecimals?: boolean;
}

export const Currency = (props: PropTypes) => {
  const { children, withoutDecimals } = props;

  if (Number.isNaN(Number(children))) return null;

  return (
    <>
      {Number(children)
        .toFixed(withoutDecimals ? 0 : 2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
    </>
  );
};

import { useCryptoCable } from "context/CryptoCableProvider";
import { FiatExternalCustomerStatus } from "interfaces/crypto";

import BankAccountDetails from "./BankAccountDetails";
import Paywall from "./Paywall";
import LoadingScreen from "./Loading";
import CreationProgress from "./CreationProgress";

const CryptoCablePage: React.FC = () => {
  const {
    externalAccountData,
    isLoading,
    isCreationInProgress,
  } = useCryptoCable();
  if (isLoading) return <LoadingScreen />;

  if (isCreationInProgress) {
    return <CreationProgress />;
  }

  if (!externalAccountData) {
    return <Paywall />;
  }

  const { bankAccounts, status } = externalAccountData;

  if (status === FiatExternalCustomerStatus.ACTIVE) {
    return <BankAccountDetails bankAccount={bankAccounts[0]} />;
  }

  return <LoadingScreen />;
};

export default CryptoCablePage;

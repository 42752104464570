import Banner, { BannerVariants } from "components/common/Banner";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface DisclaimerProps {
  isBuy: boolean;
  isFixed: boolean;
}

const Disclaimer: React.FC<DisclaimerProps> = ({ isBuy, isFixed }) => {
  const { dolarMep } = useAppSelector((state) => state.markets);

  const bannerVariant = isFixed
    ? BannerVariants.INFO_CAPITAL
    : BannerVariants.WARNING;

  const getBannerText = () => {
    if (!isFixed) {
      if (isBuy)
        return `El precio puede fluctuar hasta que se acrediten los dólares en tu cuenta el ${dayjs(
          dolarMep.open.settlementForBuy
        ).format("DD/MM/YYYY")}.`;
      return "El precio puede fluctuar hasta que se acrediten los pesos en tu cuenta.";
    }

    return `Recibís tus ${isBuy ? "dólares" : "pesos"} al precio asegurado.`;
  };

  return (
    <Banner
      text={getBannerText()}
      variant={bannerVariant}
      className={styles.disclaimer}
    />
  );
};

export default Disclaimer;

import { IconProps } from "assets/icons/BTC";

const CardPaused: React.FC<IconProps> = () => (
  <svg
    width="70"
    height="96"
    viewBox="0 0 70 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72302 21.0326L9.72302 79.3787C9.72302 83.5436 13.0993 86.9199 17.2642 86.9199L51.2115 86.9199C55.3763 86.9199 58.7527 83.5436 58.7527 79.3787L58.7527 21.0326C58.7527 16.8678 55.3763 13.4914 51.2115 13.4914L17.2642 13.4914C13.0993 13.4914 9.72302 16.8678 9.72302 21.0326Z"
      fill="url(#paint0_linear_3249_26519)"
    />
    <path
      d="M44.4551 2.4262e-06L6.23502 2.99781e-07C3.25919 1.34216e-07 0.846802 2.41559 0.846801 5.39538L0.846798 72.715C0.846798 75.6948 3.25919 78.1104 6.23502 78.1104L44.4551 78.1104C47.4309 78.1104 49.8433 75.6948 49.8433 72.715L49.8433 5.39538C49.8433 2.4156 47.4309 2.59177e-06 44.4551 2.4262e-06Z"
      fill="#113264"
    />
    <path
      d="M12.2325 68.977C12.2301 68.1581 12.4095 67.349 12.7579 66.6082C13.1063 65.8674 13.6148 65.2134 14.2467 64.6936C13.4383 64.0275 12.4575 63.6052 11.4186 63.4759C10.3798 63.3465 9.32554 63.5155 8.37886 63.963C7.43218 64.4106 6.63203 65.1184 6.07165 66.0038C5.51127 66.8893 5.21374 67.916 5.21374 68.9643C5.21374 70.0126 5.51127 71.0393 6.07165 71.9247C6.63203 72.8102 7.43218 73.518 8.37886 73.9655C9.32554 74.4131 10.3798 74.5821 11.4186 74.4527C12.4575 74.3233 13.4383 73.901 14.2467 73.2349C13.6181 72.7179 13.1116 72.068 12.7633 71.3318C12.4151 70.5957 12.2338 69.7915 12.2325 68.977Z"
      fill="#DC3E42"
    />
    <path
      d="M17.7558 63.4453C16.4741 63.445 15.2326 63.8937 14.2467 64.7138C14.8764 65.2327 15.3835 65.8847 15.7317 66.6231C16.0799 67.3615 16.2604 68.1679 16.2604 68.9845C16.2604 69.801 16.0799 70.6074 15.7317 71.3458C15.3835 72.0842 14.8764 72.7362 14.2467 73.2551C14.9306 73.8186 15.7399 74.2092 16.6062 74.3939C17.4725 74.5786 18.3704 74.5519 19.2243 74.3162C20.0782 74.0805 20.8629 73.6426 21.5123 73.0395C22.1617 72.4364 22.6568 71.6858 22.9557 70.8509C23.2546 70.016 23.3486 69.1214 23.2297 68.2425C23.1109 67.3636 22.7828 66.5263 22.273 65.801C21.7631 65.0758 21.0866 64.4839 20.3004 64.0754C19.5143 63.6669 18.6415 63.4537 17.7558 63.4538L17.7558 63.4453Z"
      fill="#FFC419"
    />
    <path
      d="M16.2608 68.9767C16.2632 68.1578 16.0837 67.3487 15.7353 66.6079C15.3869 65.8671 14.8784 65.2131 14.2465 64.6934C13.6168 65.2122 13.1097 65.8642 12.7615 66.6026C12.4134 67.341 12.2328 68.1474 12.2328 68.964C12.2328 69.7806 12.4134 70.587 12.7615 71.3254C13.1097 72.0637 13.6168 72.7158 14.2465 73.2346C14.8751 72.7176 15.3817 72.0677 15.7299 71.3315C16.0781 70.5954 16.2594 69.7912 16.2608 68.9767Z"
      fill="#F7881A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2337 8.26758L28.5675 8.26758L28.5675 12.6488L33.2337 12.6488L33.2337 8.26758ZM28.158 8.26758L27.6789 8.26758C26.6808 8.26758 25.8716 9.07782 25.8716 10.0773L25.8716 14.607L28.158 12.756L28.158 8.26758ZM25.8716 15.1339L25.8716 19.663C25.8716 20.6625 26.6808 21.4727 27.6789 21.4727L28.1585 21.4727C28.1585 21.4726 28.1585 21.4724 28.1585 21.4722L28.1585 16.9853L25.8716 15.1339ZM28.568 21.4727L33.2337 21.4727C33.2337 21.4726 33.2337 21.4724 33.2337 21.4722L33.2337 17.0921L28.568 17.0921L28.568 21.4722C28.568 21.4724 28.568 21.4726 28.568 21.4727ZM33.6432 21.4727L34.1228 21.4727C35.121 21.4727 35.9302 20.6625 35.9302 19.663L35.9302 15.1339L33.6432 16.9853L33.6432 21.4722C33.6432 21.4724 33.6432 21.4726 33.6432 21.4727ZM35.9302 14.607L35.9302 10.0773C35.9302 9.07782 35.121 8.26758 34.1228 8.26758L33.6432 8.26758L33.6432 12.7556L35.9302 14.607ZM26.197 14.8704L28.4355 13.0583L33.3663 13.0583L35.6048 14.8704L33.3663 16.6826L28.4355 16.6826L26.197 14.8704Z"
      fill="url(#paint1_linear_3249_26519)"
    />
    <path
      d="M51.3321 96.0014C61.1744 96.0014 69.1531 88.0226 69.1531 78.1804C69.1531 68.3381 61.1744 60.3594 51.3321 60.3594C41.4898 60.3594 33.5111 68.3381 33.5111 78.1804C33.5111 88.0226 41.4898 96.0014 51.3321 96.0014Z"
      fill="#1C2024"
    />
    <path
      d="M46.5748 68.6426L46.5748 87.5784H47.453L47.453 68.6426H46.5748Z"
      fill="white"
    />
    <path
      d="M55.2758 68.6426L55.2758 87.5784H56.154L56.154 68.6426H55.2758Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5975 29.7139L21.4217 33.2657L19.6482 34.1917C16.4009 35.8661 14.8469 39.3545 16.1855 41.9592C17.5241 44.5638 21.2486 45.3419 24.4959 43.6505L26.2694 42.7372L28.0937 46.2806L26.3285 47.1939C21.1261 49.8958 14.9947 48.344 12.6469 43.7774C10.299 39.2107 12.6469 33.308 17.8324 30.623L19.5975 29.7139ZM30.8825 53.1325L29.0583 49.5807L30.8319 48.6547C34.0792 46.9803 35.6331 43.4919 34.2945 40.8872C32.9559 38.2825 29.2314 37.5045 25.9842 39.1959L24.2106 40.1092L22.3864 36.5658L24.1515 35.6525C29.3539 32.9506 35.4853 34.5024 37.8332 39.069C40.181 43.6356 37.8332 49.5384 32.6476 52.2234L30.8825 53.1325Z"
      fill="#104D87"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3249_26519"
        x1="34.2402"
        y1="86.9199"
        x2="34.2402"
        y2="13.4914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1D5E0" />
        <stop offset="0.32" stopColor="#DADEE9" />
        <stop offset="0.86" stopColor="#E6EAF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3249_26519"
        x1="30.9009"
        y1="21.4727"
        x2="30.9009"
        y2="8.26758"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#D1D5E0" />
        <stop offset="0.4" stopColor="#DADEE9" />
        <stop offset="0.76" stopColor="#E6EAF4" />
      </linearGradient>
    </defs>
  </svg>
);

export default CardPaused;

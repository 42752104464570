import { Cryptos, getCrypto } from "constants/cryptos";

import { forwardRef } from "react";

import ARGFlag from "assets/icons/ARGFlag";
import classNames from "classnames";
import LoadingSpinner from "components/common/LoadingSpinner";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import px2rem from "utils/px2rem";
import CurrencySelect from "components/common/CurrencySelect";

import Balance from "../Balance";

import styles from "./styles.module.scss";

interface QuoteBlockProps {
  onClickSelectCurrency: () => void;
  quoteCurrency?: Currencies.ARS | Cryptos;
  balance: number;
  onChange: (value: string) => void;
  isLoading: boolean;
}

const QuoteBlock = forwardRef<HTMLInputElement, QuoteBlockProps>(
  (
    { onClickSelectCurrency, quoteCurrency, balance, onChange, isLoading },
    ref
  ) => {
    const getQuoteIcon = () => {
      if (!quoteCurrency) return;

      if (quoteCurrency === Currencies.ARS) return ARGFlag;

      return getCrypto(quoteCurrency).icon;
    };

    const onChangeQuote = (e: React.ChangeEvent<HTMLInputElement>) => {
      const withoutCommas = e.target.value.replace(",", ".");

      onChange(withoutCommas);
    };

    const isSell = quoteCurrency === Currencies.ARS;

    return (
      <div className={styles.bottomWrapper}>
        <div className={styles.row}>
          {isLoading && (
            <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(35)} />
          )}
          <input
            ref={ref}
            type="text"
            placeholder="0"
            inputMode="decimal"
            onChange={onChangeQuote}
            disabled={!quoteCurrency}
            className={classNames(styles.input, {
              [styles.isLoadingInput]: isLoading,
            })}
          />

          <CurrencySelect
            icon={getQuoteIcon()}
            label={quoteCurrency}
            onClick={onClickSelectCurrency}
            className={styles.currencySelect}
          />
        </div>
        <div className={styles.row}>
          <Balance
            withTicker={!isSell}
            currency={quoteCurrency}
            balance={balance}
          />
        </div>
      </div>
    );
  }
);

export default QuoteBlock;

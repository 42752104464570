import {
  PASSWORD_LETTER_CASES,
  PASSWORD_MAX_CHARACTERS,
  PASSWORD_NUMBERS_AND_SYMBOLS,
} from "constants/validation";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { Formik, FormikHelpers } from "formik";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import * as Yup from "yup";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import PasswordValidator from "components/common/PasswordValidator";
import InputField, { InputTypes } from "components/common/input/InputField";
import { supabase } from "utils/supabase";
import { getPrimaryButtonText } from "components/page-forgot-password/SendResetPasswordEmail";
import ErrorContainer from "components/common/error-modal";
import { useAuth } from "context/AuthProvider";

import styles from "./styles.module.scss";

interface FormValues {
  password: string;
  confirmPassword: string;
}

export const getPasswordRightIcon = (state: boolean) => {
  return state ? (
    <EyeOffIcon color="var(--blue800)" size={16} />
  ) : (
    <EyeIcon color="var(--blue800)" size={16} />
  );
};

const PASSWORD_VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string()
    .min(PASSWORD_MAX_CHARACTERS, "")
    .matches(PASSWORD_LETTER_CASES, "")
    .matches(PASSWORD_NUMBERS_AND_SYMBOLS, ""),
  confirmPassword: Yup.string().min(PASSWORD_MAX_CHARACTERS, "").required(""),
});

const ResetPasswordPage = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const primaryButtonText = "Cambiar contraseña";

  const handleGoBackClick = async () => {
    await signOut().then(() => {
      navigate(mainRoutes.login);
    });
  };

  const handleOnSubmit = async (
    values: FormValues,
    { setSubmitting, setErrors }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);

    try {
      const { password, confirmPassword } = values;

      if (password !== confirmPassword) {
        return setErrors({ confirmPassword: "Las contraseñas no coinciden." });
      }

      const { error } = await supabase.auth.updateUser({
        password,
      });

      if (error) {
        ampli.forgotPasswordError({
          type: error.name,
          message: error.message,
        });

        if (error.name === "AuthSessionMissingError") {
          return setError("Token inválido, intentá de nuevo más tarde.");
        }

        if (error.name === "AuthApiError") {
          return setErrors({
            confirmPassword:
              "La nueva contraseña no puede coincidir con la contraseña anterior.",
          });
        }

        await signOut().then(() => {
          setError(
            "Ocurrió un error inesperado, por favor intentá de nuevo más tarde."
          );
        });
      } else {
        ampli.changePasswordSuccess({ referral: "sign_in" });
        navigate(mainRoutes.intro);
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    ampli.forgotPasswordOpenEmail();

    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  if (error)
    return (
      <ErrorContainer
        subtitle={error}
        textPrimaryButton="Volver a intentar"
        onClickPrimaryButton={() => navigate(mainRoutes.forgotPassword)}
      />
    );

  return (
    <div className={styles.resetPasswordRoot}>
      <Formik
        initialValues={{
          password: "",
          confirmPassword: "",
        }}
        validationSchema={PASSWORD_VALIDATION_SCHEMA}
        onSubmit={handleOnSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          isSubmitting,
          isValid,
          dirty,
          values,
          errors,
        }) => (
          <BasicWrapper
            navigationHeaderProps={{
              onClick: handleGoBackClick,
              withCloseIcon: false,
            }}
            title="Cambiá tu contraseña"
            subtitle="Asegurate de mantenerla segura."
            primaryButtonProps={{
              onClick: () => handleSubmit(),
              type: "submit",
              children: getPrimaryButtonText(isSubmitting, primaryButtonText),
              disabled: !isValid || !dirty || isSubmitting,
            }}
          >
            <form onSubmit={handleSubmit}>
              <PasswordValidator
                name="password"
                label="Nueva contraseña"
                placeholder="Ingresá tu contraseña"
                value={values.password}
                onChange={handleChange}
              />
              <InputField
                id="confirmPassword"
                name="confirmPassword"
                label="Confirmá la nueva contraseña"
                placeholder="Ingresá tu contraseña"
                value={values.confirmPassword}
                type={showPassword ? InputTypes.Text : InputTypes.Password}
                iconRight={getPasswordRightIcon(showPassword)}
                onChange={handleChange}
                onIconClick={() => setShowPassword(!showPassword)}
              />
              {errors.confirmPassword && (
                <Text variant={TextVariants.RegularTextS} color="var(--red800)">
                  {errors.confirmPassword}
                </Text>
              )}
              <div className={styles.keyboardPadding} />
              <button type="submit" style={{ display: "none" }} />
            </form>
          </BasicWrapper>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordPage;

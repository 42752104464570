import { Currencies, BalanceCurrencies } from "./wallet";
import type { Operations } from "./crypto/enums";
import type { Instruments } from "./markets";

export enum BalancePeriodTime {
  ONE_DAY = "1D",
  ONE_WEEK = "1W",
  ONE_MONTH = "1M",
  ONE_YEAR = "1Y",
  MAX = "MAX",
}

export enum PortfolioHistoryMetric {
  BALANCE = "balance",
  PERFORMANCE = "performance",
}

export enum SettlementPeriod {
  CI = "CI",
  ONE_DAY = "24hs",
  INF = "INF",
}

export type PortfolioSettlements = {
  amount: number;
  quantity: number;
  period: SettlementPeriod.CI | SettlementPeriod.ONE_DAY | SettlementPeriod.INF;
};

export type PortfolioHoldings = {
  ticker: string;
  name: string;
  shortName: string;
  type: Instruments;
  subtype: string;
  logo: string;
  isCrypto: boolean;
  settlements: PortfolioSettlements[];
};

export type SettlementMovement = {
  operation: Operations;
  baseTicker: string;
  quoteTicker?: string;
  baseQuantity: number;
  quoteQuantity: number | string;
};

export type CashHoldings = {
  ticker: Currencies;
  logo: string;
  settlements: PortfolioSettlements[];
  toBeSettled?: number;
};

export interface PortfolioQueryResponse {
  displayCurrency: BalanceCurrencies;
  cash: CashHoldings[];
  holdings: PortfolioHoldings[];
}

export type BalanceResponse = {
  displayCurrency: BalanceCurrencies;
  totalBalance: number;
  variation: {
    amount: number;
    percentage: number;
  };
  period: BalancePeriodTime;
};

export type PortfolioHistoryData = {
  time: string;
  balance: number;
  variation: {
    amount: number;
    percentage: number;
  };
};

export type PortfolioHistoryResponse = {
  displayCurrency: BalanceCurrencies;
  period: BalancePeriodTime;
  metric: PortfolioHistoryMetric;
  data: PortfolioHistoryData[];
};

import { MFAErrorType } from "interfaces/auth";

export const mfaErrorTypeMessage: { [key in MFAErrorType]: string } = {
  MFA_INVALID_IP_ADDRESS: "Datos de verificación incorrectos.",
  MFA_INVALID_CODE: "El código ingresado es incorrecto.",
  MFA_CHALLENGE_EXPIRED: "El tiempo de verificación del código ha expirado.",
  INVALID_SECURITY_CODE:
    "El código que ingresaste es incorrecto. Por favor, volvé a ingresar el código que te llegó por mail nuevamente.",
};

export const mfaErrorCheck = (error: any) => {
  if (Object.values(MFAErrorType).includes(error.response?.data?.message)) {
    return error.response?.data?.message as MFAErrorType;
  }
  return null;
};

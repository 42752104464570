import GoldBadgeIcon from "assets/icons/GoldBadge";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

const GoldBadge: React.FC = () => {
  return (
    <div className={styles.goldBadgeContainer}>
      <GoldBadgeIcon />
      <Text
        className={styles.text}
        variant={TextVariants.SemiboldValueXS}
        color="#090a0a"
      >
        GOLD
      </Text>
    </div>
  );
};

export default GoldBadge;

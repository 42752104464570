import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";

import { Spinner } from "../spinner";

import "./index.scss";

export enum ButtonSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
  Toggle = "toggle",
  ExtraSmall = "extra-small",
}
export enum ButtonVariants {
  Secondary = "secondary",
  Primary = "primary",
  Buy = "buy",
  Sell = "sell",
  Terciary = "terciary",
  Outline = "outlined",
}

interface ButtonUIProps {
  size: ButtonSizes;
  variant: ButtonVariants;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  newClass?: any;
  loading?: boolean;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  needReset?: boolean;
  id?: string;
}

export default function ButtonUI({
  size,
  variant,
  disabled,
  onClick,
  children,
  type = "button",
  newClass,
  loading = false,
  color,
  needReset = true,
  id,
}: ButtonUIProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClickButton = () => async (event: React.MouseEvent) => {
    if (onClick) {
      event.stopPropagation;
      setIsLoading(true);
      onClick();
      needReset && setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <Button
      classes={{ root: `ui-button ${size} ${variant} ${newClass}` }}
      disabled={disabled || isLoading}
      type={type}
      color={color}
      onClick={handleClickButton()}
      id={id}
    >
      {isLoading ? <Spinner /> : children}
    </Button>
  );
}

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { CardTransaction } from "interfaces/card/interface";
import {
  CardTransactionActionType,
  CardTransactionStatus,
  CardTransactionType,
} from "interfaces/card/enums";
import { useCocosCard } from "context/CocosCardProvider";
import Text, { TextVariants } from "components/common/Text";
import Divider from "components/common/Divider";

import ActivityIcon from "./ActivityIcon";
import Currency from "./Currency";

import styles from "./styles.module.scss";

interface TransactionsRowProps {
  transaction: CardTransaction;
}

const TransactionRow: React.FC<TransactionsRowProps> = ({ transaction }) => {
  const navigate = useNavigate();
  const { setTransactionSelected } = useCocosCard();
  const {
    amount_local,
    created_at,
    merchant_name,
    transaction_type,
    id_card_transaction,
    status,
  } = transaction;

  const formattedDate = dayjs(created_at).format("DD/MM");
  const formattedTime = dayjs(created_at).format("HH:mm");
  const isRefund = transaction_type === CardTransactionActionType.CREDIT;
  const isRejected = status === CardTransactionStatus.REJECTED;

  const getTransactionStatus = () => {
    if (isRefund) {
      return CardTransactionStatus.REFUND;
    }
    if (isRejected) {
      return CardTransactionStatus.REJECTED;
    }

    return CardTransactionStatus.APPROVED;
  };

  const getTransactionLabel = () => {
    return (
      <div className={styles.transactionLabel}>
        <Divider orientation="vertical" className={styles.divider} />
        <Text
          variant={TextVariants.SemiboldTextS}
          color={isRefund ? "var(--green800)" : "var(--red800)"}
        >
          {isRefund ? "Devolución" : "Rechazado"}
        </Text>
      </div>
    );
  };

  const onSelectTransaction = () => {
    setTransactionSelected(transaction);
    navigate(`${mainRoutes.cardTransactions}/${id_card_transaction}`);
  };

  return (
    <div
      className={styles.transactionRowContainer}
      onClick={() => onSelectTransaction()}
    >
      <div className={styles.detailWrapper}>
        <ActivityIcon transactionStatus={getTransactionStatus()} />
        <div className={styles.info}>
          <Text
            className={styles.name}
            variant={TextVariants.RegularText}
            color="var(--slate900)"
          >
            {merchant_name}
          </Text>
          <div>
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {formattedDate}
              <Divider orientation="vertical" className={styles.divider} />
              {formattedTime}
              {(isRejected || isRefund) && getTransactionLabel()}
            </Text>
          </div>
        </div>
      </div>
      <Currency amount={amount_local} isRefund={isRefund} />
    </div>
  );
};

export default TransactionRow;

import axios, { AxiosRequestConfig } from "axios";
import { Cookies } from "react-cookie";
import mainRoutes from "router/routes";
import { supabase } from "utils/supabase";
import { setupCache } from "axios-cache-interceptor";

const baseURL = import.meta.env.VITE_API_URL + "/api";

const headers: AxiosRequestConfig["headers"] = {};
const axiosClient = axios.create({ baseURL, headers });
const API = setupCache(axiosClient, {
  ttl: 1000, // Default short cache for all GET endpoints
});

API.interceptors.request.use(async (config) => {
  const { data, error } = await supabase.auth.getSession();

  if (!error && data.session) {
    const cookies = new Cookies();
    const id_account = cookies.get("id_account");

    config.headers.Authorization = `Bearer ${data.session.access_token}`;
    config.headers["x-Account-ID"] = id_account;
  }

  return config;
});

API.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = mainRoutes.logout;
      const endpoint = error.config.url;
      console.log(`Unauthorized request to endpoint: ${endpoint}`);
    } else {
      return Promise.reject(error);
    }
  }
);

export default API;

import { Cryptos } from "constants/cryptos";

import CircularButton, {
  CircularButtonVariants,
} from "components/common/CircularButton";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppSelector } from "hooks/redux";
import { Operations } from "interfaces/crypto/enums";
import { capitalize } from "lodash";
import { ArrowDownToLine, PlusIcon, RefreshCw, Send } from "lucide-react";
import { useNavigate } from "react-router";
import { CryptoRoutes } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ActionsSectionProps {
  ticker?: Cryptos;
  onClickSend?: () => void;
}

const ActionsSection: React.FC<ActionsSectionProps> = ({
  ticker,
  onClickSend,
}) => {
  const iconColor = "var(--background-1)";
  const iconSize = px2rem(20);
  const navigate = useNavigate();

  const currentScreen = useAppSelector(getCurrentScreen);

  const onClickAction = (
    operation:
      | Operations.SWAP
      | Operations.SEND
      | Operations.RECEIVE
      | Operations.BUY
  ) => {
    const properties = ticker && {
      crypto_ticker: ticker,
    };

    const route =
      operation === Operations.BUY
        ? CryptoRoutes.SWAP
        : CryptoRoutes[operation];

    trackAction(
      `${currentScreen} - Click ${capitalize(operation)}`,
      properties,
      Projects.CRYPTO
    );

    const isBuying = operation === Operations.BUY;

    if (ticker) {
      navigate(route, {
        state: {
          defaultTicker: ticker,
          isBuying: isBuying ? isBuying : undefined,
        },
      });
      return;
    }

    isBuying ? navigate(route, { state: { isBuying } }) : navigate(route);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.actionsWrapper}>
        <CircularButton
          variant={CircularButtonVariants.Crypto}
          label="Comprar"
          onClick={() => onClickAction(Operations.BUY)}
        >
          <PlusIcon size={iconSize} color={iconColor} />
        </CircularButton>
        <CircularButton
          variant={CircularButtonVariants.Crypto}
          label="Convertir"
          onClick={() => onClickAction(Operations.SWAP)}
        >
          <RefreshCw size={iconSize} color={iconColor} />
        </CircularButton>
        <CircularButton
          variant={CircularButtonVariants.Crypto}
          label="Enviar"
          onClick={
            onClickSend ? onClickSend : () => onClickAction(Operations.SEND)
          }
        >
          <Send size={iconSize} color={iconColor} />
        </CircularButton>
        <CircularButton
          variant={CircularButtonVariants.Crypto}
          label="Recibir"
          onClick={() => onClickAction(Operations.RECEIVE)}
        >
          <ArrowDownToLine size={iconSize} color={iconColor} />
        </CircularButton>
      </div>
    </div>
  );
};

export default ActionsSection;

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import { Limit, LimitPeriod } from "interfaces/api-responses";
import { useCocosCard } from "context/CocosCardProvider";
import { MAX_LIMIT_AMOUNT } from "components/page-cocos-card/constants";
import BasicWrapper from "components/common/BasicWrapper";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import LoadingScreen from "components/page-cocos-card/Loading";
import { cardService } from "apis/services";
import { Limits } from "components/page-crypto-limits/utils";

import IncreaseLimit from "./IncreaseLimit";
import LimitData from "./Limit";
import LimitSuccess from "./LimitSuccess";

import styles from "./styles.module.scss";

const PageLimit: React.FC = () => {
  const navigate = useNavigate();
  const { selectedCard } = useCocosCard();

  const [
    openIncreaseLimitRequest,
    setOpenIncreaseLimitRequest,
  ] = useState<boolean>(false);
  const [displayLimitSuccess, setDisplayLimitSuccess] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [cardLimit, setCardLimit] = useState<number>(0);
  const [currentCardLimit, setCurrentCardLimit] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);

    const getLimit = async () => {
      try {
        const { data } = await API.get<Limit[]>(cardService.limits);
        const dailyLimit = data.filter(
          (limit) => limit.period === LimitPeriod.DAILY
        )[0].limit;

        setCardLimit(dailyLimit);
        setCurrentCardLimit(dailyLimit);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getLimit();
  }, []);

  const increaseLimit = () => {
    ampli.cardLimitRequestIncrease({ card_type: selectedCard });
    setOpenIncreaseLimitRequest(true);
  };

  const updateAvailableLimit = async (newLimit: number) => {
    setIsLoading(true);

    try {
      await API.post<any>(cardService.limits, {
        limit: newLimit,
      });

      ampli.cardLimitModified({
        card_type: selectedCard,
        card_limit: `${newLimit}/${MAX_LIMIT_AMOUNT}`,
      });
      setDisplayLimitSuccess(true);
    } catch (error) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (displayLimitSuccess) {
    return <LimitSuccess newLimit={cardLimit} />;
  }

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Límites",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
  };

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={navigationHeaderProps}
      primaryButtonProps={{
        children: "Confirmar",
        onClick: () => updateAvailableLimit(cardLimit),
        disabled: cardLimit === currentCardLimit,
      }}
      secondaryButtonProps={{
        children: "Quiero aumentar mi límite",
        onClick: () => increaseLimit(),
      }}
    >
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate900)"
        component="h1"
        className={styles.titleHeader}
      >
        Límite de tu tarjeta
      </Heading>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.subtitle}
      >
        Modificá el límite diario de consumo con tu tarjeta o solicitá un
        aumento.
      </Text>
      <LimitData
        available={cardLimit}
        setCardLimit={setCardLimit}
        type={Limits.DAILY}
      />
      <IncreaseLimit
        open={openIncreaseLimitRequest}
        onClose={() => setOpenIncreaseLimitRequest(false)}
      />
    </BasicWrapper>
  );
};

export default PageLimit;

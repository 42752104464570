import { Typography } from "@mui/material";
import RightArrowIcon from "assets/icons/arrows/RightArrow";
import { CocosLogo } from "assets/icons/cocos-logo";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes, screenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

export enum ToCryptoButtonSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

interface ToCryptoButtonProps {
  size?: ToCryptoButtonSizes;
  variant?: ThemeVariants;
}

const ToCryptoButton: React.FC<ToCryptoButtonProps> = ({
  size = ToCryptoButtonSizes.Medium,
  variant = ThemeVariants.Capital,
}) => {
  const navigate = useNavigate();
  const isCapital = variant === ThemeVariants.Capital;

  const label = isCapital ? "Crypto" : "Capital";

  const project = isCapital ? Projects.CAPITAL : Projects.CRYPTO;

  const logo = <CocosLogo size={px2rem(16)} color="#fff" />;

  const handleClick = () => {
    trackAction(
      `${screenNames.home} - Click Cocos ${label}`,
      undefined,
      project
    );

    if (isCapital) {
      navigate(CryptoRoutes.TYC);
      return;
    }

    navigate(mainRoutes.home);
  };

  return (
    <button
      className={classNames(styles.button, styles[variant])}
      onClick={handleClick}
    >
      {size === ToCryptoButtonSizes.Small && logo}
      {size === ToCryptoButtonSizes.Medium && (
        <>
          {logo}
          <RightArrowIcon />
        </>
      )}
      {size === ToCryptoButtonSizes.Large && (
        <>
          <Typography>Ir a Cocos {label}</Typography>
          <RightArrowIcon />
        </>
      )}
    </button>
  );
};

export default ToCryptoButton;

import { Spinner } from "components/common/spinner";

import "./index.scss";

const Preloader: React.FC = () => {
  return (
    <div className="pages-preloader">
      <Spinner />
    </div>
  );
};

export default Preloader;

import AllocationCard from "components/common/AllocationCard";
import SectionTitle from "components/common/SectionTitle";
import { CryptoHolding } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface AllocationSectionProps {
  holdings: CryptoHolding[];
}

const AllocationSection: React.FC<AllocationSectionProps> = ({ holdings }) => {
  const navigate = useNavigate();
  const MAX_GRAPH = 4;
  holdings.sort((a, b) => b.amountArs - a.amountArs);

  const getHoldings = () => {
    if (holdings.length <= MAX_GRAPH) {
      return holdings;
    }

    const firstThree = holdings.slice(0, 3);
    const othersTotal = holdings
      .slice(3)
      .reduce((sum, { amountArs }) => sum + amountArs, 0);
    const othersHolding: CryptoHolding = {
      amountArs: othersTotal,
      idSecurity: 0,
      priceArs: 0,
      quantity: 0,
      ticker: Currencies.ARS,
      varDailyPrice: 0,
      varDailyPricePercentage: 0,
    };

    const result = [...firstThree, othersHolding];

    return result;
  };

  return (
    <div
      className={styles.allocationSectionContainer}
      onClick={() => navigate(CryptoRoutes.ALLOCATION)}
    >
      <SectionTitle label="Distribución" />
      <AllocationCard holdings={getHoldings()} />
    </div>
  );
};

export default AllocationSection;

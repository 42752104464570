import { useRef } from "react";

import BaseBlock from "components/page-crypto-swap/Transaction/BaseBlock";
import { CryptoHolding } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface AmountSectionProps {
  onChangeQuantity: (q: number) => void;
  selectedCrypto?: CryptoHolding;
}

const AmountSection: React.FC<AmountSectionProps> = ({
  selectedCrypto,
  onChangeQuantity,
}) => {
  const baseRef = useRef<HTMLInputElement>(null);

  const onClickMax = () => {
    if (!baseRef.current || !selectedCrypto) return;
    onChangeQuantity(selectedCrypto.quantity);
    baseRef.current.value = String(selectedCrypto.quantity || 0);
  };

  const handleOnChange = (value: string) => {
    if (!baseRef.current) return;

    baseRef.current.value = value;

    const sameOrLess8Decimals = value.match(/^(\d*\.{0,1}\d{0,8}$)/);
    if (!sameOrLess8Decimals) {
      baseRef.current.value = value.toString().slice(0, -1);
      return;
    }
    onChangeQuantity(+value);
  };

  if (!selectedCrypto) return null;

  return (
    <div className={styles.amountSectionContainer}>
      <div className={styles.blockWrapper}>
        <BaseBlock
          balance={selectedCrypto.quantity}
          onClickMax={onClickMax}
          ref={baseRef}
          baseCurrency={selectedCrypto.ticker}
          disabled
          onChange={handleOnChange}
        />
      </div>
    </div>
  );
};

export default AmountSection;

import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Wifi, X } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";
import { closeRightModal } from "features/rightModal/rightModalSlice";

import OutlinedButton from "../OutlinedButton";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface ErrorPageProps {
  errorMessage: string;
  withClose?: boolean;
  capitalTheme?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage,
  withClose = false,
  capitalTheme = false,
}) => {
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTry = () => {
    window.location.reload();
  };

  const goHome = () => {
    if (isMobile) {
      navigate(capitalTheme ? mainRoutes.home : CryptoRoutes.HOME);
      return;
    }

    dispatch(closeRightModal());
  };

  return (
    <div className={styles.errorPageContainer}>
      {withClose && (
        <div className={styles.closeButton}>
          <X size={px2rem(24)} onClick={goHome} color="var(--purple900)" />
        </div>
      )}

      <div className={styles.wrapper}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Hubo un error
        </Text>
        <div className={styles.icon}>
          <Wifi color="var(--red900)" size={px2rem(24)} />
        </div>
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          {errorMessage} Por favor, volvé a intentarlo más tarde.
        </Text>
      </div>
      <OutlinedButton
        variant={capitalTheme ? ThemeVariants.Capital : ThemeVariants.Crypto}
        onClick={handleTry}
      >
        Volver a intentar
      </OutlinedButton>
    </div>
  );
};

export default ErrorPage;

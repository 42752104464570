interface BarChartProps {
  barColor: string;
  backgroundColor?: string;
  percentage: number;
  className?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  barColor,
  backgroundColor = "var(--slate300)",
  percentage,
  className,
}) => {
  return (
    <div
      style={{
        backgroundColor: barColor,
        background: `linear-gradient(90deg, ${barColor} ${
          percentage * 100
        }%, ${backgroundColor} 0%, ${backgroundColor} 100%)`,
        height: "4px",
      }}
      className={className}
    />
  );
};

export default BarChart;

import React, { useEffect } from "react";

import { usePhoneValidation } from "context/PhoneValidationProvider";
import { Screens } from "interfaces/phoneValidation";
import { screenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import VerificationRequired from "./VerificationRequired";
import PhoneConfirmation from "./PhoneConfirmation";
import ContactSupport from "./ContactSupport";
import PhoneValidationChallenge from "./PhoneValidationChallenge";

const screensComponents = {
  [Screens.VERIFICATION_REQUIRED]: <VerificationRequired />,
  [Screens.PHONE_CONFIRMATION]: <PhoneConfirmation />,
  [Screens.CONTACT_SUPPORT]: <ContactSupport />,
  [Screens.CHALLENGE]: <PhoneValidationChallenge />,
};

const PhoneValidationPage: React.FC = () => {
  const { screen } = usePhoneValidation();
  const dispatch = useAppDispatch();
  const currentScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(screenNames.phoneValidation, currentScreen);
    dispatch(setCurrentScreen(screenNames.phoneValidation));
  }, []);

  return screensComponents[screen];
};

export default PhoneValidationPage;

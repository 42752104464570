import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { MessagesSquare } from "lucide-react";
import mainRoutes from "router/routes";
import BasicWrapper from "components/common/BasicWrapper";
import ListSection from "components/page-cocos-card-settings/ListSection";
import Banner, { BannerVariants } from "components/common/Banner";

import WrongChargeBottomSheet from "./WrongChargeBottomSheet";
import SupportBottomSheet from "./SupportBottomSheet";

import styles from "./styles.module.scss";

const PageIssueMovements: React.FC = () => {
  const navigate = useNavigate();
  const [
    isWrongChargeBottomSheetOpen,
    setIsWrongChargeBottomSheetOpen,
  ] = useState<boolean>(false);
  const [
    isSupportBottomSheetOpen,
    setIsSupportBottomSheetOpen,
  ] = useState<boolean>(false);

  const navigationHeaderProps = {
    onClick: () => navigate(-1),
    title: "Problemas",
    withCloseIcon: false,
    iconColor: "var(--black-to-white)",
    rightIcon: <MessagesSquare color="var(--black-to-white)" />,
    rightIconClick: () => navigate(mainRoutes.chat),
  };

  const menuOptions = [
    {
      title: "No reconozco una compra",
      onClick: () => setIsWrongChargeBottomSheetOpen(true),
    },
    {
      title: "Problemas con un producto/servicio",
      onClick: () => setIsSupportBottomSheetOpen(true),
    },
    {
      title: "Problemas con una promoción",
      onClick: () => setIsSupportBottomSheetOpen(true),
    },
  ];

  return (
    <BasicWrapper navigationHeaderProps={navigationHeaderProps}>
      <Banner
        variant={BannerVariants.WARNING}
        text="Por favor, evitá reclamar consumos previos a los 45 días o reclamados previamente, u operaciones en cajeros automáticos."
        className={styles.banner}
      />
      <ListSection
        menuOptions={menuOptions}
        subtitle="Movimientos que no hice"
      />

      <WrongChargeBottomSheet
        open={isWrongChargeBottomSheetOpen}
        onClose={() => setIsWrongChargeBottomSheetOpen(false)}
      />
      <SupportBottomSheet
        open={isSupportBottomSheetOpen}
        onClose={() => setIsSupportBottomSheetOpen(false)}
      />
    </BasicWrapper>
  );
};

export default PageIssueMovements;

import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

export enum CircularButtonVariants {
  Crypto = "Crypto",
  Capital = "Capital",
  Pay = "Pay",
}

interface CircularButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: CircularButtonVariants;
  label: string;
}

const CircularButton: React.FC<CircularButtonProps> = ({
  label,
  variant = CircularButtonVariants.Capital,
  className,
  ...props
}) => (
  <div className={styles.circularButtonWrapper}>
    <button
      {...props}
      className={classNames(styles.circularButton, className, styles[variant])}
    />
    <Text
      variant={TextVariants.SemiboldTextS}
      className={classNames(styles.label, styles[variant])}
    >
      {label}
    </Text>
  </div>
);

export default CircularButton;

import { COPY_MESSAGE_TIME } from "constants/index";

import { useState, useEffect } from "react";

import { ampli } from "ampli";
import IconButton, { IconButtonSizes } from "components/common/IconButton";
import Text, { TextVariants } from "components/common/Text";
import {
  ActivityDetailSheetProps,
  TransferChannels,
} from "components/common/ActivityList/ActivityDetail/ActivityDetailSheet";
import { Operations, operationsLabels } from "interfaces/crypto/enums";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import { CheckCheck, Copy } from "lucide-react";
import { formatNumberToLocale, getCryptoStatusLabel } from "utils";
import { Clipboard } from "@capacitor/clipboard";
import { Browser } from "@capacitor/browser";

import styles from "./styles.module.scss";

const SheetContent: React.FC<ActivityDetailSheetProps> = (props) => {
  const {
    fee,
    status,
    idOrder,
    createdAt,
    operation,
    baseTicker,
    baseQuantity,
  } = props;

  const isBuy = operation === Operations.BUY;
  const isSell = operation === Operations.SELL;
  const isSwap = operation === Operations.SWAP;
  const isTrade = isBuy || isSell || isSwap;
  const isTransfer =
    operation === Operations.SEND || operation === Operations.RECEIVE;

  const isBlockchainSend =
    isTransfer && props.channel === TransferChannels.BLOCKCHAIN;

  const isTagSend = isTransfer && props.channel === TransferChannels.TAG;

  const textColor = "var(--slate900)";
  const secondaryTextColor = "var(--slate800)";
  const absBaseQuantity = Math.abs(baseQuantity);
  const givenAmount = isBuy ? props.quoteQuantity : absBaseQuantity;

  const formattedFee = fee?.toFixed(2).toString().replace(".", ",");
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    ampli.cryptoActivityBottomsheet({
      operation_type: operation,
      date: createdAt,
      operation_number: formatOperationNumber(idOrder),
      base_quantity: baseQuantity,
      base_ticker: baseTicker,
      referral: "home",
    });
  }, []);

  const onClickCopy = async () => {
    try {
      await Clipboard.write({ string: props.idOrder ?? "" });
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }
  };

  const openEtherscan = async (url: string) => {
    await Browser.open({
      url,
    });
  };

  const formatOperationNumber = (id: string): string => {
    return id.split("-").slice(0, 2).join("");
  };

  const shortenString = (str: string) => {
    const firstFive = str.substring(0, 5);
    const lastFive = str.substring(str.length - 5);
    return `${firstFive}...${lastFive}`;
  };
  const date = new Date(createdAt);

  const formattedDate = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} - ${date.getHours()}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.row}>
        <Text color={textColor} variant={TextVariants.RegularText}>
          Fecha
        </Text>
        <Text color={textColor} variant={TextVariants.RegularText}>
          {formattedDate}
        </Text>
      </div>

      <div className={styles.row}>
        <Text color={textColor} variant={TextVariants.RegularText}>
          Estado
        </Text>
        <Text className={styles[status]} variant={TextVariants.RegularText}>
          {getCryptoStatusLabel(status)}
        </Text>
      </div>

      <div className={styles.row}>
        <Text color={textColor} variant={TextVariants.RegularText}>
          {operationsLabels[operation]}
        </Text>

        <Text
          color={secondaryTextColor}
          className={styles.unifyText}
          variant={TextVariants.RegularText}
        >
          {isBuy && "$"}
          <Text color={textColor} variant={TextVariants.SemiboldText}>
            {formatNumberToLocale(givenAmount)}
          </Text>
          <Text variant={TextVariants.RegularText} color={secondaryTextColor}>
            {!isBuy && baseTicker}
          </Text>
        </Text>
      </div>

      {isTrade && (
        <div className={styles.row}>
          <Text color={textColor} variant={TextVariants.RegularText}>
            Recibiste
          </Text>
          <Text
            color={textColor}
            className={styles.unifyText}
            variant={TextVariants.SemiboldText}
          >
            {isBuy && (
              <>
                {formatNumberToLocale(absBaseQuantity)}
                <Text
                  variant={TextVariants.RegularText}
                  color={secondaryTextColor}
                >
                  {baseTicker}
                </Text>
              </>
            )}
            {!isBuy && (
              <>
                {isSell && (
                  <Text
                    variant={TextVariants.RegularText}
                    color={secondaryTextColor}
                  >
                    $
                  </Text>
                )}
                {formatNumberToLocale(props.quoteQuantity)}
                {!isSell && (
                  <Text
                    variant={TextVariants.RegularText}
                    color={secondaryTextColor}
                  >
                    {props.quoteTicker}
                  </Text>
                )}
              </>
            )}
          </Text>
        </div>
      )}

      {isTrade && (
        <div className={styles.row}>
          <Text color={textColor} variant={TextVariants.RegularText}>
            Cotización
          </Text>
          <div>
            <Text
              color={textColor}
              className={styles.unifyText}
              variant={TextVariants.SemiboldText}
            >
              <Text color={textColor} variant={TextVariants.SemiboldText}>
                1
              </Text>
              <Text
                color={secondaryTextColor}
                variant={TextVariants.RegularText}
              >
                {baseTicker} = {(isBuy || isSell) && "$"}
              </Text>
              <Text color={textColor} variant={TextVariants.SemiboldText}>
                {formatNumberToLocale(
                  Number(
                    (props.quoteQuantity / absBaseQuantity).toFixed(
                      isBuy || isSell ? 2 : 3
                    )
                  )
                )}
              </Text>
              <Text
                color={secondaryTextColor}
                variant={TextVariants.RegularText}
              >
                {props.quoteTicker !== Currencies.ARS && props.quoteTicker}
              </Text>
            </Text>
          </div>
        </div>
      )}

      {fee !== null && (
        <div className={styles.row}>
          <Text color={textColor} variant={TextVariants.RegularText}>
            Costo
          </Text>
          {isTagSend && (
            <Text color="var(--green800)" variant={TextVariants.RegularText}>
              Sin costo
            </Text>
          )}
          {(isBuy || isSell) && (
            <Text
              color={secondaryTextColor}
              className={styles.unifyText}
              variant={TextVariants.RegularText}
            >
              $
              <Text color={textColor} variant={TextVariants.SemiboldText}>
                {formattedFee}
              </Text>
            </Text>
          )}
          {(isSwap || isBlockchainSend) && (
            <Text
              color={secondaryTextColor}
              className={styles.unifyText}
              variant={TextVariants.RegularText}
            >
              <Text color={textColor} variant={TextVariants.SemiboldText}>
                {formattedFee}
              </Text>
              {baseTicker}
            </Text>
          )}
        </div>
      )}

      {isTransfer && (
        <>
          {props.txHash && (
            <div
              className={styles.row}
              onClick={() =>
                props.txHash &&
                props.txHashUrl &&
                openEtherscan(props.txHashUrl)
              }
            >
              <Text color={textColor} variant={TextVariants.RegularText}>
                TxHash
              </Text>
              <Text
                color="var(--purple800)"
                variant={TextVariants.SemiboldText}
              >
                {shortenString(props.txHash)}
              </Text>
            </div>
          )}
          {isTagSend && (
            <div className={styles.row}>
              <Text color={textColor} variant={TextVariants.RegularText}>
                Cocostag
              </Text>
              <Text
                color="var(--purple800)"
                variant={TextVariants.SemiboldText}
              >
                {props.address} 🥥
              </Text>
            </div>
          )}
        </>
      )}

      <div className={styles.row}>
        <Text color={textColor} variant={TextVariants.RegularText}>
          Operación Nº
        </Text>
        <div className={styles.unifyText}>
          <Text color={textColor} variant={TextVariants.SemiboldText}>
            {formatOperationNumber(idOrder)}
          </Text>
          <IconButton
            icon={copied ? CheckCheck : Copy}
            onClick={onClickCopy}
            className={styles.icon}
            size={IconButtonSizes.XSmall}
            variant={ThemeVariants.Crypto}
          />
        </div>
      </div>
    </div>
  );
};

export default SheetContent;

import { IconProps } from "assets/icons/BTC";

const CardDeleted: React.FC<IconProps> = () => (
  <svg
    width="70"
    height="96"
    viewBox="0 0 70 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72302 21.0326L9.72302 79.3787C9.72302 83.5436 13.0993 86.9199 17.2642 86.9199L51.2115 86.9199C55.3763 86.9199 58.7527 83.5436 58.7527 79.3787L58.7527 21.0326C58.7527 16.8678 55.3763 13.4914 51.2115 13.4914L17.2642 13.4914C13.0993 13.4914 9.72302 16.8678 9.72302 21.0326Z"
      fill="url(#paint0_linear_3249_26461)"
    />
    <path
      d="M44.4551 2.4262e-06L6.23505 2.99781e-07C3.25922 1.34216e-07 0.846832 2.41559 0.846832 5.39538L0.846828 72.715C0.846828 75.6948 3.25922 78.1104 6.23505 78.1104L44.4551 78.1104C47.4309 78.1104 49.8433 75.6948 49.8433 72.715L49.8433 5.39538C49.8433 2.4156 47.4309 2.59177e-06 44.4551 2.4262e-06Z"
      fill="#113264"
    />
    <path
      d="M12.2324 68.977C12.23 68.1581 12.4095 67.349 12.7579 66.6082C13.1063 65.8674 13.6148 65.2134 14.2467 64.6936C13.4383 64.0275 12.4575 63.6052 11.4186 63.4759C10.3797 63.3465 9.32551 63.5155 8.37883 63.963C7.43215 64.4106 6.632 65.1184 6.07162 66.0038C5.51124 66.8893 5.21371 67.916 5.21371 68.9643C5.21371 70.0126 5.51124 71.0393 6.07162 71.9247C6.632 72.8102 7.43215 73.518 8.37883 73.9655C9.32551 74.4131 10.3797 74.5821 11.4186 74.4527C12.4575 74.3233 13.4383 73.901 14.2467 73.2349C13.6181 72.7179 13.1115 72.068 12.7633 71.3318C12.4151 70.5957 12.2338 69.7915 12.2324 68.977Z"
      fill="#DC3E42"
    />
    <path
      d="M17.7557 63.4453C16.474 63.445 15.2326 63.8937 14.2466 64.7138C14.8763 65.2327 15.3834 65.8847 15.7316 66.6231C16.0798 67.3615 16.2603 68.1679 16.2603 68.9845C16.2603 69.801 16.0798 70.6074 15.7316 71.3458C15.3834 72.0842 14.8763 72.7362 14.2466 73.2551C14.9305 73.8186 15.7398 74.2092 16.6061 74.3939C17.4724 74.5786 18.3704 74.5519 19.2242 74.3162C20.0781 74.0805 20.8628 73.6426 21.5122 73.0395C22.1616 72.4364 22.6567 71.6858 22.9556 70.8509C23.2545 70.016 23.3485 69.1214 23.2297 68.2425C23.1108 67.3636 22.7827 66.5263 22.2729 65.801C21.763 65.0758 21.0865 64.4839 20.3004 64.0754C19.5142 63.6669 18.6414 63.4537 17.7557 63.4538L17.7557 63.4453Z"
      fill="#FFC419"
    />
    <path
      d="M16.2608 68.9767C16.2632 68.1578 16.0837 67.3487 15.7354 66.6079C15.387 65.8671 14.8784 65.2131 14.2466 64.6934C13.6169 65.2122 13.1097 65.8642 12.7616 66.6026C12.4134 67.341 12.2328 68.1474 12.2328 68.964C12.2328 69.7806 12.4134 70.587 12.7616 71.3254C13.1097 72.0637 13.6169 72.7158 14.2466 73.2346C14.8751 72.7176 15.3817 72.0677 15.7299 71.3315C16.0782 70.5954 16.2595 69.7912 16.2608 68.9767Z"
      fill="#F7881A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.2338 8.26758L28.5677 8.26758L28.5677 12.6488L33.2338 12.6488L33.2338 8.26758ZM28.1582 8.26758L27.679 8.26758C26.6808 8.26758 25.8716 9.07782 25.8716 10.0773L25.8716 14.607L28.1582 12.7559L28.1582 8.26758ZM25.8716 15.1339L25.8716 19.663C25.8716 20.6625 26.6808 21.4727 27.679 21.4727L28.1586 21.4727C28.1586 21.4726 28.1586 21.4724 28.1586 21.4722L28.1586 16.9853L25.8716 15.1339ZM28.5681 21.4727L33.2338 21.4727C33.2338 21.4726 33.2338 21.4724 33.2338 21.4722L33.2338 17.0921L28.5681 17.0921L28.5681 21.4722C28.5681 21.4724 28.5681 21.4726 28.5681 21.4727ZM33.6433 21.4727L34.1229 21.4727C35.121 21.4727 35.9302 20.6625 35.9302 19.663L35.9302 15.1338L33.6433 16.9852L33.6433 21.4722C33.6433 21.4724 33.6433 21.4726 33.6433 21.4727ZM35.9302 14.6071L35.9302 10.0773C35.9302 9.07782 35.121 8.26758 34.1229 8.26758L33.6433 8.26758L33.6433 12.7557L35.9302 14.6071ZM26.197 14.8704L28.4355 13.0583L33.3663 13.0583L35.6048 14.8704L33.3663 16.6826L28.4355 16.6826L26.197 14.8704Z"
      fill="url(#paint1_linear_3249_26461)"
    />
    <path
      d="M51.3322 96.0014C61.1744 96.0014 69.1532 88.0226 69.1532 78.1804C69.1532 68.3381 61.1744 60.3594 51.3322 60.3594C41.4899 60.3594 33.5112 68.3381 33.5112 78.1804C33.5112 88.0226 41.4899 96.0014 51.3322 96.0014Z"
      fill="#DC3E42"
    />
    <path
      d="M58.3668 71.0098L44.1626 85.214"
      stroke="white"
      strokeWidth="0.87815"
      strokeMiterlimit="10"
    />
    <path
      d="M44.1626 71.0098L58.3668 85.214"
      stroke="white"
      strokeWidth="0.87815"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5974 29.7139L21.4217 33.2657L19.6481 34.1917C16.4008 35.8661 14.8468 39.3545 16.1855 41.9592C17.5241 44.5638 21.2485 45.3419 24.4958 43.6505L26.2694 42.7372L28.0936 46.2806L26.3285 47.1939C21.1261 49.8958 14.9946 48.344 12.6468 43.7774C10.2989 39.2107 12.6468 33.308 17.8323 30.623L19.5974 29.7139ZM30.8825 53.1325L29.0583 49.5807L30.8318 48.6547C34.0791 46.9803 35.6331 43.4919 34.2945 40.8872C32.9559 38.2825 29.2314 37.5045 25.9841 39.1959L24.2106 40.1092L22.3863 36.5658L24.1515 35.6525C29.3539 32.9506 35.4853 34.5024 37.8331 39.069C40.181 43.6356 37.8331 49.5384 32.6476 52.2234L30.8825 53.1325Z"
      fill="#104D87"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3249_26461"
        x1="34.2402"
        y1="86.9199"
        x2="34.2402"
        y2="13.4914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1D5E0" />
        <stop offset="0.32" stopColor="#DADEE9" />
        <stop offset="0.86" stopColor="#E6EAF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3249_26461"
        x1="30.9009"
        y1="21.4727"
        x2="30.9009"
        y2="8.26758"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#D1D5E0" />
        <stop offset="0.4" stopColor="#DADEE9" />
        <stop offset="0.76" stopColor="#E6EAF4" />
      </linearGradient>
    </defs>
  </svg>
);

export default CardDeleted;

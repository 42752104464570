import { ReactNode } from "react";

import FilledButton from "components/common/FilledButton";
import Icon, { IconVariants } from "components/common/Icon";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon, X } from "lucide-react";

import styles from "./styles.module.scss";

export interface ErrorModalButton {
  onClick: () => void;
  title: string;
}

interface ErrorModalProps {
  open: boolean;
  icon?: LucideIcon;
  title?: string;
  onToggleDisplay?: () => void;
  primaryButton?: ErrorModalButton;
  secondaryButton?: ErrorModalButton;
  variant?: ThemeVariants;
  children: ReactNode;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  open,
  icon = X,
  title = "Algo salió mal",
  children,
  onToggleDisplay,
  primaryButton,
  secondaryButton,
  variant = ThemeVariants.Capital,
}) => {
  const hasButton = primaryButton || secondaryButton;

  const onClickOutside = () => {
    onToggleDisplay && onToggleDisplay();
  };

  return (
    <UIModalSlider
      onToggleDisplay={onClickOutside}
      open={open}
      classes={styles.errorModal}
    >
      <Text variant={TextVariants.RegularText} color="var(--slate900)">
        {title}
      </Text>
      <Icon variant={IconVariants.FAIL} icon={icon} />
      {children}
      {hasButton && (
        <div className={styles.buttonsWrapper}>
          {primaryButton && (
            <FilledButton
              onClick={primaryButton.onClick}
              variant={ThemeVariants[variant]}
            >
              {primaryButton.title}
            </FilledButton>
          )}
          {secondaryButton && (
            <OutlinedButton
              onClick={secondaryButton.onClick}
              variant={ThemeVariants[variant]}
            >
              {secondaryButton.title}
            </OutlinedButton>
          )}
        </div>
      )}
    </UIModalSlider>
  );
};

export default ErrorModal;

import { Cryptos } from "constants/cryptos";

import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface ConfirmationProps {
  amount: string | number;
  ticker: Currencies.ARS | Cryptos;
}

const Confirmation: React.FC<ConfirmationProps> = ({ amount, ticker }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        ¡Enviaste!
      </Text>
      <div className={styles.iconContainer}>
        <Check color="var(--green800)" />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.info}
      >
        Ya enviaste {amount} {ticker} que restaremos de tu portfolio.
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton
          variant={ThemeVariants.Crypto}
          onClick={() => navigate(CryptoRoutes.PORTFOLIO)}
        >
          Ir a portfolio
        </FilledButton>
        <OutlinedButton
          variant={ThemeVariants.Crypto}
          onClick={() => navigate(CryptoRoutes.HOME)}
        >
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default Confirmation;

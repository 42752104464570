import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import Text, { TextVariants } from "components/common/Text";

import SegmentedControl, { SegmentedControlProps } from "../SegmentedControl";

import styles from "./styles.module.scss";

interface NavigationBarProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  leftIcon: LucideIcon;
  rightIcon: LucideIcon;
  onClickLeft: () => void;
  onClickRight: () => void;
  segmentedControlProps: SegmentedControlProps;
  variant?: ThemeVariants;
}

const NavigationBar: React.FC<NavigationBarProps> = ({
  title,
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  onClickLeft,
  onClickRight,
  segmentedControlProps,
}) => {
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.navigationHeaderContainer}>
        <button onClick={onClickLeft}>
          <LeftIcon size={px2rem(24)} color="var(--slate900)" />
        </button>
        <div className={styles.titleWrapper}>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            {title}
          </Text>
        </div>
        <div className={styles.rightIcon} onClick={onClickRight}>
          <RightIcon size={px2rem(24)} color="var(--slate900)" />
        </div>
      </div>
      <div className={styles.tabControls}>
        <SegmentedControl {...segmentedControlProps} />
      </div>
    </header>
  );
};

export default NavigationBar;

import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import { LimitTabs, Limits } from "components/page-crypto-limits/utils";
import {
  CryptoLimitsResponse,
  Limit,
  LimitPeriod,
} from "interfaces/api-responses";

import LimitData from "../Limit";

import styles from "./styles.module.scss";

interface LimitTabProps {
  tab: LimitTabs;
}

const LimitTab: React.FC<LimitTabProps> = ({ tab }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [limitsData, setLimitsData] = useState<CryptoLimitsResponse>();

  const [dailyLimit, setDailyLimit] = useState<Limit>();
  const [weeklyLimit, setWeeklyLimit] = useState<Limit>();

  useEffect(() => {
    const getLimits = async () => {
      try {
        const { data } = await API.get<CryptoLimitsResponse>(
          `${cryptoService.limits}`
        );
        setLimitsData(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getLimits();
  }, []);

  useEffect(() => {
    if (limitsData) {
      ampli.cryptoScreenLimits({
        daily_limit_blockchain: `${limitsData.SEND[1].consumed}/${limitsData.SEND[1].limit}`,
        weekly_limit_blockchain: `${limitsData.SEND[0].consumed}/${limitsData.SEND[0].limit}`,
        daily_limit_tag: `${limitsData.SEND_TAG[1].consumed}/${limitsData.SEND_TAG[1].limit}`,
        weekly_limit_tag: `${limitsData.SEND_TAG[0].consumed}/${limitsData.SEND_TAG[0].limit}`,
      });

      const selectedLimits =
        tab === LimitTabs.BLOCKCHAIN ? limitsData.SEND : limitsData.SEND_TAG;
      const newDaily = selectedLimits.filter(
        (limit) => limit.period === LimitPeriod.DAILY
      )[0];
      const newWeekly = selectedLimits.filter(
        (limit) => limit.period === LimitPeriod.WEEKLY
      )[0];
      setDailyLimit(newDaily);
      setWeeklyLimit(newWeekly);
    }
  }, [tab, limitsData]);

  if (isLoading || !dailyLimit || !weeklyLimit) {
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorPage errorMessage="En este momento no es posible mostrar esta página." />
    );
  }

  return (
    <div className={styles.LimitTabContainer}>
      <LimitData
        used={dailyLimit.consumed}
        available={dailyLimit.available}
        max={dailyLimit.limit}
        type={Limits.DAILY}
      />
      <LimitData
        used={weeklyLimit.consumed}
        available={weeklyLimit.available}
        max={weeklyLimit.limit}
        type={Limits.WEEKLY}
      />
    </div>
  );
};

export default LimitTab;

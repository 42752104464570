import { useEffect, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import EmptyState from "components/common/EmptyState";
import SectionHeader from "components/common/SectionHeader";
import { CryptoPortfolio } from "interfaces/api-responses";
import CryptoPage from "layouts/CryptoPageLayout";
import { isEmpty } from "lodash";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import AllocationSection from "./AllocationSection";
import BalanceSection from "./BalanceSection";
import ChartSection from "./ChartSection";
import HoldingsSection from "./HoldingsSection";
import PerformanceSection from "./PerformanceSection";

import styles from "./styles.module.scss";

const CryptoPortfolioPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const previousScreen = useAppSelector(getCurrentScreen);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [portfolioData, setPortfolioData] = useState<CryptoPortfolio>();
  const [totalToDisplay, setTotalToDisplay] = useState<number>();
  const [dateToDisplay, setDateToDisplay] = useState<string>();

  useEffect(() => {
    ampli.cryptoScreenPortfolio({ previous_screen: previousScreen });
    dispatch(setCurrentScreen(cryptoScreenNames.portfolio));
  }, []);

  useEffect(() => {
    const getPortfolioData = async () => {
      try {
        const { data } = await API.get<CryptoPortfolio>(
          cryptoService.portfolio
        );

        setPortfolioData(data);
        setTotalToDisplay(data.total);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getPortfolioData();
  }, []);

  const onChangePortfolio = (v?: { value: number; time: number }) => {
    if (!v) {
      setTotalToDisplay(portfolioData?.total);
      setDateToDisplay(undefined);
      return;
    }

    const date = new Date(v.time);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    setDateToDisplay(`${day}/${month}/${year}`);
    setTotalToDisplay(v.value);
  };

  return (
    <CryptoPage loading={isLoading} error={error || !portfolioData}>
      {portfolioData && (
        <div className={styles.cryptoPortfolioPageContainer}>
          <SectionHeader title="Portfolio" />
          <BalanceSection
            available={totalToDisplay ?? portfolioData.total}
            dateToDisplay={dateToDisplay}
            variation={portfolioData.varTotalPercentage}
          />
          {isEmpty(portfolioData.holdings) ? (
            <EmptyState
              icon={Plus}
              title="Agregá crypto para empezar"
              subtitle="Todas tus compras y transferencias crypto se mostrarán en tu portfolio"
              hasButton
              buttonText="Comprá crypto"
              onClick={() =>
                navigate(CryptoRoutes.SWAP, {
                  state: { isBuying: true },
                })
              }
              buttonVariant={ThemeVariants.Crypto}
            />
          ) : (
            <>
              <ChartSection
                onChangePortfolio={onChangePortfolio}
                portfolioTotal={portfolioData.total}
              />
              <PerformanceSection />
              <HoldingsSection holdings={portfolioData.holdings} />
              <AllocationSection holdings={portfolioData.holdings} />
            </>
          )}
        </div>
      )}
    </CryptoPage>
  );
};

export default CryptoPortfolioPage;

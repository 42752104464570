import { Cryptos } from "constants/cryptos";

import { useCallback, useEffect, useRef, useState } from "react";

import API from "apis";
import { ampli } from "ampli";
import { cryptoService } from "apis/services";
import FilledButton from "components/common/FilledButton";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import {
  CryptoPrice,
  CryptoSwapHolding,
  CryptoSwapPortfolio,
} from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import { Operations } from "interfaces/crypto/enums";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { Projects, trackAction } from "utils/amplitude";
import LoadingSpinner from "components/common/LoadingSpinner";
import ErrorPage from "components/common/ErrorPage";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import BaseBlock from "./BaseBlock";
import ConfirmationBottomSheet from "./ConfirmationBottomSheet";
import QuoteBlock from "./QuoteBlock";
import TransactionBottomSheet from "./TransactionBottomSheet";
import TransactionFooter from "./TransactionFooter";

import styles from "./styles.module.scss";

enum Sheet {
  BASE = "base",
  QUOTE = "quote",
  CONFIRMATION = "confirmation",
  CLOSED = "closed",
}

interface TransactionsProps {
  isArsDefault?: boolean;
  selectedCrypto?: Cryptos;
  continueSendFlow?: () => void;
}

const MIN_BUY = 1000;

const Transaction: React.FC<TransactionsProps> = ({
  isArsDefault,
  selectedCrypto,
  continueSendFlow,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { wallet } = useAppSelector((state: RootState) => state.wallet);

  const baseRef = useRef<HTMLInputElement>(null);
  const quoteRef = useRef<HTMLInputElement>(null);

  const [holdings, setHoldings] = useState<CryptoSwapHolding[]>([]);
  const [isLoadingHoldings, setIsLoadingHoldings] = useState<boolean>(true);
  const [holdingsError, setHoldingsError] = useState<boolean>(false);

  const [exchangeRates, setExchangeRates] = useState<CryptoPrice[]>();
  const [sheet, setSheet] = useState<Sheet>(Sheet.CLOSED);
  const [baseCurrency, setBaseCurrency] = useState<Cryptos | Currencies.ARS>();
  const [quoteCurrency, setQuoteCurrency] = useState<
    Cryptos | Currencies.ARS
  >();
  const [isLoadingRate, setIsLoadingRate] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const isBuy = baseCurrency === Currencies.ARS;
  const isSell = quoteCurrency === Currencies.ARS;
  const isBuyOrSell = isBuy || isSell;

  const onConfirmTransaction = () => {
    getHoldings();
  };

  const getHoldings = async () => {
    try {
      const { data } = await API.get<CryptoSwapPortfolio>(
        cryptoService.portfolio
      );
      setHoldings(data.holdings);
    } catch (error) {
      setHoldingsError(true);
    } finally {
      setIsLoadingHoldings(false);
    }
  };

  const getExchangeRate = () => {
    if (!exchangeRates) return 0;
    if (isSell) return exchangeRates[0].bid;
    return exchangeRates[0]?.ask;
  };

  const exchangeRate = getExchangeRate();

  const getPrices = async ({
    base,
    quote,
  }: {
    base?: Cryptos | Currencies.ARS;
    quote?: Cryptos | Currencies.ARS;
  }) => {
    const isSelling = quote === Currencies.ARS;
    const noQuote = base === Currencies.ARS || isSelling;

    try {
      setIsLoadingRate(true);
      const { data } = await API.get<CryptoPrice[]>(cryptoService.prices, {
        params: {
          baseTicker: isSelling ? base : quote,
          ...(!noQuote ? { quoteTicker: base } : {}),
        },
      });

      setExchangeRates(data);

      if (!quoteRef.current || !baseRef.current) return;

      if (isSell) {
        quoteRef.current.value = String(
          (+baseRef.current.value * data[0].ask).toFixed(2)
        );
        return;
      }

      quoteRef.current.value =
        +baseRef.current.value > 0
          ? String((+baseRef.current.value / data[0].ask).toFixed(8))
          : "";
    } catch (error) {
      console.log("Error retrieving prices");
    } finally {
      setIsLoadingRate(false);
    }
  };

  useEffect(() => {
    getHoldings();

    if (!wallet) {
      dispatch(getWallet());
    }
  }, []);

  useEffect(() => {
    if (isArsDefault) {
      setBaseCurrency(Currencies.ARS);
      setQuoteCurrency(selectedCrypto);
      getPrices({ base: Currencies.ARS, quote: selectedCrypto });
      return;
    }

    setBaseCurrency(selectedCrypto);
  }, []);

  const onClickAsset = (asset: Cryptos | Currencies.ARS) => {
    setSheet(Sheet.CLOSED);

    const properties = {
      crypto_ticker: asset,
    };

    if (sheet === Sheet.BASE) {
      trackAction(
        `${cryptoScreenNames.swap} - Click First Select`,
        properties,
        Projects.CRYPTO
      );

      setBaseCurrency(asset);
      getPrices({ base: asset, quote: quoteCurrency });

      if (baseRef.current) baseRef.current.value = "";

      return;
    }

    trackAction(
      `${cryptoScreenNames.swap} - Click Drop Down`,
      properties,
      Projects.CRYPTO
    );

    setQuoteCurrency(asset);

    getPrices({ base: baseCurrency, quote: asset });

    if (quoteRef.current) quoteRef.current.value = "";
  };

  const onClickSwitch = () => {
    if (Number(quoteBalance || 0) < Number(quoteRef.current?.value)) {
      setError(`Tu balance en ${quoteCurrency} es insuficiente`);
    } else {
      setError("");
    }
    setBaseCurrency(quoteCurrency);
    setQuoteCurrency(baseCurrency);
    getPrices({ base: quoteCurrency, quote: baseCurrency });
    const tempBase = baseRef.current?.value;
    const tempQuote = quoteRef.current?.value;

    if (!quoteRef.current || !baseRef.current) {
      return;
    }

    baseRef.current.value = tempQuote ?? "";
    quoteRef.current.value = tempBase ?? "";
  };

  const baseInstrument = holdings?.find(
    ({ ticker }) => baseCurrency === ticker
  );
  const quoteInstrument = holdings?.find(
    ({ ticker }) => quoteCurrency === ticker
  );

  const baseBalance = isBuy ? wallet?.CI.ars : baseInstrument?.quantity;

  const quoteBalance = isSell
    ? Number(wallet?.CI.ars)
    : quoteInstrument?.quantity;

  const getErrorMessage = () => {
    const baseNumber = Number(baseRef.current?.value);
    if (baseNumber > Number(baseBalance)) {
      if (isBuy) {
        return "Tu balance es insuficiente";
      } else {
        return `Tu balance en ${baseCurrency} es insuficiente`;
      }
    }

    if (isBuy && baseNumber < MIN_BUY) {
      return `La compra mínima es de $${MIN_BUY}`;
    }
    return "";
  };

  const getPageTitle = () => {
    if (!isBuyOrSell) return "Convertir";
    if (isBuy) return "Comprar";
    return "Vender";
  };
  const isButtonDisabled = !baseCurrency || !quoteCurrency || !!error;

  const handleChangeBase = (value: string) => {
    if (!quoteRef.current || !baseRef.current) return;

    baseRef.current.value = value;

    setError(getErrorMessage());
    const decimals = !isBuy ? 8 : 2;
    const regex = new RegExp(`^(\\d*(\\.\\d{0,${decimals}})?)$`);
    const validated = value.match(regex);
    if (!validated) {
      baseRef.current.value = value.toString().slice(0, -1);
      return;
    }

    if (!quoteCurrency) return;
    if (isSell) {
      quoteRef.current.value = String((+value * exchangeRate).toFixed(2));
      return;
    }

    quoteRef.current.value = String((+value / exchangeRate).toFixed(8));
  };

  const handleChangeQuote = useCallback(
    (value: string) => {
      if (!baseRef.current || !quoteRef.current) return;

      quoteRef.current.value = value;

      const decimals = isSell ? 2 : 8;
      const regex = new RegExp(`^(\\d*(\\.\\d{0,${decimals}})?)$`);
      const sameOrLessDecimals = value.match(regex);

      if (!sameOrLessDecimals) {
        quoteRef.current.value = value.toString().slice(0, -1);
        return;
      }

      if (!baseCurrency) return;
      if (isBuy || !isBuyOrSell) {
        const nDecimals = isBuy ? 2 : 8;
        baseRef.current.value = String(
          (+value * exchangeRate).toFixed(nDecimals)
        );
      } else {
        baseRef.current.value = String((+value / exchangeRate).toFixed(8));
      }
      setError(getErrorMessage());
    },
    [exchangeRates, baseRef]
  );

  const onClickMax = () => {
    if (!baseRef.current || !baseBalance) return;

    baseRef.current.value = String(baseBalance);
    handleChangeBase(baseRef.current.value);
  };

  const getOperationType = () => {
    if (isSell) return Operations.SELL;
    if (isBuy) return Operations.BUY;
    return Operations.SWAP;
  };

  const onClickButton = () => {
    ampli.cryptoOperationsPreview({
      operation_type: getOperationType(),
      base_ticker: baseCurrency,
      quote_ticker: quoteCurrency,
      base_quantity: Number(baseRef.current?.value),
      quote_quantity: Number(quoteRef.current?.value),
      exchange_rate: exchangeRate,
    });

    return setSheet(Sheet.CONFIRMATION);
  };

  const getPreviewLabel = () => {
    if (isBuy) return "Previsualizar compra";

    if (isSell) return "Previsualizar venta";

    return "Previsualizar conversión";
  };

  if (isLoadingHoldings)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  if (holdingsError)
    return <ErrorPage errorMessage="No pudimos obtener tus activos." />;

  const onClose = () => {
    ampli.cryptoOperationsExit();
    return navigate(-1);
  };

  return (
    <div className={styles.transactionWrapper}>
      <div>
        <PageHeader
          title={getPageTitle()}
          onClick={onClose}
          icon={X}
          className={styles.pageHeader}
        />
        <div className={styles.formWrapper}>
          <BaseBlock
            onClickMax={onClickMax}
            ref={baseRef}
            onClickSelectCurrency={() => setSheet(Sheet.BASE)}
            baseCurrency={baseCurrency}
            balance={baseBalance}
            onChange={handleChangeBase}
          />
          <QuoteBlock
            ref={quoteRef}
            onClickSelectCurrency={() => setSheet(Sheet.QUOTE)}
            quoteCurrency={quoteCurrency}
            balance={quoteBalance || 0}
            onChange={(value) => {
              handleChangeQuote(value);
            }}
            isLoading={isLoadingRate}
          />
        </div>
        <TransactionFooter onClickSwitch={onClickSwitch} />
        {error && (
          <Text
            color="var(--red800)"
            className={styles.text}
            variant={TextVariants.RegularTextS}
          >
            {error}
          </Text>
        )}
      </div>

      <FilledButton
        variant={ThemeVariants.Crypto}
        className={styles.button}
        disabled={isButtonDisabled}
        onClick={onClickButton}
      >
        {getPreviewLabel()}
      </FilledButton>

      <TransactionBottomSheet
        isOpen={sheet === Sheet.BASE || sheet === Sheet.QUOTE}
        onToggleDisplay={() => setSheet(Sheet.CLOSED)}
        onClickAsset={onClickAsset}
        baseCurrency={baseCurrency}
        quoteCurrency={quoteCurrency}
      />

      <ConfirmationBottomSheet
        isBuyOrSell={isBuyOrSell}
        baseCurrency={baseCurrency}
        exchangeRate={exchangeRate}
        quoteCurrency={quoteCurrency}
        continueSendFlow={continueSendFlow}
        baseAmount={baseRef.current?.value}
        quoteAmount={quoteRef.current?.value}
        isOpen={sheet === Sheet.CONFIRMATION}
        onConfirmTransaction={onConfirmTransaction}
        onToggleDisplay={() => setSheet(Sheet.CLOSED)}
        operationType={getOperationType()}
      />
    </div>
  );
};

export default Transaction;

import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import mainRoutes from "router/routes";

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".capital").pop();

      if (slug) {
        navigate(slug);
      } else {
        navigate(mainRoutes.home);
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;

import Spinner from "lotties/spinner.json";
import Lottie from "react-lottie";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

export enum LoadingSpinnerVariants {
  BUTTON = "Button",
}
interface LoadingSpinnerProps {
  size?: string | number;
  variant?: ThemeVariants | LoadingSpinnerVariants;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 200,
  variant = ThemeVariants.Capital,
}) => {
  return (
    <div className={styles[variant]}>
      <Lottie
        isPaused={false}
        options={{
          loop: true,
          autoplay: true,
          animationData: Spinner,
          rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
        }}
        height={size}
        width={size}
        isClickToPauseDisabled={true}
      />
    </div>
  );
};

export default LoadingSpinner;

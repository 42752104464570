import { getCrypto } from "constants/cryptos";

import PieChart from "components/common/PieChart";
import { CryptoHolding } from "interfaces/api-responses";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface AllocationCardProps {
  holdings: CryptoHolding[];
}

const AllocationCard: React.FC<AllocationCardProps> = ({ holdings }) => {
  return (
    <div className={styles.AllocationCardWrapper}>
      <div>
        <PieChart holdings={holdings} size={64} />
      </div>
      <div className={styles.cryptosWrapper}>
        {holdings.map(({ ticker }) => {
          if (ticker === Currencies.ARS)
            return (
              <div key={ticker} className={styles.cryptoWrapper}>
                <div
                  className={styles.cryptoBadge}
                  style={{ backgroundColor: "var(--slate600)" }}
                />
                <Text
                  variant={TextVariants.RegularTextS}
                  color="var(--slate900)"
                >
                  Otros
                </Text>
              </div>
            );

          const { name, color } = getCrypto(ticker);
          return (
            <div key={ticker} className={styles.cryptoWrapper}>
              <div
                className={styles.cryptoBadge}
                style={{ backgroundColor: color }}
              />
              <Text variant={TextVariants.RegularTextS} color="var(--slate900)">
                {name}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllocationCard;

import { ReactElement } from "react";

import classNames from "classnames";
import { hapticsImpact } from "utils/haptics";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface TabOptions {
  name: string;
  icon?: ReactElement;
}

export interface SegmentedControlProps {
  options: TabOptions[];
  onClick: (option: any) => void;
  selected: string;
  disabled?: boolean;
  isBrandType?: boolean;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
  options,
  onClick,
  selected,
  isBrandType = false,
}) => {
  const onClickTab = (option: string) => {
    hapticsImpact();
    onClick(option);
  };

  const pillWidth = options.length === 2 ? 44 : 46;

  const pillBackgroundWidth = isBrandType
    ? "50%"
    : `calc((100% - ${px2rem(pillWidth)}) / ${options.length}`;

  return (
    <div
      className={classNames(styles.segmentedControlContainer, {
        [styles.brand]: isBrandType,
      })}
    >
      {options.map((option, index) => (
        <>
          <input
            type="radio"
            id={`radio-${index + 1}`}
            name="tabs"
            checked={selected === option.name}
            onClick={() => onClickTab(option.name)}
            key={option.name}
          />
          <label className={styles.tab} htmlFor={`radio-${index + 1}`}>
            {option?.icon} {option.name}
          </label>
        </>
      ))}
      <span
        className={styles.pillBackground}
        style={{
          width: pillBackgroundWidth,
        }}
      />
    </div>
  );
};

export default SegmentedControl;

import { cryptoStatus } from "constants/cryptos";

import { User } from "@supabase/supabase-js";

import { StatusOnboarding } from "./createAccount";

export interface FavoriteList {
  id_list: string;
  name: string;
}

export enum CocosProducts {
  CAPITAL = "CAPITAL",
  CRYPTO = "CRYPTO",
  PAY = "PAY",
}

export interface UserData extends User {
  id: string;
  first_name: string;
  middle_name?: string;
  last_name?: string;
  tier: string;
  is_active: boolean;
  cuit: string;
  dni: string;
  bot_on: boolean;
  cable_notification: boolean;
  dark_theme: boolean;
  last_bot_sign_in?: any;
  id_accounts: number[];
  hasAccount: boolean;
  birth_date: string | null;
  street_name: string | null;
  street_number: string | null;
  floor: string | null;
  apartment: string | null;
  state_name: string | null;
  zip_code: string | null;
  statusOnboarding: StatusOnboarding;
  signInWithBiometrics: boolean | undefined;
  favoritesLists: FavoriteList[];
  status_crypto: cryptoStatus | null;
  enabled_products: CocosProducts[];
  tag?: string | null;
  phone_confirmed: boolean;
  email_confirmed: boolean;
  latest_tnc_accepted: boolean;
  card_status: string | null;
}

export type UserSettings = {
  darkTheme: boolean;
  cableNotification: boolean;
  statusOnboarding: StatusOnboarding;
  tourStatus: "pending" | "skipped" | "completed";
};

export interface Investor {
  expiration_date: string;
  expiration_days: string;
  risk_profile: string;
}

// ENUMS

export enum InvestorTypes {
  CONSERVADOR = "Conservador",
  MODERADO = "Moderado",
  AGRESIVO = "Agresivo",
  ARRIESGADO = "Arriesgado",
}

export interface TierResponse {
  idAccount: number;
  tier: Tiers;
  aranceles: [];
  tierUpgrade: {
    propose: "gold" | null;
    requested: boolean;
  };
}

export enum Tiers {
  FREE = "FREE",
  GOLD_AVAILABLE = "GOLD_AVAILABLE",
  GOLD_REQUESTED = "GOLD_REQUESTED",
  GOLD = "GOLD",
}

import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import OptionsRow, { OptionsRowProps } from "components/common/OptionsRow";

import styles from "./styles.module.scss";

interface DefaultSectionProps {
  title: string;
  options: OptionsRowProps[];
}

const DefaultSection: React.FC<DefaultSectionProps> = ({ title, options }) => {
  return (
    <div className={styles.defaultSectionContainer}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularSubtitle}
        color="var(--slate800)"
      >
        {title}
      </Heading>
      <div className={styles.list}>
        {options.map(
          (
            { icon, label, onClick, rightIcon, isToggle, toggleValue },
            index
          ) => (
            <div key={label}>
              <OptionsRow
                icon={icon}
                label={label}
                onClick={onClick}
                rightIcon={rightIcon}
                isToggle={isToggle}
                toggleValue={toggleValue}
              />
              {index !== options.length - 1 && (
                <Divider className={styles.divider} />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default DefaultSection;

import { Network, getNetwork } from "constants/cryptos";

import { useState } from "react";

import SendAsset from "components/page-crypto-send/SendAsset";
import BottomSheet from "components/common/BottomSheet";
import Heading, { HeadingVariants } from "components/common/Heading";
import NetworksInfo from "components/common/NetworksInfo";
import Text, { TextVariants } from "components/common/Text";
import { useCryptoSend } from "context/CryptoSendProvider";
import { CryptoAvailableNetworks } from "interfaces/api-responses";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NetworkBottomSheetProps {
  networks: CryptoAvailableNetworks[];
  onClick: (value: Network) => void;
}

const NetworkBottomSheet: React.FC<NetworkBottomSheetProps> = ({
  onClick,
  networks,
}) => {
  const { selectedCrypto } = useCryptoSend();
  const [isDisplayingInfo, setIsDisplayingInfo] = useState<boolean>(false);

  const hideInfo = () => {
    setIsDisplayingInfo(false);
  };

  const showInfo = () => {
    setIsDisplayingInfo(true);
  };

  const handleClickNetwork = (value: Network) => {
    onClick(value);
  };

  const bottomSheetTitle = isDisplayingInfo ? undefined : "Seleccioná una red";

  if (!selectedCrypto) return null;

  return (
    <BottomSheet title={bottomSheetTitle}>
      {!isDisplayingInfo ? (
        <div className={styles.bottomSheetContainer}>
          <div className={styles.wrapper}>
            <div className={styles.headingWrapper}>
              <Heading
                variant={HeadingVariants.RegularSubtitle}
                component="h2"
                color="var(--slate800)"
              >
                Redes disponibles
              </Heading>
              <Info
                size={px2rem(16)}
                color="var(--purple800)"
                onClick={showInfo}
              />
            </div>
            {networks.map((network) => {
              const { name } = network;
              const { icon } = getNetwork(name);
              return (
                <SendAsset
                  icon={icon}
                  name={name}
                  ticker={selectedCrypto.ticker}
                  key={name}
                  onClick={() => handleClickNetwork({ ...network, icon })}
                />
              );
            })}
          </div>
          <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
            Corroborá que la dirección de destino soporte la red que vas a
            utilizar para evitar que tus fondos se pierdan.
          </Text>
        </div>
      ) : (
        <NetworksInfo hideInfo={hideInfo} />
      )}
    </BottomSheet>
  );
};

export default NetworkBottomSheet;

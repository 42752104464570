import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";

const Success: React.FC<IconProps> = ({
  width = px2rem(132),
  height = px2rem(120),
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 132 120"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_10603_9153)">
      <path
        d="M61.3297 19.0469L71.4492 40.4192L93.7157 32.4628L85.7623 54.7323L107.132 64.8518L85.7623 74.9713L93.7157 97.2378L71.4492 89.2815L61.3297 110.654L51.2102 89.2815L28.9437 97.2378L36.9 74.9713L15.5277 64.8518L36.9 54.7323L28.9437 32.4628L51.2102 40.4192L61.3297 19.0469Z"
        fill="#3DD68C"
      />
      <path
        d="M75.991 53.8848L55.1706 74.7022L45.7682 65.2998"
        stroke="var(--slate900)"
        strokeWidth="1.23949"
        strokeMiterlimit="10"
      />
      <path
        d="M107.132 26.2653C107.132 22.7823 105.748 19.442 103.285 16.9791C100.822 14.5163 97.482 13.1327 93.999 13.1327C97.482 13.1327 100.822 11.749 103.285 9.28619C105.748 6.82334 107.132 3.483 107.132 0C107.132 3.483 108.515 6.82334 110.978 9.28619C113.441 11.749 116.781 13.1327 120.264 13.1327C116.781 13.1327 113.441 14.5163 110.978 16.9791C108.515 19.442 107.132 22.7823 107.132 26.2653Z"
        fill="var(--blue700)"
        stroke="var(--blue700)"
        strokeWidth="1.68216"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.89393 120.001C9.89002 117.525 8.90359 115.151 7.15124 113.402C5.39889 111.652 3.02385 110.67 0.547607 110.67C3.02641 110.67 5.40368 109.685 7.15645 107.932C8.90923 106.179 9.89393 103.802 9.89393 101.323C9.89393 103.802 10.8786 106.179 12.6314 107.932C14.3842 109.685 16.7614 110.67 19.2402 110.67C16.7614 110.67 14.3842 111.654 12.6314 113.407C10.8786 115.16 9.89393 117.537 9.89393 120.016V120.001Z"
        fill="var(--blue800)"
        stroke="var(--blue800)"
        strokeWidth="1.68216"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M125.432 63.4235C125.432 62.6331 125.277 61.8503 124.975 61.1199C124.672 60.3895 124.229 59.7258 123.67 59.1667C123.112 58.6076 122.448 58.1641 121.718 57.8615C120.988 57.5589 120.205 57.4032 119.414 57.4032C120.205 57.4032 120.988 57.2474 121.718 56.9448C122.448 56.6423 123.112 56.1988 123.67 55.6397C124.229 55.0806 124.672 54.4169 124.975 53.6865C125.277 52.9561 125.432 52.1733 125.432 51.3828C125.432 52.9795 126.066 54.5108 127.195 55.6399C128.324 56.7689 129.856 57.4032 131.452 57.4032C129.856 57.4032 128.324 58.0375 127.195 59.1665C126.066 60.2955 125.432 61.8268 125.432 63.4235Z"
        fill="#FFC419"
        stroke="#FFC419"
        strokeWidth="1.68216"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10603_9153">
        <rect
          width="130.905"
          height="120"
          fill="white"
          transform="translate(0.547729)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Success;

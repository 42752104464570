import { BookUser } from "lucide-react";
import { CryptoAccount } from "interfaces/api-responses";
import AccountRow from "components/common/AccountRow";
import ButtonLabel from "components/common/ButtonLabel";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface AccountListProps {
  accounts: CryptoAccount[];
  onClickAccount: (account: CryptoAccount) => void;
  handleClickAdd: () => void;
}

const AccountList: React.FC<AccountListProps> = ({
  accounts,
  onClickAccount,
  handleClickAdd,
}) => (
  <>
    <div className={styles.accounts}>
      {accounts.map((account) => {
        const { address, name, tickers } = account;
        return (
          <AccountRow
            key={address}
            name={name}
            accountId={address}
            currencies={tickers}
            onClick={() => onClickAccount(account)}
          />
        );
      })}
    </div>
    <ButtonLabel onClick={handleClickAdd}>
      <BookUser size={px2rem(16)} color="var(--purple800)" />
      <Text
        variant={TextVariants.SemiboldTextS}
        component={"span"}
        color="var(--purple800)"
      >
        Agendar una cuenta
      </Text>
    </ButtonLabel>
  </>
);

export default AccountList;

import AssetsList from "components/common/AssetsList";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { CryptoPrice } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface AssetsSectionProps {
  cryptos?: CryptoPrice[];
}

const AssetsSection: React.FC<AssetsSectionProps> = ({ cryptos }) => {
  return (
    <div className={styles.assetsSectionContainer}>
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
      >
        Activos
      </Heading>
      {cryptos ? (
        <AssetsList cryptos={cryptos} />
      ) : (
        <Text variant={TextVariants.SemiboldTextS} color="var(--slate800)">
          No hay activos disponible para mostrar ...
        </Text>
      )}
    </div>
  );
};

export default AssetsSection;

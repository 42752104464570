import { Network } from "constants/cryptos";

import AssetSelectCard from "components/common/AssetSelectCard";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NetworkSelectorProps {
  networks: Network[];
  showInfo: () => void;
  onClickNetwork: (v: string) => void;
}

const NetworkSelector: React.FC<NetworkSelectorProps> = ({
  showInfo,
  networks,
  onClickNetwork,
}) => {
  return (
    <div className={styles.bottomSheetContainer}>
      <div className={styles.wrapper}>
        <div className={styles.headingWrapper}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h2"
            color="var(--slate800)"
          >
            Redes disponibles
          </Heading>
          <Info size={px2rem(16)} color="var(--purple800)" onClick={showInfo} />
        </div>
        {networks.map((network) => {
          const { icon, name } = network;
          return (
            <AssetSelectCard
              key={name}
              icon={icon}
              ticker={name}
              onClick={onClickNetwork}
            />
          );
        })}
      </div>
      <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
        Corroborá que la red que vas a utilizar sea soportada por la dirección
        de envío para evitar que tus fondos se pierdan.
      </Text>
    </div>
  );
};

export default NetworkSelector;

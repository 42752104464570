import Text, { TextVariants } from "components/common/Text";
import { MovementStatus } from "interfaces/api-responses";
import { getStatusLabel } from "utils";

import styles from "./styles.module.scss";

interface StatusLabelProps {
  status: MovementStatus;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status }) => {
  const textColor =
    status === MovementStatus.PENDING ? "var(--yellow800)" : "var(--red800)";
  if (status === MovementStatus.PENDING || status === MovementStatus.ERROR)
    return (
      <Text
        className={styles.statusLabel}
        variant={TextVariants.RegularTextS}
        color={textColor}
      >
        {getStatusLabel(status)}
      </Text>
    );

  return null;
};

export default StatusLabel;

import { Capacitor } from "@capacitor/core";

import { BrazeMobileClient } from "./MobileClient";
import { BrazeWebClient } from "./WebClient";
import { BrazeInterface } from "./types";

const instance: BrazeInterface = Capacitor.isNativePlatform()
  ? new BrazeMobileClient()
  : new BrazeWebClient();

export default instance;

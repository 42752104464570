import { IconProps } from "assets/icons/BTC";

const Limit: React.FC<IconProps> = ({ size, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 70 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72315 21.0326L9.72314 79.3787C9.72314 83.5436 13.0995 86.9199 17.2643 86.9199L51.2116 86.9199C55.3765 86.9199 58.7528 83.5436 58.7528 79.3787L58.7528 21.0326C58.7528 16.8678 55.3765 13.4914 51.2116 13.4914L17.2643 13.4914C13.0995 13.4914 9.72315 16.8678 9.72315 21.0326Z"
      fill="url(#paint0_linear_3269_19302)"
    />
    <path
      d="M44.4552 2.4262e-06L6.23515 2.99781e-07C3.25931 1.34216e-07 0.846924 2.41559 0.846924 5.39538L0.84692 72.715C0.84692 75.6948 3.25931 78.1104 6.23514 78.1104L44.4552 78.1104C47.431 78.1104 49.8434 75.6948 49.8434 72.715L49.8434 5.39538C49.8434 2.4156 47.431 2.59177e-06 44.4552 2.4262e-06Z"
      fill="#113264"
    />
    <path
      d="M12.2323 68.977C12.2299 68.1581 12.4094 67.349 12.7578 66.6082C13.1062 65.8674 13.6147 65.2134 14.2466 64.6936C13.4382 64.0275 12.4574 63.6052 11.4185 63.4759C10.3796 63.3465 9.32541 63.5155 8.37874 63.963C7.43206 64.4106 6.63191 65.1184 6.07153 66.0038C5.51115 66.8893 5.21362 67.916 5.21362 68.9643C5.21362 70.0126 5.51115 71.0393 6.07153 71.9247C6.63191 72.8102 7.43206 73.518 8.37874 73.9655C9.32541 74.4131 10.3796 74.5821 11.4185 74.4527C12.4574 74.3233 13.4382 73.901 14.2466 73.2349C13.618 72.7179 13.1114 72.068 12.7632 71.3318C12.415 70.5957 12.2337 69.7915 12.2323 68.977Z"
      fill="#DC3E42"
    />
    <path
      d="M17.7554 63.4453C16.4737 63.445 15.2323 63.8937 14.2463 64.7138C14.876 65.2327 15.3832 65.8847 15.7313 66.6231C16.0795 67.3615 16.2601 68.1679 16.2601 68.9845C16.2601 69.801 16.0795 70.6074 15.7313 71.3458C15.3832 72.0842 14.876 72.7362 14.2463 73.2551C14.9303 73.8186 15.7395 74.2092 16.6058 74.3939C17.4721 74.5786 18.3701 74.5519 19.2239 74.3162C20.0778 74.0805 20.8625 73.6426 21.5119 73.0395C22.1614 72.4364 22.6564 71.6858 22.9553 70.8509C23.2542 70.016 23.3482 69.1214 23.2294 68.2425C23.1106 67.3636 22.7824 66.5263 22.2726 65.801C21.7628 65.0758 21.0862 64.4839 20.3001 64.0754C19.5139 63.6669 18.6412 63.4537 17.7554 63.4538L17.7554 63.4453Z"
      fill="#FFC419"
    />
    <path
      d="M16.2604 68.9767C16.2628 68.1578 16.0833 67.3487 15.735 66.6079C15.3866 65.8671 14.878 65.2131 14.2462 64.6934C13.6165 65.2122 13.1093 65.8642 12.7612 66.6026C12.413 67.341 12.2324 68.1474 12.2324 68.964C12.2324 69.7806 12.413 70.587 12.7612 71.3254C13.1093 72.0637 13.6165 72.7158 14.2462 73.2346C14.8747 72.7176 15.3813 72.0677 15.7295 71.3315C16.0778 70.5954 16.2591 69.7912 16.2604 68.9767Z"
      fill="#F7881A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.234 8.26563L28.5678 8.26563C28.5678 8.26628 28.5678 8.26693 28.5678 8.26758L28.5678 12.6488L33.234 12.6488L33.234 8.26758C33.234 8.26693 33.234 8.26628 33.234 8.26563ZM28.1583 8.26563L27.6792 8.26563C26.681 8.26563 25.8718 9.07587 25.8718 10.0754L25.8718 14.607L28.1583 12.756L28.1583 8.26758C28.1583 8.26693 28.1583 8.26628 28.1583 8.26563ZM25.8718 15.1339L25.8718 19.661C25.8718 20.6605 26.681 21.4708 27.6792 21.4708L28.1588 21.4708L28.1588 16.9853L25.8718 15.1339ZM28.5683 21.4708L33.234 21.4708L33.234 17.0921L28.5683 17.0921L28.5683 21.4708ZM33.6435 21.4708L34.1231 21.4708C35.1212 21.4708 35.9304 20.6605 35.9304 19.661L35.9304 15.1339L33.6435 16.9853L33.6435 21.4708ZM35.9304 14.607L35.9304 10.0754C35.9304 9.07587 35.1212 8.26563 34.1231 8.26563L33.6435 8.26563C33.6435 8.26628 33.6435 8.26693 33.6435 8.26758L33.6435 12.7556L35.9304 14.607ZM26.1972 14.8704L28.4357 13.0583L33.3665 13.0583L35.605 14.8704L33.3665 16.6826L28.4357 16.6826L26.1972 14.8704Z"
      fill="url(#paint1_linear_3269_19302)"
    />
    <path
      d="M51.3322 95.9994C61.1745 95.9994 69.1532 88.0207 69.1532 78.1784C69.1532 68.3362 61.1745 60.3574 51.3322 60.3574C41.49 60.3574 33.5112 68.3362 33.5112 78.1784C33.5112 88.0207 41.49 95.9994 51.3322 95.9994Z"
      fill="#3DD68C"
    />
    <path
      d="M43.4526 77.3457L51.3157 69.4826L59.1765 77.3457"
      stroke="#1C2024"
      strokeWidth="0.823709"
      strokeMiterlimit="10"
    />
    <path
      d="M51.3157 69.4825L51.3157 86.3945"
      stroke="#1C2024"
      strokeWidth="0.823709"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.5974 29.7139L21.4216 33.2657L19.648 34.1917C16.4008 35.8661 14.8468 39.3545 16.1854 41.9592C17.524 44.5638 21.2485 45.3419 24.4958 43.6505L26.2693 42.7372L28.0935 46.2806L26.3284 47.1939C21.126 49.8958 14.9946 48.344 12.6467 43.7774C10.2989 39.2107 12.6467 33.308 17.8323 30.623L19.5974 29.7139ZM30.8827 53.1325L29.0584 49.5807L30.832 48.6547C34.0793 46.9803 35.6332 43.4919 34.2946 40.8872C32.956 38.2825 29.2316 37.5045 25.9843 39.1959L24.2107 40.1092L22.3865 36.5658L24.1516 35.6525C29.354 32.9506 35.4855 34.5024 37.8333 39.069C40.1811 43.6356 37.8333 49.5384 32.6478 52.2234L30.8827 53.1325Z"
      fill="#104D87"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3269_19302"
        x1="34.2403"
        y1="86.9199"
        x2="34.2403"
        y2="13.4914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1D5E0" />
        <stop offset="0.32" stopColor="#DADEE9" />
        <stop offset="0.86" stopColor="#E6EAF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3269_19302"
        x1="30.9011"
        y1="21.4708"
        x2="30.9011"
        y2="8.26563"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#D1D5E0" />
        <stop offset="0.4" stopColor="#DADEE9" />
        <stop offset="0.76" stopColor="#E6EAF4" />
      </linearGradient>
    </defs>
  </svg>
);

export default Limit;

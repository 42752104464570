import { useEffect } from "react";

import { ampli } from "ampli";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";

import { ButtonsOptions } from "../../utils";

import styles from "./styles.module.scss";

interface ErrorProps {
  operationType: string;
}

const Error: React.FC<ErrorProps> = ({ operationType }) => {
  const navigate = useNavigate();

  useEffect(() => {
    ampli.cryptoOperationsFail({
      operation_type: operationType,
    });
  }, []);

  const onClickButton = (value: ButtonsOptions) => {
    navigate(CryptoRoutes[value]);
  };

  return (
    <div className={styles.errorWrapper}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularTitle}
        className={styles.title}
        color="var(--slate900)"
      >
        Algo salió mal
      </Heading>
      <div className={styles.icon}>
        <X size={px2rem(24)} color="var(--red900)" />
      </div>
      <Text
        variant={TextVariants.RegularTextS}
        className={styles.text}
        color="var(--slate900)"
      >
        No pudimos realizar la compra. Por favor, volvé a intentarlo más tarde.
      </Text>
      <FilledButton
        variant={ThemeVariants.Crypto}
        onClick={() => onClickButton(ButtonsOptions.HOME)}
        className={styles.topButton}
      >
        Volver al inicio
      </FilledButton>
      <OutlinedButton
        variant={ThemeVariants.Crypto}
        onClick={() => onClickButton(ButtonsOptions.PORTFOLIO)}
      >
        Ir al portfolio
      </OutlinedButton>
    </div>
  );
};

export default Error;

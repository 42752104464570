import CurrencySelect from "components/common/CurrencySelect";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import NumberInput from "components/common/NumberInput";
import Text, { TextVariants } from "components/common/Text";
import Amount, { AmountSizes } from "components/common/Amount";
import { Currencies } from "interfaces/wallet";
import UseMaxButton from "components/common/UseMaxButton";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface TransactionProps {
  amount: string;
  isBuy: boolean;
  maxAmount: number;
  convertedAmount: number;
  setAmount: (amount: string) => void;
  checkForErrors: (value: number) => void;
}

const Transaction: React.FC<TransactionProps> = ({
  isBuy,
  amount,
  setAmount,
  maxAmount,
  checkForErrors,
  convertedAmount,
}) => {
  const ARSCurrency = { icon: ARGFlag, label: "ARS", symbol: "$" };

  const USDCurrency = { icon: USFlag, label: "US$", symbol: "US$" };

  const buyCurrency = isBuy ? ARSCurrency : USDCurrency;
  const sellCurrency = !isBuy ? ARSCurrency : USDCurrency;

  const onClickMax = () => {
    if (!maxAmount) return;

    if (maxAmount < 1) {
      setAmount("");
      return;
    }

    setAmount(String(maxAmount));
    checkForErrors(maxAmount);
  };

  return (
    <>
      <div className={styles.transcationWrapper}>
        <div className={styles.topBlock}>
          <div className={styles.inputWrapper}>
            <div className={styles.input}>
              <NumberInput
                value={amount}
                setValue={setAmount}
                onChange={(e) => checkForErrors(Number(e))}
                isSmall
              />
            </div>
            <CurrencySelect
              disabled
              icon={buyCurrency.icon}
              label={buyCurrency.label}
              className={styles.currencySelect}
            />
          </div>
          <div className={styles.maxWrapper}>
            <Text
              variant={TextVariants.RegularTextS}
              color="var(--slate800)"
              className={styles.available}
            >
              Disponible:
              <Amount
                size={AmountSizes.Small}
                amount={maxAmount}
                mainColor="var(--slate800)"
                secondaryColor="var(--slate800)"
                currency={isBuy ? Currencies.ARS : Currencies.USD}
              />
            </Text>
            <UseMaxButton
              isShort
              onClick={onClickMax}
              variant={ThemeVariants.Capital}
            />
          </div>
        </div>
        <div className={styles.bottomBlock}>
          <div className={styles.convertedAmount}>
            <Amount
              amount={isNaN(convertedAmount) ? 0 : convertedAmount}
              size={AmountSizes.Large}
              mainColor="var(--slate800)"
              secondaryColor="var(--slate800)"
            />
          </div>
          <CurrencySelect
            disabled
            icon={sellCurrency.icon}
            label={sellCurrency.label}
            className={styles.currencySelect}
          />
        </div>
      </div>
    </>
  );
};

export default Transaction;

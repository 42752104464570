import PhoneValidationPage from "components/page-phone-validation";
import { PhoneValidationProvider } from "context/PhoneValidationProvider";

const PhoneValidation: React.FC = () => (
  <PhoneValidationProvider>
    <PhoneValidationPage />
  </PhoneValidationProvider>
);

export default PhoneValidation;

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import CloseAccountModal from "components/common/CloseAccountPage";
import PageHeader from "components/common/PageHeader";
import DefaultSection from "components/page-crypto-profile/DefaultSection";
import { ArrowLeft, Moon, Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { useDarkModeMutation } from "apis/user/mutations/useDarkModeMutation";
import type { RootState } from "store/store";

import styles from "./styles.module.scss";

const ConfigSection: React.FC = () => {
  const navigate = useNavigate();
  const [isClosingAccount, setIsClosingAccount] = useState(false);
  const darkModeMutation = useDarkModeMutation();

  const dark_theme = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );
  const [darkMode, setDarkMode] = useState<boolean>(dark_theme || false);

  useEffect(() => {
    ampli.cryptoScreenSettings();
  }, []);

  const handleCloseAccount = () => {
    setIsClosingAccount(true);
  };

  const handleDarkMode = (darkFlag: boolean) => {
    darkModeMutation.mutate(darkFlag);

    darkFlag
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
    setDarkMode(darkFlag);
  };

  const configOptions = [
    {
      label: "Modo oscuro",
      icon: Moon,
      onClick: () => handleDarkMode(!darkMode),
      isToggle: true,
      toggleValue: darkMode,
    },
    {
      label: "Eliminar cuenta",
      icon: Trash2,
      onClick: handleCloseAccount,
    },
  ];

  if (isClosingAccount)
    return <CloseAccountModal closeModal={() => setIsClosingAccount(false)} />;

  return (
    <div className={styles.configSectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Configuración"
        onClick={() => navigate(-1)}
      />
      <DefaultSection title="Configuración" options={configOptions} />
    </div>
  );
};

export default ConfigSection;

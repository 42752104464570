import { useState } from "react";

import { ampli } from "ampli";
import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { CheckCheck, Copy, Info, Eye, EyeOff } from "lucide-react";
import px2rem from "utils/px2rem";
import { Clipboard } from "@capacitor/clipboard";

import styles from "./styles.module.scss";

type DetailRowProps = {
  label: string;
  value: string;
  variant?: ThemeVariants;
  withCopy?: boolean;
  withHide?: boolean;
  copyTrackingItem?: string;
} & ({ withInfo?: false } | { withInfo: true; onClickInfo: () => void });

const DetailRow: React.FC<DetailRowProps> = ({
  label,
  value,
  variant = ThemeVariants.Crypto,
  withCopy,
  withHide,
  copyTrackingItem = "",
  ...props
}) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const hasInfo = props.withInfo;
  const CopyIcon = copied ? CheckCheck : Copy;
  const EyeIcon = isHidden ? EyeOff : Eye;

  const onClickCopy = async () => {
    ampli.cclCryptoDataCopied({ item: copyTrackingItem });

    try {
      await Clipboard.write({ string: value });
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickHide = () => {
    setIsHidden(!isHidden);
  };

  return (
    <li className={styles.item}>
      <div className={styles.data}>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {label}
        </Text>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {withHide && isHidden ? "***" : value}
        </Text>
      </div>
      <div className={styles.iconsWrapper}>
        {hasInfo && (
          <Info
            onClick={props.onClickInfo}
            className={classNames(styles.infoIconColor, styles[variant])}
            size={px2rem(16)}
          />
        )}
        {withCopy && (
          <div className={classNames(styles.copyIcon, styles[variant])}>
            <CopyIcon
              size={px2rem(16)}
              onClick={onClickCopy}
              className={classNames(styles.copyIconColor, styles[variant])}
            />
          </div>
        )}
        {withHide && (
          <div className={classNames(styles.copyIcon, styles[variant])}>
            <EyeIcon
              size={px2rem(16)}
              onClick={onClickHide}
              className={classNames(styles.copyIconColor, styles[variant])}
            />
          </div>
        )}
      </div>
    </li>
  );
};

export default DetailRow;

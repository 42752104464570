import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export enum QuietButtonSizes {
  Xsmall = "xsmall",
  Small = "small",
  Default = "default",
}

interface QuietButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: string;
  variant?: ThemeVariants;
  size?: QuietButtonSizes;
}

const QuietButton: React.FC<QuietButtonProps> = ({
  size = QuietButtonSizes.Default,
  variant = ThemeVariants.Capital,
  color = "var(--slate900)",
  disabled,
  className,
  children,
  ...props
}) => (
  <button
    disabled={disabled}
    className={classNames(
      styles.button,
      className,
      styles[variant],
      styles[size],
      {
        [styles.disabled]: disabled,
      }
    )}
    {...props}
  >
    <Text variant={TextVariants.SemiboldTextS} color={color} component="span">
      {children}
    </Text>
  </button>
);

export default QuietButton;

import { CocosTariffURL } from "constants/index";

import Text, { TextVariants } from "components/common/Text";
import { Info } from "lucide-react";
import px2rem from "utils/px2rem";
import { Browser } from "@capacitor/browser";

import styles from "./styles.module.scss";

interface FeesProps {
  isFixed: boolean;
}

const Fees: React.FC<FeesProps> = ({ isFixed }) => {
  const feesText = isFixed
    ? "Esta operación no tiene comisiones"
    : "Hay comisiones según el";

  const onClickTariff = () => {
    Browser.open({ url: CocosTariffURL });
  };

  return (
    <div className={styles.feesWrapper}>
      <Info size={px2rem(16)} color="var(--blue800)" />
      <Text variant={TextVariants.SemiboldTextS} color="var(--blue900)">
        {feesText}
        {!isFixed && (
          <Text
            component="span"
            color="var(--blue800)"
            onClick={onClickTariff}
            className={styles.feesLink}
            variant={TextVariants.SemiboldTextS}
          >
            {" "}
            tarifario vigente
          </Text>
        )}
        .
      </Text>
    </div>
  );
};

export default Fees;

import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { Browser } from "@capacitor/browser";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";
import Limit from "assets/illustrations/CocosCard/Limit";
import { ThemeVariants } from "interfaces/theme";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";

import styles from "./styles.module.scss";

interface IncreaseLimitProps {
  open: boolean;
  onClose: () => void;
}

const IncreaseLimit: React.FC<IncreaseLimitProps> = ({ open, onClose }) => {
  const { isIOS } = getPlatform();

  const contactCompliance = () => {
    Browser.open({
      url: `mailto:${ATENCION_CAPITAL_EMAIL}`,
    });
  };

  const complianceLink = (
    <Text
      component="a"
      variant={TextVariants.SemiboldText}
      onClick={contactCompliance}
      className={styles.complianceLink}
    >
      {ATENCION_CAPITAL_EMAIL}
    </Text>
  );

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        Aumentar mi límite
      </Text>
      <div className={styles.sheetContent}>
        <Limit size={px2rem(96)} />
        <Text variant={TextVariants.RegularText} className={styles.text}>
          Escribinos a {complianceLink} para aumentar el límite de tu tarjeta.
        </Text>
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton variant={ThemeVariants.Pay} onClick={contactCompliance}>
          Contactanos
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={onClose}>
          En otro momento
        </OutlinedButton>
      </div>
    </UIModalSlider>
  );
};

export default IncreaseLimit;

import { useState, useEffect } from "react";

import API from "apis";
import { ampli } from "ampli";
import { BannersService, cryptoService } from "apis/services";
import { Banner } from "interfaces/api-responses";
import { ExternalAccountData } from "interfaces/crypto";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import Cable from "assets/images/Cable";
import QuietButton, { QuietButtonSizes } from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface CryptoCableProps {
  onClick: () => void;
  isCapital?: boolean;
}

const CryptoCableBanner: React.FC<CryptoCableProps> = ({
  onClick,
  isCapital,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bridgeAccountData, setBridgeAccountData] = useState<any>({});
  const [showCryptoCableBanner, setShowCryptoCableBanner] = useState<boolean>(
    false
  );

  useEffect(() => {
    const getCryptoCableBannerData = async () => {
      try {
        const { data } = await API.get<Banner>(
          `${BannersService.banners}/crypto_cable_welcome`
        );
        const { seen } = data;
        setShowCryptoCableBanner(!seen);
      } catch (error) {
        setShowCryptoCableBanner(false);
      }
    };

    getCryptoCableBannerData();
  }, []);

  useEffect(() => {
    if (showCryptoCableBanner) {
      setIsLoading(true);
      const getBridgeAccount = async () => {
        try {
          const { data } = await API.get<ExternalAccountData>(
            cryptoService.fiatExternalCustomer
          );
          setBridgeAccountData(data);
        } catch (error: any) {
          if (error?.response?.status === 404) {
            setBridgeAccountData(null);
          }
        } finally {
          setIsLoading(false);
        }
      };

      getBridgeAccount();
    }
  }, [showCryptoCableBanner]);

  const trackingEventClick = (action: string) => {
    ampli.cryptoBannerClicked({
      referral: isCapital ? "capital" : "crypto",
      tapped_button: action,
      type: "ccl",
    });
  };

  const onClickBanner = () => {
    trackingEventClick("open");
    onClick();
  };

  const hideBanner = async () => {
    trackingEventClick("dismiss");
    setIsHidden(true);

    await API.put<Banner>(`${BannersService.banners}/crypto_cable_welcome`, {
      seen: true,
    });
  };

  if (isCapital && isLoading) return <LoadingSpinner size={100} />;

  if (!showCryptoCableBanner || isHidden || bridgeAccountData) return null;

  return (
    <div
      className={classNames(styles.cryptoCableContainer, {
        [styles.marginTop]: isCapital,
      })}
    >
      <div className={styles.cryptoCable}>
        <div className={styles.text}>
          <Heading
            variant={HeadingVariants.RegularSubtitle}
            component="h2"
            color="var(--slate900)"
          >
            Traé tus dólares del exterior
          </Heading>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {isCapital ? "Recibilos al 1%" : "Recibilos en USDC al 1%"}
          </Text>
        </div>

        <div className={styles.buttonsWrapper}>
          <QuietButton
            variant={ThemeVariants.Crypto}
            size={QuietButtonSizes.Small}
            onClick={onClickBanner}
          >
            Conocé más
          </QuietButton>
          <Text
            variant={TextVariants.SemiboldTextS}
            color="var(--purple800)"
            onClick={hideBanner}
          >
            Ahora no
          </Text>
        </div>
        <div className={styles.cableImage}>
          <Cable />
        </div>
      </div>
    </div>
  );
};

export default CryptoCableBanner;

import { useEffect, useState } from "react";

import { AdviceIcon } from "assets/icons/advice";
import { Schedules } from "interfaces/calendar";
import { isEmpty } from "lodash";
import { RootState } from "store/store";
import IconButton from "@mui/material/IconButton";
import { CloseIcon } from "assets/icons/close";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface PropTypes {
  open: boolean;
  type: Schedules;
  className?: string;
}

const MarketClosedHeader = (props: PropTypes) => {
  const { open, type } = props;
  const { schedules } = useAppSelector((state: RootState) => state.markets);
  const openHours = schedules[type].openHours;
  const [openOnce, setOpenOnce] = useState<boolean>(open);

  const closedMessage = isEmpty(openHours)
    ? "En este momento el mercado no esta disponible para operar."
    : `Podés operar en días hábiles de ${openHours[0].startTime.substring(
        0,
        5
      )} a ${openHours[0].endTime.substring(0, 5)}.`;

  useEffect(() => {
    setOpenOnce(open);
  }, [open]);

  const closeModal = () => {
    setOpenOnce(false);
  };

  if (!openOnce) return null;

  return (
    <div className={classNames(styles.marketCloseContainer, props.className)}>
      <div className={styles.leftContainer}>
        <div className={styles.imgContainer}>
          <AdviceIcon size={24} />
        </div>
        <div className={styles.infoLabel}>
          <Typography>El mercado está cerrado.</Typography>
          &nbsp;
          <Typography>{closedMessage}</Typography>
        </div>
      </div>
      <IconButton className={styles.closeIcon} onClick={closeModal}>
        <CloseIcon size={16} />
      </IconButton>
    </div>
  );
};

export default MarketClosedHeader;

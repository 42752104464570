import { Network, getNetwork } from "constants/cryptos";

import AssetSelectCard from "components/common/AssetSelectCard";
import Text, { TextVariants } from "components/common/Text";
import { CryptoAvailableNetworks } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface SelectBottomSheetProps {
  networks: CryptoAvailableNetworks[];
  onClickNetwork: (v: Network) => void;
}

const SelectBottomSheet: React.FC<SelectBottomSheetProps> = ({
  networks,
  onClickNetwork,
}) => {
  return (
    <div className={styles.SelectSheetWrapper}>
      <Text
        color="var(--slate900)"
        className={styles.heading}
        variant={TextVariants.RegularText}
      >
        Seleccioná una red
      </Text>
      <div>
        <Text
          color="var(--slate800)"
          className={styles.text}
          variant={TextVariants.RegularText}
        >
          Redes disponibles
        </Text>
        <div className={styles.networkList}>
          {networks.map(({ name, id_network }) => {
            const network = getNetwork(name);
            return (
              <AssetSelectCard
                name={""}
                ticker={name}
                key={id_network}
                icon={network.icon}
                onClick={() => onClickNetwork(network)}
              />
            );
          })}
        </div>
        <Text
          color="var(--slate600)"
          className={styles.bottomText}
          variant={TextVariants.RegularTextS}
        >
          Corroborá que la dirección soporte la red que vas a utilizar para
          evitar que tus fondos se pierdan.
        </Text>
      </div>
    </div>
  );
};

export default SelectBottomSheet;

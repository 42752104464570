import { ButtonHTMLAttributes, DetailedHTMLProps, MouseEvent } from "react";

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import LoadingSpinner, {
  LoadingSpinnerVariants,
} from "components/common/LoadingSpinner";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export enum FilledButtonVariants {
  Warning = "Warning",
}

export interface FilledButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  variant?: ThemeVariants | FilledButtonVariants;
  isLoading?: boolean;
}

const FilledButton: React.FC<FilledButtonProps> = ({
  isLoading,
  variant = ThemeVariants.Capital,
  className,
  children,
  onClick,
  ...props
}) => {
  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isLoading || !onClick) return;

    onClick(e);
  };

  return (
    <button
      {...props}
      className={classNames(styles.buttonStyles, className, styles[variant], {
        [styles.isLoading]: isLoading,
      })}
      onClick={handleButtonClick}
    >
      {isLoading ? (
        <div className={styles.loaderWrapper}>
          <LoadingSpinner
            variant={LoadingSpinnerVariants.BUTTON}
            size={px2rem(80)}
          />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default FilledButton;

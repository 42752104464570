import { ThemeVariants } from "interfaces/theme";
import EmptyState from "components/common/EmptyState";
import FilledButton from "components/common/FilledButton";
import { Plus } from "lucide-react";

import styles from "./styles.module.scss";

interface NoAccountsProps {
  onClickButton: () => void;
}

const NoAccounts: React.FC<NoAccountsProps> = ({ onClickButton }) => (
  <div className={styles.emptyStateWrapper}>
    <EmptyState
      title="Tu agenda está vacía"
      subtitle='Hacé click en "Agendar una cuenta" para agregar una dirección nueva'
      icon={Plus}
    />
    <FilledButton
      className={styles.button}
      variant={ThemeVariants.Crypto}
      onClick={onClickButton}
    >
      Agendar una cuenta
    </FilledButton>
  </div>
);

export default NoAccounts;

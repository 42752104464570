import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";

const Lens: React.FC<IconProps> = ({ size = px2rem(98), ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 98 96"
    fill="none"
  >
    <g clipPath="url(#clip0_3612_46813)">
      <path
        d="M66.9414 56.9414L96.7499 86.7499C97.2366 87.2366 97.51 87.8967 97.51 88.5849C97.51 89.2731 97.2366 89.9332 96.75 90.4198L91.9326 95.2372C91.4459 95.7239 90.7859 95.9973 90.0976 95.9973C89.4094 95.9973 88.7493 95.7239 88.2627 95.2372L58.4541 65.4287L66.9414 56.9414Z"
        fill="#1C2024"
      />
      <path
        d="M38.5266 76.0552C59.5287 76.0552 76.5542 59.0296 76.5542 38.0276C76.5542 17.0255 59.5287 0 38.5266 0C17.5245 0 0.499023 17.0255 0.499023 38.0276C0.499023 59.0296 17.5245 76.0552 38.5266 76.0552Z"
        fill="#8B8D98"
      />
      <path
        d="M38.5264 67.7096C54.9192 67.7096 68.2081 54.4206 68.2081 38.0279C68.2081 21.6351 54.9192 8.34619 38.5264 8.34619C22.1337 8.34619 8.84473 21.6351 8.84473 38.0279C8.84473 54.4206 22.1337 67.7096 38.5264 67.7096Z"
        fill="url(#paint0_linear_3612_46813)"
      />
      <path
        d="M84.2495 91.2367L92.7375 82.7412"
        stroke="white"
        strokeWidth="1.29376"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3612_46813"
        x1="59.0322"
        y1="58.5336"
        x2="17.5645"
        y2="17.066"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.14" stopColor="#E6EAF4" />
        <stop offset="0.68" stopColor="#DADEE9" />
        <stop offset="1" stopColor="#D1D5E0" />
      </linearGradient>
      <clipPath id="clip0_3612_46813">
        <rect
          width="97.0021"
          height="96"
          fill="white"
          transform="translate(0.499023)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Lens;

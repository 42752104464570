import CryptoSendPage from "components/page-crypto-send";
import { CryptoSendProvider } from "context/CryptoSendProvider";

const Send: React.FC = () => (
  <CryptoSendProvider>
    <CryptoSendPage />
  </CryptoSendProvider>
);

export default Send;

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress } from "@mui/material";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const Spinner = () => {
  const classes = useStyles();

  return (
    <div className={`spinner ${classes.spinner}`}>
      <CircularProgress />
    </div>
  );
};

import QuietButton, { QuietButtonSizes } from "components/common/QuietButton";
import { Clipboard } from "@capacitor/clipboard";
import { Search } from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface SearchInputProps {
  searchValue: string;
  withPaste?: boolean;
  placeholder: string;
  onChange: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchValue,
  onChange,
  placeholder,
  withPaste = false,
}) => {
  const pasteText = async () => {
    try {
      const { value } = await Clipboard.read();
      onChange(value);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.searchInputWrapper}>
      <Search
        size={px2rem(16)}
        color="var(--slate800)"
        className={styles.searchIcon}
      />
      <input
        type="text"
        value={searchValue}
        className={styles.input}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
      {withPaste && (
        <QuietButton
          onClick={pasteText}
          className={styles.pasteButton}
          size={QuietButtonSizes.Xsmall}
          variant={ThemeVariants.Crypto}
        >
          Pegar
        </QuietButton>
      )}
    </div>
  );
};

export default SearchInput;

import { useDeviceCheck } from "../../hooks/useDeviceCheck";

export interface DeviceMediaQuery {
  minWidth?: number;
  maxWidth?: number;
}

export const DesktopDevice: DeviceMediaQuery = {
  minWidth: 992,
};

export const TabletDevice: DeviceMediaQuery = {
  minWidth: 851,
  maxWidth: 991,
};

export const MobileDevice: DeviceMediaQuery = {
  maxWidth: 850,
};

export const Desktop = ({ children }: any) => {
  const { isDesktop } = useDeviceCheck();

  return isDesktop ? children : null;
};

export const Tablet = ({ children }: any) => {
  const { isTablet } = useDeviceCheck();

  return isTablet ? children : null;
};

export const Mobile = ({ children }: any) => {
  const { isMobile } = useDeviceCheck();

  return isMobile ? children : null;
};
export const DesktopOrTablet = ({ children }: any) => {
  const { isDesktopOrTablet } = useDeviceCheck();

  return isDesktopOrTablet ? children : null;
};

import classNames from "classnames";

import styles from "./styles.module.scss";

export enum HeadingVariants {
  RegularTitle = "regularTitle",
  RegularSubtitle = "regularSubtitle",
  SemiboldSubtitle = "semiboldSubtitle",
  SemiboldValue = "semiboldValue",
  RegularValueS = "regularValueS",
  SemiboldValueS = "semiboldValueS",
}

interface HeadingProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  variant: HeadingVariants;
  component: "h1" | "h2" | "h3" | "span";
  className?: string;
  color?: string;
}

const Heading: React.FC<HeadingProps> = ({
  variant,
  component: Component,
  className,
  color,
  ...props
}) => {
  return (
    <Component
      className={classNames(styles.heading, styles[variant], className)}
      style={{ color: color }}
      {...props}
    />
  );
};

export default Heading;

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface TimeFrameTabsProps {
  selectedTime: number;
  onClickOption: (v: number) => void;
  timeOptions: { label: string; value: number }[];
}

const TimeFrameTabs: React.FC<TimeFrameTabsProps> = ({
  timeOptions,
  selectedTime,
  onClickOption,
}) => {
  return (
    <div className={styles.timeFrameWrapper}>
      {timeOptions.map(({ label, value }) => (
        <button
          className={classNames(styles.button, [
            { [styles.selectedButton]: selectedTime === value },
          ])}
          key={value}
          onClick={() => onClickOption(value)}
        >
          <Text
            color="var(--slate900)"
            variant={TextVariants.SemiboldUpperValueS}
          >
            {label}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default TimeFrameTabs;

import { Cryptos } from "constants/cryptos";

import { ThemeVariants } from "interfaces/theme";
import EmptyState from "components/common/EmptyState";
import FilledButton from "components/common/FilledButton";
import { Plus } from "lucide-react";

import styles from "./styles.module.scss";

interface NoAssetsProps {
  defaultTicker?: Cryptos | null;
  handleClickBuy: () => void;
}

const NoAssets: React.FC<NoAssetsProps> = ({
  defaultTicker,
  handleClickBuy,
}) => {
  const emptyStateTitle = defaultTicker
    ? `Comprá ${defaultTicker} para empezar`
    : "Agregá crypto para empezar";

  return (
    <div className={styles.empty}>
      <EmptyState
        title={emptyStateTitle}
        subtitle="Todas tus compras y transferencias crypto se mostrarán en tu portfolio"
        icon={Plus}
      />
      <FilledButton variant={ThemeVariants.Crypto} onClick={handleClickBuy}>
        {`Comprá ${defaultTicker || "crypto"}`}
      </FilledButton>
    </div>
  );
};

export default NoAssets;

import { DetailedHTMLProps, InputHTMLAttributes } from "react";

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface InputCheckboxProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  name: string;
  checked: boolean;
  rightLabel?: JSX.Element | string;
  onChange?: () => void;
  variant?: ThemeVariants;
}

const InputCheckbox = ({
  children,
  name,
  checked,
  rightLabel,
  onChange,
  variant = ThemeVariants.Capital,
  ...props
}: InputCheckboxProps) => {
  const inputStyles = classNames(styles[variant], {
    [styles.checkmark]: true,
    [styles.checkmarkSelected]: checked,
  });

  return (
    <div>
      <label className={styles.inputContainer} htmlFor={name}>
        {children}
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          onChange={onChange}
          {...props}
        />
        <span className={inputStyles} />
        {rightLabel}
      </label>
    </div>
  );
};

export default InputCheckbox;

import {
  CryptoPrivacyURL,
  CryptoTagTyCUrl,
  CryptoTermsAndConditionsURL,
} from "constants/index";

import { useEffect } from "react";

import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";
import PageHeader from "components/common/PageHeader";
import DefaultSection from "components/page-crypto-profile/DefaultSection";
import { ArrowLeft, BookCheck, BookLock } from "lucide-react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const LegalsSection: React.FC = () => {
  const navigate = useNavigate();
  const legalOptions = [
    {
      label: "Términos y condiciones",
      icon: BookCheck,
      onClick: () => Browser.open({ url: CryptoTermsAndConditionsURL }),
    },
    {
      label: "Términos y condiciones cocostag",
      icon: BookCheck,
      onClick: () => Browser.open({ url: CryptoTagTyCUrl }),
    },
    {
      label: "Políticas de privacidad",
      icon: BookLock,
      onClick: () => Browser.open({ url: CryptoPrivacyURL }),
    },
  ];

  useEffect(() => {
    ampli.cryptoScreenLegal();
  }, []);

  return (
    <div className={styles.legalsSectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Legales"
        onClick={() => navigate(-1)}
      />
      <DefaultSection title="Legales" options={legalOptions} />
    </div>
  );
};

export default LegalsSection;

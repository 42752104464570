import { CryptoCablePrivacyURL } from "constants/index";

import { useEffect, useState } from "react";

import { Browser } from "@capacitor/browser";
import API from "apis";
import { cryptoService } from "apis/services";
import { ampli } from "ampli";
import { DollarSign } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import InputCheckbox from "components/common/input/InputCheckbox";
import { CryptoRoutes } from "router/routes";
import { ThemeVariants } from "interfaces/theme";

import CreateAccount from "../CreateAccount";
import LoadingScreen from "../Loading";

enum PurchaseConditionStatus {
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  SUFFICIENT_FUNDS = "FUNDS_AVAILABLE",
}
interface FiatExternalCustomerPurchaseResponse {
  status: PurchaseConditionStatus;
  ticker: string | null;
}

const Paywall: React.FC = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    purcharseConditions,
    setPurcharseConditions,
  ] = useState<FiatExternalCustomerPurchaseResponse>();
  const [displayBridgeTos, setDisplayBridgeTos] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const userHasFunds =
    purcharseConditions?.status === PurchaseConditionStatus.SUFFICIENT_FUNDS;

  useEffect(() => {
    const getPurchase = async () => {
      setIsLoading(true);
      try {
        const { data } = await API.get<FiatExternalCustomerPurchaseResponse>(
          cryptoService.fiatExternalCustomerPurchase
        );
        setPurcharseConditions(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getPurchase();
  }, []);

  useEffect(() => {
    if (purcharseConditions) {
      ampli.cclScreenPaywall({
        is_button_active: userHasFunds,
      });
    }
  }, [userHasFunds]);

  if (isLoading || !purcharseConditions) return <LoadingScreen />;

  if (displayBridgeTos) {
    return <CreateAccount />;
  }

  const currencyToRedeem = userHasFunds
    ? purcharseConditions.ticker
    : "USDC/USDT/DAI";

  const onClickContinue = () => {
    ampli.cclCryptoPaywallAccepted();
    setDisplayBridgeTos(true);
  };

  const onClose = () => {
    ampli.cclCryptoExit({ referral: "paywall" });
    return navigate(CryptoRoutes.HOME);
  };

  const onClickLegals = () => {
    Browser.open({ url: CryptoCablePrivacyURL });
  };

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Crypto}
      navigationHeaderProps={{
        title: "Antes de continuar",
        onClick: onClose,
      }}
      title="La apertura de tu cuenta tiene un costo por única vez de 10 US$."
      subtitle={`Debemos realizar un cobro único equivalente a 10 US$ en Crypto Dólar (${currencyToRedeem}) que deberás tener disponible en tu cuenta. ¿Estás de acuerdo?`}
      icon={DollarSign}
      primaryButtonProps={{
        children: "Sí, estoy de acuerdo",
        onClick: onClickContinue,
        disabled: !userHasFunds || !termsAccepted,
      }}
      secondaryButtonProps={{
        children: "En otro momento",
        onClick: onClose,
      }}
    >
      {userHasFunds ? (
        <InputCheckbox
          name="terms"
          checked={termsAccepted}
          onChange={() => setTermsAccepted(!termsAccepted)}
          variant={ThemeVariants.Crypto}
        >
          Acepto los{" "}
          <Text
            component="span"
            color="var(--purple800)"
            variant={TextVariants.SemiboldTextS}
            onClick={() => onClickLegals()}
          >
            Términos y Condiciones
          </Text>
          .
        </InputCheckbox>
      ) : (
        <Text variant={TextVariants.RegularTextS} color="var(--red800)">
          Para realizar esta operación deberás tener disponible 10 USDC, USDT o
          DAI.
        </Text>
      )}
    </BasicWrapper>
  );
};

export default Paywall;

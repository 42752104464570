import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface RightModalState {
  open: boolean;
  onClose?: () => void;
  withCloseButton: boolean;
}

export const initialState: RightModalState = {
  open: false,
  onClose: undefined,
  withCloseButton: true,
};

const rightModalSlice = createSlice({
  name: "rightModal",
  initialState,
  reducers: {
    openRightModal(
      state,
      action: PayloadAction<
        | {
            onClose?: () => void;
            withCloseButton?: boolean;
          }
        | undefined
      >
    ) {
      state.open = true;
      if (action.payload) {
        state.onClose = action.payload.onClose;
        state.withCloseButton = action.payload.withCloseButton || false;
      }
    },
    closeRightModal(state) {
      state.open = false;
      state.withCloseButton = true;
    },
  },
});

export const { openRightModal, closeRightModal } = rightModalSlice.actions;

export default rightModalSlice.reducer;

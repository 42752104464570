import { Cryptos, getCrypto } from "constants/cryptos";

import ARGFlag from "assets/icons/ARGFlag";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency } from "utils";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface DetailsHeaderProps {
  ticker: Cryptos | Currencies.ARS;
  amount: number;
}

const DetailsHeader: React.FC<DetailsHeaderProps> = ({ ticker, amount }) => {
  const Icon = ticker === Currencies.ARS ? ARGFlag : getCrypto(ticker).icon;

  const decimal = amount >= 0.05 ? 2 : 6;

  const amountFormatted = formatToCurrency(amount, false, false, decimal);

  return (
    <div className={styles.container}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        Cantidad a enviar
      </Text>
      <div className={styles.iconsContainer}>
        <Icon size={px2rem(32)} />
      </div>
      <Heading
        component={"h1"}
        variant={HeadingVariants.RegularTitle}
        className={styles.amount}
        color="var(--slate900)"
      >
        {amountFormatted} <span>{ticker}</span>
      </Heading>
    </div>
  );
};

export default DetailsHeader;

import { useEffect, useState } from "react";

import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import ActiveSection from "components/common/MFA/ActiveSection";
import px2rem from "utils/px2rem";
import { supabase } from "utils/supabase";

import TwoFAInactive from "./inactiveSection";

import styles from "./styles.module.scss";

const Cocos2FAPage: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.mfa.listFactors();

      if (error) {
        setError(true);
        return;
      }

      const factors = data.all.filter((x) => x.status === "verified");
      setIsActive(factors.length > 0);
      setLoading(false);
    })();
  }, []);

  if (loading)
    return (
      <div className={styles.loading}>
        <LoadingSpinner size={px2rem(100)} />
      </div>
    );

  if (error)
    return (
      <ErrorPage errorMessage="En este momento no es posible mostrar esta página." />
    );

  if (isActive) return <ActiveSection />;

  return <TwoFAInactive />;
};

export default Cocos2FAPage;

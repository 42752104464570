import { ShoppingBag } from "lucide-react";
import px2rem from "utils/px2rem";
import { CardTransactionStatus } from "interfaces/card/enums";

import ActivityBadge from "../ActivityBadge";

import styles from "./styles.module.scss";

interface ActivityIconProps {
  transactionStatus: CardTransactionStatus;
}

const ActivityIcon: React.FC<ActivityIconProps> = ({ transactionStatus }) => {
  return (
    <div className={styles.activityIconContainer}>
      <div className={styles.icon}>
        <ShoppingBag color="var(--black-to-white)" size={px2rem(16)} />
      </div>
      <ActivityBadge transactionStatus={transactionStatus} />
    </div>
  );
};

export default ActivityIcon;

import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencySymbol } from "utils";
import classNames from "classnames";

import styles from "./styles.module.scss";

export enum AmountSizes {
  Small,
  Default,
  Large,
}

interface AmountProps {
  amount: number;
  size?: AmountSizes;
  mainColor?: string;
  currency?: Currencies;
  withDecimals?: boolean;
  isSmallCurrency?: boolean;
  secondaryColor?: string;
}

const Amount: React.FC<AmountProps> = ({
  amount,
  currency,
  withDecimals = true,
  size = AmountSizes.Default,
  mainColor = "var(--slate900)",
  isSmallCurrency = false,
  secondaryColor = "var(--slate800)",
}) => {
  const absAmount = Math.abs(amount);

  const splittedPrice = (formatToCurrency(absAmount) || "0,00").split(",");

  const integerPart = splittedPrice[0];

  const decimalPart = splittedPrice[1];

  const isNegative = amount < 0;

  const isSmall = size === AmountSizes.Small;
  const isLarge = size === AmountSizes.Large;

  const getSymbolVariant = () => {
    if (isSmall) return TextVariants.RegularTextS;
    if (isLarge) return TextVariants.SemiboldTextL;
    return TextVariants.RegularUpperText;
  };

  const getIntegerVariant = () => {
    if (isSmall) return TextVariants.SemiboldTextS;
    if (isLarge) return TextVariants.SemiboldTextXL;
    return TextVariants.SemiboldUpperValue;
  };

  const getDecimalVariant = () => {
    if (isSmall) return TextVariants.SemiboldValueXS;
    if (isLarge) return TextVariants.SemiboldText;
    return TextVariants.SemiboldTextS;
  };

  return (
    <Text
      variant={getIntegerVariant()}
      color={mainColor}
      className={styles.wrapper}
    >
      {currency && (
        <Text
          color={secondaryColor}
          className={classNames(styles.currency, {
            [styles.isSmallCurrency]: isSmallCurrency,
          })}
          variant={getSymbolVariant()}
        >
          {isNegative && "–"}
          {getCurrencySymbol(currency)}{" "}
        </Text>
      )}
      {integerPart}
      {withDecimals && (
        <>
          ,
          <Text variant={getDecimalVariant()} color={mainColor}>
            {decimalPart}
          </Text>
        </>
      )}
    </Text>
  );
};

export default Amount;

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/es-mx";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");

export const getTransactionGroupName = (date: string) => {
  const formattedDate = dayjs(date).locale("es-mx").format("MMMM YYYY");

  return formattedDate;
};

import { Cryptos, getCrypto } from "constants/cryptos";

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface FilterChipProps {
  crypto: Cryptos;
  onClick: (ticker: Cryptos) => void;
  selected?: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({
  crypto,
  onClick,
  selected,
}) => {
  const { icon: Icon, ticker } = getCrypto(crypto);

  const textColor = selected ? "var(--background-1)" : "var(--slate900)";

  return (
    <div
      className={classNames(styles.chipWrapper, {
        [styles.selected]: selected,
      })}
      onClick={() => onClick(ticker)}
    >
      <Icon size={px2rem(24)} />
      <Text variant={TextVariants.SemiboldUpperValueS} color={textColor}>
        {ticker}
      </Text>
    </div>
  );
};

export default FilterChip;

import { CRYPTOS, Cryptos } from "constants/cryptos";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { useLocation } from "react-router-dom";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import AssetsPage from "components/common/Assets";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Transaction from "./Transaction";

import styles from "./styles.module.scss";

export interface SwapLocationState {
  defaultTicker: Cryptos;
  isBuying?: boolean;
}

const CryptoSwapPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const locationState = useLocation().state as SwapLocationState | null;
  const [selectedCrypto, setSelectedCrypto] = useState<Cryptos | undefined>(
    locationState?.defaultTicker
  );

  const previousScreen = useAppSelector(getCurrentScreen);

  const shouldShowSelectAsset =
    !locationState?.defaultTicker && locationState?.isBuying && !selectedCrypto;

  useEffect(() => {
    ampli.cryptoScreenOperations({
      previous_screen: previousScreen,
      tapped_button: locationState?.isBuying ? "buy" : "swap",
    });

    dispatch(setCurrentScreen(cryptoScreenNames.swap));
  }, []);

  if (shouldShowSelectAsset)
    return (
      <AssetsPage
        assets={CRYPTOS}
        onClick={(crypto) => setSelectedCrypto(crypto.ticker)}
      />
    );

  return (
    <div className={styles.swapPageWrapper}>
      <div className={styles.contentWrapper}>
        <Transaction
          selectedCrypto={selectedCrypto}
          isArsDefault={locationState?.isBuying}
        />
      </div>
    </div>
  );
};

export default CryptoSwapPage;

import { getCrypto } from "constants/cryptos";

import AssetAllocation from "components/page-crypto-allocation/AssetAllocation";
import { CryptoHolding } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface AllocationListProps {
  holdings: CryptoHolding[];
}

const AllocationList: React.FC<AllocationListProps> = ({ holdings }) => {
  const total = holdings.reduce((sum, item) => sum + item.amountArs, 0);
  holdings.sort((a, b) => b.amountArs - a.amountArs);

  return (
    <div className={styles.allocationListContainer}>
      {holdings.map(({ quantity, amountArs, ticker }) => {
        const { name, icon, ticker: cryptoTicker } = getCrypto(ticker);
        const hold = Math.round(amountArs * 1000);
        const newTotal = Math.round(total * 1000);
        const percentage = hold / newTotal;

        return (
          <AssetAllocation
            key={ticker}
            assetName={name}
            icon={icon}
            assetAmount={quantity}
            assetTicker={cryptoTicker}
            amount={amountArs}
            percentage={percentage}
          />
        );
      })}
    </div>
  );
};

export default AllocationList;

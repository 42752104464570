import * as braze from "@braze/web-sdk";
import { FeatureFlag, InitializationOptions } from "@braze/web-sdk";

import { BrazeInterface } from "./types";

export class BrazeWebClient implements BrazeInterface {
  changeUser(userId: string): void {
    braze.changeUser(userId);
  }

  getDeviceId(): string | undefined {
    return braze.getDeviceId();
  }

  getFeatureFlag(id: string): Promise<FeatureFlag | null | undefined> {
    return Promise.resolve(braze.getFeatureFlag(id));
  }

  initialize(apiKey: string, options: InitializationOptions): boolean {
    return braze.initialize(apiKey, options);
  }

  logCustomEvent(eventName: string, eventProperties?: object): boolean {
    return braze.logCustomEvent(eventName, eventProperties);
  }

  logFeatureFlagImpression(featureFlagId: string): boolean | undefined {
    return braze.logFeatureFlagImpression(featureFlagId);
  }

  openSession(): void {
    braze.openSession();
  }

  setEmail(email: string): boolean {
    const user = braze.getUser();

    if (!user) {
      return false;
    }

    return user.setEmail(email);
  }

  subscribeToFeatureFlagsUpdates(
    subscriber: (featureFlags: FeatureFlag[]) => void
  ): string | undefined {
    return braze.subscribeToFeatureFlagsUpdates(subscriber);
  }
}

import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { X, ArrowLeft } from "lucide-react";
import TextBadge from "components/common/Badges/TextBadge";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

export interface NavigationHeaderProps {
  title?: string;
  badgeText?: string;
  onClick: () => void;
  iconColor?: string;
  titleColor?: string;
  withCloseIcon?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rightIconClick?: () => void;
  variant?: ThemeVariants;
}

const NavigationHeader = ({
  title,
  onClick,
  badgeText,
  iconColor,
  titleColor = "var(--slate800)",
  withCloseIcon = true,
  variant = ThemeVariants.Capital,
  leftIcon = (
    <ArrowLeft
      color={iconColor}
      className={classNames(styles.icon, styles[variant])}
    />
  ),
  rightIcon,
  rightIconClick,
}: NavigationHeaderProps) => {
  const icon = withCloseIcon ? (
    <X color={iconColor} className={classNames(styles.icon, styles[variant])} />
  ) : (
    leftIcon
  );

  return (
    <div className={styles.navigationHeaderContainer}>
      <button onClick={onClick}>{icon}</button>
      <div className={styles.titleWrapper}>
        {title && (
          <Text variant={TextVariants.RegularText} color={titleColor}>
            {title}
          </Text>
        )}
        {badgeText && variant && (
          <TextBadge text={badgeText} variant={variant} />
        )}
      </div>
      {rightIcon && (
        <div className={styles.rightIcon} onClick={rightIconClick}>
          {rightIcon}
        </div>
      )}
    </div>
  );
};

export default NavigationHeader;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "apis";
import { calculatorService } from "apis/services";
import { ErrorMessage } from "interfaces/errors";
import { BondData, FlowAnalysis } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";

export interface AnalysisData {
  bond: string;
  price: number;
  expiration_date: string;
  quantity: number;
  logo: string;
}

export interface CalculatorState {
  bondsList: BondData[];
  loading: boolean;
  errorMessage: ErrorMessage;
  bond: any;
  analisis: FlowAnalysis;
  check: boolean;
}

export const initialState: CalculatorState = {
  bondsList: [],
  loading: false,
  errorMessage: {
    message: "",
    status: null,
  },
  bond: {},
  analisis: {
    bond: "",
    price: 0,
    quantity: 0,
    expiration_date: "",
    logo_file_name: "",
    duration: 0,
    tir: 0,
    flow: [
      {
        name: "",
        date: "",
        amort: 0,
        vr: 0,
        capital: 0,
        cashFlow: 0,
        acumulated: 0,
        base_days: 0,
        id_currency: Currencies.USD,
        int: 0,
        interes: 0,
        vp: 0,
        nvp: 0,
      },
      {
        name: "",
        date: "",
        amort: 0,
        vr: 0,
        capital: 0,
        cashFlow: 0,
        acumulated: 0,
        base_days: 0,
        id_currency: Currencies.USD,
        int: 0,
        interes: 0,
        vp: 0,
        nvp: 0,
      },
    ],
  },
  check: false,
};

export const checkFlow = createAsyncThunk(
  "calculator/checkFlow",
  async ({
    instrument_code,
    invest,
  }: {
    instrument_code: string;
    invest: boolean;
  }) => {
    try {
      const { data } = await API.get<BondData[]>(
        calculatorService.gotFlowList,
        {
          params: { code: instrument_code, invest },
        }
      );

      return data;
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        return error.message;
      }
    }
  }
);

export const getAnalysis = createAsyncThunk(
  "calculator/getAnalysis",
  async (data: AnalysisData) => {
    try {
      const resp = await API.post<any>(calculatorService.analysis, {
        bond: data.bond,
        price: data.price,
        expiration_date: data.expiration_date,
        quantity: data.quantity,
        logo: data.logo,
      });
      return resp.data;
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        return {
          message: error?.response?.data?.Detalle
            ? error.response.data.Detalle.toString()
            : error.toString(),
          status: error?.response ? error.response.status : "500",
        };
      }
    }
  }
);

const calculatorSlice = createSlice({
  name: "calculator",
  initialState,
  reducers: {
    resetGotFlow(state) {
      state.analisis.flow = [];
    },
    resetAnalysis(state) {
      state.analisis = { ...initialState.analisis };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkFlow.fulfilled, (state, action: PayloadAction<boolean>) => {
        state.check = action.payload;
        state.loading = false;
      })
      .addCase(checkFlow.rejected, (state, action: PayloadAction<any>) => {
        state.errorMessage = action.payload;
        state.loading = false;
      })
      .addCase(getAnalysis.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAnalysis.fulfilled, (state, action: PayloadAction<any>) => {
        state.analisis = action.payload;
        state.loading = false;
      })
      .addCase(getAnalysis.rejected, (state, action: PayloadAction<any>) => {
        state.errorMessage = {
          message: action.payload.message,
          status: action.payload.status,
        };
        state.loading = false;
      });
  },
});

export const { resetGotFlow, resetAnalysis } = calculatorSlice.actions;

export default calculatorSlice.reducer;

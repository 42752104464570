import { SVGProps } from "react";

export interface IconProps extends SVGProps<SVGSVGElement> {
  size?: number | string;
}

const BTCIcon: React.FC<IconProps> = ({ size = 24, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M23.6409 14.9045C22.0381 21.3339 15.5262 25.2467 9.09606 23.6434C2.66863 22.0405 -1.24415 15.5282 0.359423 9.0994C1.96159 2.66933 8.47348 -1.24375 14.9016 0.359122C21.3313 1.96199 25.2438 8.47501 23.6408 14.9047L23.6409 14.9045H23.6409Z"
        fill="#F7931A"
      />
      <path
        d="M17.2921 10.2915C17.5309 8.69433 16.315 7.83582 14.6523 7.26311L15.1917 5.09967L13.8747 4.77152L13.3496 6.878C13.0034 6.79165 12.6479 6.71028 12.2945 6.62962L12.8234 4.50924L11.5073 4.18109L10.9676 6.34383C10.6811 6.27859 10.3997 6.21412 10.1267 6.14619L10.1282 6.13939L8.31209 5.68588L7.96177 7.09246C7.96177 7.09246 8.93883 7.31643 8.91824 7.33022C9.45153 7.46332 9.54797 7.81635 9.53196 8.09616L8.91754 10.5608C8.95426 10.5702 9.00189 10.5836 9.05445 10.6048C9.01052 10.5939 8.96376 10.5819 8.91525 10.5703L8.05404 14.023C7.98887 14.185 7.82344 14.4281 7.45059 14.3358C7.46379 14.3549 6.49341 14.0969 6.49341 14.0969L5.83957 15.6044L7.55336 16.0317C7.87219 16.1116 8.18462 16.1953 8.4923 16.2739L7.94734 18.4622L9.26276 18.7903L9.80245 16.6253C10.1618 16.7229 10.5105 16.8129 10.852 16.8977L10.3141 19.0525L11.6311 19.3807L12.176 17.1965C14.4217 17.6215 16.1103 17.4502 16.821 15.4189C17.3937 13.7836 16.7925 12.8403 15.611 12.2252C16.4716 12.0267 17.1197 11.4608 17.2925 10.2916L17.2921 10.2913L17.2921 10.2915ZM14.2831 14.5107C13.8761 16.1461 11.1227 15.2621 10.23 15.0404L10.9531 12.1413C11.8458 12.3642 14.7085 12.8052 14.2832 14.5107H14.2831ZM14.6904 10.2678C14.3191 11.7553 12.0274 10.9996 11.284 10.8142L11.9397 8.18498C12.6831 8.37029 15.0772 8.71616 14.6905 10.2678H14.6904Z"
        fill="white"
      />
    </svg>
  );
};

export default BTCIcon;

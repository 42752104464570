import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { Currencies } from "interfaces/wallet";
import Amount, { AmountSizes } from "components/common/Amount";

import styles from "./styles.module.scss";

export enum CurrencySizes {
  DEFAULT = "default",
  SMALL = "small",
}

interface CurrencyProps {
  amount: number;
  ticker?: string | Currencies;
  withoutDecimals?: boolean;
  decimal?: number;
  className?: string;
  size?: CurrencySizes;
  isRefund?: boolean;
}

const Currency: React.FC<CurrencyProps> = ({
  amount,
  className = "currency",
  size = CurrencySizes.DEFAULT,
  isRefund = false,
}) => {
  const defaultSize = size === CurrencySizes.DEFAULT;
  const slate900 = "var(--slate900)";
  const green800 = "var(--green800)";
  const slate800 = "var(--slate800)";

  const variant = defaultSize
    ? TextVariants.SemiboldUpperValue
    : TextVariants.SemiboldUpperValueS;

  return (
    <>
      <Text
        className={classNames(styles.currency, className)}
        variant={variant}
        color={isRefund ? green800 : slate900}
      >
        {isRefund ? (
          <span style={{ color: green800 }}>+</span>
        ) : (
          <span style={{ color: slate800 }}>-</span>
        )}
      </Text>
      <Amount
        amount={amount}
        currency={Currencies.ARS}
        mainColor={isRefund ? green800 : slate900}
        secondaryColor={isRefund ? green800 : slate800}
        size={defaultSize ? AmountSizes.Default : AmountSizes.Small}
        isSmallCurrency
      />
    </>
  );
};

export default Currency;

import { CryptoPrivacyURL, CryptoTagTyCUrl, CDN_URL } from "constants/index";

import { Browser } from "@capacitor/browser";
import { ThemeVariants } from "interfaces/theme";
import BulletList from "components/common/BulletList";
import FilledButton from "components/common/FilledButton";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { DollarSign, User2, Zap } from "lucide-react";
import { useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router";
import { CryptoRoutes } from "router/routes";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";

import Tag from "./components/Tag";

import styles from "./styles.module.scss";

interface CocosTagBottomSheetProps {
  open: boolean;
  onClose: () => void;
}

const CocosTagBottomSheet: React.FC<CocosTagBottomSheetProps> = ({
  open,
  onClose,
}) => {
  const navigate = useNavigate();

  const user = useAppSelector(getLoggedUser);

  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  if (user?.tag) return null;

  const getBackgroundImage = () => {
    if (darkMode) {
      return `${CDN_URL}/reactions/intro-background-dark.png`;
    }
    return `${CDN_URL}/reactions/intro-background.png`;
  };

  const handleOnClick = () => {
    navigate(CryptoRoutes.TAG);
  };

  const onClickLegals = (url: string) => {
    Browser.open({ url });
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <div className={styles.CocosTagBottomSheetContainer}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          Cocostag
        </Text>
        <div className={styles.wrapper}>
          <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
            Decile chau a las direcciones de red.
          </Text>
          <Text variant={TextVariants.RegularTextL} color="var(--slate800)">
            Enviar y recibir crypto nunca fue tan fácil.
          </Text>
        </div>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${getBackgroundImage()})`,
          }}
        >
          <Tag />
        </div>
        <BulletList
          bullets={[
            { icon: DollarSign, text: "Enviá y recibí sin costos" },
            { icon: User2, text: "A contactos de Cocos Crypto" },
            { icon: Zap, text: "Rápido, fácil y seguro" },
          ]}
        />

        <div className={styles.buttonWrapper}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate600)">
            Al continuar, acepto los{" "}
            <Text
              component="span"
              color="var(--purple800)"
              variant={TextVariants.SemiboldTextS}
              onClick={() => onClickLegals(CryptoTagTyCUrl)}
            >
              Términos de Condiciones
            </Text>{" "}
            y doy mi consentimiento a la{" "}
            <Text
              component="span"
              color="var(--purple800)"
              variant={TextVariants.SemiboldTextS}
              onClick={() => onClickLegals(CryptoPrivacyURL)}
            >
              Política de Privacidad
            </Text>
            .
          </Text>
          <FilledButton onClick={handleOnClick} variant={ThemeVariants.Crypto}>
            Continuar
          </FilledButton>
        </div>
      </div>
    </UIModalSlider>
  );
};

export default CocosTagBottomSheet;

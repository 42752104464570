export enum Operations {
  SWAP = "SWAP",
  SEND = "SEND",
  RECEIVE = "RECEIVE",
  BUY = "BUY",
  SELL = "SELL",
  SETTLEMENT = "SETTLEMENT",
}

export const operationsLabels = {
  [Operations.SWAP]: "Convertiste",
  [Operations.SEND]: "Enviaste",
  [Operations.RECEIVE]: "Recibiste",
  [Operations.BUY]: "Compraste",
  [Operations.SELL]: "Vendiste",
  [Operations.SETTLEMENT]: "Vencimiento",
};

import { BiometryType } from "@capgo/capacitor-native-biometric";

export const biometryServer = "www.cocos.capital";

export const faceIdBiometryTypes = [
  BiometryType.FACE_ID,
  BiometryType.FACE_AUTHENTICATION,
  BiometryType.IRIS_AUTHENTICATION,
  BiometryType.FACE_ID,
];
export const touchIdBiometryTypes = [
  BiometryType.TOUCH_ID,
  BiometryType.FINGERPRINT,
  BiometryType.MULTIPLE,
];

export const oldCapacitorBiometryPreferencesKey = "newSignInWithBiometrics";
export const capacitorBiometryPreferencesKey = "signin_biometry_enabled";

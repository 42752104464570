import Text, { TextVariants } from "components/common/Text";
import LoadingSpinner from "components/common/LoadingSpinner";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const LoaderAndRedirect = () => (
  <div className={styles.RedirectToGeneratePasswordWrapper}>
    <div className={styles.textContainer}>
      <LoadingSpinner size={px2rem(140)} />
      <Text
        variant={TextVariants.RegularTextXL}
        color="var(--slate900)"
        className={styles.textContainerHeading}
      >
        Estamos validando tu mail...
      </Text>
      <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
        Por favor, esperá unos segundos.
      </Text>
    </div>
  </div>
);

export default LoaderAndRedirect;

import { COPY_MESSAGE_TIME } from "constants/index";

import { useEffect, useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import API from "apis";
import { transfersService } from "apis/services";
import classNames from "classnames";
import BankAccountCard from "components/common/BankAccountCard";
import Banner, { BannerVariants } from "components/common/Banner";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import OutlinedButton from "components/common/OutlinedButton";
import PageHeader from "components/common/PageHeader";
import Toast, { ToastVariants } from "components/common/Toast";
import VerticalSteps from "components/common/VerticalSteps";
import { BankAccountDataResponse } from "interfaces/api-responses";
import { Copy, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

const ReceiveArsPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [bankData, setBankData] = useState<BankAccountDataResponse>();

  const navigate = useNavigate();
  const { isIOS } = getPlatform();

  const copyCVU = async () => {
    try {
      await Clipboard.write({ string: bankData?.cvu });
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, COPY_MESSAGE_TIME);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const getBankInfo = async () => {
      try {
        setIsLoading(true);
        const { data } = await API.get<BankAccountDataResponse>(
          transfersService.depositAccounts + "?currency=ARS"
        );
        setBankData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getBankInfo();
  }, []);

  if (isLoading || !bankData)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(200)} />
      </div>
    );

  return (
    <div className={styles.pageWrapper}>
      <PageHeader
        icon={X}
        title="Ingresá ARS"
        onClick={() => navigate(CryptoRoutes.HOME)}
        onClickSecondaryIcon={() => navigate(mainRoutes.chat)}
      />
      <Banner
        className={styles.banner}
        variant={BannerVariants.INFO_CRYPTO}
        text="Únicamente podes recibir dinero de cuentas que estén a tu nombre. "
      />
      <BankAccountCard
        items={[
          { label: "CVU", value: bankData.cvu, isBold: true },
          { label: "CUIL", value: bankData.cuit },
        ]}
        button={{
          icon: Copy,
          onClick: copyCVU,
          text: "Copiar CVU",
          variant: ThemeVariants.Crypto,
        }}
      />
      <Heading
        component="h3"
        color="var(--slate800)"
        className={styles.title}
        variant={HeadingVariants.RegularTitle}
      >
        Cómo ingresar pesos
      </Heading>
      <VerticalSteps
        steps={[
          {
            label: "Copiá el CVU",
            value: "Corroborá que sea el correcto al transferir.",
          },
          {
            label: "Realizá la transferencia",
            value: "Usá ese CVU como destinatario.",
          },
          {
            label: "¡Listo!",
            value: "El dinero se acreditará de manera automática.",
          },
        ]}
        className={styles.verticalSteps}
        variant={ThemeVariants.Crypto}
      />
      <OutlinedButton
        variant={ThemeVariants.Crypto}
        onClick={() => navigate(CryptoRoutes.HOME)}
        className={classNames(styles.button, { [styles.ios]: isIOS })}
      >
        Volver al inicio
      </OutlinedButton>
      {copied && (
        <Toast
          variant={ToastVariants.Success}
          onClose={() => setCopied(false)}
          message="CVU copiado exitosamente"
        />
      )}
    </div>
  );
};

export default ReceiveArsPage;

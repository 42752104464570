import { useState, useEffect } from "react";

import { useSearchParams } from "react-router-dom";
import API from "apis";
import { cryptoService } from "apis/services";

import ActivityDetailSheet from "./ActivityDetailSheet";

const ActivityDetail: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [detailData, setDetailData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const activityId = searchParams.get("id");

  useEffect(() => {
    if (!activityId) return;

    const getActivityDetailData = async () => {
      try {
        setIsLoading(true);
        const { data } = await API.get<any>(
          `${cryptoService.activityDetail}/${activityId}/details`
        );

        setDetailData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    getActivityDetailData();
  }, [activityId]);

  return <ActivityDetailSheet {...detailData} isLoading={isLoading} />;
};

export default ActivityDetail;

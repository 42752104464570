import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { CapacitorUpdater } from "@capgo/capacitor-updater";
import API from "apis";
import { screenNames } from "router/routes";

import { trackAction } from "./amplitude";

interface LastVersionResponse {
  version: string;
  downloadUrl: string;
}

async function checkVersionAndUpdate(setIsOpen: (v: boolean) => void) {
  if (import.meta.env.VITE_UPDATE) return;
  const currentVersion = (await CapacitorUpdater.current()).bundle.version;
  const { data: latestRelease } = await API.get<LastVersionResponse>(
    "/v1/app/releases/latest"
  );

  if (latestRelease.version === currentVersion) return;

  const trackProps = {
    currentVersion: currentVersion,
    latestVersion: latestRelease.version,
    url: latestRelease.downloadUrl,
  };

  try {
    setIsOpen(true);
    trackAction(
      `${screenNames.updater} - Start new version download`,
      trackProps
    );
    const version = await CapacitorUpdater.download({
      version: latestRelease.version,
      url: latestRelease.downloadUrl,
    });
    await CapacitorUpdater.set(version);
  } catch (error: any) {
    const errorProps = {
      ...trackProps,
      error,
    };

    trackAction(`${screenNames.updater} - Failed Update`, errorProps);
  } finally {
    setIsOpen(false);
  }
}

async function checkForUpdates(setIsOpen: (v: boolean) => void) {
  if (!Capacitor.isNativePlatform()) return;

  CapacitorUpdater.notifyAppReady();

  App.addListener("appStateChange", async (state) => {
    if (state.isActive) {
      checkVersionAndUpdate(setIsOpen);
    }
  });
  const HTML = document.querySelector("html");

  if (HTML) {
    HTML.style.height = "calc(100% - var(--safe-area-top))";
  }
}

export { checkForUpdates };

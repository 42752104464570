import { ChangeEvent, useEffect, useRef, useState } from "react";

import classNames from "classnames";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

interface NumberInputProps {
  isDesktop?: boolean;
  value: string;
  setValue: (value: string) => void;
  onChange: (value: string) => void;
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  currencySymbol?: string;
  isSmall?: boolean;
  className?: string;
}

const MAX_DECIMALS = 2;
const MAX_INT_LENGTH = 10;

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  setValue,
  onChange,
  onKeyDown,
  className,
  currencySymbol,
  isSmall = false,
  isDesktop = false,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const integerHandle = isDesktop
    ? value.replace(".", ",")
    : Math.trunc(Number(value)).toString();

  const integerPart = integerHandle.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  const decimalPart = value.split(".")[1];

  const regex = /^(?!0[0-9])([0-9]+([.,][0-9]*)?|0)?$/;
  const validateInput = (value: string) => {
    return regex.test(value);
  };

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "0") return;

    const sanitizedValue = event.target.value.replace(",", ".");

    const isInvalidValue =
      !validateInput(sanitizedValue) ||
      sanitizedValue.split(".")[1]?.length > MAX_DECIMALS ||
      sanitizedValue.split(".")[0]?.length > MAX_INT_LENGTH ||
      sanitizedValue.includes("-");

    if (isInvalidValue) return;

    onChange(sanitizedValue);
    setValue(sanitizedValue);
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 50);
  }, [currencySymbol]);

  return (
    <div className={classNames({ [styles.isSmall]: isSmall }, className)}>
      <input
        type="text"
        inputMode="decimal"
        placeholder="0"
        className={styles.input}
        value={value}
        onChange={onChangeValue}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div
        onClick={() => inputRef.current?.focus()}
        className={classNames(styles.display, { [styles.isSmall]: isSmall })}
      >
        {currencySymbol && (
          <Text
            color="var(--slate800)"
            className={styles.currency}
            variant={TextVariants.RegularText}
          >
            {currencySymbol}{" "}
          </Text>
        )}
        <Text
          color="var(--slate900)"
          className={styles.integerNumber}
          variant={TextVariants.SemiboldText}
        >
          {integerPart || 0}
        </Text>
        {!isDesktop && (
          <>
            <Text
              color="var(--slate900)"
              className={styles.integerNumber}
              variant={TextVariants.SemiboldText}
            >
              ,
            </Text>
            <Text
              variant={TextVariants.SemiboldTextS}
              color="var(--slate900)"
              className={styles.decimalNumber}
            >
              {decimalPart || "00"}
            </Text>
          </>
        )}
        {isDesktop && isFocused && <div className={styles.customCursor} />}
      </div>
    </div>
  );
};

export default NumberInput;

import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";
import { ChevronDown, ChevronUp } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface AccordionRowProps {
  label: string;
  onClick: (value: boolean) => void;
}

const AccordionRow: React.FC<AccordionRowProps> = ({ label, onClick }) => {
  const [displayed, setDisplayed] = useState<boolean>(false);
  const Icon = displayed ? ChevronUp : ChevronDown;

  const handleOnClick = (value: boolean) => {
    setDisplayed(value);
    onClick(value);
  };

  return (
    <div
      className={styles.accordionRow}
      onClick={() => handleOnClick(!displayed)}
    >
      <Text
        className={styles.label}
        variant={TextVariants.RegularText}
        color="var(--slate900)"
      >
        {label}
      </Text>
      <Icon className={styles.icon} size={px2rem(16)} color="var(--slate800)" />
    </div>
  );
};

export default AccordionRow;

import { ChevronRight, LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import Switch from "../Switch";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export interface OptionsRowProps {
  icon: LucideIcon;
  label: string;
  onClick: () => void;
  isToggle?: boolean;
  rightIcon?: JSX.Element;
  toggleValue?: boolean;
}

const OptionsRow: React.FC<OptionsRowProps> = ({
  icon: Icon,
  label,
  onClick,
  isToggle = false,
  rightIcon,
  toggleValue = false,
}) => {
  return (
    <div className={styles.row} key={label} onClick={onClick}>
      <div className={styles.wrapper}>
        <Icon size={px2rem(20)} color="var(--purple900)" />
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {label}
        </Text>
      </div>
      <div className={styles.rightIcons}>
        {rightIcon}
        {isToggle ? (
          <Switch variant={ThemeVariants.Crypto} value={toggleValue} />
        ) : (
          <ChevronRight size={px2rem(16)} color="var(--slate800)" />
        )}
      </div>
    </div>
  );
};

export default OptionsRow;

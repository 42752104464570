import { useEffect, useState } from "react";

import PageHeader from "components/common/PageHeader";
import { useCryptoSend } from "context/CryptoSendProvider";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Heading, { HeadingVariants } from "components/common/Heading";
import { CryptoTag } from "interfaces/api-responses";
import { ThemeVariants } from "interfaces/theme";
import API from "apis";
import { cryptoService } from "apis/services";
import { useDebounce } from "hooks/useDebounce";
import SearchInput from "components/common/SearchInput";
import Text, { TextVariants } from "components/common/Text";
import LoadingSpinner from "components/common/LoadingSpinner";
import px2rem from "utils/px2rem";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";

import TagCard from "./TagCard";

import styles from "./styles.module.scss";

const SearchTagSection: React.FC = () => {
  const navigate = useNavigate();
  const [foundTag, setFoundTag] = useState<CryptoTag>();
  const { selectedCrypto, setSelectedTag } = useCryptoSend();
  const [recentTags, setRecentTags] = useState<CryptoTag[]>([]);
  const [searchError, setSearchError] = useState<boolean>(false);
  const [searchTagValue, setSearchTagValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useAppSelector(getLoggedUser);

  const searchedOwnTag = foundTag?.name === user?.tag;

  const showTagCard = foundTag && !searchError && !isLoading && !searchedOwnTag;
  const showSameTagError =
    foundTag && !searchError && !isLoading && searchedOwnTag;
  const showRecentTags =
    !isEmpty(recentTags) && !searchError && !foundTag && !isLoading;

  const debouncedSearchValue = useDebounce(searchTagValue);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getRecentSearchs = async () => {
      try {
        const { data } = await API.get<CryptoTag[]>(cryptoService.recentTags);

        setRecentTags(data);
      } catch (error) {
        console.log(error);
      }
    };
    getRecentSearchs();
  }, []);

  useEffect(() => {
    const getTag = async () => {
      try {
        setIsLoading(true);
        setSearchError(false);
        const { data } = await API.get<CryptoTag>(
          `${cryptoService.tag}/${debouncedSearchValue}`
        );

        setFoundTag(data);
      } catch (error) {
        setSearchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (!debouncedSearchValue) {
      setFoundTag(undefined);
      setSearchError(false);
      return;
    }

    getTag();
  }, [debouncedSearchValue]);

  return (
    <div className={styles.searchWrapper}>
      <PageHeader
        title={`Enviar ${selectedCrypto?.ticker} por cocostag`}
        onClick={handleClose}
        icon={X}
      />
      <SearchInput
        withPaste
        searchValue={searchTagValue}
        onChange={setSearchTagValue}
        placeholder="Buscá un cocostag"
      />
      <Heading
        variant={HeadingVariants.RegularTitle}
        color="var(--slate800)"
        component="h1"
        className={styles.title}
      >
        {foundTag ? "Resultado de búsqueda" : "Recientes"}
      </Heading>
      {isLoading && (
        <LoadingSpinner size={px2rem(60)} variant={ThemeVariants.Crypto} />
      )}
      {searchError && (
        <Text color="var(--red800)" variant={TextVariants.RegularTextS}>
          No encontramos ningún cocostag.
        </Text>
      )}
      {showRecentTags && (
        <div className={styles.tagList}>
          {recentTags.map((userTag) => (
            <TagCard
              tagName={userTag.accountDisplayName}
              tag={userTag.name}
              key={userTag.name}
              onClick={() => setSelectedTag(userTag)}
            />
          ))}
        </div>
      )}
      {showTagCard && (
        <TagCard
          tag={foundTag.name}
          key={foundTag.name}
          tagName={foundTag.accountDisplayName}
          onClick={() => setSelectedTag(foundTag)}
        />
      )}
      {showSameTagError && (
        <Text color="var(--red800)" variant={TextVariants.RegularTextS}>
          No podés enviarte a vos mismo.
        </Text>
      )}
    </div>
  );
};

export default SearchTagSection;

import { getCrypto } from "constants/cryptos";

import DailyReturnCard from "components/common/DailyReturnCard";
import { CryptoPrice } from "interfaces/api-responses";

import styles from "./styles.module.scss";

interface CryptoCardCarrouselProps {
  cryptos: CryptoPrice[];
}

const CryptoCardCarrousel: React.FC<CryptoCardCarrouselProps> = ({
  cryptos,
}) => {
  return (
    <div className={styles.carrouselWrapper}>
      {cryptos.map(({ baseTicker, varDailyPricePercentage }) => {
        const { icon, ticker, name } = getCrypto(baseTicker);

        return (
          <DailyReturnCard
            key={ticker}
            icon={icon}
            name={name}
            short_name={ticker}
            variation={varDailyPricePercentage}
          />
        );
      })}
    </div>
  );
};

export default CryptoCardCarrousel;

import QuietButton from "components/common/QuietButton";
import Text, { TextVariants } from "components/common/Text";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface ButtonProps {
  text: string;
  onClick: () => void;
  icon?: LucideIcon;
  variant: ThemeVariants;
}

interface BankAccountCardProps {
  items: { label: string; value: string; isBold?: boolean }[];
  button: ButtonProps;
}

const BankAccountCard: React.FC<BankAccountCardProps> = ({ items, button }) => {
  const { onClick, text, variant, icon } = button;
  const ButtonIcon = icon ? icon : null;
  return (
    <div className={styles.cardWrapper}>
      <ul>
        {items.map(({ label, value, isBold }) => (
          <li key={label}>
            <Text variant={TextVariants.RegularText} color="var(--slate800)">
              {label}
            </Text>
            <Text
              variant={
                isBold
                  ? TextVariants.SemiboldUpperValue
                  : TextVariants.RegularUpperValue
              }
              color="var(--slate900)"
            >
              {value}
            </Text>
          </li>
        ))}
      </ul>

      <QuietButton
        onClick={onClick}
        className={styles.button}
        variant={variant}
      >
        {ButtonIcon && <ButtonIcon size={px2rem(16)} color="var(--slate900)" />}
        {text}
      </QuietButton>
    </div>
  );
};

export default BankAccountCard;

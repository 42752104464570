import { Network, getNetwork } from "constants/cryptos";

import { useState } from "react";

import BottomSheet from "components/common/BottomSheet";
import NetworksInfo from "components/common/NetworksInfo";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import NetworkSelector from "./NetworkSelector";

interface NetworkBottomSheetProps {
  networks: Network[];
  onClick: (network: Network) => void;
  onClose: () => void;
}

const NetworkBottomSheet: React.FC<NetworkBottomSheetProps> = ({
  networks,
  onClick,
  onClose,
}) => {
  const [isDisplayingInfo, setIsDisplayingInfo] = useState<boolean>(false);

  const hideInfo = () => {
    setIsDisplayingInfo(false);
  };

  const showInfo = () => {
    trackAction(
      `${cryptoScreenNames.receive} - Click Network Info`,
      undefined,
      Projects.CRYPTO
    );
    setIsDisplayingInfo(true);
  };

  const onClickNetwork = (value: string) => {
    const network = getNetwork(value);
    onClick(network);
  };

  const bottomSheetTitle = isDisplayingInfo ? undefined : "Seleccioná una red";

  return (
    <BottomSheet onClose={onClose} title={bottomSheetTitle}>
      {!isDisplayingInfo ? (
        <NetworkSelector
          showInfo={showInfo}
          networks={networks}
          onClickNetwork={onClickNetwork}
        />
      ) : (
        <NetworksInfo hideInfo={hideInfo} />
      )}
    </BottomSheet>
  );
};

export default NetworkBottomSheet;

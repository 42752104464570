import { useState } from "react";

import { Check, Copy } from "lucide-react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import px2rem from "utils/px2rem";
import { Clipboard } from "@capacitor/clipboard";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import { useCocosCard } from "context/CocosCardProvider";
import { CardTransactionActionType } from "interfaces/card/enums";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Divider from "components/common/Divider";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import Toast, { ToastVariants } from "components/common/Toast";

import TransactionHeader from "./TransactionHeader";
import PaymentDetail from "./PaymentDetail";

import styles from "./styles.module.scss";

const TransactionDetail: React.FC = () => {
  const navigate = useNavigate();
  const { transactionSelected } = useCocosCard();
  const {
    merchant_name,
    amount_local,
    created_at,
    status,
    id_card_transaction,
    payments,
    transaction_type,
  } = transactionSelected;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const CopyIcon = isCopied ? Check : Copy;
  const slate900 = "var(--slate900)";
  const slate800 = "var(--slate800)";
  const date = dayjs(created_at).format("DD/MM/YYYY - HH:mm");
  const statusColor =
    status === "APPROVED" ? "var(--green800)" : "var(--red800)";
  const statusLabel = status === "APPROVED" ? "Aprobada" : "Rechazada";

  const navigationHeaderProps = {
    iconColor: slate900,
    title: "Detalle de operación",
    onClick: () => navigate(-1),
  };

  const secondaryButtonProps = {
    children: "Volver a tarjetas",
    onClick: () => navigate(-1),
  };

  const onClickCopy = async (value: string) => {
    try {
      await Clipboard.write({ string: value });
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BasicWrapper
      navigationHeaderProps={navigationHeaderProps}
      secondaryButtonProps={secondaryButtonProps}
      themeVariant={ThemeVariants.Pay}
    >
      <div className={styles.transactionDetailWrapper}>
        <div className={styles.amountHeader}>
          <TransactionHeader
            payments={payments}
            isCredit={
              transaction_type === CardTransactionActionType.CREDIT ||
              amount_local <= 0
            }
          />
          <div className={styles.totalAmount}>
            <Text variant={TextVariants.SemiboldTextXL} color="var(--slate800)">
              -
            </Text>
            <PriceLabel price={amount_local} size={PriceLabelSizes.Default} />
          </div>
        </div>
        <div className={styles.transactionDetailContainer}>
          <div className={styles.row}>
            <Heading
              variant={HeadingVariants.RegularSubtitle}
              color={slate800}
              component="span"
            >
              Estado
            </Heading>
            <Heading
              className={styles.status}
              variant={HeadingVariants.RegularSubtitle}
              color={statusColor}
              component="span"
            >
              {statusLabel}
            </Heading>
          </div>
          <div className={styles.row}>
            <Heading
              variant={HeadingVariants.RegularSubtitle}
              color={slate800}
              component="span"
            >
              Pagaste a
            </Heading>
            <Heading
              className={styles.businessName}
              variant={HeadingVariants.RegularSubtitle}
              color={slate900}
              component="span"
            >
              {merchant_name}
            </Heading>
          </div>
          <div className={styles.row}>
            <Heading
              variant={HeadingVariants.RegularSubtitle}
              color={slate800}
              component="span"
            >
              Fecha
            </Heading>
            <Heading
              variant={HeadingVariants.SemiboldValueS}
              color={slate900}
              component="span"
            >
              {date}
            </Heading>
          </div>

          {!isEmpty(payments) && (
            <>
              <Divider className={styles.divider} />
              <PaymentDetail payments={payments} />
            </>
          )}
          <Divider className={styles.divider} />
          <div className={styles.idContent}>
            <div className={styles.id}>
              <Heading
                variant={HeadingVariants.RegularSubtitle}
                component="h3"
                color={slate800}
              >
                N° de operación
              </Heading>

              <Heading
                className={styles.id}
                variant={HeadingVariants.SemiboldSubtitle}
                component="h3"
                color={slate900}
              >
                {id_card_transaction}
              </Heading>
            </div>
            <div className={styles.copyContainer}>
              <CopyIcon
                size={px2rem(16)}
                onClick={() => onClickCopy(id_card_transaction)}
                color="var(--white-to-black)"
              />
            </div>
            {isCopied && (
              <Toast
                variant={ToastVariants.Success}
                message="¡Listo! Ya copiaste el número de operación."
                onClose={() => setIsCopied(false)}
              />
            )}
          </div>
        </div>
      </div>
    </BasicWrapper>
  );
};

export default TransactionDetail;

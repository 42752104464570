import { Cryptos } from "constants/cryptos";

import { useEffect } from "react";

import { ampli } from "ampli";
import FilledButton from "components/common/FilledButton";
import OutlinedButton from "components/common/OutlinedButton";
import Text, { TextVariants } from "components/common/Text";
import { Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import { getWallet } from "features/wallet/walletSlice";
import { Currencies } from "interfaces/wallet";
import { ThemeVariants } from "interfaces/theme";
import { formatNumberToLocale } from "utils";
import { useAppDispatch } from "hooks/redux";

import { ButtonsOptions } from "../../utils";

import styles from "./styles.module.scss";

interface ConfirmationStepProps {
  baseCurrency: Currencies.ARS | Cryptos;
  quoteCurrency: Currencies.ARS | Cryptos;
  quoteAmount: string | number;
  baseAmount: string | number;
  continueSendFlow?: () => void;
  onConfirmTransaction: () => void;
  exchangeRate: number;
  operationType: string;
}

const ConfirmationStep: React.FC<ConfirmationStepProps> = ({
  quoteAmount,
  baseAmount,
  baseCurrency,
  quoteCurrency,
  continueSendFlow,
  onConfirmTransaction,
  exchangeRate,
  operationType,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isSell = quoteCurrency === Currencies.ARS;
  const isBuy = baseCurrency === Currencies.ARS;

  useEffect(() => {
    ampli.cryptoOperationsSuccess({
      operation_type: operationType,
      base_ticker: baseCurrency,
      quote_ticker: quoteCurrency,
      base_quantity: Number(baseAmount),
      quote_quantity: Number(quoteAmount),
      exchange_rate: exchangeRate,
    });

    onConfirmTransaction();
    dispatch(getWallet());
  }, []);

  const onClickButton = (value: ButtonsOptions) => {
    navigate(CryptoRoutes[value]);
  };

  const onClickContinueSend = () => {
    continueSendFlow && continueSendFlow();
  };

  const getTitle = () => {
    if (isBuy) return "¡Compraste!";
    if (isSell) return "¡Vendiste!";
    return "¡Convertiste!";
  };

  const goToHome = () => {
    ampli.cryptoOperationsExit();
    return onClickButton(ButtonsOptions.HOME);
  };

  return (
    <div className={styles.container}>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate900)"
        className={styles.title}
      >
        {getTitle()}
      </Text>
      <div className={styles.iconContainer}>
        <Check color="var(--green800)" />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.info}
      >
        {isSell ? "Ya tenés $ " : "Ya tenés "}
        <Text
          component="span"
          color="var(--slate900)"
          variant={TextVariants.SemiboldText}
        >
          {formatNumberToLocale(Number(quoteAmount))}
        </Text>
        {isSell && ` ${quoteCurrency}`} en tu portfolio.
      </Text>
      <div className={styles.buttonsWrapper}>
        <FilledButton
          variant={ThemeVariants.Crypto}
          onClick={() => {
            continueSendFlow
              ? onClickContinueSend()
              : onClickButton(ButtonsOptions.PORTFOLIO);
          }}
        >
          {continueSendFlow ? "Seguir con el envío" : "Ir a portfolio"}
        </FilledButton>
        <OutlinedButton onClick={goToHome} variant={ThemeVariants.Crypto}>
          Volver al inicio
        </OutlinedButton>
      </div>
    </div>
  );
};

export default ConfirmationStep;

import { useState } from "react";

import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import AuthAPI from "apis/auth";
import BasicWrapper from "components/common/BasicWrapper";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import MfaChallenge from "components/common/MfaChallenge";
import Text, { TextVariants } from "components/common/Text";
import Toast, { ToastVariants } from "components/common/Toast";
import { MfaVerifyParams, useAuth } from "context/AuthProvider";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { updateHas2FA } from "features/user/userSlice";
import { useAppDispatch } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { ThemeVariants } from "interfaces/theme";
import { LockKeyhole } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import { mfaErrorCheck } from "utils/mfa";

import Config2FA from "./Config2FA";

import styles from "./styles.module.scss";

const TwoFAInactive: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isMobile } = useDeviceCheck();
  const { verify2FA } = useAuth();

  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);

  const [enrollData, setEnrollData] = useState<AuthMFAEnrollResponse>();
  const [mfaError, setMfaError] = useState<MFAErrorType>();

  const state = location.state as {
    theme: ThemeVariants;
    isCocosCard: boolean;
  };

  const variant = state?.theme || ThemeVariants.Capital;
  const isCocosCard = state?.isCocosCard;

  const handleGoBack = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }
    dispatch(closeRightModal());
  };

  const handleVerifySms = async (
    { mfaChallengeId, mfaCode }: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    let hasError = false;
    try {
      const enrollData: AuthMFAEnrollResponse = await AuthAPI.post(
        "/v1/factors",
        {
          factor_type: "totp",
          smsChallengeId: mfaChallengeId,
          smsCode: mfaCode,
        }
      );

      if (enrollData?.data?.totp?.qr_code) {
        enrollData.data.totp.qr_code = `data:image/svg+xml;utf-8,${enrollData.data.totp.qr_code}`;
      }

      setEnrollData(enrollData);
      setStep(3);
    } catch (error: any) {
      const mfaError = mfaErrorCheck(error);
      if (mfaError) {
        setMfaError(mfaError);
      } else {
        console.error(error);
      }
      hasError = true;
    } finally {
      callAfterVerify();
    }
    return hasError;
  };

  const handleVerify = async (
    { mfaFactorId, mfaChallengeId, mfaCode }: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    const { error } = await verify2FA({
      mfaFactorId,
      mfaChallengeId,
      mfaCode,
    });

    if (error) {
      setMfaError(error.message as MFAErrorType);
      callAfterVerify();
      return true;
    }

    dispatch(updateHas2FA(true));
    setTimeout(() => {
      navigate(isCocosCard ? mainRoutes.card : mainRoutes.home);
    }, 2000);
    setStep(5);
    return false;
  };

  if (step === 1) {
    return (
      <BasicWrapper
        navigationHeaderProps={
          isMobile
            ? {
                onClick: handleGoBack,
                title: "Autenticación de 2 pasos",
                withCloseIcon: false,
              }
            : undefined
        }
        icon={LockKeyhole}
        primaryButtonProps={{
          children: "Configurar Google Authenticator",
          onClick: () => setStep(2),
        }}
        themeVariant={variant}
      >
        <div className={styles.textContainer}>
          <Heading
            component="h2"
            variant={HeadingVariants.RegularTitle}
            color="var(--slate900)"
          >
            {isCocosCard
              ? "Para emitir tu tarjeta necesitás activar la autenticación de 2 pasos"
              : "Mantené tu cuenta segura"}
          </Heading>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Configurá Google Authenticator para mantener tu cuenta segura.
          </Text>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Al utilizar una aplicación de autenticación de dos pasos te asegurás
            que nadie más pueda iniciar sesión en tu cuenta.
          </Text>
        </div>
      </BasicWrapper>
    );
  }
  if (step === 2) {
    // SMS
    {
      return (
        <MfaChallenge
          onVerify={handleVerifySms}
          onClickBack={() => setStep(1)}
          factorType={MFAType.SMS}
          error={mfaError}
          variant={variant}
        />
      );
    }
  }
  if (step === 3) {
    // Config 2FA
    {
      return (
        <Config2FA
          onClickBack={() => setStep(2)}
          onClickContinue={() => setStep(4)}
          enrollData={enrollData}
          variant={variant}
        />
      );
    }
  }

  if (step === 4) {
    return (
      <MfaChallenge
        onVerify={handleVerify}
        onClickBack={() => setStep(3)}
        factorType={MFAType.ENROLL}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enrollId={enrollData!.data!.id}
        error={mfaError}
        variant={variant}
      />
    );
  }

  return (
    <div>
      <LoadingSpinner variant={variant} />
      <Toast
        message="Autenticación activada exitosamente"
        variant={ToastVariants.Success}
      />
    </div>
  );
};

export default TwoFAInactive;

import Text, { TextVariants } from "components/common/Text";
import { LucideIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NavigationTabProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: LucideIcon;
  title: string;
  route: string;
}

const NavigationTab: React.FC<NavigationTabProps> = ({
  icon: Icon,
  title,
  route,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const selected = location.pathname === route;
  const color = selected ? "var(--purple900)" : "var(--slate600)";

  return (
    <button
      className={styles.navigationTabContainer}
      {...props}
      onClick={() => navigate(route)}
    >
      <div className={styles.icon}>
        <Icon size={px2rem(24)} color={color} />
      </div>
      <Text variant={TextVariants.SemiboldTextXS} color={color}>
        {title}
      </Text>
    </button>
  );
};

export default NavigationTab;

import axios, { AxiosRequestConfig } from "axios";
import { supabase } from "utils/supabase";

const baseURL = import.meta.env.VITE_API_URL + "/auth";

const headers: AxiosRequestConfig["headers"] = {};

const AuthAPI = axios.create({ baseURL, headers });

AuthAPI.interceptors.request.use(async (config) => {
  const { data, error } = await supabase.auth.getSession();

  if (!error && data.session) {
    config.headers.Authorization = `Bearer ${data.session.access_token}`;
  }

  return config;
});

export default AuthAPI;

import { LucideIcon } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import classNames from "classnames";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

export enum BulletListVariants {
  Capital = "Capital",
  Crypto = "Crypto",
}

interface BulletListProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  bullets: { icon: LucideIcon; text: string | React.ReactNode }[];
  variant?: BulletListVariants;
}

const BulletList: React.FC<BulletListProps> = ({
  bullets,
  className,
  variant = BulletListVariants.Crypto,
  ...props
}) => {
  return (
    <div {...props} className={classNames(styles.listWrapper, className)}>
      {bullets.map(({ icon: Icon, text }, index) => (
        <div key={index} className={styles.row}>
          <div className={classNames(styles.icon, styles[variant])}>
            <Icon size={px2rem(12)} />
          </div>
          <Text
            variant={TextVariants.RegularTextS}
            color="var(--slate800)"
            className={styles.text}
          >
            {text}
          </Text>
        </div>
      ))}
    </div>
  );
};

export default BulletList;

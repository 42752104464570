import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { ampli } from "ampli";
import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import { useCocosCard } from "context/CocosCardProvider";
import mainRoutes from "router/routes";
import Mail from "assets/illustrations/Mail";
import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import { UIModalSlider } from "components/common/modal-slider";
import Text, { TextVariants } from "components/common/Text";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";

import styles from "./styles.module.scss";

interface SupportBottomSheetProps {
  open: boolean;
  onClose: () => void;
}

const SupportBottomSheet: React.FC<SupportBottomSheetProps> = ({
  open,
  onClose,
}) => {
  const { selectedCard } = useCocosCard();
  const { isIOS } = getPlatform();
  const navigate = useNavigate();

  const mailToSupport = () => {
    ampli.cardProductPromotionSupport({ card_type: selectedCard });
    window.location.href = `mailto:${ATENCION_CAPITAL_EMAIL}`;
  };

  const backToCard = () => {
    onClose();
    navigate(mainRoutes.card);
  };

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <Text variant={TextVariants.RegularText} className={styles.title}>
        Contactate con soporte
      </Text>
      <div className={styles.sheetContent}>
        <Mail />
        <Text variant={TextVariants.RegularText} className={styles.text}>
          Envianos un mail a <span>atencion@cocos.capital</span> desde el correo
          que utilizás para ingresar a la aplicación.
        </Text>
        <Text variant={TextVariants.RegularText} className={styles.text}>
          Incluí el número de comprobante y documentación de respaldo relevante.
        </Text>
      </div>
      <div
        className={classNames(styles.buttonWrapper, { [styles.ios]: isIOS })}
      >
        <FilledButton variant={ThemeVariants.Pay} onClick={mailToSupport}>
          Contactar a soporte
        </FilledButton>
        <OutlinedButton variant={ThemeVariants.Pay} onClick={backToCard}>
          Volver a tarjeta
        </OutlinedButton>
      </div>
    </UIModalSlider>
  );
};

export default SupportBottomSheet;

import { useEffect } from "react";

import { Browser } from "@capacitor/browser";
import { ampli } from "ampli";
import PageHeader from "components/common/PageHeader";
import DefaultSection from "components/page-crypto-profile/DefaultSection";
import { ArrowLeft, GraduationCap } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { externalRoutes } from "router/routes";

import styles from "./styles.module.scss";

const LearnSection: React.FC = () => {
  const navigate = useNavigate();
  const learnOptions = [
    {
      label: "Cocos Academy",
      icon: GraduationCap,
      onClick: () => Browser.open({ url: externalRoutes.academy }),
    },
  ];

  useEffect(() => {
    ampli.cryptoScreenEducation();
  }, []);

  return (
    <div className={styles.learnSectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Aprendé"
        onClick={() => navigate(-1)}
      />
      <DefaultSection title="Aprendé" options={learnOptions} />
    </div>
  );
};

export default LearnSection;

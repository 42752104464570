import { useEffect, useState } from "react";

import { AuthMFAEnrollResponse } from "@supabase/supabase-js";
import AuthAPI from "apis/auth";
import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import MfaChallenge from "components/common/MfaChallenge";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import Toast, { ToastVariants } from "components/common/Toast";
import { MfaVerifyParams, useAuth } from "context/AuthProvider";
import { MFAErrorType, MFAType } from "interfaces/auth";
import { ArrowLeft, LockKeyhole } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { updateHas2FA } from "features/user/userSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen } from "utils/amplitude";
import { mfaErrorCheck } from "utils/mfa";
import px2rem from "utils/px2rem";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import Config2FA from "./Config2FA";

import styles from "./styles.module.scss";

const TwoFAInactive: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { verify2FA } = useAuth();

  const [step, setStep] = useState<1 | 2 | 3 | 4 | 5>(1);

  const [enrollData, setEnrollData] = useState<AuthMFAEnrollResponse>();
  const [mfaError, setMfaError] = useState<MFAErrorType>();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    trackScreen(
      cryptoScreenNames.faConfig,
      previousScreen,
      undefined,
      Projects.CRYPTO
    );
    dispatch(setCurrentScreen(cryptoScreenNames.faConfig));
  }, []);

  const handleVerifySms = async (
    { mfaChallengeId, mfaCode }: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    let hasError = false;
    try {
      const enrollData: AuthMFAEnrollResponse = await AuthAPI.post(
        "/v1/factors",
        {
          factor_type: "totp",
          smsChallengeId: mfaChallengeId,
          smsCode: mfaCode,
        }
      );

      if (enrollData?.data?.totp?.qr_code) {
        enrollData.data.totp.qr_code = `data:image/svg+xml;utf-8,${enrollData.data.totp.qr_code}`;
      }

      setEnrollData(enrollData);
      setStep(3);
    } catch (error: any) {
      const mfaError = mfaErrorCheck(error);
      if (mfaError) {
        setMfaError(mfaError);
      } else {
        console.error(error);
      }
      hasError = true;
    } finally {
      callAfterVerify();
    }
    return hasError;
  };

  const handleVerify = async (
    { mfaFactorId, mfaChallengeId, mfaCode }: MfaVerifyParams,
    callAfterVerify: () => void
  ) => {
    setMfaError(undefined);
    const { error } = await verify2FA({
      mfaFactorId,
      mfaChallengeId,
      mfaCode,
    });

    if (error) {
      setMfaError(error.message as MFAErrorType);
      callAfterVerify();
      return true;
    }

    dispatch(updateHas2FA(true));
    setTimeout(() => {
      navigate(CryptoRoutes.HOME);
    }, 2000);
    setStep(5);
    return false;
  };

  if (step === 1) {
    return (
      <div className={styles.inactive2FAContainer}>
        <div className={styles.wrapper}>
          <PageHeader
            icon={ArrowLeft}
            title="Autenticación de 2 pasos"
            onClick={() => navigate(-1)}
          />
          <div className={styles.icon}>
            <LockKeyhole size={px2rem(24)} color="var(--purple900)" />
          </div>
          <div>
            <Heading
              component="h2"
              variant={HeadingVariants.RegularTitle}
              color="var(--slate900)"
            >
              Mantené tu cuenta segura
            </Heading>
            <Text variant={TextVariants.RegularText} color="var(--slate800)">
              Configurá Google Authenticator para mantener tu cuenta segura.
            </Text>
          </div>
          <Text variant={TextVariants.RegularText} color="var(--slate800)">
            Al utilizar una aplicación de autenticación de dos pasos te asegurás
            que nadie más pueda iniciar sesión en tu cuenta.
          </Text>
        </div>

        <FilledButton
          variant={ThemeVariants.Crypto}
          className={styles.button}
          onClick={() => {
            setStep(2);
          }}
        >
          Configurar Google Authenticator
        </FilledButton>
      </div>
    );
  }
  if (step === 2) {
    // SMS
    return (
      <MfaChallenge
        onVerify={handleVerifySms}
        onClickBack={() => setStep(1)}
        factorType={MFAType.SMS}
        variant={ThemeVariants.Crypto}
        error={mfaError}
      />
    );
  }
  if (step === 3) {
    // Config 2FA
    return (
      <Config2FA
        onClickBack={() => setStep(2)}
        onClickContinue={() => setStep(4)}
        enrollData={enrollData}
      />
    );
  }

  if (step === 4) {
    return (
      <MfaChallenge
        onVerify={handleVerify}
        onClickBack={() => setStep(3)}
        factorType={MFAType.ENROLL}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        enrollId={enrollData!.data!.id}
        variant={ThemeVariants.Crypto}
        error={mfaError}
      />
    );
  }

  return (
    <div>
      <LoadingSpinner variant={ThemeVariants.Crypto} />
      <Toast
        message="Autenticación activada exitosamente"
        variant={ToastVariants.Success}
      />
    </div>
  );
};

export default TwoFAInactive;

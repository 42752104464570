import * as marketActions from "./marketsActions";
import * as marketSlice from "./marketsSlice";

const {
  confirmBidOrder,
  confirmCaucionOrder,
  confirmDolarOrder,
  confirmOrder,
  createList,
  getDolarMep,
  getHistoricalDataByLongTicker,
  getInvestmentSuggested,
  getMarketDetailByShortTicker,
  getMarketSchedules,
  getMarketsTypesLists,
  getOrderById,
  getOrders,
  getSegment,
  getTickersRules,
  toggleInList,
} = marketActions;

const {
  cleanHistoricalData,
  clearCaucionError,
  clearDolarOrder,
  clearOrderDetail,
  clearOrdersListError,
  clearShortTickerMarketData,
  removeMarketError,
  resetCaucionOrder,
  resetMarketDataDetail,
  resetOrder,
  setCancelOrderError,
  setCancelOrderLoading,
  setCaucionOrder,
  setCaucionOrderError,
  setConfirmOrderData,
  setCreateListLoading,
  setCreateOrderError,
  setCreateOrderLoading,
  setDolarMepData,
  setDolarMepOrder,
  setGetOrderError,
  setGetOrderLoading,
  setInvestmentSuggested,
  setListError,
  setMarketData,
  setMarketDataDetail,
  setMarketDetailLoading,
  setMarketError,
  setMarketHistoricalData,
  setMarketLoading,
  setMarketSchedules,
  setOrderDetail,
  setOrderListError,
  setOrderListLoading,
  setOrdersData,
  setTickersRulesData,
  setTickersRulesError,
  setTickersRulesLoading,
  updateCaucionOrderOptions,
  updateDolarOrderOptions,
  updateOrderOptions,
} = marketSlice;

export {
  cleanHistoricalData,
  clearCaucionError,
  clearDolarOrder,
  clearOrderDetail,
  clearOrdersListError,
  clearShortTickerMarketData,
  confirmBidOrder,
  confirmCaucionOrder,
  confirmDolarOrder,
  confirmOrder,
  createList,
  getDolarMep,
  getHistoricalDataByLongTicker,
  getInvestmentSuggested,
  getMarketDetailByShortTicker,
  getMarketSchedules,
  getMarketsTypesLists,
  getOrderById,
  getOrders,
  getSegment,
  getTickersRules,
  removeMarketError,
  resetCaucionOrder,
  resetMarketDataDetail,
  resetOrder,
  setCancelOrderError,
  setCancelOrderLoading,
  setCaucionOrder,
  setCaucionOrderError,
  setConfirmOrderData,
  setCreateListLoading,
  setCreateOrderError,
  setCreateOrderLoading,
  setDolarMepData,
  setDolarMepOrder,
  setGetOrderError,
  setGetOrderLoading,
  setInvestmentSuggested,
  setListError,
  setMarketData,
  setMarketDataDetail,
  setMarketDetailLoading,
  setMarketError,
  setMarketHistoricalData,
  setMarketLoading,
  setMarketSchedules,
  setOrderDetail,
  setOrderListError,
  setOrderListLoading,
  setOrdersData,
  setTickersRulesData,
  setTickersRulesError,
  setTickersRulesLoading,
  toggleInList,
  updateCaucionOrderOptions,
  updateDolarOrderOptions,
  updateOrderOptions,
};

import { Cryptos } from "constants/cryptos";

import Heading, { HeadingVariants } from "components/common/Heading";
import { Operations } from "interfaces/crypto/enums";
import { formatNumberToLocale } from "utils";

import styles from "./styles.module.scss";

type SheetAmountProps =
  | {
      operation: Operations.SWAP;
      quoteTicker: Cryptos;
      quoteQuantity: number;
    }
  | {
      operation:
        | Operations.BUY
        | Operations.SELL
        | Operations.SEND
        | Operations.RECEIVE;
      baseTicker: Cryptos;
      baseQuantity: number;
    };

const SheetAmount: React.FC<SheetAmountProps> = (props) => {
  const amount =
    props.operation === Operations.SWAP
      ? props.quoteQuantity
      : Math.abs(props.baseQuantity);
  return (
    <div className={styles.amountWrapper}>
      <Heading
        component="h1"
        color="var(--slate900)"
        className={styles.text}
        variant={HeadingVariants.SemiboldValue}
      >
        {formatNumberToLocale(amount)}
      </Heading>
      <Heading
        component="h1"
        color="var(--slate800)"
        className={styles.text}
        variant={HeadingVariants.RegularTitle}
      >
        {props.operation === Operations.SWAP
          ? props.quoteTicker
          : props.baseTicker}
      </Heading>
    </div>
  );
};

export default SheetAmount;

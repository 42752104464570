import Heading, { HeadingVariants } from "components/common/Heading";
import { CryptoHolding } from "interfaces/api-responses";

import HoldingsList from "./HoldingsList";

import styles from "./styles.module.scss";

interface HoldingsSectionProps {
  holdings: CryptoHolding[];
}

const HoldingsSection: React.FC<HoldingsSectionProps> = ({ holdings }) => {
  holdings.sort((a, b) => b.amountArs - a.amountArs);

  return (
    <div className={styles.holdingsSectionContainer}>
      <Heading
        component="h2"
        variant={HeadingVariants.RegularTitle}
        color="var(--slate800)"
        className={styles.title}
      >
        Tenencia
      </Heading>
      <HoldingsList holdings={holdings} />
    </div>
  );
};

export default HoldingsSection;

import { Cryptos, Network } from "constants/cryptos";

import { ThemeVariants } from "interfaces/theme";
import FilledButton from "components/common/FilledButton";
import { cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

interface ShareButtonProps {
  ticker: Cryptos;
  address?: string;
  network?: Network;
  disabled?: boolean;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  ticker,
  address,
  network,
  disabled,
}) => {
  const handleShare = () => {
    trackAction(
      `${cryptoScreenNames.receive} - Click Share Address`,
      undefined,
      Projects.CRYPTO
    );

    if (!address) return;
    navigator.share({
      title: `Cocos Crypto`,
      text: `Te comparto mi dirección de ${ticker} en la red ${network?.name}:\n\n${address}\n\napp.cocos.capital/crypto`,
    });
  };
  return (
    <div className={styles.shareButton}>
      <FilledButton
        disabled={disabled}
        onClick={handleShare}
        variant={ThemeVariants.Crypto}
      >
        Compartir dirección
      </FilledButton>
    </div>
  );
};

export default ShareButton;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import API from "apis";
import { walletService } from "apis/services";
import { PortfolioResponse } from "interfaces/api-responses";
import { ErrorMessage } from "interfaces/errors";
import {
  Currencies,
  MappedHoldingsType,
  PortfolioTotal,
} from "interfaces/wallet";
import _ from "lodash";

export interface PortfolioState {
  holdings?: MappedHoldingsType;
  errorMessage: ErrorMessage;
  loading: boolean;
  totalPortfolio: PortfolioTotal;
  currencyValue: Currencies;
  displayTypeValue: string;
  displayTotal: boolean;
}

export const initialState: PortfolioState = {
  holdings: undefined,
  errorMessage: {
    message: "",
    status: null,
  },
  loading: false,
  totalPortfolio: {},
  currencyValue: Currencies.ARS,
  displayTypeValue: "amount",
  displayTotal: true,
};

export const getPortfolio = createAsyncThunk(
  "portfolio/getPortfolio",
  async () => {
    const { data } = await API.get<PortfolioResponse>(walletService.portfolio);
    return data;
  }
);

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setPortfolioTotal(
      state,
      action: PayloadAction<{ available: { ars: number; usd: number } }>
    ) {
      state.totalPortfolio = {
        ...state.totalPortfolio,
        ...action.payload,
      };
    },
    setCurrencyValue(state, action: PayloadAction<{ currency: Currencies }>) {
      state.currencyValue = action.payload.currency;
    },
    setDisplayTypeValue(state, action: PayloadAction<{ typeValue: string }>) {
      state.displayTypeValue = action.payload.typeValue;
    },
    setDisplayTotal(state, action: PayloadAction<{ shoulDisplay: boolean }>) {
      state.displayTotal = action.payload.shoulDisplay;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPortfolio.pending, (state) => {
      state.loading = true;
      state.errorMessage = initialState.errorMessage;
    });
    builder.addCase(
      getPortfolio.fulfilled,
      (state, action: PayloadAction<PortfolioResponse>) => {
        const mappedData = _.groupBy(action.payload.tickers, "type");
        state.loading = false;
        state.holdings = mappedData;
        state.totalPortfolio.total = {
          ...state.totalPortfolio,
          ...action.payload.total,
        };
      }
    );
    builder.addCase(getPortfolio.rejected, (state, action) => {
      state.loading = false;
      state.errorMessage = {
        message: action.error.message || "",
        status: Number(action.error.code),
      };
    });
  },
});

export const {
  setPortfolioTotal,
  setCurrencyValue,
  setDisplayTotal,
  setDisplayTypeValue,
} = portfolioSlice.actions;

export default portfolioSlice.reducer;

import { Phone } from "lucide-react";
import { usePhoneValidation } from "context/PhoneValidationProvider";
import BasicWrapper from "components/common/BasicWrapper";
import { Screens } from "interfaces/phoneValidation";
import { trackAction } from "utils/amplitude";

const PhoneValidationScreen = () => {
  const { setScreen, goToIntro, phone } = usePhoneValidation();

  const onClickSkip = () => {
    trackAction("phonevalidation_chose_later");
    goToIntro();
  };

  const onConfirm = () => {
    setScreen(phone ? Screens.PHONE_CONFIRMATION : Screens.CONTACT_SUPPORT);
    trackAction("phonevalidation_chose_verify");
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Teléfono",
        onClick: onClickSkip,
      }}
      icon={Phone}
      title="Para mantener tu cuenta segura, requerimos verificar tu teléfono."
      subtitle="Con tu número actualizado te asegurás de poder iniciar sesión siempre en Cocos."
      primaryButtonProps={{
        children: "Verificar teléfono",
        onClick: onConfirm,
      }}
      secondaryButtonProps={{
        children: "En otro momento",
        onClick: onClickSkip,
      }}
    />
  );
};

export default PhoneValidationScreen;

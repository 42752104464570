import { IconProps } from "../BTC";

const USDTIcon: React.FC<IconProps> = ({ size = 24, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Tether">
      <rect width="24" height="24" rx="12" fill="#50AF95" />
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5191 12.5165C13.4343 12.5229 12.9959 12.549 12.018 12.549C11.2402 12.549 10.688 12.5257 10.4943 12.5165C7.48853 12.3843 5.245 11.861 5.245 11.2346C5.245 10.6081 7.48853 10.0856 10.4943 9.95125V11.9954C10.6908 12.0095 11.2537 12.0428 12.0314 12.0428C12.9648 12.0428 13.4321 12.0039 13.5163 11.9961V9.95266C16.5157 10.0863 18.7542 10.6095 18.7542 11.2346C18.7542 11.8596 16.5164 12.3829 13.5163 12.5158L13.5191 12.5165ZM13.5191 9.74125V7.91206H17.7049V5.12268H6.30843V7.91206H10.4936V9.74054C7.09186 9.8968 4.53369 10.5706 4.53369 11.3781C4.53369 12.1856 7.09186 12.8587 10.4936 13.0157V18.8773H13.5184V13.0136C16.9123 12.8573 19.4663 12.1842 19.4663 11.3774C19.4663 10.5706 16.9144 9.89751 13.5184 9.74054L13.5191 9.74125Z"
        fill="white"
      />
    </g>
  </svg>
);

export default USDTIcon;

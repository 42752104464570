import classNames from "classnames";
import GoldBadge from "components/common/GoldBadge";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { Tiers } from "interfaces/user";
import { User2 } from "lucide-react";
import { useSelector } from "react-redux";
import { getLoggedUser } from "store/selectors/user.selector";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface AvatarProps {
  variant?: ThemeVariants.Capital | ThemeVariants.Crypto;
  isLarge?: boolean;
  onClick?: () => void;
}

const Avatar: React.FC<AvatarProps> = ({
  variant = ThemeVariants.Capital,
  isLarge = false,
  onClick,
}) => {
  const user = useSelector(getLoggedUser);

  if (!user) return null;

  const { first_name, last_name, tier } = user;

  const isGold = tier === Tiers.GOLD;

  const initials = last_name ? first_name[0] + last_name[0] : first_name[0 - 2];

  const textVariant = isLarge
    ? TextVariants.SemiboldUpperValue
    : TextVariants.SemiboldUpperValueS;

  const iconSize = isLarge ? px2rem(48) : px2rem(16);
  const iconColor =
    variant === ThemeVariants.Capital ? "var(--blue900)" : "var(--purple900)";

  return (
    <div
      className={classNames(styles.avatarContainer, styles[variant], {
        [styles.large]: isLarge,
      })}
      onClick={onClick}
    >
      {initials ? (
        <Text variant={textVariant} color="var(--slate900)">
          {initials}
        </Text>
      ) : (
        <User2 size={iconSize} color={iconColor} />
      )}
      {isGold && <GoldBadge />}
    </div>
  );
};

export default Avatar;

import Heading, { HeadingVariants } from "components/common/Heading";

import styles from "./styles.module.scss";

interface TagProps {
  tag?: string;
}

const Tag: React.FC<TagProps> = ({ tag = "tunombre" }) => {
  return (
    <div className={styles.TagContainer}>
      <Heading
        variant={HeadingVariants.SemiboldSubtitle}
        component="h3"
        color="var(--purple800)"
      >
        {tag} 🥥
      </Heading>
    </div>
  );
};

export default Tag;

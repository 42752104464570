import { useEffect } from "react";

import { ThemeVariants } from "interfaces/theme";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import SendMethodSheet from "components/common/SendMethodSheet";
import CryptoLimitsComponent from "components/page-crypto-limits";
import { useCryptoSend } from "context/CryptoSendProvider";
import { cryptoScreenNames } from "router/routes";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { Projects, trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import AmountPage from "./AmountPage";
import SelectionPage from "./SelectionPage";

import styles from "./styles.module.scss";

const CryptoSendPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    error,
    isLoading,
    sendMethod,
    selectedTag,
    setSendMethod,
    selectedCrypto,
    selectedAccount,
    setSelectedCrypto,
    isLimitsOpen,
    setIsLimitsOpen,
  } = useCryptoSend();

  const previousScreen = useAppSelector(getCurrentScreen);
  const hasReceiver = selectedTag || selectedAccount;

  useEffect(() => {
    trackScreen(
      cryptoScreenNames.send,
      previousScreen,
      undefined,
      Projects.CRYPTO
    );
    dispatch(setCurrentScreen(cryptoScreenNames.send));
  }, []);

  if (isLimitsOpen) {
    return <CryptoLimitsComponent goBack={() => setIsLimitsOpen(false)} />;
  }

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  if (error)
    return (
      <ErrorPage
        withClose
        errorMessage="En este momento no podemos mostrar la información."
      />
    );

  return (
    <div className={styles.sendPageContainer}>
      {(!selectedCrypto || !hasReceiver) && <SelectionPage />}
      {selectedCrypto && hasReceiver && <AmountPage />}
      <SendMethodSheet
        isOpen={!!(!sendMethod && selectedCrypto)}
        onClose={() => setSelectedCrypto(undefined)}
        onSelectMethod={setSendMethod}
      />
    </div>
  );
};

export default CryptoSendPage;

import { useEffect, useState } from "react";

import { Phone } from "lucide-react";
import API from "apis";
import { phoneService } from "apis/services";
import { screenNames } from "router/routes";
import { usePhoneValidation } from "context/PhoneValidationProvider";
import BasicWrapper from "components/common/BasicWrapper";
import { trackScreen, trackAction } from "utils/amplitude";
import { Screens } from "interfaces/phoneValidation";

const PhoneValidationScreen = () => {
  const {
    setScreen,
    setChallengeData,
    goToIntro,
    phone,
    remainingTime,
  } = usePhoneValidation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    trackScreen(screenNames.phoneValidationCurrentNumber, "");
  }, []);

  const onChangNumber = () => {
    trackAction("phonevalidation_chose_change_number");
    setScreen(Screens.CONTACT_SUPPORT);
  };

  const sendCodeValidation = async () => {
    setIsLoading(true);

    try {
      if (remainingTime >= 0) {
        const { data } = await API.post(phoneService.validation);
        setChallengeData(data);
      }

      setScreen(Screens.CHALLENGE);
    } catch (error: any) {
      throw new Error(`Error sending validation sms code: ${error}`);
    } finally {
      trackAction("phonevalidation_chose_keep_number");
      setIsLoading(false);
    }
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Teléfono",
        onClick: goToIntro,
      }}
      icon={Phone}
      title={`¿El +${phone} sigue siendo tu número?`}
      subtitle={
        "Mantené tu número actualizado para asegurarte de poder iniciar sesión en Cocos siempre. Vas a recibir un código de 6 dígitos a continuación para confirmarlo."
      }
      primaryButtonProps={{
        children: `Mantener +${phone}`,
        onClick: sendCodeValidation,
        isLoading,
      }}
      secondaryButtonProps={{
        children: "Cambié mi número",
        onClick: onChangNumber,
      }}
    />
  );
};

export default PhoneValidationScreen;

import { configureStore } from "@reduxjs/toolkit";
import rightModalReducer from "features/rightModal/rightModalSlice";
import pricesReducer from "features/prices/capitalPricesSlice";
import portfolioReducer from "features/wallet/portfolioSlice";
import calculatorReducer from "features/calculator/calculatorSlice";
import userReducer from "features/user/userSlice";
import globalReducer from "features/global/globalSlice";
import marketsReducer from "features/markets/marketsSlice";
import walletReducer from "features/wallet/walletSlice";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    user: userReducer,
    global: globalReducer,
    prices: pricesReducer,
    wallet: walletReducer,
    markets: marketsReducer,
    portfolio: portfolioReducer,
    rightModal: rightModalReducer,
    calculator: calculatorReducer,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const ConnectionLostIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="283"
      height="161"
      viewBox="0 0 283 161"
      fill="none"
    >
      <g clipPath="url(#clip0_2836_51329)">
        <path
          d="M18.5223 155.217H263.246C267.627 155.213 271.871 153.693 275.257 150.916C278.644 148.139 280.963 144.275 281.821 139.983C285.91 119.698 281.329 102.479 236.099 71.5622C184.752 36.4665 178.701 -12.2924 117.63 2.82814C84.7477 10.9708 81.0965 27.1159 31.2469 65.3379C6.61066 84.2275 0.270119 103.096 0.00296831 132.326C-0.157396 149.85 6.46406 155.217 18.5223 155.217Z"
          fill="#F3F5FD"
        />
        <path
          d="M105.698 103.191C105.698 103.191 106.39 99.8688 105.277 97.7853C104.164 95.7019 103.469 96.9849 103.537 98.688C103.605 100.391 103.946 104.084 103.946 104.084"
          fill="#E98862"
        />
        <path
          d="M105.23 101.437C105.23 101.437 104.433 94.2487 98.3593 99.2982C97.1906 100.27 100.425 105.159 100.425 105.159L105.23 101.437Z"
          fill="#E98862"
        />
        <path
          d="M63.1856 155.003C59.5348 155.003 57.6561 153.66 57.5233 153.56L57.9672 152.966C58.0208 153.003 63.4717 156.845 74.1573 150.928C85.3992 144.704 86.5902 139.213 89.4671 125.95C90.3888 121.7 91.5362 116.411 93.2563 110.115C94.8291 104.357 100.862 98.2378 108.943 98.2378V98.9813C101.231 98.9813 95.4739 104.818 93.9744 110.31C92.2592 116.588 91.1143 121.866 90.1942 126.107C87.3523 139.21 86.0548 145.191 74.5179 151.579C69.7203 154.234 65.946 155.003 63.1856 155.003Z"
          fill="black"
        />
        <path
          d="M98.3582 99.2992C98.3191 99.4266 98.3 99.5593 98.3016 99.6925C98.4169 100.364 99.1462 101.134 99.6783 101.215C100.545 101.346 103 100.092 103.786 99.3683C104.52 98.6906 103.496 97.1488 103.368 96.9436C103.363 96.9377 103.358 96.9313 103.354 96.9246C102.629 95.9703 98.6834 98.3437 98.3582 99.2992Z"
          fill="#E98862"
        />
        <path
          d="M141.284 93.9012C141.284 93.9012 138.407 92.377 139.05 84.5485L133.105 83.4473C133.105 83.4473 133.773 90.4657 130.766 92.6941L141.284 93.9012Z"
          fill="#E98862"
        />
        <g opacity="0.15">
          <path
            d="M131.984 91.2269L136.924 87.4523L133.045 87.4121C132.932 88.7387 132.572 90.0324 131.984 91.2269Z"
            fill="black"
          />
        </g>
        <path
          d="M142.422 81.5131C142.011 83.8884 140.714 86.1372 138.617 86.9038C136.331 87.8104 133.859 88.1442 131.415 87.876C124.907 87.3429 126.194 72.5588 129.284 69.6865C132.373 66.8142 141.441 68.6333 142.544 73.4366C143.114 75.9216 142.814 79.2527 142.422 81.5131Z"
          fill="#E98862"
        />
        <path
          d="M124.732 68.3149C124.732 66.3508 127.178 63.1331 133.703 67.5714L133.71 67.5758C135.405 67.5283 137.098 67.7133 138.743 68.1257C139.922 68.4658 141.023 69.0315 141.986 69.7912C143.802 71.2635 145.126 73.6856 144.509 77.5781C143.437 84.3426 140.664 86.1479 139.267 86.9777C138.911 87.1889 138.824 87.0149 138.701 86.6219C138.313 85.1614 138.036 83.6738 137.872 82.1718C137.659 80.6103 136.255 79.6177 136.425 77.7476C136.484 77.0937 137.537 74.6991 134.277 74.6715C132.443 74.6567 133.129 74.7295 131.172 74.7295C130.893 74.7295 130.646 74.7169 130.419 74.6968H130.417C123.66 74.6968 124.732 70.2786 124.732 68.3149Z"
          fill="#0F1011"
        />
        <path
          d="M135.919 80.4631C135.879 81.0619 136.076 81.6525 136.468 82.1072C136.86 82.5619 137.415 82.8442 138.014 82.893C138.612 82.9419 139.206 82.7534 139.667 82.3682C140.127 81.9831 140.418 81.4323 140.475 80.8349C140.505 80.535 140.474 80.2322 140.384 79.9445C140.294 79.6568 140.147 79.3901 139.952 79.1604C139.757 78.9306 139.518 78.7424 139.248 78.607C138.979 78.4716 138.685 78.3917 138.384 78.3723C136.754 78.3206 136.021 79.2062 135.919 80.4631Z"
          fill="#E98862"
        />
        <path
          d="M136.924 73.2188L138.607 73.8344C138.607 73.8344 138.011 82.2366 137.539 84.5323C137.067 86.828 135.751 88.3244 134.257 88.4523C131.964 88.6486 130.302 88.6642 128.62 88.0638C126.723 87.3864 126.989 82.3013 126.989 82.3013C126.989 82.3013 133.093 82.9619 134.782 82.1525C136.471 81.3432 136.924 73.2188 136.924 73.2188Z"
          fill="#0F1011"
        />
        <path
          d="M133.113 80.0554C133.411 80.0554 133.653 79.8142 133.653 79.5167C133.653 79.2192 133.411 78.978 133.113 78.978C132.816 78.978 132.574 79.2192 132.574 79.5167C132.574 79.8142 132.816 80.0554 133.113 80.0554Z"
          fill="#0F1011"
        />
        <path
          d="M128.86 79.7068C129.158 79.7068 129.399 79.4656 129.399 79.1681C129.399 78.8706 129.158 78.6294 128.86 78.6294C128.562 78.6294 128.321 78.8706 128.321 79.1681C128.321 79.4656 128.562 79.7068 128.86 79.7068Z"
          fill="#0F1011"
        />
        <path
          d="M127.926 77.6485C127.859 77.6489 127.794 77.6227 127.747 77.5755C127.699 77.5284 127.672 77.4641 127.671 77.397C127.671 77.3298 127.697 77.2653 127.744 77.2175C127.791 77.1697 127.856 77.1426 127.923 77.1421L129.351 77.1247C129.384 77.1235 129.418 77.1291 129.449 77.1412C129.48 77.1532 129.509 77.1714 129.532 77.1947C129.556 77.218 129.575 77.2459 129.588 77.2768C129.601 77.3076 129.608 77.3407 129.607 77.3741C129.608 77.4074 129.602 77.4404 129.589 77.4713C129.577 77.5022 129.559 77.5303 129.536 77.5541C129.512 77.578 129.485 77.597 129.454 77.6101C129.423 77.6232 129.391 77.6302 129.357 77.6306L127.929 77.6485H127.926Z"
          fill="#0F1011"
        />
        <path
          d="M134.277 78.1689C134.257 78.1689 134.237 78.1666 134.218 78.1622L132.81 77.8232C132.746 77.8063 132.691 77.765 132.657 77.7082C132.623 77.6514 132.613 77.5836 132.628 77.5192C132.644 77.4549 132.684 77.3992 132.74 77.364C132.796 77.3288 132.863 77.3169 132.928 77.3309L134.336 77.6696C134.396 77.6841 134.449 77.7202 134.484 77.771C134.52 77.8218 134.535 77.8838 134.528 77.9453C134.521 78.0068 134.491 78.0635 134.445 78.1047C134.399 78.1459 134.339 78.1688 134.277 78.1689Z"
          fill="#0F1011"
        />
        <g opacity="0.15">
          <path
            d="M130.72 82.1328C130.702 82.1328 130.684 82.13 130.666 82.1246L129.368 81.7261C129.343 81.7184 129.32 81.7054 129.3 81.688C129.28 81.6706 129.264 81.6492 129.253 81.6253C129.242 81.6013 129.237 81.5753 129.237 81.549C129.236 81.5226 129.242 81.4966 129.253 81.4725L130.106 79.5739C130.127 79.5295 130.164 79.4951 130.21 79.478C130.256 79.4609 130.306 79.4626 130.351 79.4827C130.396 79.5027 130.43 79.5395 130.448 79.5851C130.466 79.6307 130.465 79.6815 130.445 79.7263L129.679 81.4327L130.775 81.7692C130.818 81.7823 130.854 81.8102 130.878 81.8479C130.902 81.8856 130.911 81.9305 130.904 81.9745C130.898 82.0186 130.875 82.0588 130.842 82.0878C130.808 82.1169 130.765 82.1328 130.72 82.1328Z"
            fill="black"
          />
        </g>
        <path
          d="M129.373 84.4102C129.373 84.4102 131.362 84.2006 132.758 84.6876C132.758 84.6876 132.748 83.3778 131.173 83.2488C129.598 83.1198 129.373 84.4102 129.373 84.4102Z"
          fill="white"
        />
        <path
          d="M118.552 151.845C117.845 151.727 109.87 150.366 109.87 150.366L111.186 142.67C111.186 142.67 119.14 144.028 119.845 144.146L118.552 151.845Z"
          fill="#E98862"
        />
        <path
          d="M105.321 159.227C105.287 159.435 105.296 159.647 105.348 159.85C105.4 160.054 105.493 160.244 105.622 160.41C105.751 160.576 105.913 160.713 106.097 160.814C106.282 160.914 106.485 160.976 106.695 160.994C106.985 161.02 107.276 160.962 107.534 160.827C107.793 160.693 108.007 160.488 108.153 160.236L112.659 151.776C112.746 151.612 112.796 151.431 112.806 151.246C112.817 151.061 112.787 150.876 112.719 150.703C112.07 149.328 111.879 147.781 112.176 146.289C112.454 145.136 113.052 144.083 113.9 143.253C113.982 143.181 114.042 143.087 114.073 142.983C114.104 142.878 114.105 142.767 114.075 142.662C114.045 142.557 113.986 142.463 113.904 142.391C113.823 142.318 113.722 142.27 113.614 142.252L109.228 141.519C109.099 141.497 108.967 141.501 108.839 141.531C108.711 141.56 108.59 141.615 108.484 141.691C108.377 141.767 108.287 141.864 108.217 141.975C108.148 142.087 108.102 142.211 108.081 142.34L105.321 159.227Z"
          fill="#C1D8ED"
        />
        <path
          d="M143.242 131.03C146.552 131.168 149.923 131.265 153.294 131.348L158.36 131.46L163.42 131.551L163.924 131.56C166.014 131.599 168.012 132.429 169.514 133.881C171.016 135.334 171.91 137.302 172.018 139.388C172.125 141.473 171.437 143.522 170.092 145.122C168.748 146.721 166.846 147.751 164.771 148.004L114.764 154.071L112.728 139.305L162.512 131.625L163.862 148.078L158.725 148.41L153.59 148.72C150.162 148.918 146.731 149.103 143.24 149.247L143.242 131.03Z"
          fill="#262626"
        />
        <path
          d="M155.857 148.612C148.954 147.608 148.266 147.424 148.04 147.364L150.067 139.824C150.044 139.817 150.015 139.81 149.979 139.802C150.545 139.925 154.054 140.461 156.981 140.886L155.857 148.612Z"
          fill="#E98862"
        />
        <path
          d="M164.481 155.323C164.553 155.52 164.584 155.73 164.572 155.939C164.56 156.149 164.504 156.354 164.409 156.541C164.314 156.728 164.181 156.894 164.019 157.028C163.857 157.161 163.669 157.26 163.467 157.318C163.187 157.398 162.89 157.396 162.611 157.314C162.332 157.231 162.082 157.07 161.891 156.85L155.86 149.399C155.744 149.254 155.661 149.087 155.616 148.907C155.571 148.727 155.565 148.54 155.599 148.358C155.975 146.885 155.868 145.329 155.294 143.921C154.801 142.842 154.015 141.922 153.024 141.267C152.93 141.213 152.853 141.132 152.803 141.035C152.753 140.938 152.731 140.829 152.741 140.721C152.75 140.612 152.79 140.508 152.857 140.421C152.923 140.335 153.012 140.269 153.115 140.231L157.282 138.678C157.405 138.633 157.536 138.612 157.667 138.616C157.798 138.621 157.927 138.652 158.046 138.707C158.165 138.761 158.272 138.839 158.361 138.935C158.45 139.031 158.52 139.144 158.565 139.267L164.481 155.323Z"
          fill="#C1D8ED"
        />
        <path
          d="M132.139 147.909C130.043 147.731 127.949 147.602 125.854 147.481C123.76 147.37 121.666 147.258 119.573 147.184L116.432 147.063L113.292 146.973C111.199 146.903 109.106 146.874 107.013 146.835L107.739 131.811C111.657 132.359 115.579 132.862 119.501 133.377C123.422 133.892 127.349 134.367 131.274 134.849L137.165 135.543L143.06 136.203C146.993 136.626 150.925 137.045 154.866 137.391L153.137 152.199C149.222 151.628 145.298 151.132 141.375 150.637L135.485 149.923L129.592 149.243C125.662 148.809 121.732 148.369 117.798 147.967C113.864 147.565 109.93 147.153 105.991 146.784C104.102 146.608 102.349 145.724 101.084 144.312C99.8185 142.899 99.1337 141.061 99.1667 139.165C99.1996 137.27 99.9477 135.456 101.261 134.088C102.575 132.72 104.357 131.899 106.251 131.787L106.717 131.761C108.807 131.639 110.897 131.527 112.986 131.375L116.119 131.161L119.253 130.916C121.341 130.759 123.429 130.564 125.518 130.37C127.606 130.167 129.693 129.956 131.78 129.695L132.139 147.909Z"
          fill="#262626"
        />
        <path
          d="M150.845 128.75L151.947 147.229L120.138 146.856L122.539 130.495L150.845 128.75Z"
          fill="#262626"
        />
        <path
          d="M114.098 124.929C113.438 124.928 112.79 124.762 112.213 124.444C111.635 124.126 111.148 123.667 110.796 123.11L99.5172 105.272L106.123 101.103L113.557 112.859L121.416 95.7783L128.515 99.041L117.65 122.657C117.357 123.296 116.896 123.843 116.316 124.241C115.737 124.64 115.061 124.875 114.359 124.922C114.27 124.926 114.184 124.929 114.098 124.929Z"
          fill="#367CFF"
        />
        <path
          d="M120.301 131.74C121.86 135.32 152.493 136.807 152.902 130.51C153.162 126.502 149.628 123.003 149.628 115.192C149.628 107.381 156.82 100.753 150.96 94.8971C149.259 93.1981 146.324 92.1014 142.744 92.0977C142.476 94.1833 129.803 94.1908 129.707 92.146C129.223 92.1534 128.847 92.1609 128.613 92.172C116.267 92.7037 121.692 103.396 121.811 113.709C121.956 125.777 118.692 128.052 120.301 131.74Z"
          fill="#367CFF"
        />
        <path
          d="M129.687 92.146C129.784 94.1908 130.896 97.1984 134.331 97.4252C138.907 97.7264 142.457 94.1833 142.725 92.0977H142.713C138.208 92.0977 132.314 92.1051 129.687 92.146Z"
          fill="#E98862"
        />
        <path
          d="M158.655 126.448C157.8 126.448 156.966 126.178 156.274 125.676L134.25 109.718L139.012 103.155L149.39 110.675L145.307 100.853L152.799 97.7432L162.401 120.84C162.658 121.456 162.758 122.125 162.693 122.789C162.628 123.453 162.4 124.091 162.03 124.645C161.659 125.2 161.157 125.655 160.569 125.969C159.98 126.284 159.323 126.448 158.655 126.448H158.655Z"
          fill="#367CFF"
        />
        <path
          d="M225.303 155.006C219.255 155.012 213.228 154.299 207.349 152.88C199.056 150.909 191.24 147.304 184.359 142.277C175.984 136.097 169.215 127.742 164.24 117.442C160.028 108.723 153.578 103.039 146.543 101.847C140.98 100.905 135.455 102.847 130.983 107.312L130.457 106.787C135.103 102.144 140.859 100.128 146.667 101.113C153.943 102.345 160.592 108.176 164.91 117.117C176.048 140.176 194.217 148.895 207.498 152.149C221.913 155.681 233.848 153.723 233.967 153.703L234.092 154.436C231.181 154.845 228.243 155.035 225.303 155.006Z"
          fill="black"
        />
        <path
          d="M139.59 106.745C139.59 106.745 139.241 103.37 137.548 101.722C135.856 100.073 135.583 101.506 136.164 103.109C136.746 104.711 138.193 108.128 138.193 108.128"
          fill="#E98862"
        />
        <path
          d="M139.139 105.075C139.139 105.075 135.277 97.5456 131.021 104.197C130.203 105.477 135.887 109.745 135.887 109.745L139.139 105.075Z"
          fill="#E98862"
        />
        <path
          d="M139.013 103.157L134.25 109.719L156.274 125.677L161.037 119.115L139.013 103.157Z"
          fill="#367CFF"
        />
        <path
          d="M229.749 131.859L228.313 153.249C228.296 153.501 228.331 153.754 228.416 153.992C228.501 154.231 228.634 154.449 228.806 154.634C228.979 154.818 229.188 154.966 229.42 155.066C229.652 155.167 229.903 155.219 230.156 155.219H260.056C260.512 155.219 260.953 155.05 261.292 154.744C261.632 154.438 261.845 154.018 261.892 153.564L264.105 132.173C264.131 131.916 264.104 131.657 264.023 131.411C263.943 131.166 263.812 130.94 263.639 130.748C263.466 130.556 263.254 130.403 263.018 130.298C262.782 130.193 262.526 130.139 262.268 130.139H231.592C231.124 130.139 230.673 130.316 230.331 130.635C229.989 130.955 229.781 131.392 229.749 131.859Z"
          fill="#9EBCEA"
        />
        <path
          d="M230.944 132.055L229.47 153.612C229.464 153.707 229.477 153.802 229.509 153.892C229.541 153.982 229.592 154.065 229.657 154.134C229.722 154.204 229.801 154.26 229.889 154.298C229.976 154.336 230.071 154.355 230.166 154.355H260.026C260.199 154.355 260.365 154.291 260.493 154.176C260.622 154.06 260.702 153.901 260.72 153.73L262.992 132.173C263.002 132.076 262.991 131.978 262.961 131.885C262.931 131.792 262.881 131.707 262.816 131.634C262.751 131.562 262.671 131.504 262.581 131.464C262.492 131.425 262.396 131.404 262.298 131.404H231.639C231.462 131.405 231.292 131.472 231.163 131.593C231.035 131.714 230.956 131.879 230.944 132.055Z"
          fill="white"
        />
        <path
          d="M261.215 152.642H222.08C221.368 152.642 220.79 153.218 220.79 153.93C220.79 154.641 221.368 155.218 222.08 155.218H261.215C261.927 155.218 262.504 154.641 262.504 153.93C262.504 153.218 261.927 152.642 261.215 152.642Z"
          fill="#9EBCEA"
        />
        <path
          d="M195.06 105.136C197.889 105.136 200.182 102.844 200.182 100.018C200.182 97.1909 197.889 94.8994 195.06 94.8994C192.231 94.8994 189.938 97.1909 189.938 100.018C189.938 102.844 192.231 105.136 195.06 105.136Z"
          fill="#9EBCEA"
        />
        <path
          d="M204.572 94.5427C204.3 94.5429 204.033 94.4753 203.793 94.3462C203.554 94.217 203.351 94.0303 203.202 93.8029C202.893 93.3329 202.543 92.8916 202.155 92.4842L201.966 92.2983C201.059 91.3902 199.982 90.6697 198.797 90.1781C197.611 89.6865 196.34 89.4335 195.056 89.4335C193.772 89.4335 192.501 89.6865 191.315 90.1781C190.13 90.6697 189.053 91.3902 188.146 92.2983C187.686 92.7562 187.275 93.2603 186.918 93.8025C186.674 94.1494 186.304 94.3878 185.887 94.4676C185.47 94.5474 185.039 94.4622 184.683 94.2301C184.328 93.998 184.077 93.6371 183.983 93.2235C183.889 92.8098 183.959 92.3759 184.179 92.0132C184.659 91.2826 185.213 90.6034 185.832 89.9862C188.192 87.6241 191.369 86.2573 194.708 86.1682C198.047 86.0791 201.292 87.2744 203.775 89.5074H203.8L204.287 89.9862C204.906 90.6031 205.46 91.2821 205.939 92.0124C206.101 92.259 206.192 92.5445 206.205 92.8388C206.217 93.1331 206.15 93.4252 206.01 93.6844C205.87 93.9436 205.662 94.1602 205.409 94.3113C205.156 94.4625 204.867 94.5425 204.572 94.5431L204.572 94.5427Z"
          fill="#9EBCEA"
        />
        <path
          d="M178.7 90.0135C178.404 90.0131 178.114 89.9325 177.86 89.7805C177.607 89.6285 177.399 89.4107 177.259 89.1502C177.12 88.8897 177.053 88.5962 177.067 88.301C177.081 88.0058 177.175 87.7198 177.338 87.4735C178.125 86.2897 179.027 85.1869 180.032 84.1815C184.018 80.2003 189.424 77.9639 195.06 77.9639C200.696 77.9639 206.102 80.2003 210.088 84.1815C211.093 85.1842 211.995 86.2842 212.781 87.4654C212.909 87.644 212.999 87.8463 213.046 88.0605C213.094 88.2746 213.098 88.496 213.058 88.7117C213.018 88.9273 212.935 89.1328 212.814 89.3158C212.693 89.4988 212.537 89.6556 212.354 89.777C212.171 89.8983 211.966 89.9817 211.75 90.0222C211.534 90.0626 211.313 90.0593 211.098 90.0125C210.884 89.9656 210.681 89.8762 210.502 89.7495C210.323 89.6227 210.171 89.4613 210.056 89.2748C209.389 88.2745 208.625 87.3428 207.774 86.4935C204.402 83.1255 199.828 81.2336 195.06 81.2336C190.291 81.2336 185.718 83.1255 182.345 86.4935C181.494 87.3452 180.73 88.2793 180.064 89.2819C179.915 89.5068 179.712 89.6914 179.474 89.8191C179.236 89.9467 178.97 90.0135 178.7 90.0135Z"
          fill="#9EBCEA"
        />
        <path
          d="M218.121 85.5669C217.851 85.5671 217.585 85.5005 217.347 85.3732C217.109 85.2459 216.906 85.0617 216.757 84.8371C215.794 83.3898 214.689 82.0415 213.46 80.8119C208.58 75.9367 201.961 73.1979 195.06 73.1979C188.159 73.1979 181.54 75.9367 176.66 80.8119C175.429 82.0447 174.321 83.3943 173.352 84.8416C173.106 85.1914 172.733 85.431 172.312 85.509C171.891 85.5871 171.457 85.4975 171.101 85.2594C170.746 85.0212 170.498 84.6535 170.41 84.2349C170.323 83.8163 170.403 83.3802 170.633 83.0199C171.721 81.3961 172.964 79.8819 174.346 78.4987C179.84 73.0104 187.291 69.9272 195.06 69.9272C202.828 69.9272 210.279 73.0104 215.773 78.4987C217.156 79.8816 218.398 81.3981 219.482 83.0258C219.645 83.2721 219.739 83.558 219.753 83.8533C219.767 84.1486 219.701 84.4421 219.561 84.7027C219.422 84.9633 219.214 85.1812 218.96 85.3334C218.707 85.4855 218.417 85.5664 218.121 85.5669Z"
          fill="#9EBCEA"
        />
        <path
          d="M213.074 113.894C219.914 113.894 225.46 108.354 225.46 101.519C225.46 94.6839 219.914 89.1431 213.074 89.1431C206.233 89.1431 200.688 94.6839 200.688 101.519C200.688 108.354 206.233 113.894 213.074 113.894Z"
          fill="#FD5D76"
        />
        <path
          d="M217.96 107.906C217.783 107.906 217.608 107.871 217.445 107.802C217.282 107.732 217.135 107.631 217.012 107.503L207.241 97.3539C207.121 97.2297 207.027 97.0831 206.964 96.9224C206.901 96.7617 206.87 96.5902 206.873 96.4176C206.876 96.245 206.913 96.0747 206.982 95.9165C207.052 95.7583 207.151 95.6153 207.276 95.4955C207.4 95.3758 207.547 95.2818 207.708 95.2189C207.869 95.1559 208.04 95.1253 208.213 95.1287C208.386 95.1321 208.556 95.1695 208.715 95.2387C208.873 95.308 209.016 95.4077 209.135 95.5322L218.907 105.682C219.086 105.867 219.206 106.101 219.252 106.355C219.299 106.608 219.27 106.869 219.169 107.106C219.068 107.344 218.9 107.546 218.685 107.688C218.47 107.83 218.217 107.905 217.96 107.905V107.906Z"
          fill="white"
        />
        <path
          d="M208.188 107.906C207.93 107.906 207.678 107.831 207.463 107.689C207.248 107.547 207.079 107.345 206.978 107.107C206.877 106.87 206.849 106.609 206.895 106.356C206.942 106.102 207.062 105.868 207.241 105.683L217.012 95.5331C217.132 95.4088 217.275 95.3093 217.433 95.2402C217.592 95.1712 217.762 95.1339 217.934 95.1306C218.107 95.1273 218.279 95.158 218.439 95.2209C218.6 95.2839 218.747 95.3778 218.871 95.4974C218.996 95.6171 219.095 95.76 219.164 95.9181C219.233 96.0761 219.271 96.2463 219.274 96.4188C219.277 96.5912 219.247 96.7627 219.184 96.9233C219.121 97.0839 219.027 97.2305 218.907 97.3548L209.135 107.504C209.013 107.632 208.866 107.733 208.703 107.802C208.54 107.871 208.365 107.907 208.188 107.906Z"
          fill="white"
        />
        <path
          d="M242.945 136.915L242.264 136.235L237.848 140.648L238.529 141.328L242.945 136.915Z"
          fill="#9EBCEA"
        />
        <path
          d="M242.264 141.328L242.945 140.647L238.529 136.235L237.849 136.915L242.264 141.328Z"
          fill="#9EBCEA"
        />
        <path
          d="M253.249 136.915L252.568 136.235L248.153 140.648L248.833 141.328L253.249 136.915Z"
          fill="#9EBCEA"
        />
        <path
          d="M252.568 141.328L253.249 140.647L248.833 136.235L248.153 136.915L252.568 141.328Z"
          fill="#9EBCEA"
        />
        <path
          d="M241.083 148.867L240.37 148.215C240.448 148.13 242.319 146.133 245.831 146.133C249.362 146.133 251.01 148.153 251.079 148.24L250.322 148.84C250.266 148.771 248.874 147.098 245.831 147.098C242.739 147.099 241.099 148.849 241.083 148.867Z"
          fill="#9EBCEA"
        />
        <path
          d="M63.6622 155.216H19.092C15.8225 152.609 15.597 146.735 18.9859 144.112C21.5979 142.091 25.5467 142.631 28.0017 144.734C27.5429 143.169 27.5339 141.506 27.9756 139.936C28.7741 137.108 30.5485 134.234 33.2591 133.091C40.1734 130.176 45.0863 136.284 46.0708 139.699C47.8408 137.096 51.8008 135.723 54.2866 137.474C57.4355 139.691 57.293 143.188 54.7811 145.698C58.1298 145.074 60.6483 145.555 62.9697 148.045C64.7356 149.941 65.0061 153.172 63.6622 155.216Z"
          fill="#8DDBE0"
        />
        <path
          d="M224.461 30.4894H140.289C140.765 27.2944 142.514 24.3328 145.187 22.887C148.492 21.0984 152.51 21.6263 156.238 22.0877C159.965 22.549 164.085 22.8078 167.146 20.631C168.686 19.5358 169.776 17.939 171.023 16.5185C174.588 12.4542 180.208 9.67405 185.422 11.1087C188.047 11.8307 190.286 13.519 192.774 14.622C195.388 15.7752 198.246 16.2704 201.096 16.0641C204.036 15.8467 206.959 14.886 209.875 15.3169C213.57 15.8664 216.597 18.5766 218.788 21.5999C220.864 24.4655 222.347 27.2442 224.461 30.4894Z"
          fill="#DEE8F5"
        />
        <path
          d="M119.198 54.9194H35.1173C34.6976 54.13 34.4646 53.2551 34.4361 52.3618C34.4076 51.4684 34.5843 50.5805 34.9526 49.766C35.321 48.9515 35.8713 48.2322 36.5612 47.6633C37.2511 47.0944 38.0623 46.691 38.9325 46.4841C41.6777 45.8335 44.7804 47.1686 47.2826 45.8685C49.2241 44.8587 50.1212 42.5797 50.5342 40.4338C50.9472 38.288 51.0644 36.0212 52.1073 34.0999C53.8598 30.8736 58.0211 29.4326 61.6139 30.2014C65.2066 30.9703 68.1911 33.5846 70.1556 36.6875C70.9638 37.963 71.6979 39.404 73.0295 40.1163C74.5673 40.9372 76.4809 40.5461 78.06 39.8107C79.6391 39.0754 81.0738 38.018 82.7266 37.4719C84.4128 36.9536 86.2195 36.9784 87.8909 37.5427C89.5622 38.107 91.0136 39.1823 92.0396 40.6164C93.4591 42.6902 94.0533 45.5447 96.2813 46.7068C97.2278 47.1347 98.2596 47.3409 99.2981 47.3098C103.424 47.4095 107.665 46.4488 111.614 47.6489C113.349 48.2027 114.935 49.1458 116.249 50.4059C117.564 51.666 118.572 53.2099 119.198 54.9194Z"
          fill="#DEE8F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_2836_51329">
          <rect width="283" height="161" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import React, { ChangeEvent, useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import classNames from "classnames";
import QuietButton, { QuietButtonSizes } from "components/common/QuietButton";
import { InputAdornment } from "@mui/material";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

export enum InputTypes {
  Text = "text",
  Date = "date",
  Email = "email",
  Number = "number",
  Tel = "tel",
  Password = "password",
}

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  withPaste?: boolean;
  iconRight?: JSX.Element;
  onIconClick?: (event?: any) => void;
  onClickPaste?: (v: string) => void;
  variant?: ThemeVariants;
}

const InputField: React.FC<InputFieldProps> = ({
  type = InputTypes.Text,
  variant = ThemeVariants.Crypto,
  label,
  hasError,
  errorMessage,
  iconRight,
  withPaste = false,
  onIconClick,
  onChange,
  onClickPaste,
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputFieldStyles = classNames(styles.input, {
    [styles.inputError]: hasError,
  });

  const pasteText = async () => {
    try {
      const { value } = await Clipboard.read();
      setInputValue(value);
      onClickPaste && onClickPaste(value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange && onChange(event);
  };

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={props.id} className={styles.label}>
        {label}
      </label>

      <input
        type={type}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        value={inputValue}
        className={inputFieldStyles}
        id={props.id}
        name={props.name}
        {...props}
      />
      {iconRight && (
        <InputAdornment
          className={onIconClick ? "cursor" : ""}
          onClick={onIconClick}
          position="end"
        >
          {iconRight}
        </InputAdornment>
      )}

      {withPaste && (
        <QuietButton
          size={QuietButtonSizes.Small}
          variant={
            variant === ThemeVariants.Crypto
              ? ThemeVariants.Crypto
              : ThemeVariants.Capital
          }
          onClick={pasteText}
        >
          Pegar
        </QuietButton>
      )}
      {hasError && (
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--red800)"
          className={styles.errorMessage}
        >
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

export default InputField;

import Text, { TextVariants } from "components/common//Text";
import AvailableLabel from "components/common/AvailableLabel";
import Variation from "components/common/CurrentVariation";

import styles from "./styles.module.scss";

interface BalanceProps {
  available: number;
  variation: number;
  amount: number;
  dateToDisplay?: string;
  variationLabel?: string;
}

const Balance: React.FC<BalanceProps> = ({
  amount,
  available,
  variation,
  dateToDisplay,
  variationLabel,
}) => {
  return (
    <div className={styles.balanceContainer}>
      <AvailableLabel available={available} />
      {dateToDisplay ? (
        <Text variant={TextVariants.RegularTextS}>{dateToDisplay}</Text>
      ) : (
        <Variation value={variation} amount={amount} text={variationLabel} />
      )}
    </div>
  );
};

export default Balance;

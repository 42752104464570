import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { Factor } from "@supabase/supabase-js";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import DefaultSection from "components/page-crypto-profile/DefaultSection";
import { useBiometry } from "hooks/useBiometry";
import { MFAType } from "interfaces/auth";
import { ThemeVariants } from "interfaces/theme";
import {
  ArrowLeft,
  KeySquare,
  LockKeyhole,
  ScanFace,
  ShieldCheck,
} from "lucide-react";
import { useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { RootState } from "store/store";
import px2rem from "utils/px2rem";
import { supabase } from "utils/supabase";

import styles from "./styles.module.scss";

const SecuritySection: React.FC = () => {
  const navigate = useNavigate();
  const { biometricsSignIn } = useBiometry();

  const [factors, setFactors] = useState<Factor[]>([]);
  const twoFAColor = factors.length > 0 ? "var(--green800)" : "var(--red800)";
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    ampli.cryptoScreenSecurity();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await supabase.auth.mfa.listFactors();

      if (data) {
        const factors = data.all.filter(
          (x) => x.status === "verified" && x.factor_type === MFAType.TOTP
        );

        setFactors(factors);
      }
      setLoading(false);
    })();
  }, []);

  const signInWithBiometrics = useAppSelector((state: RootState) => {
    return Boolean(state.user.user?.signInWithBiometrics);
  });

  const handleSignInWithBiometrics = async (signInWithBiometrics: boolean) => {
    await biometricsSignIn.setEnabled(signInWithBiometrics);
  };

  const securityOptions = [
    {
      label: "Cambiar contraseña",
      icon: LockKeyhole,
      onClick: () => navigate(mainRoutes.forgotPassword),
      rightIcon: <ShieldCheck color="var(--green800)" size={px2rem(20)} />,
    },
    {
      label: "Verificación en 2 pasos",
      icon: KeySquare,
      onClick: () => navigate(CryptoRoutes.TWOFA),
      rightIcon: <ShieldCheck color={twoFAColor} size={px2rem(20)} />,
    },
    /*{
      label: "Código PIN",
      icon: Asterisk,
      onClick: () => () => navigate("/"),
      rightIcon: <ShieldCheck color="var(--red800)" size={px2rem(20)} />,
    },*/
    {
      label: "Iniciar sesión con biometría",
      icon: ScanFace,
      onClick: () => handleSignInWithBiometrics(!signInWithBiometrics),
      isToggle: true,
      toggleValue: signInWithBiometrics,
    },
  ];

  if (loading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} />
      </div>
    );

  return (
    <div className={styles.securitySectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Seguridad"
        onClick={() => navigate(-1)}
      />
      <DefaultSection title="Seguridad" options={securityOptions} />
    </div>
  );
};

export default SecuritySection;

import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import SectionTitle from "components/common/SectionTitle";

import styles from "./styles.module.scss";

const PerformanceSection: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.performanceSectionContainer}>
      <SectionTitle
        label="Rendimiento"
        onClick={() => navigate(CryptoRoutes.PERFORMANCE)}
      />
      {/* FUTURO COMPONENTE */}
    </div>
  );
};

export default PerformanceSection;

export enum CardStatus {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  EXPIRED = "EXPIRED",
  DISABLED = "DISABLED",
  REJECTED = "REJECTED",
  CREATED = "CREATED",
}

export enum UserStatus {
  ACTIVE = "ACTIVE",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export enum CardTypes {
  PHYSICAL = "PHYSICAL",
  VIRTUAL = "VIRTUAL",
}

export enum CardTypesLabel {
  PHYSICAL = "Física",
  VIRTUAL = "Virtual",
}

export enum CardSections {
  ONBOARDING = "ONBOARDING",
  CARD_SELECTION = "CARD_SELECTION",
  VIRTUAL_READY = "VIRTUAL_READY",
  PHYSICAL_INTRO = "PHYSICAL_INTRO",
  SHIPPING_INFO = "SHIPPING_INFO",
  SHIPPING_PAYMENT = "SHIPPING_PAYMENT",
  PHYSICAL_READY = "PHYSICAL_READY",
  PHYSICAL_ACTIVATION = "PHYSICAL_ACTIVATION",
  HOME = "HOME",
}

export enum CardTransactionStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  REFUND = "REFUND",
}

export enum CardTransactionType {
  PURCHASE = "PURCHASE",
  WITHDRAWAL = "WITHDRAWAL",
  EXTRACASH = "EXTRACASH",
  BALANCE_INQUIRY = "BALANCE_INQUIRY",
  REFUND = "REFUND",
  PAYMENT = "PAYMENT",
  REVERSAL_PURCHASE = "REVERSAL_PURCHASE",
  REVERSAL_WITHDRAWAL = "REVERSAL_WITHDRAWAL",
  REVERSAL_EXTRACASH = "REVERSAL_EXTRACASH",
  REVERSAL_BALANCE_INQUIRY = "REVERSAL_BALANCE_INQUIRY",
  REVERSAL_REFUND = "REVERSAL_REFUND",
  REVERSAL_PAYMENT = "REVERSAL_PAYMENT",
}

export enum CardTransactionActionType {
  DEBIT = "debit",
  CREDIT = "credit",
}

import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";

const Error: React.FC<IconProps> = ({ size = px2rem(84), ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 84 84"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_10603_9139)">
      <path
        d="M59.3975 0H24.6025L0 24.6025V59.3975L24.6025 84.0032H59.3975L84.0032 59.3975V24.6025L59.3975 0Z"
        fill="var(--red700)"
      />
      <path
        d="M56.465 7.07617H27.5349L7.07588 27.5352V56.4653L27.5349 76.9244H56.465L76.9241 56.4653V27.5352L56.465 7.07617Z"
        stroke="white"
        strokeWidth="1.3564"
        strokeMiterlimit="10"
      />
      <path
        d="M57.9442 25.9395L25.4648 58.4156"
        stroke="white"
        strokeWidth="1.3564"
        strokeMiterlimit="10"
      />
      <path
        d="M25.4648 25.9395L57.9442 58.4156"
        stroke="white"
        strokeWidth="1.3564"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_10603_9139">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Error;

interface PropTypes {
  size?: number | string;
  color?: string;
}

export const EllipseIcon = (props: PropTypes) => {
  const { size = 8, color = "#0062E1" } = props;

  return (
    <svg
      fill="none"
      height={size}
      viewBox="0 0 8 8"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4" cy="4" fill={color} r="4" />
    </svg>
  );
};

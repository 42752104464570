import NumbersBadge from "components/common/Badges/NumbersBadge";
import Text, { TextVariants } from "components/common/Text";
import Currency from "components/common/Text/Currency";
import { Limits } from "components/page-crypto-limits/utils";
import { Currencies } from "interfaces/wallet";
import { Send } from "lucide-react";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";

import LimitUsed from "./components/LimitUsed";

import styles from "./styles.module.scss";

interface LimitProps {
  used: number;
  available: number;
  max: number;
  type: Limits;
  currency?: Currencies;
}

const LimitData: React.FC<LimitProps> = ({
  used,
  available,
  max,
  type,
  currency = Currencies.USD,
}) => {
  const usedPercentage = used / max;

  return (
    <div className={styles.LimitContainer}>
      <div className={styles.row}>
        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          <Send size={px2rem(12)} color="var(--slate800)" />
          Límite de envío
        </Text>
        <NumbersBadge
          className={styles.badge}
          variant={ThemeVariants.Crypto}
          number={type}
        />
      </div>
      <LimitUsed used={used} percentage={usedPercentage} />
      <div className={styles.row}>
        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          Disp.:
          <Currency
            className={styles.currency}
            amount={available}
            ticker={currency}
            color="var(--slate900)"
          />
        </Text>

        <Text
          className={styles.limitText}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          Máx..:
          <Currency
            className={styles.currency}
            amount={max}
            ticker={currency}
            color="var(--slate900)"
          />
        </Text>
      </div>
    </div>
  );
};

export default LimitData;

import CryptoAccountsPage from "components/page-crypto-accounts";

import styles from "./styles.module.scss";

const AgendaSection: React.FC = () => {
  return (
    <div className={styles.agendaSectionContainer}>
      <CryptoAccountsPage />
    </div>
  );
};

export default AgendaSection;

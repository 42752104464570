export enum MFAType {
  TOTP = "totp",
  MAIL = "mail",
  SMS = "sms",
  ENROLL = "enroll",
}

export enum MFAErrorType {
  MFA_INVALID_IP_ADDRESS = "MFA_INVALID_IP_ADDRESS",
  MFA_INVALID_CODE = "MFA_INVALID_CODE",
  MFA_CHALLENGE_EXPIRED = "MFA_CHALLENGE_EXPIRED",
  INVALID_SECURITY_CODE = "INVALID_SECURITY_CODE",
}

export const MFAErrors = [
  MFAErrorType.INVALID_SECURITY_CODE,
  MFAErrorType.MFA_CHALLENGE_EXPIRED,
  MFAErrorType.MFA_INVALID_CODE,
  MFAErrorType.MFA_INVALID_IP_ADDRESS,
];

export enum TokenType {
  RECOVERY = "recovery",
  SIGN_UP = "signup",
}

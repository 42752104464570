import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";

import styles from "./styles.module.scss";

type ButtonLabelProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const ButtonLabel: React.FC<ButtonLabelProps> = ({ className, ...props }) => {
  return <button {...props} className={classNames(styles.button, className)} />;
};

export default ButtonLabel;

import BrazePlugin from "braze-cordova-sdk";
import { FeatureFlag, InitializationOptions } from "@braze/web-sdk";

import { BrazeInterface } from "./types";

export class BrazeMobileClient implements BrazeInterface {
  changeUser(userId: string): void {
    BrazePlugin.changeUser(userId);
  }

  getDeviceId(): string | undefined {
    return BrazePlugin.getDeviceId();
  }

  getFeatureFlag(id: string): Promise<FeatureFlag | null | undefined> {
    return BrazePlugin.getFeatureFlag(id);
  }

  // Not needed for mobile platform
  initialize(_apiKey: string, _options: InitializationOptions): boolean {
    return true;
  }

  logCustomEvent(eventName: string, eventProperties?: object): boolean {
    return BrazePlugin.logCustomEvent(eventName, eventProperties);
  }

  logFeatureFlagImpression(featureFlagId: string): boolean | undefined {
    return BrazePlugin.logFeatureFlagImpression(featureFlagId);
  }

  // Not needed for mobile platform
  openSession(): void {
    return;
  }

  setEmail(email: string): boolean {
    return BrazePlugin.setEmail(email);
  }

  subscribeToFeatureFlagsUpdates(
    subscriber: (featureFlags: FeatureFlag[]) => void
  ): string | undefined {
    // Note: the name for the Cordova plugin is singular, do not use plural as in Web SDK
    return BrazePlugin.subscribeToFeatureFlagUpdates(subscriber);
  }
}

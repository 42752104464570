import { useEffect, useState } from "react";

import ReactGA from "react-ga";
import GoogleTagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { socket } from "socket";
import { store } from "store/store";
import { Capacitor } from "@capacitor/core";
import { AuthProvider } from "context/AuthProvider";
import brazeClient from "utils/braze";
import { AppStateProvider } from "context/AppStateProvider";
import { initializeAmplitude } from "utils/amplitude";
import * as Sentry from "@sentry/react";
import Preloader from "components/common/preloader";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import AppRouter from "./router";
import setSafeArea from "./utils/safe-area";

enum AmplitudeStatus {
  Loading = "loading",
  Failed = "failed",
  Ok = "ok",
}

const App: React.FC = () => {
  const isNative = Capacitor.isNativePlatform();
  const [isBrazeInitialized, setBrazeInitialized] = useState(false);
  const [amplitudeStatus, setAmplitudeStatus] = useState(
    AmplitudeStatus.Loading
  );

  useEffect(() => {
    ReactGA.initialize("G-FWJLFRE40K");
    GoogleTagManager.initialize({ gtmId: "GTM-MR7H4HJ" });
    if (isNative) {
      setSafeArea();
      const body = document.querySelector("body");

      if (body) {
        body.classList.add("capacitor");
      }
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (!isBrazeInitialized) {
        const initSuccess = brazeClient.initialize(
          import.meta.env.VITE_BRAZE_WEB_API_KEY,
          {
            baseUrl: import.meta.env.VITE_BRAZE_API_ENDPOINT,
            enableLogging: process.env.NODE_ENV !== "production",
          }
        );

        console.log(`Braze initialized correctly: ${initSuccess}`);
        setBrazeInitialized(initSuccess);
      }
    };

    initialize();
  }, [setBrazeInitialized, isBrazeInitialized]);

  useEffect(() => {
    const initialize = async () => {
      if (amplitudeStatus !== AmplitudeStatus.Ok) {
        try {
          // Sync deviceID between Braze and Amplitude
          await initializeAmplitude(brazeClient.getDeviceId());

          setAmplitudeStatus(AmplitudeStatus.Ok);
        } catch (e: any) {
          console.log(`Amplitude not initialized: ${e}`);
          setAmplitudeStatus(AmplitudeStatus.Failed);
        }
      }
    };

    initialize();
  }, [setAmplitudeStatus, amplitudeStatus]);

  if (amplitudeStatus === AmplitudeStatus.Loading) {
    return <Preloader />;
  }

  return (
    <Provider store={store}>
      <AppStateProvider value={{ isBrazeInitialized }}>
        <AuthProvider>
          <SkeletonTheme
            baseColor="var(--slate300)"
            highlightColor="var(--slate100)"
          >
            <AppRouter />
          </SkeletonTheme>
        </AuthProvider>
      </AppStateProvider>
    </Provider>
  );
};

export default Sentry.withProfiler(App);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PricesState {
  subscribeList: string[];
}

export const initialState: PricesState = {
  subscribeList: [],
};

const capitalPricesSlice = createSlice({
  name: "capitalPrices",
  initialState,
  reducers: {
    setSubscribeList(state, action: PayloadAction<string[]>) {
      state.subscribeList = action.payload;
    },
    addTickerToSubscribeList(state, action: PayloadAction<string>) {
      if (state.subscribeList.includes(action.payload)) return;
      state.subscribeList = [...state.subscribeList, action.payload];
    },
    clearSubscribeList(state) {
      state.subscribeList = initialState.subscribeList;
    },
  },
});

export const {
  setSubscribeList,
  clearSubscribeList,
  addTickerToSubscribeList,
} = capitalPricesSlice.actions;

export default capitalPricesSlice.reducer;

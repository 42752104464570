import { Cryptos, getCrypto, CRYPTOS } from "constants/cryptos";

import px2rem from "utils/px2rem";

import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export interface AccountRowProps {
  name: string;
  accountId: string;
  currencies: Cryptos[];
  onClick?: () => void;
}

const AccountRow: React.FC<AccountRowProps> = ({
  name,
  accountId,
  currencies,
  onClick,
}) => {
  const firstSix = accountId.substring(0, 6);
  const lastSix = accountId.substring(accountId.length - 6);

  currencies.sort(
    (a, b) =>
      CRYPTOS.findIndex((crypto) => crypto.ticker === a) -
      CRYPTOS.findIndex((crypto) => crypto.ticker === b)
  );

  const shortId = `${firstSix}...${lastSix}`;
  return (
    <div className={styles.accountRowContainer} onClick={onClick}>
      <div className={styles.topRow}>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {name}
        </Text>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {shortId}
        </Text>
      </div>
      <div className={styles.cryptoList}>
        {currencies.map((currency) => {
          const { icon: Icon, ticker } = getCrypto(currency);

          return <Icon key={ticker} size={px2rem(16)} />;
        })}
      </div>
    </div>
  );
};

export default AccountRow;

import classNames from "classnames";
import { IterationCw, Minus, X } from "lucide-react";
import { CardTransactionStatus } from "interfaces/card/enums";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ActivityBadgeProps {
  transactionStatus: CardTransactionStatus;
}

const ActivityBadge: React.FC<ActivityBadgeProps> = ({ transactionStatus }) => {
  const isRefund = transactionStatus === CardTransactionStatus.REFUND;
  const isRejected = transactionStatus === CardTransactionStatus.REJECTED;

  const getTransactionIcon = () => {
    if (isRefund) {
      return IterationCw;
    }
    if (isRejected) {
      return X;
    }

    return Minus;
  };

  const Icon = getTransactionIcon();

  return (
    <div
      className={classNames(styles.activityBadgeContainer, {
        [styles.refund]: isRefund,
        [styles.rejected]: isRejected,
      })}
    >
      {<Icon color="var(--white-to-black)" size={px2rem(10)} />}
    </div>
  );
};

export default ActivityBadge;

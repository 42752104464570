import { getCrypto } from "constants/cryptos";

import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import API from "apis";
import { cryptoService } from "apis/services";
import { ThemeVariants } from "interfaces/theme";
import Balance from "components/common/Balance";
import ChipCarrousel from "components/common/ChipCarrousel";
import LoadingSpinner from "components/common/LoadingSpinner";
import SectionTitle from "components/common/SectionTitle";
import { CryptoPortfolio } from "interfaces/api-responses";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes, cryptoScreenNames } from "router/routes";
import { Projects, trackAction } from "utils/amplitude";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const PortfolioSection: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [portfolioData, setPortfolioData] = useState<CryptoPortfolio>();
  const navigate = useNavigate();

  useEffect(() => {
    const getPortfolioData = async () => {
      try {
        const { data } = await API.get<CryptoPortfolio>(
          cryptoService.portfolio
        );

        setPortfolioData(data);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getPortfolioData();
  }, []);

  if (isLoading)
    return (
      <div className={styles.loaderWrapper}>
        <LoadingSpinner variant={ThemeVariants.Crypto} size={px2rem(100)} />
      </div>
    );

  if (error || !portfolioData)
    return <Typography>Ups.. hubo un error</Typography>;

  portfolioData.holdings.sort((a, b) => b.amountArs - a.amountArs);

  const chipList = portfolioData.holdings.map(({ quantity, ticker }) => {
    const { icon } = getCrypto(ticker);
    return {
      icon,
      primaryText: quantity,
      secondaryText: ticker,
    };
  });

  const onClickPortfolio = () => {
    trackAction(
      `${cryptoScreenNames.home} - Click Portfolio`,
      undefined,
      Projects.CRYPTO
    );

    navigate(CryptoRoutes.PORTFOLIO);
  };

  return (
    <div className={styles.portfolioSectionWrapper}>
      <div className={styles.wrapper}>
        <SectionTitle label="Portfolio" onClick={onClickPortfolio} />
        <Balance
          available={portfolioData.total}
          variation={portfolioData.varTotalPercentage}
          amount={portfolioData.varTotal}
        />
      </div>

      {!isEmpty(chipList) && <ChipCarrousel list={chipList} />}
    </div>
  );
};

export default PortfolioSection;

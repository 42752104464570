import { Mail } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import BasicWrapper from "components/common/BasicWrapper";
import { trackAction } from "utils/amplitude";

const ContactSupport = () => {
  const navigate = useNavigate();

  const title = "Te vamos a contactar vía mail";
  const subtitle =
    "Para cambiar tu número de teléfono móvil te vamos a enviar un mail con los pasos a seguir.";

  const handleCloseClick = () => {
    trackAction("phonevalidation_contact_support_exit");
    navigate(mainRoutes.home);
  };

  const goToHome = () => {
    trackAction("phonevalidation_chose_later");
    navigate(mainRoutes.home);
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Cambiar número",
        onClick: handleCloseClick,
      }}
      icon={Mail}
      title={title}
      subtitle={subtitle}
      secondaryButtonProps={{ children: "Ir al inicio", onClick: goToHome }}
    />
  );
};

export default ContactSupport;

import { IconProps } from "assets/icons/BTC";
import px2rem from "utils/px2rem";

const Mail: React.FC<IconProps> = ({
  width = px2rem(72),
  height = px2rem(96),
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3435_28968)">
      <mask
        id="mask0_3435_28968"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="52"
        width="72"
        height="44"
      >
        <path
          d="M0.437501 52.2637V92.3148C0.437189 92.7986 0.532202 93.2777 0.71711 93.7247C0.902017 94.1717 1.1732 94.5779 1.51515 94.9201C1.8571 95.2623 2.26313 95.5337 2.71003 95.7189C3.15692 95.9041 3.63593 95.9994 4.11969 95.9994H67.881C68.3652 96 68.8447 95.9052 69.2921 95.7203C69.7396 95.5353 70.1461 95.264 70.4886 94.9218C70.8311 94.5795 71.1027 94.1731 71.2879 93.7258C71.4731 93.2785 71.5682 92.799 71.5679 92.3148V52.2637H0.437501Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_3435_28968)">
        <path
          d="M36.0003 74.1292L0.4375 52.2637H71.5632L36.0003 74.1292Z"
          fill="#113264"
        />
        <path
          d="M36.0003 74.1315L0.4375 52.2637V95.9994H71.5632V52.2637L36.0003 74.1315Z"
          fill="#0588F0"
        />
      </g>
      <path
        d="M63.4375 1.38385e-06H8.56272C6.40771 1.38385e-06 4.34097 0.856075 2.81715 2.3799C1.29333 3.90372 0.437256 5.97046 0.437256 8.12547L0.437256 36.8313C0.437256 38.9863 1.29333 41.0531 2.81715 42.5769C4.34097 44.1007 6.40771 44.9568 8.56272 44.9568H29.8687L36.0025 57.9049L42.1339 44.9568H63.4398C64.5071 44.9571 65.564 44.7472 66.5501 44.339C67.5362 43.9308 68.4322 43.3323 69.187 42.5777C69.9417 41.8232 70.5404 40.9274 70.9489 39.9414C71.3574 38.9554 71.5677 37.8986 71.5677 36.8313V8.12547C71.5677 7.05802 71.3574 6.00103 70.9487 5.01489C70.5401 4.02875 69.9411 3.1328 69.1861 2.37822C68.4311 1.62364 67.5348 1.02522 66.5484 0.617154C65.562 0.20909 64.5049 -0.000621581 63.4375 1.38385e-06Z"
        fill="url(#paint0_linear_3435_28968)"
      />
      <g opacity="0.15">
        <path
          d="M22.6921 25.8828C24.4272 25.8828 25.8337 24.4763 25.8337 22.7412C25.8337 21.0061 24.4272 19.5996 22.6921 19.5996C20.9571 19.5996 19.5505 21.0061 19.5505 22.7412C19.5505 24.4763 20.9571 25.8828 22.6921 25.8828Z"
          fill="#1C2024"
        />
        <path
          d="M36.1382 25.8828C37.8732 25.8828 39.2798 24.4763 39.2798 22.7412C39.2798 21.0061 37.8732 19.5996 36.1382 19.5996C34.4031 19.5996 32.9966 21.0061 32.9966 22.7412C32.9966 24.4763 34.4031 25.8828 36.1382 25.8828Z"
          fill="#1C2024"
        />
        <path
          d="M49.5842 25.8828C51.3193 25.8828 52.7258 24.4763 52.7258 22.7412C52.7258 21.0061 51.3193 19.5996 49.5842 19.5996C47.8492 19.5996 46.4426 21.0061 46.4426 22.7412C46.4426 24.4763 47.8492 25.8828 49.5842 25.8828Z"
          fill="#1C2024"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3435_28968"
        x1="68.4213"
        y1="-9.94403"
        x2="12.1572"
        y2="46.3201"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E6EAF4" />
        <stop offset="0.82" stopColor="#D1D5E0" />
      </linearGradient>
      <clipPath id="clip0_3435_28968">
        <rect
          width="71.1257"
          height="96"
          fill="white"
          transform="translate(0.437256)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Mail;

import { useState } from "react";

import Text, { TextVariants } from "components/common/Text";

import AccordionRow from "./Row";

import styles from "./styles.module.scss";

export type AccordionItemProps = {
  label: string;
  detail: string | React.ReactNode;
};

const AccordionItem: React.FC<AccordionItemProps> = ({ label, detail }) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);

  return (
    <div className={styles.accordionItemContainer}>
      <AccordionRow label={label} onClick={setShowDetail} />
      {showDetail && (
        <div className={styles.detail}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {detail}
          </Text>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
